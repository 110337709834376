// import { Container, Row, Col } from 'react-bootstrap';
// import AppLayout from './AppLayout';
// import { Collapse, Divider } from 'antd';

import { Collapse, CollapseBody, CollapseHeader } from 'accordion-collapse-react-native';
import React, { memo, useEffect } from 'react';

import DividerElement from '../elements/Divider';
import TextElement from '../elements/Text';
import { View } from 'react-native';
import ViewWithBack from '../elements/ViewWIthScroll';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import style from './Style';


const Learning = () => {
    const navigation = useNavigation();
    const props = { navigation };
    
    const isFocused = useIsFocused();
    const [expanded, setExpanded] = React.useState([false, false, false, false]);
    useEffect(() => {
        setExpanded([false, false, false, false]);
    }, [isFocused]);

    const handleCollapse = (isExpanded, index) => {
        let temp = expanded;
        temp[index] = isExpanded;
        setExpanded(temp);
    };

    return (

        <ViewWithBack pageName="FAQ"  {...props} header={true}
            backAction={() => {
                props.navigation.navigate('Help');
            }}
        >
            <View style={style.container}>
                <Collapse isExpanded={expanded[0]} onToggle={(isExpanded) => {
                    handleCollapse(isExpanded, 0);
                }}>
                    <CollapseHeader>
                        <View style={style.headerContainer}>
                            <TextElement theme={props.theme} style={style.headerText}>
                                What is role of a mentor?
                            </TextElement>
                        </View>
                    </CollapseHeader>
                    <CollapseBody>
                        <View style={style.body}>
                            <View style={style.container}>
                                <TextElement theme={props.theme}>As a mentor you will guide and support your mentee to become the best version of themselves.  At various points in the mentoring journey, you will adopt the following roles:{'\n'}{'\n'}</TextElement>
                                <TextElement theme={props.theme}>Trusted advisor.{'\n'}{'\n'}</TextElement>
                                <TextElement theme={props.theme}>Understand your mentee’s development needs. </TextElement>
                                <TextElement theme={props.theme}>Work with them to establish realistic yet challenging goals.</TextElement>
                                <TextElement theme={props.theme}>Give advice and guidance, share your ideas and feedback.</TextElement>
                                <TextElement theme={props.theme}>Share your experiences on what helped you to succeed.</TextElement>
                                <TextElement theme={props.theme}>Challenge your mentee to look for personal strengths and weaknesses.</TextElement>
                                <TextElement theme={props.theme}>Respect what you have agreed to keep confidential.</TextElement>
                                <TextElement theme={props.theme}>Be straightforward.{'\n'}{'\n'}</TextElement>

                                <TextElement theme={props.theme}>Motivator. {'\n'}{'\n'}</TextElement>
                                <TextElement theme={props.theme}>Provide positive reinforcement and encouragement.</TextElement>
                                <TextElement theme={props.theme}>Act as a sounding board for ideas and approaches.</TextElement>
                                <TextElement theme={props.theme}>Ask open ended questions.</TextElement>
                                <TextElement theme={props.theme}>Listen without judgment or bias.</TextElement>
                                <TextElement theme={props.theme}>Share insight into potential opportunities. {'\n'}{'\n'}</TextElement>

                                <TextElement theme={props.theme}>Encourage a growth mindset. {'\n'}{'\n'}</TextElement>
                                <TextElement theme={props.theme}>Discuss the big picture of what your mentee wants to achieve and share your perspective.</TextElement>
                                <TextElement theme={props.theme}>Share personal stories of successes and failures.</TextElement>
                                <TextElement theme={props.theme}>Identify resources to help your mentee.</TextElement>
                                <TextElement theme={props.theme}>Provide helpful guidance that your mentee may not have considered.{'\n'}{'\n'}</TextElement>

                                <TextElement theme={props.theme}>Be supportive.{'\n'}{'\n'}</TextElement>
                                <TextElement theme={props.theme}>Endorse your mentee whenever the opportunity arises.</TextElement>
                                <TextElement theme={props.theme}>Expand your mentee’s sphere of influence.</TextElement>
                                <TextElement theme={props.theme}>Help to create opportunities for stretch experiences.</TextElement>
                                <TextElement theme={props.theme}>Provide helpful contacts and resources.</TextElement>
                                <TextElement theme={props.theme}>Help with decision making that progresses their development.{'\n'}{'\n'}</TextElement>

                                <TextElement theme={props.theme}>Play devil’s advocate.{'\n'}{'\n'}</TextElement>
                                <TextElement theme={props.theme}>Encourage critical thinking.</TextElement>
                                <TextElement theme={props.theme}>Invite new ways of working and thinking.</TextElement>
                            </View>
                        </View>
                    </CollapseBody>
                </Collapse>
                <DividerElement theme={props.theme} height={10} />
                <Collapse isExpanded={expanded[1]} onToggle={(isExpanded) => {
                    handleCollapse(isExpanded, 1);
                }}>
                    <CollapseHeader>
                        <View style={style.headerContainer}>
                            <TextElement theme={props.theme} style={style.headerText}>
                                What is role of a mentee?
                            </TextElement>
                        </View>
                    </CollapseHeader>
                    <CollapseBody>
                        <View style={style.body}>
                            <View style={style.container}>
                                <TextElement theme={props.theme}>As a mentee, you will need to play a number of different roles during your mentoring journey.  the following are some of those roles:{'\n\n'}</TextElement>
                                <TextElement theme={props.theme}>Drive the relationship. {'\n'}{'\n'}</TextElement>
                                <TextElement theme={props.theme}>Be proactive throughout the mentoring process.</TextElement>
                                <TextElement theme={props.theme}>Agree on the preferred method of communication and stick to it.</TextElement>
                                <TextElement theme={props.theme}>Schedule meetings and prepare an agenda for each session.</TextElement>
                                <TextElement theme={props.theme}>Consider goals that you wish to achieve and discuss these with your mentor.</TextElement>
                                <TextElement theme={props.theme}>Think about and bring new topics for discussion to the table. {'\n\n'}</TextElement>

                                <TextElement theme={props.theme}>Plan well. {'\n'}{'\n'}</TextElement>
                                <TextElement theme={props.theme}>Work with your mentor to create a mentoring plan with goals, development activities and timelines.</TextElement>
                                <TextElement theme={props.theme}>Prioritise goals in a meaningful manner.</TextElement>
                                <TextElement theme={props.theme}>Plan time for yourself to deliver what you have committed to.</TextElement>
                                <TextElement theme={props.theme}>Be clear about what you need and the support you want from your mentor.{'\n\n'}</TextElement>

                                <TextElement theme={props.theme}>Work together. {'\n'}{'\n'}</TextElement>
                                <TextElement theme={props.theme}>Stay in touch with your mentor and keep them informed on your progress.</TextElement>
                                <TextElement theme={props.theme}>Be present and listen well.</TextElement>
                                <TextElement theme={props.theme}>Work with your mentor to identify learning and development resources.{'\n\n'}</TextElement>

                                <TextElement theme={props.theme}>Give back. {'\n'}{'\n'}</TextElement>

                                <TextElement theme={props.theme}>Share information that you think your mentor may find valuable.</TextElement>
                                <TextElement theme={props.theme}>Maintain respect and consider your line manager.</TextElement>
                                <TextElement theme={props.theme}>Consider opportunities on how you can give back to your mentor.{'\n\n'}</TextElement>

                                <TextElement theme={props.theme}>Learn. {'\n'}{'\n'}</TextElement>
                                <TextElement theme={props.theme}>Take responsibility for your own learning and development. </TextElement>
                                <TextElement theme={props.theme}>Be fully committed to the learning process.</TextElement>
                                <TextElement theme={props.theme}>Keep an open mind. </TextElement>
                                <TextElement theme={props.theme}>Receive feedback and coaching graciously. </TextElement>
                                <TextElement theme={props.theme}>Be passionate about your development.</TextElement>
                                <TextElement theme={props.theme}>Ask questions to clarify.</TextElement>
                            </View>
                        </View>
                    </CollapseBody>
                </Collapse>
                <DividerElement theme={props.theme} height={10} />
                <Collapse isExpanded={expanded[2]} onToggle={(isExpanded) => {
                    handleCollapse(isExpanded, 2);
                }}>
                    <CollapseHeader>
                        <View style={style.headerContainer}>
                            <TextElement theme={props.theme} style={style.headerText}>
                                How to help your mentee to develop a growth mindset?
                            </TextElement>
                        </View>
                    </CollapseHeader>
                    <CollapseBody>
                        <View style={style.body}>
                            <View style={style.container}>
                                <TextElement theme={props.theme}>
                                    Embarking on the mentoring journey with the right mindset is paramount! We may feel that we have equipped ourselves with all we need to know and do to achieve success, but what about your mentee? So how can you help your mentee develop a growth mindset, and why is this important?{'\n\n'}
                                    Mentee’s can benefit from developing a growth mindset as much as you or I can, if not more! Dealing with pressure and competition are just some of the things that separate the good performers from the great ones. During the mentoring journey, show your mentee how developing a growth mindset will benefit them now and in any future endeavours.
                            </TextElement>
                                <TextElement theme={props.theme}>
                                    Let’s recap Fixed mindset vs. Growth mindset {'\n\n'}
                                </TextElement>
                                <TextElement theme={props.theme}>
                                    A growth mindset is the understanding that personal qualities and abilities can change utilizing an input-output mechanism. If we put in the effort to develop our intelligence, we will likely see good results. Our formal schooling built our knowledge and understanding, but the learning process did not stop there. Developing a growth mindset is a never-ending process of learning-implementing-repeat. It is the belief that we can develop our intelligence and abilities continually.
                                {'\n\n'}
                                    A fixed mindset is an idea that our intelligence and abilities are fixed at one point and can’t develop or grow. If we hesitate to accept that a growth mindset exists and need development, we jeopardize our ability to change the more important things in life. This means that when we face changing circumstances or a challenge, we tell ourselves: I can’t do this! In reality, we are closing ourselves to the ability to develop and empower ourselves. We should be asking ourselves: what can I do to make this happen? Once a growth mindset starts to advance within us, we can now use it to empower, motivate and change others. A growth mindset is a valuable tool that your mentee needs to develop for success! Your mentoring sessions are a good opportunity for you to instill these concepts. During your sessions, you can focus on specific problems that a mentee has and show them how to apply a growth mindset to each situation. {'\n\n'}
                                </TextElement>
                                <TextElement theme={props.theme}>
                                    Start with yourself. {'\n\n'}
                                </TextElement>
                                <TextElement theme={props.theme}>
                                    Your mindset will strongly influence your mentee’s mindset. To acquire the growth mindset for yourself, focus on strategies that will help shift your mind toward the belief that anything can be accomplished, helping you reach your full potential. Apply the same approach that you have encouraged your mentee to follow. That may require a good honest look at your mindset. As you come face to face with your weaknesses, you will find ways to acknowledge and embrace your imperfections. Turn those into learning opportunities. As we all are different, you may need to explore various strategies to find the one that works for you. Do your research. If you share your progress and ideas with others, you will be welcoming the opportunity for growth.
                                {'\n\n'}
                                    Take time to reflect on the positive changes that you have made in your mindset and build your confidence in the process of change. As you notice the changes taking effect, set new achievable goals. Never stop growing! Realise that your goals need to be realistic. You are not going to master the process in one go. Now that you are developing a growth mindset, own it! It’ll help you become a great mentor as you look for ways to help others improve. Your mentoring sessions will become more about really helping your mentee grow and develop. You are now ready to impart that growth mindset to your mentee. {'\n\n'}
                                </TextElement>
                                <TextElement theme={props.theme}>
                                    Introducing a growth mindset to your mentee. {'\n\n'}
                                </TextElement>
                                <TextElement theme={props.theme}>
                                    It is vital to introduce the idea of a growth mindset to your mentee because if they can understand it, they can easily learn how to apply it. Explain the theory to them and help them understand the science behind a mindset. This will help them approach every challenge with a growth mindset. You may even want to sit down and discuss some of the research with them so that they can understand the process.
                                {'\n\n'}
                                    Once you have helped your mentee understand how amazing our brains are programmed, connect the science to specific behaviour patterns that help them learn. Perhaps you can now ask them: ‘how will thinking like this help you work through a challenge? How can you use the growth mindset to achieve your development objectives?’ Helping the mentee understand a growth mindset is a great way to counteract some of the negative self-talk and focus on positive ‘I can’ outcomes. Some mentees may grasp the concept quicker than others. Here are some great ideas that can help you conduct effective mentoring sessions. {'\n\n'}
                                </TextElement>
                                <TextElement theme={props.theme}>
                                    Your mentee already has a mindset. {'\n\n'}
                                </TextElement>
                                <TextElement theme={props.theme}>
                                    A mentee needs to see that they are already displaying a mindset, either fixed or growth, in their reactions to certain situations. Specific examples will help them feel good about what they are doing to develop and highlight areas where they may need improvement. Perhaps your mentee has a role model that they look up to. Being interested in others’ work shows a growth mindset as they are aspiring to be like someone. Or if they feel like they have perhaps failed at a project or objective, this would be displaying a fixed mindset.
                                {'\n\n'}
                                    Next would be to show them how mindset affects the effort they put in. An effort is needed to do well with a goal or objective. A mindset affects not only the output but also what effort they put in. Any effort put in to do better or show greater interest would be displaying a growth mindset. Not showing an interest in mentoring meetings or preparing topics or ‘homework’ in advance might display a fixed mindset. {'\n\n'}
                                </TextElement>
                                <TextElement theme={props.theme}>
                                    When challenges come along {'\n\n'}
                                </TextElement>
                                <TextElement theme={props.theme}>
                                    New challenges will arise for your mentee that they may not know how to handle. Discuss various approaches that they can take to apply a growth mindset to each challenge. From the outset, show your mentee that challenges are healthy. Even if that challenge distorts the way they see themselves, there is always a silver lining to each problem they overcome. For example, if they didn’t get selected for a promotion, they could think: “I thought I was good enough; I am not.” Here you can help your mentee see things from a different perspective.
                                {'\n\n'}
                                    Perhaps they could have approached the preparation better, or that a little more work needs to be done to be successful in the next promotion or perhaps they need to gain experience in other areas to boost chances of success for the next promotion. Understanding that a growth mindset values effort will help them receive setbacks with grace and turn stress and thoughts of giving in; toward positive endeavours and achievement. Continual praise for their persistence at a challenge is necessary. If one way of doing something didn’t work, try a different way. And if that didn’t work, keep repeating the process till it becomes a natural process for your mentee. A growth mindset shows them that challenges should be transformed into opportunities, instead of rigidly becoming stuck in the thought rut of ‘I am a failure.’ Give them practical steps that can help them. For example, help them set out a detailed plan of how to overcome the problem. {'\n\n'}
                                </TextElement>
                                <TextElement theme={props.theme}>
                                    It’s harder for some. {'\n\n'}
                                </TextElement>
                                <TextElement theme={props.theme}>
                                    Not everybody embraces change quite the same way. Our thoughts and actions may have gotten stuck in a little bit of a fixed mindset over time without us even knowing. Our backgrounds, work environments, and other external factors play a big part in how we perceive ourselves and the future. Not everyone can look at life through rose coloured glasses. Some also struggle with negative thoughts or perhaps take criticism harshly. We all then face an environment where developing a growth mindset can be difficult. But remember, negative ideas and how we perceive the world around us are just mindsets! With some effort, a fixed mindset can be changed into a growth mindset. We all can adopt the attitude that we choose! We must start somewhere, right? Knowing that a change is possible at any stage is a good start. Thomas Edison even understood that failure was part of the process when he said, “I have not failed.
                                {'\n\n'}
                                    I’ve just found 10,000 ways that won’t work”. So ask yourself: “What can I change? How can I change it?” Then begin to write down the steps you need to take. Follow them closely and review them often. Gradually this process will become easier as you see the change in your mindset. To conclude, there are real-world implications where having a growth mindset approach is advantageous, whether you are the mentor or the mentee. The key is to focus on changing one small thing in a mindset, this habit of mindset change will then cascade into other areas of life. If you want to live more productively and purposefully, re-examine your own and your mentee’s mindset to see if it’s serving its purpose well! Developing a growth mindset in your mentee is crucial now! It is not a complicated process but easy to accomplish if you take one step at a time.
                                {'\n\n'}
                                    Start with yourself and see how effective change can be. Then show your mentee how they can begin changing their mindset. Use practical examples of challenges that they are facing now as an opportunity to teach how to apply a growth mindset. Remember that it will be harder for some. With effort, anyone can change their fixed mindset to a growth mindset.
                            </TextElement>
                            </View>
                        </View>
                    </CollapseBody>
                </Collapse>
                <DividerElement theme={props.theme} height={10} />
                <Collapse isExpanded={expanded[3]} onToggle={(isExpanded) => {
                    handleCollapse(isExpanded, 3);
                }}>
                    <CollapseHeader>
                        <View style={style.headerContainer}>
                            <TextElement theme={props.theme} style={style.headerText}>
                                How to lead as a mentee?
                            </TextElement>
                        </View>
                    </CollapseHeader>
                    <CollapseBody>
                        <View style={style.body}>
                            <View style={style.container}>
                                <TextElement theme={props.theme}>
                                    LEADX is a mentee led programme where you drive your own development.{'\n\n'}
                                </TextElement>
                                <TextElement theme={props.theme}>
                                    Before the meeting: {'\n\n'}
                                </TextElement>
                                <TextElement theme={props.theme} style={style.leftShift}>
                                    Check out your mentor’s LinkedIn page and recent blogs.
                                </TextElement>
                                <TextElement theme={props.theme} style={style.leftShift}>
                                    Share via chat some summary info about your career, where you want to go and how they help you get there.
                                </TextElement>
                                <TextElement theme={props.theme} style={style.leftShift}>
                                    Think about your goals for this mentorship.
                                </TextElement>
                                <TextElement theme={props.theme} style={style.leftShift}>
                                    Think about how your mentor may be able to help you. {'\n\n'}
                                </TextElement>
                                <TextElement theme={props.theme}>
                                    During your first meeting: {'\n\n'}
                                </TextElement>
                                <TextElement theme={props.theme} style={style.leftShift}>
                                    Agree how often you want to meet, when, where and how.
                                </TextElement>
                                <TextElement theme={props.theme} style={style.leftShift}>
                                    Discuss your goals clearly, timelines and any challenges that you foresee.
                                </TextElement>
                                <TextElement theme={props.theme} style={style.leftShift}>
                                    Ask for your mentor’s feedback and suggestions.
                                </TextElement>
                                <TextElement theme={props.theme} style={style.leftShift}>
                                    Say thank you. {'\n\n'}
                                </TextElement>
                                <TextElement theme={props.theme}>
                                    After the first meeting: {'\n\n'}
                                </TextElement>
                                <TextElement theme={props.theme} style={style.leftShift}>
                                    Keep a journal to capture meeting notes, actions, insights and more.
                                </TextElement>
                                <TextElement theme={props.theme} style={style.leftShift}>
                                    Take action before the next meeting – do your homework!
                                </TextElement>
                                <TextElement theme={props.theme} style={style.leftShift}>
                                    Send calendar invitations so that there are always three future meetings in the diary. {'\n\n'}
                                </TextElement>
                                <TextElement theme={props.theme}>
                                    Future meetings: {'\n\n'}
                                </TextElement>
                                <TextElement theme={props.theme} style={style.leftShift}>
                                    Prepare an agenda for each meeting.
                                </TextElement>
                                <TextElement theme={props.theme} style={style.leftShift}>
                                    Plan your questions in advance.
                                </TextElement>
                                <TextElement theme={props.theme} style={style.leftShift}>
                                    If you have questions that you would like to ask your mentor, send them the questions in advance so that they have time to reflect and consider.
                                </TextElement>
                                <TextElement theme={props.theme} style={style.leftShift}>
                                    Be specific about what you want to discuss.
                                </TextElement>
                            </View>
                        </View>
                    </CollapseBody>
                </Collapse>
            </View>
        </ViewWithBack>
    );
};

export default memo(Learning, (prevProps, nextProps) => {
    if (prevProps.theme !== nextProps.theme)
        return false;
    else
        return true;
});