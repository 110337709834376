// This file will contain the routing logic

import * as Linking from 'expo-linking';

import AddContent from '../components/journey/designer/AddContent';
import AddNewFeedback from '../components/feedback/AddNewFeedback';
import AddTopic from '../components/journey/designer/AddTopic';
import AdminFeedbacks from '../components/feedback/AdminFeedbacks';
import AdminJourneyStats from '../components/journey/adminStats';
// import AdminSurveys from '../components/mentorshipSurvey/Surveys/admin/adminSurveys';
import Assessment from '../components/journey/learner/Assessment';
import Auth from '../components/auth/Auth';
import CompleteProfile from '../components/ProfileComplete';
import CreateGoal from '../components/goals/createGoal/CreateGoal';
import DesignerDashboard from '../components/journey/designer/DesignerDashboard';
import DesignerHome from '../components/journey/designer/DesignerHome';
import DesignerJourney from '../components/journey/designer/DesignerJourney';
import Details from '../components/details/Details';
import Feedback from '../components/feedback/Feedback';
import Help from '../components/help/Help';
import Learning from '../components/learning/Learning';
import Login from '../components/login/Login';
import Logout from '../components/logout/Logout';
import MenteeJourneyStats from '../components/journey/menteeStats';
import Module from '../components/journey/designer/Module/Module';
import ModuleForm from '../components/journey/designer/Module/ModuleForm';
import { NavigationContainer } from '@react-navigation/native';
import NotificationDetail from '../components/Notifications/NotificationDetail';
import Notifications from '../components/Notifications';
import ProfileEdit from '../components/profile/ProfileEdit';
import ReviewJourney from '../components/journey/designer/ReviewJourney';
import Role from '../components/userManagement/Role/Role';
import RoleForm from '../components/userManagement/Role/RoleForm';
import Roles from '../components/userManagement/Role/Roles';
import Space from '../components/journey/designer/Space/Space';
import SpaceForm from '../components/journey/designer/Space/SpaceForm';
import Spaces from '../components/journey/designer/Space/Spaces';
// import Survey from '../components/mentorshipSurvey/Survey/Survey';
// import SurveyForm from '../components/mentorshipSurvey/Form/SurveyForm';
// import Surveys from '../components/mentorshipSurvey/Surveys/pendingSurveys/pendingSurveys';
// import SurveysList from '../components/mentorshipSurvey/Surveys/admin/surveyList';
import TabNavigator from './TabNavigator';
import Terms from '../components/terms/Terms';
import Surveys from '../components/surveys';
import SurveyDetails from '../components/surveys/SurveyDetails';
import Topic from '../components/journey/designer/Topic';
import { View } from 'react-native';
import ViewFeedback from '../components/feedback/ViewFeedback';
import ViewGoals from '../components/goals/viewGoals/ViewGoal';
import Welcome from '../components/welcome/Welcome';
import WelcomeSlide from '../components/welcome/WelcomeSlide';
import WelcomeSlide2 from '../components/welcome/WelcomeSlide2';
import WelcomeSlide3 from '../components/welcome/WelcomeSlide3';
import WelcomeSlide4 from '../components/welcome/WelcomeSlide4';
import { createStackNavigator } from '@react-navigation/stack';
import SurveyForm from '../components/surveys/Form/SurveyForm';

const prefix = Linking.createURL('/');
const Stack = createStackNavigator();

const linking = {
  prefixes: [prefix],
  config: {
    Auth: {
      path: 'Auth/:code',
      parse: {
        id: (code) => `${code}`,
      },
    },
  },
};

const Main = (props) => {
  // console.log("main props", props);
  const properties = props.properties;
  // console.log("main properties", properties);

  return (
    <NavigationContainer linking={linking} fallback={<View />}>
      <Stack.Navigator initialRouteName='Login' headerMode='none'>
        <Stack.Screen
          name='Home'
          children={(props) => <TabNavigator {...props} properties={properties} />}
        />
        <Stack.Screen
          key='Terms & Conditions'
          name='Terms & Conditions'
          component={Terms}
          initialParams={{ ...properties }}
        />

        <Stack.Screen
          key='Surveys'
          name='Surveys'
          component={Surveys}
          initialParams={{ ...properties }}
        />
        <Stack.Screen
          key='Surveydetails'
          name='Surveydetails'
          component={SurveyDetails}
          initialParams={{ ...properties }}
        />

        <Stack.Screen
          key='Adminjourneystats'
          name='Adminjourneystats'
          component={AdminJourneyStats}
          initialParams={{ ...properties }}
        />
        <Stack.Screen
          key='Menteejourneystats'
          name='Menteejourneystats'
          component={MenteeJourneyStats}
          initialParams={{ ...properties }}
        />
        <Stack.Screen
          key='Welcome'
          name='Welcome'
          component={Welcome}
          initialParams={{ ...properties }}
        />
        <Stack.Screen
          key='Viewgoal'
          name='Viewgoal'
          component={ViewGoals}
          initialParams={{ ...properties }}
        />
        <Stack.Screen
          key='Profileedit'
          name='Profileedit'
          component={ProfileEdit}
          initialParams={{ ...properties }}
        />
        <Stack.Screen
          key='Login'
          name='Login'
          component={Login}
          initialParams={{ ...properties }}
        />
        <Stack.Screen
          key='Creategoal'
          name='Creategoal'
          component={CreateGoal}
          initialParams={{ ...properties }}
        />
        <Stack.Screen
          key='Completeprofile'
          name='Completeprofile'
          component={CompleteProfile}
          initialParams={{ ...properties }}
        />
        <Stack.Screen key='Auth' name='Auth' component={Auth} initialParams={{ ...properties }} />
        <Stack.Screen
          key='Details'
          name='Details'
          component={Details}
          initialParams={{ ...properties }}
        />
        <Stack.Screen
          key='Welcomeslide'
          name='Welcomeslide'
          component={WelcomeSlide}
          initialParams={{ ...properties }}
        />
        <Stack.Screen
          key='Welcomeslide2'
          name='Welcomeslide2'
          component={WelcomeSlide2}
          initialParams={{ ...properties }}
        />
        <Stack.Screen
          key='Welcomeslide3'
          name='Welcomeslide3'
          component={WelcomeSlide3}
          initialParams={{ ...properties }}
        />
        <Stack.Screen
          key='Welcomeslide4'
          name='Welcomeslide4'
          component={WelcomeSlide4}
          initialParams={{ ...properties }}
        />

        <Stack.Screen
          key='Assessment'
          name='Assessment'
          component={Assessment}
          initialParams={{ ...properties }}
        />
        <Stack.Screen
          key='Designerhome'
          name='Designerhome'
          component={DesignerHome}
          initialParams={{ ...properties }}
        />
        <Stack.Screen
          key='ReviewJourney'
          name='ReviewJourney'
          component={ReviewJourney}
          initialParams={{ ...properties }}
        />
        <Stack.Screen
          key='Topic'
          name='Topic'
          component={Topic}
          initialParams={{ ...properties }}
        />
        <Stack.Screen
          key='Addtopic'
          name='Addtopic'
          component={AddTopic}
          initialParams={{ ...properties }}
        />
        <Stack.Screen
          key='Addcontent'
          name='Addcontent'
          component={AddContent}
          initialParams={{ ...properties }}
        />
        <Stack.Screen
          key='DesignerDashboard'
          name='DesignerDashboard'
          component={DesignerDashboard}
          initialParams={{ ...properties }}
        />
        <Stack.Screen
          key='Notifications'
          name='Notifications'
          component={Notifications}
          initialParams={{ ...properties }}
        />
        <Stack.Screen
          key='Notification'
          name='Notification'
          component={NotificationDetail}
          initialParams={{ ...properties }}
        />
        {/* <Stack.Screen key="Survey" name="survey"
                    component={Survey} initialParams={{ ...properties }}
                />
                <Stack.Screen key="Surveys" name="Surveys"
                    component={Surveys} initialParams={{ ...properties }}
                />
                <Stack.Screen key="SurveyForm" name="SurveyForm"
                    component={SurveyForm} initialParams={{ ...properties }}
                />
                <Stack.Screen key="SurveyList" name="SurveyList"
                    component={SurveysList} initialParams={{ ...properties }}
                />
                <Stack.Screen key="AdminSurveys" name="AdminSurveys"
                    component={AdminSurveys} initialParams={{ ...properties }}
                /> */}
        <Stack.Screen
          key='DesignerJourney'
          name='DesignerJourney'
          component={DesignerJourney}
          initialParams={{ ...properties }}
        />
        <Stack.Screen
          key='Viewfeedback'
          name='Viewfeedback'
          component={ViewFeedback}
          initialParams={{ ...properties }}
        />
        <Stack.Screen
          key='AdminFeedbacks'
          name='AdminFeedbacks'
          component={AdminFeedbacks}
          initialParams={{ ...properties }}
        />
        <Stack.Screen
          key='SpaceForm'
          name='SpaceForm'
          component={SpaceForm}
          initialParams={{ ...properties }}
        />
        <Stack.Screen
          key='Spaces'
          name='Spaces'
          component={Spaces}
          initialParams={{ ...properties }}
        />
        <Stack.Screen
          key='Space'
          name='Space'
          component={Space}
          initialParams={{ ...properties }}
        />
        <Stack.Screen
          key='ModuleForm'
          name='ModuleForm'
          component={ModuleForm}
          initialParams={{ ...properties }}
        />
        <Stack.Screen
          key='Module'
          name='Module'
          component={Module}
          initialParams={{ ...properties }}
        />
        <Stack.Screen
          key='RoleForm'
          name='RoleForm'
          component={RoleForm}
          initialParams={{ ...properties }}
        />
        <Stack.Screen
          key='Roles'
          name='Roles'
          component={Roles}
          initialParams={{ ...properties }}
        />
        <Stack.Screen key='Role' name='Role' component={Role} initialParams={{ ...properties }} />
        <Stack.Screen
          key='Learning'
          name='Learning'
          component={Learning}
          initialParams={{ ...properties }}
        />
        <Stack.Screen
          key='Addnewfeedback'
          name='Addnewfeedback'
          component={AddNewFeedback}
          initialParams={{ ...properties }}
        />
        <Stack.Screen
          key='Help'
          name='Help'
          children={(props) => <Help {...props} {...properties} />}
        />
        <Stack.Screen
          key='Logout'
          name='Logout'
          children={(props) => <Logout {...props} {...properties} />}
        />
        <Stack.Screen
          key='Feedback'
          name='Feedback'
          component={Feedback}
          initialParams={{ ...properties }}
        />
        <Stack.Screen
          key='SurveyForm'
          name='SurveyForm'
          component={SurveyForm}
          initialParams={{ ...properties }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default Main;
