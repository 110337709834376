import { createSlice } from '@reduxjs/toolkit';

const counterSlice = createSlice({
    name: 'hrAdmin',
    initialState: {
        value: {},
        statsReports : [
            'getCountOfActiveMentors',
            'getCountOfActiveMentees',
            'getCountOfActiveConnections',
            'getCountOfTotalGoals',
            'getCountOfCompletedGoals',
            'getCountOfInProgressGoals'
        ]
    },
    reducers: {
        hrAdminReducer: (state, action) => {
            // console.log("Reducer called", action);
            state.value = action.payload.stats;
        }
    }
});

// Action creators are generated for each case reducer function
export const { hrAdminReducer } = counterSlice.actions;
export default counterSlice.reducer;
