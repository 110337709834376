import { startsWith } from 'lodash';

class EditorCommand {

    static execute(currentLine, operation) {
        let command = COMMAND_LIST[operation];
        if (!command)
            return `Invalid command ${operation}`;
        return command.execute(currentLine, command.symbol);
    }

    static heading1(currentLine, symbol) {
        const isAlreadyExist = startsWith(currentLine, '#');

        if (isAlreadyExist) {
            const replaceString = currentLine.substring(0, currentLine.indexOf(' ') + 1);
            currentLine = currentLine.replace(replaceString, '');
            return currentLine;
        }
        else
            return `${symbol} ${currentLine}`;

    }

    static heading2(currentLine, symbol) {
        const isAlreadyExist = startsWith(currentLine, '#');

        if (isAlreadyExist) {
            const replaceString = currentLine.substring(0, currentLine.indexOf(' ') + 1);
            currentLine = currentLine.replace(replaceString, '');
            return currentLine;
        }
        else
            return `${symbol} ${currentLine}`;
    }

    static heading3(currentLine, symbol) {
        const isAlreadyExist = startsWith(currentLine, '#');

        if (isAlreadyExist) {
            const replaceString = currentLine.substring(0, currentLine.indexOf(' ') + 1);
            currentLine = currentLine.replace(replaceString, '');
            return currentLine;
        }
        else
            return `${symbol} ${currentLine}`;
    }

    static heading4(currentLine, symbol) {
        const isAlreadyExist = startsWith(currentLine, '#');

        if (isAlreadyExist) {
            const replaceString = currentLine.substring(0, currentLine.indexOf(' ') + 1);
            currentLine = currentLine.replace(replaceString, '');
            return currentLine;
        }
        else
            return `${symbol} ${currentLine}`;
    }

    static heading5(currentLine, symbol) {
        const isAlreadyExist = startsWith(currentLine, '#');

        if (isAlreadyExist) {
            const replaceString = currentLine.substring(0, currentLine.indexOf(' ') + 1);
            currentLine = currentLine.replace(replaceString, '');
            return currentLine;
        }
        else
            return `${symbol} ${currentLine}`;
    }

    static heading6(currentLine, symbol) {
        const isAlreadyExist = startsWith(currentLine, '#');

        if (isAlreadyExist) {
            const replaceString = currentLine.substring(0, currentLine.indexOf(' ') + 1);
            currentLine = currentLine.replace(replaceString, '');
            return currentLine;
        }
        else
            return `${symbol} ${currentLine}`;
    }

    static link(currentLine, symbol) {
        return `${currentLine} ${symbol}`;
    }

    static list(currentLine, symbol) {
        const isAlreadyExist = startsWith(currentLine, '+');

        if (isAlreadyExist) {
            const replaceString = currentLine.substring(0, currentLine.indexOf(' ') + 1);
            currentLine = currentLine.replace(replaceString, '');
            return currentLine;
        }
        else
            return `${symbol} ${currentLine}`;
    }

    static enter(currentLine) {
        if (currentLine.includes('+ ') && currentLine.length > 2)
            currentLine += '\n+ ';
        else if(currentLine.includes('+ ') && currentLine.trim().length == 1)
            currentLine = ''; 
        else
            currentLine += '\n';
        return currentLine;
    }
}

const COMMAND_LIST = {
    h1: {
        symbol: '#',
        execute: EditorCommand.heading1
    },
    h2: {
        symbol: '##',
        execute: EditorCommand.heading2
    },
    h3: {
        symbol: '###',
        execute: EditorCommand.heading3
    },
    h4: {
        symbol: '####',
        execute: EditorCommand.heading4
    },
    h5: {
        symbol: '#####',
        execute: EditorCommand.heading5
    },
    h6: {
        symbol: '######',
        execute: EditorCommand.heading6
    },
    list: {
        symbol: '+',
        execute: EditorCommand.list
    },
    link: {
        symbol: '[link text](https://www.google.com)',
        execute: EditorCommand.link
    },
    enter: {
        symbol: '\n',
        execute: EditorCommand.enter
    },
};

export default EditorCommand;