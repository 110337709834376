import * as React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import ButtonElement from '../../elements/Button';
import JourneyConfig from '../JourneyConfig.json';
import JourneyService from '../../../service/JourneyService';
import { ProgressBar } from 'react-native-paper';
import RadioButtonElement from '../../elements/RadioButton';
import { ScrollView } from 'react-native';
import TextElement from '../../elements/Text';
import { View } from 'react-native';
import ViewWithBack from '../../elements/ViewWithBack';
import { assessmentReducer } from './AssessmentSlice';
import style from './styles/assessmentStyle';
import { useIsFocused } from '@react-navigation/native';

const Assessment = (parentProps) => {
  const props = { ...parentProps, ...parentProps.route.params };
  const { assessments, topicId, journeyEnrollStatus, journeyId, spaceId, moduleId } =
    props.route.params;
  const assessmentScores = useSelector((state) => state.assessmentScore.value);
  // console.log("assessmentScores", assessmentScores);
  const assessmentScore = Object.keys(assessmentScores).length
    ? assessmentScores[topicId]
      ? assessmentScores[topicId]
      : {}
    : {};
  // console.log('assessmentScore!!', assessmentScore);

  const dispatch = useDispatch();

  const [assessment, setAssessment] = useState(assessments);
  const [isApiDataLoaded, setIsApiDataLoaded] = useState(false);
  const isFocused = useIsFocused();
  // const [assessmentScore, setAssessmentScore] = useState({});
  const [retake, setRetake] = useState(false);
  const [submitAssessment, setSubmitAssessment] = useState(false);
  const [correctAns, setCorrectAns] = useState(0);
  let correct = 0;

  const fetchAssessmentScore = () => {
    if (Object.keys(assessmentScore).length) {
      setIsApiDataLoaded(true);
    }

    const journeyService = new JourneyService(props);
    journeyService
      .fetchAssessmentScore(topicId, journeyId, spaceId, moduleId.split('#')[0], isApiDataLoaded)
      .then((assessmentScore) => {
        // console.log('assessmentScore fetched for ', topicId, 'is: ', assessmentScore);
        setIsApiDataLoaded(true);
        dispatch(
          assessmentReducer({
            assessmentScore: {
              [topicId]: assessmentScore,
            },
          })
        );
      });
  };

  useEffect(() => {
    if (isFocused) {
      fetchAssessmentScore();
    }
  }, [isFocused, submitAssessment]);

  const retakeAssessment = () => {
    setRetake(true);
    setSubmitAssessment(false);
    setCorrectAns(0);
    setAssessment(assessments);
  };

  const updateUserAns = (userAnswerObject) => {
    setAssessment(
      assessment.map((assessment, index) => {
        if (index == parseInt(userAnswerObject.sequence)) {
          return { ...assessment, ...userAnswerObject };
        } else {
          return assessment;
        }
      })
    );
  };

  const submitRequest = (assessment) => {
    // setSubmitAssessment(true);
    assessment.forEach((element) => {
      // console.log("element type", element.type);
      if (element.type == 'Single Choice') {
        // console.log("userAnswer",element?.userAnswer);
        if (element.answer == element?.userAnswer || element.answerIndex == element?.userAnswer) {
          correct = correct + 1;
        }
      } else if (element.type == 'True/False') {
        if (element.answer == element?.userAnswer || element.answerIndex == element?.userAnswer) {
          correct = correct + 1;
        }
      }
    });
    setCorrectAns(correct);
    const journeyService = new JourneyService(props);
    const assessmentData = {
      correct_answers: correct,
      total_questions: assessment.length,
    };
    // console.log(assessmentData);
    journeyService
      .saveAssessmentScore(assessmentData, topicId, isApiDataLoaded, journeyId, spaceId, moduleId)
      .then(() => {
        // console.log("assessmentScore saved", assessmentData);
        setIsApiDataLoaded(true);
        setSubmitAssessment(true);
      });
  };

  return (
    <ViewWithBack
      pageName='Assessment'
      {...props}
      backAction={() => {
        props.navigation.goBack();
      }}>
      <ScrollView showsVerticalScrollIndicator={false} showsHorizontalScrollIndicator={false}>
        {isApiDataLoaded ? (
          <View>
            {(submitAssessment || !retake) && Object.keys(assessmentScore).length ? (
              <View>
                <AssessmentResult
                  assessment={assessment}
                  submitAssessment={submitAssessment}
                  retakeAssessment={retakeAssessment}
                  assessmentScore={assessmentScore}
                  correctAns={correctAns}
                  journeyEnrollStatus={journeyEnrollStatus}
                  {...props}
                />
              </View>
            ) : (
              <View>
                {/* retake == true */}
                <TextElement theme={'DARK'} style={style.heading}>
                  Please complete the following Assessment
                </TextElement>
                {assessments.length ? (
                  assessments.map((assessment, index) => {
                    // {console.log("assessment", assessment, "retake", retake)}
                    return (
                      <View key={index} style={style.assessmentQuestionContainer}>
                        <TextElement>
                          {index + 1}. {assessment.question}
                        </TextElement>
                        <RadioButtonElement
                          sequence={index}
                          data={assessment.options}
                          handleRadioButton={updateUserAns}></RadioButtonElement>
                      </View>
                    );
                  })
                ) : (
                  <View></View>
                )}
                <View style={style.submitButtonContainer}>
                  <ButtonElement
                    theme={props.theme}
                    color='#ffffff'
                    style={style.submitButton}
                    onPress={() => submitRequest(assessment)}>
                    Submit
                  </ButtonElement>
                </View>
                <View style={style.submitBottomSpace}></View>
              </View>
            )}
          </View>
        ) : (
          <View></View>
        )}
        <View style={style.bottomSpace}></View>
      </ScrollView>
    </ViewWithBack>
  );
};

const AssessmentResult = (props) => {
  const {
    assessment,
    submitAssessment,
    retakeAssessment,
    assessmentScore,
    correctAns,
    journeyEnrollStatus,
  } = props;

  const getResultStyle = (element) => {
    if (element.type == 'Single Choice' || 'True/False') {
      if (element.answerIndex == element?.userAnswer) {
        //user selected ans is correct
        return '#F8FFE4';
      } else {
        //user selected ans is incorrect
        return '#FFEDEE';
      }
    }
  };

  return (
    <View>
      {submitAssessment ? (
        <View>
          <View style={style.assessmentResultContainer}>
            <ProgressBar
              progress={correctAns / assessment.length}
              color={JourneyConfig.colors.teal}
            />
            <TextElement style={style.scoreText}>
              {' '}
              Score: {Math.round((correctAns / assessment.length) * 100)}%
            </TextElement>
            {Math.round(assessmentScore?.result_in_percentage) < 80 ? (
              <ShowRetake
                retakeAssessment={retakeAssessment}
                journeyEnrollStatus={journeyEnrollStatus}
                {...props}
              />
            ) : (
              <View />
            )}
          </View>
          <View>
            {assessment.map((element, index) => {
              // console.log("element", element);
              return (
                <View
                  key={index}
                  style={{
                    ...style.resultOptionsContainer,
                    backgroundColor: getResultStyle(element),
                  }}>
                  <TextElement>
                    {index + 1}. {element.question}
                  </TextElement>
                  {element.options.map((option, index) => {
                    // { console.log("option", option, index); }
                    return (
                      <TextElement key={index}>
                        {index + 1}.{option}
                      </TextElement>
                    );
                  })}
                </View>
              );
            })}
          </View>
        </View>
      ) : (
        <View style={style.assessmentResultContainer}>
          <ProgressBar
            progress={assessmentScore?.result_in_percentage / 100}
            color={JourneyConfig.colors.teal}
          />
          <TextElement style={style.scoreText}>
            {' '}
            Score: {Math.round(assessmentScore?.result_in_percentage)}%
          </TextElement>
          {Math.round(assessmentScore?.result_in_percentage) < 80 ? (
            <ShowRetake
              retakeAssessment={retakeAssessment}
              journeyEnrollStatus={journeyEnrollStatus}
              {...props}
            />
          ) : (
            <View />
          )}
        </View>
      )}
    </View>
  );
};

const ShowRetake = (props) => {
  const { retakeAssessment, journeyEnrollStatus } = props;
  // console.log("journeyEnrollStatus", journeyEnrollStatus)
  return journeyEnrollStatus != 'completed' ? (
    <View style={style.retakeButtonContainer}>
      <ButtonElement
        theme={props.theme}
        color='#ffffff'
        style={style.retakeButton}
        onPress={retakeAssessment}>
        Retake
      </ButtonElement>
    </View>
  ) : (
    <View></View>
  );
};

export default Assessment;
