import { Platform } from 'react-native';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    backgroundColor: '#222745',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoContainer: { alignItems: 'center', marginBottom: 10 },
  logo: { height: 90, width: 220 },
  divider: { height: 2, backgroundColor: '#f9f9f9' },
  buttonRow: { flexDirection: 'row-reverse', width: '95%' },
  buttonContainer: { width: '8%' },
  acceptButton: { backgroundColor: '#2EADB7', padding: 10, borderRadius: 5, margin: 5 },
  declineButton: { backgroundColor: '#f5f5f5', padding: 10, borderRadius: 5, margin: 5 },
  declineText: { color: '#C70039' },
  textBold: { fontSize: 16, fontWeight: 'bold' },
  cosentContainer: { backgroundColor: '#ffffff', margin: 10, borderRadius: 5, padding: 10, width: '95%' },
  textStyle: { fontSize: 15 },
  link: { color: '#3B71CA' },
  bottomPadding: { paddingBottom: 10 },
  consentText: { fontSize: 15, fontWeight: 'bold', marginBottom: 10 },
  rowContainer: { flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: 5 },
  checkBoxContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    flex: 0.7
  },
  checkBox: {},
  employeeNotice: { display: 'flex' },
  consentContainer1: { flex: 5.3 },
  consentContainer2: { flexDirection: 'row', marginTop: 20, marginBottom: 10, justifyContent: 'center', alignItems: 'center', gap: 5 },
  consentContainer3: { flex: 5, justifyContent: 'center' },
  consentContainer4: { padding: 10, flexDirection: 'row', marginTop: 10 },
});