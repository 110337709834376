import * as React from 'react';
import * as WebBrowser from 'expo-web-browser';

import { Avatar, Modal, Portal, Provider } from 'react-native-paper';
import { KeyboardAvoidingView, SafeAreaView, ScrollView } from 'react-native';
import { SceneMap, TabView } from 'react-native-tab-view';
import {
  colors,
  topicStatusList,
  topicStatusListCompleted,
  topicStatusListEnrolled,
  topicStatusListInProgress,
} from './Metadata';
import { every, max } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import Alert from '../../elements/Alert';
import ButtonElement from '../../elements/Button';
import ColorConstant from '../../../utils/ColorConstant.json';
import { DatePickerModal } from 'react-native-paper-dates';
import DropdownElement from '../../elements/Dropdown';
import Elements from '../../elements';
import Fab from '../../elements/Fab';
import JourneyService from '../../../service/JourneyService';
import Markdown from 'react-native-markdown-display';
import TextElement from '../../elements/Text';
import TouchableElement from '../../elements/TouchableElement';
import { View } from 'react-native';
import ViewElement from '../../elements/ViewWithBack';
import { journeyTopicReducer } from './JourneyTopicsSlice';
import style from './styles/journeyDetailStyle';
import { useIsFocused } from '@react-navigation/native';

const isTimelineEnabled = false; // To Enable timeline and status features

const moment = require('moment');

const getName = require('../../../utils/NameMapping').getName;

const ContentIcons = ({ iconName, color, count }) => {
  return (
    <View style={style.contentIconContainer}>
      <Avatar.Icon icon={iconName} size={50} color={color || '#ffffff'} style={style.avatarStyle} />
      <TextElement style={style.countText}>{count} </TextElement>
    </View>
  );
};

const StatusTOC = ({ journey, enrolledJourney, ...props }) => {
  const allJourneyTopics = useSelector((state) => state.learnerJourneyTopics.value);
  const journeyTopics = allJourneyTopics[journey?.id] ? allJourneyTopics[journey?.id] : [];
  const isEnrolled = enrolledJourney?.status && enrolledJourney?.status != 'Not enrolled';
  // console.log('enrolledJourney', enrolledJourney);
  // console.log('isEnrolled', isEnrolled);
  return (
    <View style={style.journeyStatusContainer}>
      <View style={{ flex: 3 }}>
        {journeyTopics.length ? (
          <Elements.Touchable
            id='toc'
            onPress={() => {
              props.navigation.navigate('Journeytoc', { ...journey, topics: journeyTopics });
            }}>
            <Elements.Text theme='DARK' style={{ textDecorationLine: 'underline' }}>
              Table of Content
            </Elements.Text>
          </Elements.Touchable>
        ) : (
          <View />
        )}
      </View>

      <View style={{ flex: 3, flexDirection: 'row-reverse' }}>
        {isEnrolled ? (
          <View style={style.journeyStatus}>
            <TextElement theme='DARK'>Status: </TextElement>
            <TextElement
              theme='DARK'
              style={{ ...style.enrollStatus, color: colors[enrolledJourney?.status] }}>
              {enrolledJourney?.status?.charAt(0).toUpperCase() + enrolledJourney?.status?.slice(1)}
            </TextElement>
          </View>
        ) : (
          <View />
        )}
      </View>
    </View>
  );
};

const JourneyDetail = (parentProps) => {
  // console.log("parentProps:", parentProps);
  const props = { ...parentProps.route.params, ...parentProps };
  // console.log("jd props", props);
  const { journeyId } = props?.route?.params;
  const allJourneys = useSelector((state) => state.learnerJourneys.allJourneys);
  const journey = allJourneys.filter((journey) => journey?.id == journeyId)[0];

  const [enrolledJourney, setEnrolledJourney] = useState({});
  const [activeTab, setActiveTab] = useState(0);
  const [isApiDataLoaded, setIsApiDataLoaded] = useState(false);
  const isFocused = useIsFocused();
  const journeyService = new JourneyService(props);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [expectedCompletionDate, setExpectedCompletionDate] = useState('');

  const routes = [
    { key: 'content', title: 'Content' },
    { key: 'about', title: 'About' },
  ];
  const disenrolledJourney = {
    status: 'Not enrolled',
  };

  const renderScene = SceneMap({
    content: () => (
      <Content
        journey={journey}
        enrolledJourney={enrolledJourney}
        setExpectedCompletionDate={setExpectedCompletionDate}
        {...props}
        isFocused={isFocused}
      />
    ),
    about: () => <About journey={journey} />,
  });

  const fetchData = async () => {
    const enrolled_journey = await checkEnrollment(journey);
    setEnrolledJourney(enrolled_journey);
    setExpectedCompletionDate(enrolled_journey.expected_completion_date);
  };

  const checkEnrollment = async (journey) => {
    // setIsApiDataLoaded(false);
    let response = await journeyService.checkEnrollment(journey, isApiDataLoaded);
    setIsApiDataLoaded(true);
    // console.log("response", response);

    if (response?.message) {
      return disenrolledJourney;
    } else return response;
  };

  const flush = () => {
    setIsApiDataLoaded(false);
    setEnrolledJourney({});
    setActiveTab(0);
  };

  useEffect(() => {
    if (isFocused) fetchData();
    else flush();
  }, [isFocused]);

  const enrollJourney = () => {
    const enrolledJourneyDetails = {
      display_name: journey.display_name,
      description: journey.description,
    };
    journeyService.enrollJourney(journey, false, enrolledJourneyDetails).then((response) => {
      // console.log("response", response);
      Alert.alert('Success', response.message);
      fetchData();
    });
  };

  const disenrollJourney = () => {
    journeyService.disenrollJourney(journey, false, enrolledJourney).then((response) => {
      // console.log('response', response);
      Alert.alert('Info', response.message);
      // fetchData();
      setEnrolledJourney(disenrolledJourney);
    });
  };

  const completeJourney = () => {
    const allTopicsCompleted = () => {
      if (!enrolledJourney.journey_definitions) {
        return false; // If journey_definitions is not present, consider it as not all topics completed
      }

      const allCompleted = every(
        enrolledJourney.journey_definitions,
        (item) => item.status === 'completed'
      );
      return allCompleted;
    };
    const isJourneyCompleted = isTimelineEnabled ? allTopicsCompleted() : true;
    if (isJourneyCompleted) {
      enrolledJourney.completion_date = moment().unix();
      journeyService.completeJourney(journey, false, enrolledJourney).then((response) => {
        // console.log('response', response);
        Alert.alert('Info', response.message);
        fetchData();
      });
    } else {
      Alert.alert('Whoops', 'Please complete all topics first');
    }
  };

  const JourneyStatus = () => {
    if (isApiDataLoaded) {
      if (enrolledJourney?.status && enrolledJourney?.status == 'enrolled')
        return (
          <Fab
            actions={[
              {
                icon: 'account-minus',
                label: 'Disenroll',
                onPress: disenrollJourney,
                color: '#2EADB7',
              },
              {
                icon: 'account-check',
                label: 'Complete',
                onPress: completeJourney,
                color: '#2EADB7',
              },
            ]}
            icon='account-details-outline'
          />
        );
      else if (enrolledJourney?.status && enrolledJourney?.status == 'Not enrolled')
        return <Fab icon='account-plus' onPress={enrollJourney} />;
    }
  };

  const updateExpectedCompletionDate = (date) => {
    enrolledJourney.expected_completion_date = date;
    journeyService.updateEnrolledJourney(journey, false, enrolledJourney).then((response) => {
      // console.log('response', response);
      Alert.alert('Info', response.message);
      // fetchData();
    });
  };

  // console.log('enrolledJourney', enrolledJourney);

  const maxTimelineFromDefinitions =
    enrolledJourney && enrolledJourney.journey_definitions
      ? Math.max(
          ...Object.values(enrolledJourney.journey_definitions).map((item) => item.timeline || 0)
        )
      : 0;

  // console.log('maxTimelineFromDefinitions', maxTimelineFromDefinitions);

  const minExpectedCompletionDate = enrolledJourney
    ? Math.max(enrolledJourney.expected_completion_date || 0, maxTimelineFromDefinitions)
    : '';

  // console.log('minExpectedCompletionDate', minExpectedCompletionDate);
  return (
    <Provider>
      <ViewElement
        pageName='Journey Details'
        {...props}
        backAction={() => {
          props.navigation.goBack();
        }}>
        <View style={style.header}>
          <TextElement theme={'DARK'} style={style.journeyTitle}>
            {journey?.display_name}
          </TextElement>

          {/* If user Enrolled in this journey  */}

          {enrolledJourney?.status && enrolledJourney?.status !== 'Not enrolled' ? (
            <View>
              <TextElement theme={'DARK'} style={style.journeyDescription}>
                Enrolled Date:{' '}
                {enrolledJourney.created_at
                  ? moment.unix(enrolledJourney.created_at).format('YYYY/MM/DD')
                  : '-'}
              </TextElement>

              {isTimelineEnabled &&
              enrolledJourney?.status &&
              enrolledJourney?.status === 'enrolled' ? (
                <TouchableElement
                  onPress={() => {
                    setShowDatePicker(true);
                  }}
                  id='status'>
                  <TextElement theme={'DARK'} style={style.journeyDescription}>
                    Expected Completion Date:{' '}
                    {expectedCompletionDate
                      ? moment.unix(expectedCompletionDate).format('YYYY/MM/DD')
                      : '-'}
                    <Avatar.Icon
                      size={20}
                      color='#ffffff'
                      icon='pencil'
                      style={{ backgroundColor: 'transparent' }}
                    />
                  </TextElement>
                </TouchableElement>
              ) : (
                <TextElement theme={'DARK'} style={style.journeyDescription}>
                  Completion Date:{' '}
                  {enrolledJourney?.completion_date
                    ? moment.unix(enrolledJourney?.completion_date).format('YYYY/MM/DD')
                    : '-'}
                </TextElement>
              )}
            </View>
          ) : (
            <View />
          )}

          <StatusTOC journey={journey} enrolledJourney={enrolledJourney} {...props} />
        </View>

        <TabView
          navigationState={{ index: activeTab, routes }}
          renderScene={renderScene}
          onIndexChange={(index) => setActiveTab(index)}
          style={{ flex: 1 }}
        />
        <JourneyStatus></JourneyStatus>
        <TimelineDatePicker
          showDatePicker={showDatePicker}
          setShowDatePicker={setShowDatePicker}
          // setDate={setExpectedCompletionDate}
          setDate={(date) => {
            setExpectedCompletionDate(date);
            updateExpectedCompletionDate(date);
          }}
          minDate={minExpectedCompletionDate}
        />
      </ViewElement>
    </Provider>
  );
};

const About = ({ journey }) => {
  const allJourneyTopics = useSelector((state) => state.learnerJourneyTopics.value);
  const topics = allJourneyTopics[journey?.id] ? allJourneyTopics[journey?.id] : [];

  const getJourneyStats = (topics) => {
    // console.log("topics", topics);
    let links = 0;
    let assessmenents = 0;
    let contents = 0;

    topics.forEach((topic) => {
      topic?.sub_topic.forEach((subTopic) => {
        // console.log("subTopic", subTopic);
        if (subTopic.content_type == 'Link') links = links + 1;
        if (subTopic.content_type == 'Assessment') assessmenents = assessmenents + 1;
        if (subTopic.content_type == 'Content') contents = contents + 1;
      });
    });
    return {
      links,
      contents,
      assessmenents,
    };
  };

  const journeyStats = getJourneyStats(topics);
  // console.log("journeyStats", journeyStats);
  return (
    <View style={style.container}>
      <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={style.scrollView}>
        <View style={style.aboutAuthorContainer}>
          <TextElement theme='LIGHT' style={style.aboutTitle}>
            Description
          </TextElement>
          <TextElement theme={'LIGHT'} style={style.aboutAuthorText}>
            {journey?.description.trim()}{' '}
          </TextElement>
          <View style={style.seperator}></View>
          <TextElement theme='LIGHT' style={style.aboutTitle}>
            About Author
          </TextElement>
          <TextElement style={style.aboutAuthorText}>{getName(journey?.created_by)}</TextElement>
        </View>
        <View style={style.contentIconsContainer}>
          <TextElement theme='LIGHT' style={style.aboutTitle}>
            Contents
          </TextElement>
          <View style={style.aboutIconContainer}>
            <ContentIcons
              iconName='file-word'
              color='lightblue'
              count={journeyStats.contents || 0}
            />
            <ContentIcons iconName='link-variant' color='orange' count={journeyStats.links || 0} />
            <ContentIcons iconName='trophy' color='white' count={journeyStats.assessmenents || 0} />
          </View>
        </View>
      </ScrollView>
    </View>
  );
};

const Content = (props) => {
  // console.log('Content props :: ', props.journey);
  const [isTopicApiDataLoaded, setIsTopicApiDataLoaded] = useState(false);
  const { enrolledJourney, journey, isFocused, setExpectedCompletionDate } = props;
  const allJourneyTopics = useSelector((state) => state.learnerJourneyTopics.value);
  const topics = allJourneyTopics[journey?.id] ? allJourneyTopics[journey?.id] : [];
  const journeyService = new JourneyService(props);
  const dispatch = useDispatch();

  const updateCompletionDateIfNeeded = () => {
    // console.log('enrolledJourney', enrolledJourney);

    if (enrolledJourney.expected_completion_date) {
      // console.log('Expected completion date is already present.');
      return;
    }
    if (topics.length !== Object.keys(enrolledJourney.journey_definitions).length) {
      return;
    }
    // Check if all timelines are present
    const allTimelinesPresent = every(
      enrolledJourney.journey_definitions,
      (item) => item.timeline !== '' || item.timeline !== undefined
    );
    if (!allTimelinesPresent) {
      // console.log('Inside expected date not present');
      return;
    }
    const timelines = enrolledJourney.journey_definitions
      .filter((item) => item.timeline !== '' || item.timeline !== undefined)
      .map((item) => item.timeline);

    // Find the highest timeline
    const highestTimeline = timelines.length > 0 ? max(timelines) : null;
    if (highestTimeline) {
      enrolledJourney.expected_completion_date = highestTimeline;
      setExpectedCompletionDate(highestTimeline);
    }
    // console.log('enrolledJourney after', enrolledJourney);
  };

  const updateEnrolledJourney = () => {
    updateCompletionDateIfNeeded();
    journeyService.updateEnrolledJourney(journey, false, enrolledJourney).then((response) => {
      // console.log('response', response);
      Alert.alert('Info', response.message);
      // fetchData();
    });
  };

  useEffect(() => {
    if (isFocused)
      journeyService.fetchJourneyTopics(journey, isTopicApiDataLoaded).then((journeyTopics) => {
        setIsTopicApiDataLoaded(true);
        dispatch(journeyTopicReducer({ topics: journeyTopics, journeyId: journey?.id }));
      });
  }, [isFocused]);

  // console.log("isTopicApiDataLoaded", isTopicApiDataLoaded, "topics", topics);

  const TopicsTableHeader = () => {
    return (
      <View id='journey' style={style.rowArea}>
        <View style={{ flex: 4, margin: 5 }}>
          <TextElement style={style.bold}>Name</TextElement>
        </View>
        {isTimelineEnabled ? (
          <View style={{ flex: 1, alignItems: 'center' }}>
            <TextElement style={style.bold}>Status</TextElement>
          </View>
        ) : (
          <View></View>
        )}
        {isTimelineEnabled ? (
          <View style={{ flex: 1, alignItems: 'center' }}>
            <TextElement style={style.bold}>Timeline</TextElement>
          </View>
        ) : (
          <View></View>
        )}
      </View>
    );
  };

  return (
    <View style={style.container}>
      <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={style.scrollView}>
        {isTopicApiDataLoaded ? (
          <View style={style.topicsContainer}>
            <TopicsTableHeader />

            {topics && topics.length ? (
              topics.map((topic, index) => {
                return (
                  <TopicRow
                    key={index}
                    topic={topic}
                    journey={props.journey}
                    enrolledJourney={enrolledJourney}
                    updateEnrolledJourney={updateEnrolledJourney}
                    {...props}
                  />
                );
              })
            ) : (
              <View style={style.noResultContainer}>
                <Avatar.Icon
                  icon='email-open-outline'
                  color='#CECECE'
                  size={50}
                  style={style.icon}
                />
                <View style={style.seperator}></View>
                <TextElement style={style.noResultsText}>No Topics Found!</TextElement>
              </View>
            )}
            <View style={style.marginBottomSpacer}></View>
          </View>
        ) : (
          <View style={style.loadingContainer}>
            <TextElement style={style.loadingText}>Loading...</TextElement>
          </View>
        )}
      </ScrollView>
    </View>
  );
};

const TopicRow = (props) => {
  // console.log('TopicRow journey :: ', props.journey);
  const { topic, enrolledJourney, journey, updateEnrolledJourney } = props;
  let enrolledJourneyTopicDefinition = enrolledJourney?.journey_definitions?.[topic.id];
  // console.log('enrolledJourneyTopicDefinition', enrolledJourneyTopicDefinition);
  const [isOpen, setIsOpen] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [contentTitle, setContentTitle] = useState({});
  const [content, setContent] = useState({});
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [timeline, setTimeline] = useState(enrolledJourneyTopicDefinition?.timeline || '');
  const [topicStatus, setTopicStatus] = useState(enrolledJourneyTopicDefinition?.status || '');
  const [showDatePicker, setShowDatePicker] = useState(false);
  // console.log('enrolledJourney in topic row', enrolledJourney);
  // console.log('topicStatus', topicStatus);

  const updateJourneyDefinition = (newTimeline, newStaus = topicStatus) => {
    // console.log('timeline in update', timelineToUse, 'newStaus', newStaus);
    let updatedDefinition = {};
    updatedDefinition[topic.id] = {
      weightage: 0,
      status: newStaus,
    };
    if (newTimeline) {
      updatedDefinition[topic.id].timeline = newTimeline;
    }

    enrolledJourney.journey_definitions = {
      ...enrolledJourney?.journey_definitions,
      ...updatedDefinition,
    };
    // console.log('updated enrolled', enrolledJourney);
    updateEnrolledJourney(enrolledJourney);
  };

  const handleShow = (record, topic) => {
    if (
      record.content_type === 'Assessment' &&
      enrolledJourney?.status &&
      enrolledJourney?.status != 'Not enrolled'
    ) {
      // console.log("record", record);
      // console.log("topic", topic);
      props.navigation.navigate('Assessment', {
        assessments: record.content,
        topicId: topic.id,
        journeyEnrollStatus: enrolledJourney?.status,
        journeyId: journey.journey_id,
        spaceId: journey.space_id,
        moduleId: journey.id,
      });
    } else if (record.content_type === 'Content') {
      setContentTitle(record.name);
      setShowContent(true);
      setContent(record.content);
    }
  };

  const handleClose = (setter) => {
    setter(false);
  };

  const JourneyTouchable = () => (
    <TouchableElement onPress={() => setIsOpen(!isOpen)} id='journey' style={style.rowArea}>
      <View style={style.topicContainer}>
        <View style={style.topicName}>
          <TextElement numberOfLines={1}>{topic.display_name}</TextElement>
        </View>
        {isTimelineEnabled ? (
          <View style={style.statusTimeline}>
            <TextElement>-</TextElement>
          </View>
        ) : (
          <View></View>
        )}
        {isTimelineEnabled ? (
          <View style={style.statusTimeline}>
            <TextElement>-</TextElement>
          </View>
        ) : (
          <View></View>
        )}
      </View>
    </TouchableElement>
  );

  const Status = () => {
    return (
      <View style={style.statusTimelineContainer}>
        {enrolledJourney?.status && enrolledJourney?.status == 'enrolled' ? (
          <TouchableElement
            onPress={() => {
              setShowStatusModal(true);
            }}
            id='status'>
            <TextElement style={{ fontSize: 12 }}>
              {topicStatus ? topicStatusList[topicStatus] : '-'}
              {/* <Avatar.Icon
                  size={13}
                  color='#000000'
                  icon='pencil'
                  style={{ backgroundColor: 'transparent' }}
                /> */}
            </TextElement>
          </TouchableElement>
        ) : (
          <TextElement>{topicStatus ? topicStatusList[topicStatus] : '-'}</TextElement> //enrolledJourney?.status == 'completed'
        )}
      </View>
    );
  };

  const Timeline = () => {
    return (
      <View style={style.statusTimelineContainer}>
        {enrolledJourney?.status && enrolledJourney?.status == 'enrolled' ? (
          <TouchableElement
            onPress={() => {
              setShowDatePicker(true);
            }}
            id='timeline'>
            <TextElement style={{ fontSize: 12 }}>
              {timeline ? moment.unix(timeline).format('YYYY/MM/DD') : '-'}
              {/* <Avatar.Icon
                  size={13}
                  color='#000000'
                  icon='pencil'
                  style={{ backgroundColor: 'transparent' }}
                /> */}
            </TextElement>
          </TouchableElement>
        ) : (
          <TextElement>{timeline ? moment.unix(timeline).format('YYYY/MM/DD') : '-'}</TextElement> //enrolledJourney?.status == 'completed'
        )}
      </View>
    );
  };

  return (
    <View>
      {enrolledJourney?.status && enrolledJourney?.status === 'Not enrolled' ? (
        <JourneyTouchable />
      ) : (
        <TouchableElement
          onPress={() => {
            setIsOpen(!isOpen);
          }}
          id='journey'
          style={style.rowArea}>
          <View style={style.topicContainer}>
            <View style={style.topicName}>
              <TextElement numberOfLines={1}>{topic.display_name}</TextElement>
            </View>
            {isTimelineEnabled ? <Status /> : <View />}
            {isTimelineEnabled ? <Timeline /> : <View />}
          </View>
        </TouchableElement>
      )}
      <ExpandedSubTopicsView
        isOpen={isOpen}
        topic={topic}
        WebBrowser={WebBrowser}
        handleShow={handleShow}
      />
      <ShowContentModal
        showContent={showContent}
        setShowContent={setShowContent}
        handleClose={handleClose}
        contentTitle={contentTitle}
        content={content}
      />
      <StatusSelectionModal
        status={topicStatus}
        handleClose={handleClose}
        showStatusModal={showStatusModal}
        setShowStatusModal={setShowStatusModal}
        setStatus={setTopicStatus}
        handleUpdate={(newStatus) => {
          if (newStatus) {
            updateJourneyDefinition(null, newStatus);
          } else {
            Alert.alert('Bad input', 'Please select a status');
          }
        }}
        {...props}
      />
      <TimelineDatePicker
        showDatePicker={showDatePicker}
        setShowDatePicker={setShowDatePicker}
        // setDate={setTimeline}
        setDate={(newTimeline) => {
          setTimeline(newTimeline);
          updateJourneyDefinition(newTimeline);
        }}
        maxDate={enrolledJourney.expected_completion_date}
      />
    </View>
  );
};

const ExpandedSubTopicsView = ({ isOpen, topic, WebBrowser, handleShow }) => {
  if (!isOpen) {
    return <View />;
  }

  const RenderSubTopics = () => {
    if (topic.sub_topic?.length) {
      return topic.sub_topic.map((subTopic, index) => {
        return (
          <View key={index} style={style.subTopicContainer}>
            <View style={style.subTopicIconView}>
              <Avatar.Icon
                size={40}
                icon={
                  subTopic.content_type === 'Link'
                    ? 'link-variant'
                    : subTopic.content_type === 'Assessment'
                    ? 'file-percent-outline'
                    : 'book-open-outline'
                }
                style={style.icon}
              />
            </View>
            <TouchableElement
              onPress={() => {
                subTopic.content_type === 'Link'
                  ? WebBrowser.openBrowserAsync(subTopic.content)
                  : handleShow(subTopic, topic);
              }}
              id={'row-' + index}
              style={style.subTopicInfo}>
              <TextElement style={[style.name, { color: '#000000' }]}>{subTopic.name}</TextElement>
              <TextElement style={[style.description, { color: '#000000' }]}>
                {' '}
                {subTopic.description}
              </TextElement>
              {/* <TextElement style={style.description}> Diff Level: Mid</TextElement> */}
              {subTopic.content_type === 'Link' ? (
                <TextElement style={style.link} numberOfLines={1}>
                  {subTopic.content}
                </TextElement>
              ) : (
                <View />
              )}
            </TouchableElement>
          </View>
        );
      });
    } else {
      return <NoResultContainer />;
    }
  };

  return <View style={style.topicRowContainer}>{RenderSubTopics()}</View>;
};

const ShowContentModal = ({ showContent, setShowContent, handleClose, contentTitle, content }) => {
  if (!showContent) {
    return null;
  }

  return (
    <View style={style.contentOuterView}>
      {
        <Portal>
          <Modal
            visible={showContent}
            onDismiss={() => {
              handleClose(setShowContent);
            }}
            contentContainerStyle={style.modalcontainerStyle}>
            <SafeAreaView style={style.modalView}>
              <View style={style.contentView}>
                <TouchableElement
                  onPress={() => {
                    handleClose(setShowContent);
                  }}
                  id='close'>
                  <Avatar.Icon size={40} icon='close' color='black' style={style.icon} />
                </TouchableElement>
              </View>

              <View style={style.contentTitle}>
                <TextElement style={style.name} text={contentTitle} />
              </View>

              <ScrollView showsVerticalScrollIndicator={false} style={{ padding: 10 }}>
                <Markdown
                  style={{
                    heading1: style.markdownHeading,
                    heading2: style.markdownHeading,
                    heading3: style.markdownHeading,
                    heading4: style.markdownHeading,
                    heading5: style.markdownHeading,
                    heading6: style.markdownHeading,
                  }}>
                  {content}
                </Markdown>
                {/* <TextElement style={style.contentTitle}>{content}</TextElement> */}
              </ScrollView>
            </SafeAreaView>
          </Modal>
        </Portal>
      }
    </View>
  );
};

const StatusSelectionModal = ({
  handleClose,
  showStatusModal,
  setShowStatusModal,
  status,
  setStatus,
  handleUpdate,
  ...props
}) => {
  const calculateMaxHeight = (itemCount) => {
    const itemHeight = 55;
    const maxHeight = Math.min(itemCount * itemHeight, 200);
    return maxHeight;
  };

  const dropdownData =
    status === 'enrolled'
      ? topicStatusListEnrolled
      : status === 'inProgress'
      ? topicStatusListInProgress
      : topicStatusListCompleted;

  const Divider = () => {
    return <View style={{ height: 5 }}></View>;
  };
  return (
    <Portal>
      <Modal
        visible={showStatusModal}
        onDismiss={() => {
          handleClose(setShowStatusModal);
        }}
        contentContainerStyle={style.modalcontainerStyle}>
        <KeyboardAvoidingView behavior={'padding'} style={style.keyboard}>
          <View style={style.modalView1}>
            <View style={{ flex: 1 }}>
              <TextElement theme='LIGHT' style={style.journeyDescription}>
                Status
              </TextElement>
              <Divider theme={props.theme} />
              <View style={style.dropdownContainer}>
                <DropdownElement
                  theme={props.theme}
                  data={dropdownData}
                  value={status}
                  onChange={(item) => {
                    // console.log('status changed to', item.value);
                    setStatus(item.value);
                  }}
                  maxHeight={calculateMaxHeight(dropdownData.length)}
                  style={style.dropdown}
                  containerStyle={{ padding: 10, backgroundColor: 'pink' }}
                  placeholderStyle={style.placeholderStyle}
                  selectedTextStyle={style.selectedTextStyle}
                />
              </View>
            </View>
            <Divider theme={props.theme} />
            <View style={style.editButtonContainer}>
              <ButtonElement
                testId='set-modal'
                theme={props.theme}
                mode='contained'
                color={ColorConstant[props.theme]?.BUTTON_TEXT}
                onPress={() => {
                  // console.log('set clicked', status);
                  handleUpdate(status);
                  handleClose(setShowStatusModal);
                }}>
                <TextElement theme={props.theme} style={style.buttonText}>
                  Update
                </TextElement>
              </ButtonElement>

              <ButtonElement
                testId='close-modal'
                theme={props.theme}
                mode='contained'
                color={ColorConstant[props.theme]?.BUTTON_TEXT}
                style={style.closeButton}
                onPress={() => {
                  handleClose(setShowStatusModal);
                }}>
                <TextElement theme={props.theme} style={style.buttonText}>
                  Close
                </TextElement>
              </ButtonElement>
            </View>
          </View>
        </KeyboardAvoidingView>
      </Modal>
    </Portal>
  );
};

const TimelineDatePicker = ({ showDatePicker, setShowDatePicker, setDate, maxDate, minDate }) => {
  // console.log('mindate', minDate, 'maxDate', maxDate);
  const calculateStartDate = () => {
    const today = new Date();
    const defaultStartDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    if (minDate && minDate !== '') {
      const minDateObject = new Date(moment.unix(minDate).format('YYYY/MM/DD'));
      const startDate = new Date(minDateObject);
      // startDate.setDate(minDateObject.getDate() + 1);
      return startDate;
    }
    return defaultStartDate;
  };

  const handleDateSelection = (date) => {
    if (date.date) {
      const timestamp = moment(date.date, 'YYYY-MM-DD HH:mm:ss').unix();
      setDate(timestamp);
      setShowDatePicker(false);
    } else {
      setShowDatePicker(false);
      Alert.alert('Bad input', 'Please select a valid date');
    }
  };

  const startDate = calculateStartDate();
  const endDate =
    maxDate && maxDate !== '' ? new Date(moment.unix(maxDate).format('YYYY/MM/DD')) : undefined;

  // console.log('startDate', startDate);
  // console.log('endDate', endDate);

  return (
    <View style={style.rowContainer}>
      <DatePickerModal
        locale='en'
        mode='single'
        label='Select timeline'
        visible={showDatePicker}
        onDismiss={() => setShowDatePicker(false)}
        validRange={{
          startDate: startDate,
          endDate: endDate,
        }}
        onConfirm={handleDateSelection}
      />
    </View>
  );
};

const NoResultContainer = () => (
  <View style={style.noResultContainer}>
    <Avatar.Icon icon='email-open-outline' color='#CECECE' size={50} style={style.icon} />
    <View style={style.seperator} />
    <TextElement style={style.noResultsText}>No Contents Found!</TextElement>
  </View>
);

export default JourneyDetail;
