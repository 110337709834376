import { Animated, Easing, KeyboardAvoidingView, LogBox, Platform, Text, View } from 'react-native';
import { Avatar, Card, Modal, Paragraph, Portal, Provider } from 'react-native-paper';
import React, { memo, useEffect, useState } from 'react';
import { SafeAreaView, ScrollView } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import Alert from '../elements/Alert';
import ButtonElement from '../elements/Button';
import CheckBoxElement from '../elements/CheckBox';
import ColorConstant from '../../utils/ColorConstant.json';
import Constants from 'expo-constants';
import DividerElement from '../elements/Divider';
import Elements from '../elements';
import SearchService from '../../service/SearchService';
import TagInput from '../elements/TagInput';
import TextElement from '../elements/Text';
import TextInputElement from '../elements/TextInput';
import TouchableElement from '../elements/TouchableElement';
import ViewElement from '../elements/View';
import { convertToJSON } from '../../dal/Parser';
import { getName } from '../../utils/NameMapping';
import { getUserIdentity } from '../../utils/UserIdentity';
import { searchMappings } from './Metadata';
import { searchReducer } from './SearchSlice';
import style from './Style';
import { useIsFocused } from '@react-navigation/native';

LogBox.ignoreLogs(['EventEmitter.removeListener']);

let openMenu = false;
const widthAnimation = new Animated.Value(0);
const spin = widthAnimation.interpolate({
  inputRange: [0, 1],
  outputRange: [0, 320],
});

const menuHandler = (isFocused = true) => {
  Animated.timing(widthAnimation, {
    toValue: isFocused ? (!openMenu ? 1 : 0) : 0,
    duration: 210,
    easing: Easing.ease,
    useNativeDriver: false,
  }).start();
  openMenu = !openMenu;
  // console.log('openMenu after', openMenu);
};

const Search = (props) => {
  // console.log("props", props);
  const searchCache = useSelector((state) => state.search.value);
  const dispatch = useDispatch();

  const [uIdentityLoaded, setUIdentityLoaded] = React.useState(false);
  const [userIdentity, setUserIdentity] = React.useState({});
  const [data, setData] = useState([]);
  const [name, setName] = useState('');
  const [aoe, setAOE] = useState('');
  const [location, setLocation] = useState('');
  const [commode, setCommode] = useState([]);
  const [softSkills, setSoftSkills] = useState([]);
  const [tools, setTools] = useState([]);
  const [technologies, settechnologies] = useState([]);
  const [isTeam, setIsTeam] = useState(false);
  const [isf2f, setIsf2f] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isBooked, setIsBooked] = useState(false);
  const [status, setStatus] = useState([]);

  const [isApiDataLoaded, setIsApiDataLoaded] = useState(false);

  const isFocused = useIsFocused();

  // console.log('search isFocused', isFocused);

  useEffect(() => {
    if (!isFocused) {
      resetMenuSelection();
      menuHandler(isFocused);
    }
  }, [isFocused]);

  const getRecommendedMentors = () => {
    getUserIdentity().then((uIdentity) => {
      if (uIdentity) {
        setUserIdentity(uIdentity);
        // Recommended Mentors search field
        const searchFields = {
          department: uIdentity.department,
          location: uIdentity.country,
          email: uIdentity.email_id,
          status: ['open'],
        };
        getSearchResult(searchFields, true);
      }
    });
  };

  const fetchData = (searchFields, toSave = false) => {
    if (searchFields) {
      // console.log('searchFields present if', searchFields);
      getSearchResult(searchFields, toSave);
    } else if (searchCache && searchCache.length) {
      // console.log('searchCache found else if', searchCache);
      setData(searchCache);
      setIsApiDataLoaded(true);
      getRecommendedMentors();
    } else if (!searchCache || !searchCache.length) {
      // console.log('searchCache not found else if', searchCache);
      getRecommendedMentors();
    }
  };

  // console.log("open", open);
  const getSearchResult = (searchFields, toSave) => {
    // console.log('searchFields', searchFields);
    searchFields.is_mentor = 1;
    // console.log('getSearchResult toSave', toSave);
    const jsonData = convertToJSON(searchFields);
    // console.log('jsonData', jsonData);
    const searchService = new SearchService(props);
    searchService.fetchSearchData(jsonData).then((response) => {
      // console.log('response', response);
      setIsApiDataLoaded(true);
      setData(response);
      if (toSave) {
        dispatch(searchReducer({ search: response }));
      }
    });
  };

  const prepareCardData = (data) => {
    const dataList = data ? [...data] : [];
    // console.log('dataList', dataList);
    const transformedData = dataList.map((record) => {
      const card = { ...record };
      const fullName = getName(record.email_id);
      card.name = fullName;
      card.email = record.email_id;
      card.dept = record.department ? record.department : 'N/A';
      card.rating = record.rating;
      card.location = record.location ? record.location : 'N/A';
      card.status = record.status;
      // card.name = Mapping(card.name) != null ? Mapping(card.name) : card.name;
      card.matching = (record.matched_fields || [])
        .map((field) => searchMappings[field])
        .toString();
      return card;
    });
    const allRecordsWithoutSource = transformedData.filter(
      (record) => record.email != userIdentity.email_id
    );
    return allRecordsWithoutSource;
  };

  const resetMenuSelection = () => {
    setName('');
    setLocation('');
    setAOE('');
    setCommode([]);
    setSoftSkills([]);
    setTools([]);
    settechnologies([]);
    setIsTeam(false);
    setIsf2f(false);
    setIsOpen(false);
    setIsBooked(false);
    setStatus([]);
  };

  useEffect(() => {
    getUserIdentity().then((uIdentity) => {
      setUserIdentity(uIdentity);
      setUIdentityLoaded(true);
    });
  }, [uIdentityLoaded]);

  const menuScreenProps = {
    data,
    setUserIdentity,
    prepareCardData,
    name,
    setName,
    aoe,
    setAOE,
    location,
    setLocation,
    commode,
    setCommode,
    softSkills,
    setSoftSkills,
    tools,
    setTools,
    technologies,
    settechnologies,
    isTeam,
    setIsTeam,
    isf2f,
    setIsf2f,
    isOpen,
    setIsOpen,
    isBooked,
    setIsBooked,
    status,
    setStatus,
    fetchData,
  };

  const mainScreenProps = {
    data,
    userIdentity,
    setUserIdentity,
    prepareCardData,
    name,
    setName,
    fetchData,
    isApiDataLoaded,
  };

  return (
    <Provider>
      <SafeAreaView style={style.safeArea}>
        <Animated.View
          style={{
            width: spin,
            backgroundColor: '#f5f5f5',
            flex: 1,
            zIndex: 1,
            position: 'absolute',
            opacity: spin,
            bottom: 0,
            top: 0,
            paddingTop: Constants.statusBarHeight,
          }}>
          <MenuScreen {...props} {...menuScreenProps} />
        </Animated.View>

        <View style={style.mainScreenContainer}>
          <MainScreen {...props} {...mainScreenProps} />
        </View>
      </SafeAreaView>
    </Provider>
  );
};

const MenuScreen = (props) => {
  const {
    data,
    setUserIdentity,
    prepareCardData,
    name,
    setName,
    aoe,
    setAOE,
    location,
    setLocation,
    commode,
    setCommode,
    softSkills,
    setSoftSkills,
    tools,
    setTools,
    technologies,
    settechnologies,
    isTeam,
    setIsTeam,
    isf2f,
    setIsf2f,
    isOpen,
    setIsOpen,
    isBooked,
    setIsBooked,
    status,
    setStatus,
    fetchData,
  } = props;

  let disableSearch = true;
  const handleAOEChange = (value) => {
    setAOE(value);
  };
  const handleLocationChange = (value) => {
    setLocation(value);
  };
  const handleNameChange = (value) => {
    setName(value);
  };
  const toReset = () => {
    menuHandler();
    disableSearch = true;
    setName('');
    setLocation('');
    setAOE('');
    setCommode([]);
    setSoftSkills([]);
    setTools([]);
    settechnologies([]);
    setIsTeam(false);
    setIsf2f(false);
    setIsOpen(false);
    setIsBooked(false);
    setStatus([]);
    fetchData();
  };

  const toSearch = () => {
    // console.log('menu search called');
    menuHandler();
    getUserIdentity().then((uIdentity) => {
      if (uIdentity) {
        setUserIdentity(uIdentity);
        const searchFields = {
          areaOfExpertise: aoe,
          skills_softSkill: softSkills,
          skills_tools: tools,
          skills_technologies: technologies,
          communicationModes: commode,
          name: name,
          status: status,
          location: location,
          email: uIdentity.email_id,
        };
        validateData(searchFields);
      }
    });
  };

  const validateData = (searchFields) => {
    const isInvalid = checkInputData(searchFields);

    if (isInvalid) {
      Alert.alert('Missing field', isInvalid);
    } else {
      fetchData(searchFields);
    }
  };

  const checkInputData = (data) => {
    const { name, areaOfExpertise } = data;
    if (name && name.length < 3) {
      return 'Name should have at least three letters';
    }
    if (areaOfExpertise && areaOfExpertise.length < 3) {
      return 'Area of Expertise should have at least three letters';
    }
    return false;
  };

  useEffect(() => {
    if (data && !data.Message) {
      prepareCardData(data);
    }
  }, [data]);

  if (
    aoe.length ||
    commode.length ||
    location.length ||
    name.length ||
    softSkills.length ||
    technologies.length ||
    tools.length ||
    status.length
  ) {
    disableSearch = false;
  }

  const handleCheckbox = (value, setState) => {
    setState((prevValues) => {
      if (prevValues.includes(value)) {
        return prevValues.filter((record) => record !== value);
      } else {
        return [...prevValues, value];
      }
    });
  };

  const getButtonStyle = (disabled) => ({
    ...style.button,
    backgroundColor: disabled ? '#f5f5f5' : '#D9D9D9',
    borderColor: disabled ? '#38383D' : '#D9D9D9',
    borderWidth: disabled ? 0.3 : 0,
  });

  return (
    <ScrollView showsVerticalScrollIndicator={false} showsHorizontalScrollIndicator={false}>
      <View>
        <View>
          <View style={style.advancedSearchContainer}>
            <TextElement theme={'LIGHT'} style={style.sectionTitle}>
              {' '}
              Advanced Search{' '}
            </TextElement>
            {/* <View style={style.closeContainer}>
              <TouchableElement
                onPress={() => {
                  menuHandler();
                }}
                style={style.filterButton}
                id='close'>
                <Avatar.Icon icon='close' size={35} style={style.closeIcon} color='#212644' />
              </TouchableElement>
            </View> */}
          </View>
          <TextInputElement
            theme={'LIGHT'}
            label='Name'
            style={style.input}
            value={name}
            onChangeText={(name) => handleNameChange(name)}
          />
          <TextInputElement
            theme={'LIGHT'}
            style={style.input}
            label='Area Of Expertise'
            value={aoe}
            onChangeText={(aoe) => handleAOEChange(aoe)}
          />
          <DividerElement height={10} />

          <TextInputElement
            theme={'LIGHT'}
            style={style.input}
            label='Location'
            value={location}
            onChangeText={(loc) => handleLocationChange(loc)}
          />
          <DividerElement height={10} />

          <View style={style.input}>
            <TextElement theme={'LIGHT'} style={style.inputTitle}>
              {' '}
              Communcation Modes:{' '}
            </TextElement>
            <CheckBoxElement
              theme={'LIGHT'}
              label='Teams'
              value={isTeam}
              handleCheckBox={() => {
                setIsTeam(!isTeam);
                handleCheckbox('teams', setCommode);
              }}
            />

            <CheckBoxElement
              theme={'LIGHT'}
              label='Face To Face'
              value={isf2f}
              handleCheckBox={() => {
                setIsf2f(!isf2f);
                handleCheckbox('face-to-face', setCommode);
              }}
            />

            <TextElement theme={'LIGHT'} style={style.inputTitle}>
              Status:
            </TextElement>

            <CheckBoxElement
              theme={'LIGHT'}
              label='Open'
              value={isOpen}
              handleCheckBox={() => {
                setIsOpen(!isOpen);
                handleCheckbox('open', setStatus);
              }}
            />

            <CheckBoxElement
              theme={'LIGHT'}
              label='Booked'
              value={isBooked}
              handleCheckBox={() => {
                setIsBooked(!isBooked);
                handleCheckbox('booked', setStatus);
              }}
            />
          </View>
          <DividerElement height={10} />
          <View style={style.mandatoryContainer}>
            <TextElement theme={'LIGHT'} style={style.mandatory}>
              *Please use comma ( , ) to add values
            </TextElement>
          </View>
          <DividerElement height={10} />

          <View style={style.input}>
            <TextElement theme={'LIGHT'} style={style.inputTitle}>
              Enter Soft Skills
            </TextElement>
            <TagInput value={softSkills} setter={setSoftSkills} backgroundColor='#FFFFFF' />

            <TextElement theme={'LIGHT'} style={style.inputTitle}>
              Enter Tools
            </TextElement>
            <TagInput value={tools} setter={setTools} backgroundColor='#FFFFFF' />

            <TextElement theme={'LIGHT'} style={style.inputTitle}>
              Enter Technologies
            </TextElement>
            <TagInput value={technologies} setter={settechnologies} backgroundColor='#FFFFFF' />
          </View>
          <DividerElement height={10} />
        </View>

        <View style={style.buttonContainer}>
          <TouchableElement style={getButtonStyle(disableSearch)} onPress={toReset} id='reset'>
            <TextElement>Reset</TextElement>
          </TouchableElement>

          <TouchableElement
            id='search'
            style={getButtonStyle(disableSearch)}
            onPress={disableSearch ? () => {} : toSearch}>
            <TextElement>Search</TextElement>
          </TouchableElement>
        </View>
      </View>
    </ScrollView>
  );
};

const MainScreen = (props) => {
  // console.log("props", props);
  const {
    data,
    userIdentity,
    setUserIdentity,
    prepareCardData,
    name,
    setName,
    fetchData,
    isApiDataLoaded,
  } = props;

  const isFocused = useIsFocused();
  const [cardData, setCardData] = useState([]);
  const [destEmail, setDestEmail] = useState();

  const hideVisible = () => setShow(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleShow = (record) => {
    setDestEmail(record.email);
    setShow(true);
  };

  useEffect(() => {
    if (isFocused) {
      fetchData();
    } else {
      toReset();
    }
  }, [isFocused]);

  const handleNameChange = (value) => {
    if (value) {
      setName(value);
    } else {
      toReset();
      fetchData();
    }
  };

  let [addMessage, setAddMessage] = useState(false);
  let sendRequest = async (sourceEmail, destinationEmail) => {
    addMessage = addMessage ? addMessage : '';
    setShow(false);
    const searchService = new SearchService(props);
    await searchService.sendMentorRequest(sourceEmail, destinationEmail, addMessage);
  };

  const toSearch = () => {
    getUserIdentity().then((uIdentity) => {
      if (uIdentity) {
        setUserIdentity(uIdentity);
        const searchFields = {
          name: name,
          email: uIdentity.email_id,
        };
        validateData(searchFields);
      }
    });
  };

  const validateData = (searchFields) => {
    const isInvalid = checkInputData(searchFields);

    if (isInvalid) {
      Alert.alert('Missing field', isInvalid);
      return false;
    } else {
      fetchData(searchFields);
    }
  };

  const checkInputData = (data) => {
    const { name } = data;
    if (!name) {
      return 'Please enter a name';
    }
    if (name.length <= 2) {
      return 'Name should have at least three letters';
    }
    return false;
  };

  const toReset = () => {
    // console.log('main to reset called!!');
    setName('');
    setCardData([]);
  };

  useEffect(() => {
    if (data && !data.Message) {
      const allRecordsWithoutSource = prepareCardData(data);
      // console.log('allRecordsWithoutSource', allRecordsWithoutSource);
      setCardData(allRecordsWithoutSource);
    }
  }, [data, isFocused]);

  const Loader = () => {
    return (
      <View style={style.centerAlign}>
        <TextElement style={style.infoFeedback}>Loading...</TextElement>
      </View>
    );
  };

  const NoDataFound = () => {
    return (
      <View>
        <View style={style.divider10}></View>
        <Card style={style.cardStyle}>
          <Card.Content>
            <Paragraph style={style.noMentorAvailable}>No mentor available!</Paragraph>
          </Card.Content>
        </Card>
      </View>
    );
  };

  return (
    <ViewElement pageName='Find Mentor' previousPath='/' {...props}>
      <View style={style.container}>
        <Elements.Searchbar
          style={style.searchbar}
          onSubmitEditing={toSearch}
          placeholder='Search by name'
          onChangeText={(name) => {
            handleNameChange(name);
          }}
          value={name}
        />

        <TouchableElement
          onPress={() => {
            menuHandler();
          }}
          style={style.filterButton}
          id='filter'>
          <Avatar.Icon
            icon='filter-menu-outline'
            size={47}
            style={style.filterIcon}
            color='#000000'
          />
        </TouchableElement>
      </View>
      {
        <View style={style.searchView}>
          <ScrollView showsVerticalScrollIndicator={false} showsHorizontalScrollIndicator={false}>
            <View style={style.divider10}></View>

            {isApiDataLoaded ? (
              <View style={style.cardContainer}>
                {cardData.length > 0 ? (
                  cardData.map((record, index) => (
                    <View key={index}>
                      <View style={style.card} key={index}>
                        <View style={style.infoContainer}>
                          <TouchableElement
                            style={style.info}
                            id='see-details'
                            onPress={() => {
                              props.navigation.navigate('Profiledetails', record);
                            }}>
                            <Avatar.Icon
                              size={35}
                              icon='information'
                              style={style.infoIcon}
                              color='#212644'
                            />
                          </TouchableElement>
                        </View>
                        <Avatar.Image
                          size={60}
                          source={require('../../asset/img/userImages/male2.png')}
                          style={style.profileImage}
                        />
                        <Text style={style.name}>
                          {record.name.length > 21 ? record.name.substr(0, 21) + '.' : record.name}
                        </Text>
                        <Text style={style.textStyle}>
                          Dept:{' '}
                          {record.dept.length < 11
                            ? record.dept
                            : record.dept.substring(0, 14) + '...'}
                        </Text>
                        <Text style={style.textStyle}>Location: {record.location}</Text>
                        <Text
                          style={
                            record.status == 'BOOKED'
                              ? { fontSize: 12, fontWeight: 'bold' }
                              : style.textStyle
                          }>
                          Status:{' '}
                          {record.status.toString().charAt(0).toUpperCase() +
                            record.status.toString().toLowerCase().slice(1)}
                        </Text>

                        <View style={style.divider5}></View>

                        {/* <View style={style.divider5}></View> */}

                        <View style={style.connectButtonContainer1}>
                          <View style={style.connectButtonContainer2}>
                            {record.status == 'BOOKED' || record.status == 'NOT_AVAILABLE' ? (
                              <View style={style.divider22}></View>
                            ) : (
                              <TouchableElement
                                style={style.connectButton}
                                onPress={() => {
                                  handleShow(record, index);
                                }}
                                id='connect'>
                                <TextElement theme='DARK' style={style.textStyle}>
                                  Connect
                                </TextElement>
                              </TouchableElement>
                            )}

                            <View style={style.divider5}></View>
                          </View>
                        </View>
                      </View>
                    </View>
                  ))
                ) : (
                  <NoDataFound />
                )}
              </View>
            ) : (
              <Loader />
            )}

            {/* <View style={style.divider100}></View> */}
          </ScrollView>
          <Portal>
            <Modal
              visible={show}
              onDismiss={handleClose}
              contentContainerStyle={style.containerStyle}>
              <KeyboardAvoidingView
                behavior='position'
                style={{ flex: 1, justifyContent: 'center' }}
                keyboardVerticalOffset={Platform.OS == 'android' ? -550 : -450}>
                <View style={style.addConnectionModal}>
                  <TextElement theme={'LIGHT'} text='Remarks' />

                  <TextInputElement
                    theme={'LIGHT'}
                    editable={true}
                    placeholder={'Enter reason'}
                    maxLength={100}
                    onChangeText={(e) => setAddMessage(e)}
                  />

                  <View style={style.buttonRow}>
                    <ButtonElement
                      theme={'LIGHT'}
                      color={ColorConstant.LIGHT?.BUTTON_BACKGROUND_COLOR}
                      style={style.modalButton}
                      onPress={hideVisible}>
                      Cancel
                    </ButtonElement>
                    <ButtonElement
                      theme={'LIGHT'}
                      color={ColorConstant.LIGHT?.BUTTON_BACKGROUND_COLOR}
                      style={style.modalButton}
                      onPress={() => {
                        sendRequest(userIdentity.email_id, destEmail);
                      }}>
                      Send
                    </ButtonElement>
                  </View>
                </View>
              </KeyboardAvoidingView>
            </Modal>
          </Portal>
        </View>
      }
    </ViewElement>
  );
};

export default memo(Search, (prevProps, nextProps) => {
  if (prevProps.theme !== nextProps.theme)
    return false; // Only on theme change the component will get refreshed.
  else {
    return true;
  }
});
