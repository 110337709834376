import { StyleSheet } from 'react-native';

export default StyleSheet.create({
   container: {
      backgroundColor: '#f7f7f7',
    },
    label: {
      padding: 5,
      paddingBottom: 0,
      fontSize: 14,
      color: '#7D8792',
      lineHeight: 20,
      fontWeight: 'bold',
    },
    dropdownContainer: {
      padding: 5,
      flexDirection: 'row',
      alignItems: 'center',
      borderBottomWidth: 2,
      borderColor: '#7D8792',
    },
    dropdownValue: {
      flex: 5.7,
      fontSize: 16,
    },
    icon: {
      backgroundColor: 'transparent',
    },
    scrollView: {
      margin: 2,
      maxHeight: 250,
      zIndex: 999,
      elevation: 9,
    },
    searchInput: {
      margin: 5,
      height: 50,
      width: '95%',
    },
    listItemContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      padding: 5,
    },
    listItemText: {
      fontSize: 14,
      paddingLeft: 10,
    }
});

