import { KeyboardAvoidingView, ScrollView, View } from 'react-native';
import { getDate } from '../../utils/utils';
import { useEffect, useState } from 'react';

import TextElement from '../../elements/Text';
import TextInputElement from '../../elements/TextInput';
import Users from '../User/Users';
import ViewWithBack from '../../elements/ViewWithBack';
import style from './styles/roleStyle';
import { useIsFocused } from '@react-navigation/native';
import { useSelector } from 'react-redux';

const getName = require('../../../utils/NameMapping').getName;
const jp = require('jsonpath');

const Role = (parentProps) => {
    const props = { ...parentProps, ...parentProps.route.params };
    const isFocused = useIsFocused();
    const { roleId } = props.route.params;
    const roles = useSelector((state) => state.roles.value);
    // console.log("roles from redux", roles);
    const selectedRole = roles.filter(selectedRole => selectedRole.id == roleId)[0];
    // console.log("selectedRole", selectedRole);
    const spaces = useSelector(state => state.spaces.value);
    // console.log("spaces", spaces);

    // const [isApiDataLoaded, setIsApiDataLoaded] = useState(true);

    const [spaceTextToBeDisplayed, setspaceTextToBeDisplayed] = useState('');
    const [moduleTextToBeDisplayed, setModuleTextToBeDisplayed] = useState('');

    // const [modules, setModules] = useState('');


    const fetchData = () => {
        if (selectedRole && Object.keys(selectedRole).length) {
            // console.log('selectedRole found', selectedRole);
            // setIsApiDataLoaded(true);
        }

        if (spaces.length) {
            // console.log("spaces found", spaces);
            setSpaceAndModule(spaces);
            // setIsApiDataLoaded(true);
        }
    };

    const setSpaceAndModule = (spaces) => {
        const roleDetails = selectedRole.details;
        // Extracting spaceids from selected role
        const roleSpaceList = jp.query(roleDetails, '$..space');

        // Extracting moduleIds from selected role
        let roleModuleList = jp.query(roleDetails, '$..modules').flat();

        let spaceNames = [];
        let moduleNames = [];

        // constructing space names to display
        roleSpaceList.forEach(spaceId => {
            spaceNames.push(jp.query(spaces, `$[?(@.id=='${spaceId}')]`)[0]?.display_name || '');
        });

        // constructing module names to display
        roleModuleList.forEach(moduleId => {
            if (roleModuleList.includes('*'))
                moduleNames = ['All'];
            else
                moduleNames.push(jp.query(spaces, `$..modules[?(@.id=='${moduleId}')]`)[0]?.display_name || '');
        });

        setspaceTextToBeDisplayed(spaceNames.join(', '));
        setModuleTextToBeDisplayed(moduleNames.join(', '));
    };

    useEffect(() => {
        if (isFocused) {
            fetchData();
        }
    }, [isFocused]);

    return (
        <ViewWithBack pageName="Role"  {...props} backAction={() => { props.navigation.navigate('Roles'); }} >

            <ScrollView
                showsVerticalScrollIndicator={false}
                showsHorizontalScrollIndicator={false}
                style={style.scrollview}
            >
                <KeyboardAvoidingView behavior="padding" style={style.keyboard}>
                    <View style={style.main}>
                        <View style={style.divider}></View>
                        <View style={style.roleContainer}>
                            <View style={style.infoContainer}>
                                <TextElement theme={'DARK'} style={style.header}>Tags: {selectedRole.tags ? selectedRole.tags : '-'}</TextElement>
                                <View style={style.divider5}></View>
                                <TextElement theme={'DARK'} style={style.header}>Access : {selectedRole.access}</TextElement>
                                <View style={style.divider5}></View>
                                <TextInputElement
                                    theme="LIGHT"
                                    label="Space Name"
                                    value={spaceTextToBeDisplayed}
                                    editable={false}
                                    labelStyle={style.labelStyle} />

                                <TextInputElement
                                    theme="LIGHT"
                                    label="Module Name"
                                    value={moduleTextToBeDisplayed}
                                    editable={false}
                                    labelStyle={style.labelStyle} />

                            </View>
                            <View style={style.divider5}></View>
                            <TextElement style={style.textStyle}>Description: {selectedRole.description}</TextElement>
                            <TextElement style={style.textStyle}>Id : {selectedRole.id}</TextElement>

                            <TextElement style={style.textStyle}>Created by {selectedRole.created_by ? getName(selectedRole.created_by) : '-'} on {selectedRole.created_at ? getDate(selectedRole.created_at) : '-'}</TextElement>

                            {
                                selectedRole.updated_by ?
                                    <TextElement style={style.textStyle}>Updated by {selectedRole.updated_by ? getName(selectedRole.updated_by) : '-'} on {selectedRole.updated_at ? getDate(selectedRole.updated_at) : '-'}</TextElement>
                                    :
                                    <View></View>
                            }
                        </View>
                    </View>
                    <Users {...props} roleId={selectedRole.id}></Users>
                </KeyboardAvoidingView>
            </ScrollView>

        </ViewWithBack>
    );
};

export default Role;