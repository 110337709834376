import { StyleSheet } from 'react-native';
import journeyConfig from '../../JourneyConfig.json';

export default StyleSheet.create({
    divider: { backgroundColor: journeyConfig.travelexColors.TRXBlue },
    singleSelectContainer: { padding: 2 },
    rowContainer: { flexDirection: 'row' },
    bottomSpace: { height: 70 },
    divider5: { height: 5 },
    option: { flex: 3, margin: 5 },
    icon: { backgroundColor: 'transparent' },
    nextQuestion: { flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' },
    nextQuestionButton: { fontWeight: 'bold', fontSize: 16 },
    keyboard: { height: '100%', justifyContent: 'center' },
    submit: { flexDirection: 'row', justifyContent: 'flex-end' },
    submitButton: { backgroundColor: journeyConfig.travelexColors.actionBlue },
    container: { width: '100%', alignItems: 'center', height: '100%' },
    container1: { width: '100%', height: '100%' },
    contentContainer: { padding: 5 },
    divider10: { height: 10 }
});


