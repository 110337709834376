//unused page

import 'intl';
import 'intl/locale-data/jsonp/en';

import { LogBox, TouchableOpacity } from 'react-native';
import { ProgressStep, ProgressSteps } from 'react-native-progress-steps';
import React, { memo, useEffect } from 'react';
import { ScrollView, StyleSheet, Switch, Text, View } from 'react-native';

import Alert  from './elements/Alert';
import AvatarElement from './elements/Avatar';
import { Dimensions } from 'react-native';
import DividerElement from './elements/Divider';
import DropdownElement from './elements/Dropdown';
import MultiSelectElement from './elements/Multiselect';
import ProfileService from '../service/ProfileService';
import TagInput from './elements/TagInput';
import TextInputElement from './elements/TextInput';
import TimePickerModal from './elements/TimePickerModal';
import ViewWithBack from './elements/ViewWithBack';
import { getTimeWithMode } from './utils/utils';
import icon from '../asset/img/userImages/male6.jpg';
import { useIsFocused } from '@react-navigation/native';

LogBox.ignoreLogs(['EventEmitter.removeListener']);

const moment = require('moment');

const { height } = Dimensions.get('window');

const textStyle = { flexShrink: 1, padding: 7, fontSize: 16, backgroundColor: '#ffffff' };
const dropDownStyles = StyleSheet.create({
    container: { padding: 16 },
    dropdown: {
        height: 50,
        backgroundColor: 'transparent',
        borderBottomColor: 'gray',
        borderBottomWidth: 0.5,
    },
    placeholderStyle: {
        fontSize: 16,
    },
    selectedTextStyle: {
        fontSize: 14,
    },
    iconStyle: {
        width: 20,
        height: 20,
    },
    icon: {
        marginRight: 5,
        padding: 7
    },
    selectedStyle: {
        display: 'none'
    }
});

const Profile = (props) => {
    const profileService = new ProfileService(props);
    const isFocused = useIsFocused();
    const [isMentor, setIsMentor] = React.useState(false);
    const handleToggle = () => {
        setIsMentor(!isMentor);
    };

    // Time Picker
    const [visibleFromTime, setVisibleFromTime] = React.useState(false);
    const [visibleToTime, setVisibleToTime] = React.useState(false);
    const [fromTime, setFromTime] = React.useState('');
    const [toTime, setToTime] = React.useState('');
    const onDismissFromTime = React.useCallback(() => {
        setVisibleFromTime(false);
    }, [setVisibleFromTime]);
    const onDismissToTime = React.useCallback(() => {
        setVisibleToTime(false);
    }, [setVisibleToTime]);
    const [softSkills, setSoftSkills] = React.useState([]);
    const [tools, setTools] = React.useState([]);
    const [technologies, settechnologies] = React.useState([]);
    const [aoe, setAOE] = React.useState([]);
    const [knowledge, setKnowledge] = React.useState([]);
    const [others, setOthers] = React.useState([]);
    const [aboutMe, setAboutMe] = React.useState('');
    const [linkedinId, setLinkedinId] = React.useState('');
    const [userIdentity, setUserIdentity] = React.useState({});


    // const data = [
    //     { label: 'Mentor', value: '1' },
    //     { label: 'Mentee', value: '2' },
    //     { label: 'HR Admin', value: '3' },
    // ];
    const [jobLevel, setJobLevel] = React.useState('');
    const jobLevelList = [
        {
            label: '1 : Senior Executive: CEO, Executive Director or Executive Committee member, Non-Executive Director',
            value: '1',
        },
        {
            label: '2 : Executive: Commercial Director, Lead Functional Director, Country Lead, Managing Director',
            value: '2',
        },
        {
            label: '3 : Director/Head of: Functional Director for a Geo, Head of Department within a function, Company Secretary',
            value: '3',
        },
        {
            label: '4 : Senior Manager/Senior SME: Divisional Manager, Senior Manager in a Department, Senior SME/ Specialist, Senior legal Counsel, Senior Architect',
            value: '4',
        },
        {
            label: '5 : Manager/SME: Area Manager, Manager in a Department, Team Manager, Executive Assistant, SME/ Specialist, Product Owner, Legal Counsel, Technical manager, Senior Developer, Engineer',
            value: '5',
        },
        {
            label: '6 : Senior colleague: Senior Advisor, Developers, Designers, Senior /Lead Analyst, Officer, Business Partner, Specialist Administrator, Technical Lead, Technical Team Leader, Architect, Scrum master',
            value: '6',
        },
        {
            label: '7 : Intermediate colleague: Bureau Manager, Advisor, Analyst',
            value: '7',
        },
        {
            label: '8 : Associate colleague: Travel Service Partner, Coordinator, Administrator, TRP',
            value: '8'
        }

    ];

    const [communicationMode, setCommunicationMode] = React.useState('');
    const commList = [
        { value: 'Face To Face', label: 'Face To Face' },
        { value: 'Teams', label: 'Teams' },
    ];

    const [days, setDays] = React.useState('');
    const daysList = [
        { value: 'Sunday', label: 'Sunday' },
        { value: 'Monday', label: 'Monday' },
        { value: 'Tuesday', label: 'Tuesday' },
        { value: 'Wednesday', label: 'Wednesday' },
        { value: 'Thursday', label: 'Thursday' },
        { value: 'Friday', label: 'Friday' },
        { value: 'Saturday', label: 'Saturday' },
    ];
    const [status, setStatus] = React.useState('OPEN');
    const statusList = [
        { value: 'OPEN', label: 'OPEN' },
        { value: 'BOOKED', label: 'BOOKED' },
        // { value: 'AVAILABLE', label: 'AVAILABLE' },
        // { value: 'DISABLED', label: 'DISABLED' },
        { value: 'NOT_AVAILABLE', label: 'NOT AVAILABLE' },
    ];

    const onConfirmFromTime = React.useCallback(
        ({ hours, minutes }) => {
            setVisibleFromTime(false);
            hours = hours.toString().length > 1 ? hours : '0' + hours;
            minutes = minutes.toString().length > 1 ? minutes : '0' + minutes;
            setFromTime(hours + ':' + minutes);
        },
        []
    );
    const onConfirmToTime = React.useCallback(
        ({ hours, minutes }) => {
            setVisibleToTime(false);
            hours = hours.toString().length > 1 ? hours : '0' + hours;
            minutes = minutes.toString().length > 1 ? minutes : '0' + minutes;
            setToTime(hours + ':' + minutes);
        },
        []
    );


    let addProfile = async () => {
        const userEmailId = userIdentity.email_id;
        const department = userIdentity.department;
        const jobTitle = userIdentity.jobTitle;
        const location = userIdentity.country;

        // console.log('userEmailId', userEmailId);
        if (aboutMe && jobLevel && aoe.length) {

            if (fromTime && toTime) {
                const fromHour = fromTime.split(':')[0];
                const toHour = toTime.split(':')[0];
                const fromMinute = fromTime.split(':')[1];
                const toMinute = toTime.split(':')[1];
                let diff = moment.duration(moment.duration(toTime).subtract(moment.duration(fromTime)));
                if (toHour < fromHour || ((toHour == fromHour) && (toMinute < fromMinute))) {
                    Alert.alert('Bad input','Please select To Time greater than From Time');
                } else if ((diff.hours() == 0) && (diff.minutes() < 30)) {
                    Alert.alert('Info','Availability should be minimum of 30 minutes');
                } else {
                    let skills = {
                        'soft_skills': softSkills,
                        'tools': tools,
                        'technologies': technologies,
                        'industry_knowledge': knowledge,
                        'others': others,
                    };
                    let sendRequestParams = {
                        data: {
                            'name': {
                                first: userIdentity && userIdentity.email_id ? getName(userIdentity.email_id).firstName : '',
                                last: userIdentity && userIdentity.email_id ? getName(userIdentity.email_id).lastName : ''
                            },
                            'email_id': userEmailId,
                            'department': department,
                            'location': location,
                            'jobTitle': jobTitle,
                            'about_me': aboutMe,
                            'linkedin_id': linkedinId ? linkedinId : userIdentity && userIdentity.email_id ? getName(userIdentity.email_id).firstName + getName(userIdentity.email_id).lastName : '',
                            'job_level': jobLevel,
                            'area_of_expertize': aoe.join(','),
                            'skills': skills,
                            'mentor': {
                                'status': status,
                                'mentoring_skills': skills,
                            },
                            'mentee': {},
                            'preferences': {
                                'communication_modes': communicationMode,
                                'availability': {
                                    'days': days,
                                    'times': [
                                        {
                                            'from': fromTime,
                                            'to': toTime
                                        }
                                    ]
                                }

                            },
                            'is_mentor': isMentor ? isMentor : false
                        }
                    };

                    await profileService.createProfile(sendRequestParams.data);
                    // console.log("addProfile response ", response);
                }
            } else if (!fromTime && !toTime) {
                let skills = {
                    'soft_skills': softSkills,
                    'tools': tools,
                    'technologies': technologies,
                    'industry_knowledge': knowledge,
                    'others': others,
                };
                let sendRequestParams = {
                    data: {
                        'name': {
                            first: userIdentity && userIdentity.email_id ? getName(userIdentity.email_id).firstName : '',
                            last: userIdentity && userIdentity.email_id ? getName(userIdentity.email_id).lastName : ''
                        },
                        'email_id': userEmailId,
                        'department': department,
                        'location': location,
                        'jobTitle': jobTitle,
                        'about_me': aboutMe,
                        'linkedin_id': linkedinId ? linkedinId : userIdentity && userIdentity.email_id ? getName(userIdentity.email_id).firstName + getName(userIdentity.email_id).lastName : '',
                        'job_level': jobLevel,
                        'area_of_expertize': aoe.join(','),
                        'skills': skills,
                        'mentor': {
                            'status': status,
                            'mentoring_skills': skills,
                        },
                        'mentee': {},
                        'preferences': {
                            'communication_modes': communicationMode,
                            'availability': {
                                'days': days,
                                'times': [
                                    {
                                        'from': fromTime,
                                        'to': toTime
                                    }
                                ]
                            }

                        },
                        'is_mentor': isMentor ? isMentor : false
                    }
                };

                await profileService.createProfile(sendRequestParams.data);
                // console.log("addProfile response ", response);
            } else if (fromTime && !toTime) {
                Alert.alert('Missing input','Please select To Time');
            } else if (toTime && !fromTime) {
                Alert.alert('Missing input','Please select From Time');
            }

        } else {

            let missingFields = [];
            if (!aboutMe) {
                missingFields.push('About Me');
            }
            if (!jobLevel) {
                missingFields.push('Job Level');
            }
            if (!aoe.length) {
                missingFields.push('Area Of Expertise');
            }

            const alertMessage = `Please enter mandatory fields: ${missingFields}`;
            Alert.alert('Missing input',alertMessage);
        }
    };

    let getName = (email_id) => {

        let firstName = email_id.replace('@travelex.com', '').split('.')[0];
        firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
        let lastName = email_id.replace('@travelex.com', '').split('.')[1];
        lastName = lastName.charAt(0).toUpperCase() + lastName.slice(1);

        return {
            firstName: firstName,
            lastName: lastName
        };
    };

    useEffect(() => {
        const fetchData = () => {
            // let userIdentity = JSON.parse(await ApplicationStorage.getItem('userIdentity'));
            props.getUserIdentity().then((uIdentity) => {
                if (uIdentity) {
                    setUserIdentity(uIdentity);
                    profileService.fetchProfile(uIdentity.email_id).then((up) => {
                        // console.log("up", JSON.stringify(up))
                        if (!up.error_message) {
                            props.navigation.navigate('Dashboard');
                        }
                    });
                }
            });

        };
        if (isFocused) {
            fetchData();
        }
    }, [isFocused]);

    return (

        <ViewWithBack pageName={'Complete Profile'} backAction={() => { props.navigation.navigate('Dashboard'); }}>
            <View style={{ backgroundColor: '#cce9f4', width: '100%', alignItems: 'center' }}>
                <ScrollView style={{ width: '100%', maxHeight: (height * (90 / 100)) }}
                    showsVerticalScrollIndicator={false}
                    showsHorizontalScrollIndicator={false}>
                    <View>

                        <View style={{ backgroundColor: '#cce9f4', padding: 10, height: '100%' }}>
                            <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                                <AvatarElement path={icon} size={100} />
                            </View>

                            <ProgressSteps progressBarColor="#0096FF" activeStepIconColor="#0096FF" activeStepIconBorderColor="#0096FF" disabledStepIconColor="#0096FF" labelColor="#0096FF" activeLabelColor="#0096FF" completedProgressBarColor="green" completedStepIconColor="green">
                                <ProgressStep label="Personal" style={{ backgroundColor: '#f5f5f5' }}>
                                    <View>
                                        <View style={{ alignItems: 'flex-start', width: '100%' }}>
                                            <Text style={{ color: '#F47765' }}>*Please use comma ( , ) to add values</Text>
                                        </View>
                                        <DividerElement height={15} />

                                        <DividerElement height={15} />
                                        <View>
                                            <TextInputElement
                                                label='First Name'
                                                value={userIdentity && userIdentity.email_id ? getName(userIdentity.email_id).firstName : ''}
                                            />
                                            <DividerElement height={15} />

                                            <TextInputElement
                                                label='Last Name'
                                                value={userIdentity && userIdentity.email_id ? getName(userIdentity.email_id).lastName : ''}

                                            />
                                            <DividerElement height={15} />

                                            <TextInputElement
                                                label='Email'
                                                value={userIdentity && userIdentity.email_id ? userIdentity.email_id : ''}
                                            />
                                            <DividerElement height={15} />

                                        </View>

                                        <View>
                                            <Text style={textStyle}>LinkedIn ID</Text>
                                            <TextInputElement
                                                onChangeText={(linkedinId) => {
                                                    setLinkedinId(linkedinId);
                                                }}
                                                value={linkedinId}
                                            />
                                        </View>
                                        <DividerElement height={15} />

                                        <View>
                                            <Text style={textStyle}>About Me<Text style={{ color: '#F47765' }}> *</Text></Text>
                                            <TextInputElement
                                                onChangeText={(aboutMe) => {
                                                    setAboutMe(aboutMe);
                                                }}
                                                value={aboutMe}
                                            />
                                        </View>
                                        <DividerElement height={15} />
                                        <View style={{ backgroundColor: '#ffffff', borderRadius: 10 }}>
                                            <Text style={textStyle}>Select Job Level<Text style={{ color: '#F47765' }}> *</Text></Text>

                                            <DropdownElement
                                                data={jobLevelList}
                                                value={jobLevel}
                                                onChange={item => {
                                                    setJobLevel(item.value);
                                                }}
                                                style={dropDownStyles.dropdown}
                                                placeholderStyle={dropDownStyles.placeholderStyle}
                                                selectedTextStyle={dropDownStyles.selectedTextStyle}
                                                iconStyle={dropDownStyles.iconStyle}
                                                leftIconStyle={dropDownStyles.icon} />
                                        </View>
                                        <DividerElement height={15} />

                                        <View style={{ backgroundColor: '#ffffff', borderRadius: 10 }}>
                                            <TextInputElement
                                                label='Department'
                                                value={userIdentity && userIdentity.department ? userIdentity.department : ''}
                                            />
                                        </View>
                                        <DividerElement height={15} />

                                        <View style={{ backgroundColor: '#ffffff', borderRadius: 10 }}>
                                            <TextInputElement
                                                label='Location'
                                                value={userIdentity && userIdentity.country ? userIdentity.country : ''}
                                            />
                                        </View>
                                        <DividerElement height={15} />

                                        <View style={{ backgroundColor: '#ffffff', borderRadius: 10 }}>
                                            <TextInputElement
                                                label='Job Title'
                                                value={userIdentity && userIdentity.jobTitle ? userIdentity.jobTitle : ''}
                                            />
                                        </View>
                                        <DividerElement height={15} />
                                    </View>
                                </ProgressStep>
                                <ProgressStep label="Professional" >
                                    <View>
                                        <View style={{ alignItems: 'flex-start', width: '100%' }}>
                                            <Text style={{ color: '#F47765' }}>*Please use comma ( , ) to add values</Text>
                                        </View>
                                        <DividerElement height={15} />

                                        <View style={{ backgroundColor: '#ffffff', borderRadius: 10 }}>
                                            <Text style={textStyle}>Enter Area of Expertise<Text style={{ color: '#F47765' }}> *</Text></Text>
                                            <TagInput value={aoe} setter={setAOE} />
                                        </View>

                                        <DividerElement height={15} />

                                        <View style={{ backgroundColor: '#ffffff', borderRadius: 10 }}>
                                            <Text style={textStyle}>Enter Soft Skills</Text>
                                            <TagInput value={softSkills} setter={setSoftSkills} />
                                        </View>
                                        <DividerElement height={15} />

                                        <View style={{ backgroundColor: '#ffffff', borderRadius: 10 }}>
                                            <Text style={textStyle}>Enter Tools</Text>
                                            <TagInput value={tools} setter={setTools} />
                                        </View>
                                        <DividerElement height={15} />

                                        <View style={{ backgroundColor: '#ffffff', borderRadius: 10 }}>
                                            <Text style={textStyle}>Enter Technologies</Text>
                                            <TagInput value={technologies} setter={settechnologies} />
                                        </View>
                                        <DividerElement height={15} />

                                        <View style={{ backgroundColor: '#ffffff', borderRadius: 10 }}>
                                            <Text style={textStyle}>Enter Industry Knowledge</Text>
                                            <TagInput value={knowledge} setter={setKnowledge} />
                                        </View>
                                        <DividerElement height={15} />

                                        <View style={{ backgroundColor: '#ffffff', borderRadius: 10 }}>
                                            <Text style={textStyle}>Enter Other Info</Text>
                                            <TagInput value={others} setter={setOthers} />
                                        </View>
                                        <DividerElement height={15} />
                                    </View>
                                </ProgressStep>
                                <ProgressStep label="Preferences" style onSubmit={() => {
                                    addProfile();

                                }}>
                                    <View>
                                        <View style={{ alignItems: 'flex-start', width: '100%' }}>
                                            <Text style={{ color: '#F47765' }}>*Please use comma ( , ) to add values</Text>
                                        </View>
                                        <DividerElement height={15} />

                                        <View style={{ backgroundColor: '#ffffff', borderRadius: 10 }}>
                                            <Text style={textStyle}>Select Days</Text>
                                            <MultiSelectElement
                                                data={daysList}
                                                value={days}
                                                onChange={item => {

                                                    setDays(item);
                                                }}
                                                style={dropDownStyles.dropdown}
                                                placeholderStyle={dropDownStyles.placeholderStyle}
                                                selectedTextStyle={dropDownStyles.selectedTextStyle}
                                                iconStyle={dropDownStyles.iconStyle}
                                                leftIconStyle={dropDownStyles.icon}
                                                selectedStyle={dropDownStyles.selectedStyle} />
                                        </View>
                                        <DividerElement height={15} />

                                        <View style={{ backgroundColor: '#ffffff', borderRadius: 10 }}>
                                            <Text style={textStyle}>Select Status</Text>
                                            <DropdownElement
                                                data={statusList}
                                                value={status}
                                                onChange={item => {
                                                    if (status == 'BOOKED') {
                                                        Alert.alert('Info','You cannot change the Booked status');
                                                    } else {
                                                        setStatus(item.value);
                                                    }
                                                }}
                                                style={dropDownStyles.dropdown}
                                                placeholderStyle={dropDownStyles.placeholderStyle}
                                                selectedTextStyle={dropDownStyles.selectedTextStyle}
                                                iconStyle={dropDownStyles.iconStyle}
                                                leftIconStyle={dropDownStyles.icon} />
                                        </View>
                                        <DividerElement height={15} />

                                        <View style={{ backgroundColor: '#ffffff', borderRadius: 10 }}>
                                            <Text style={textStyle}>Select Communication Modes</Text>
                                            <MultiSelectElement
                                                data={commList}
                                                value={communicationMode}
                                                onChange={item => {

                                                    setCommunicationMode(item);
                                                }}
                                                style={dropDownStyles.dropdown}
                                                placeholderStyle={dropDownStyles.placeholderStyle}
                                                selectedTextStyle={dropDownStyles.selectedTextStyle}
                                                iconStyle={dropDownStyles.iconStyle}
                                                leftIconStyle={dropDownStyles.icon}
                                                selectedStyle={dropDownStyles.selectedStyle} />
                                        </View>
                                        <DividerElement height={15} />


                                        <View>
                                            <TimePickerModal
                                                visible={visibleFromTime}
                                                onDismiss={onDismissFromTime}
                                                onConfirm={onConfirmFromTime}
                                                label="Select from time"
                                            />
                                            <View style={{ width: '100%', alignItems: 'flex-start' }}>
                                                <TouchableOpacity style={{ backgroundColor: 'white', padding: 20, width: '100%' }} onPress={() => { setVisibleFromTime(true); }}>
                                                    <Text style={textStyle}>From Time : {getTimeWithMode(fromTime)}</Text>
                                                </TouchableOpacity>

                                            </View>
                                        </View>

                                        <DividerElement height={15} />
                                        <View>
                                            <TimePickerModal
                                                visible={visibleToTime}
                                                onDismiss={onDismissToTime}
                                                onConfirm={onConfirmToTime}
                                                label="Select to time"
                                            />
                                            <View style={{ width: '100%', alignItems: 'flex-start' }}>
                                                <TouchableOpacity style={{ backgroundColor: 'white', padding: 20, width: '100%' }} onPress={() => { setVisibleToTime(true); }}>
                                                    <Text style={textStyle}>To Time : {getTimeWithMode(toTime)}</Text>
                                                </TouchableOpacity>
                                            </View>
                                        </View>

                                        <DividerElement height={15} />
                                        <View style={{ flexDirection: 'row' }}>
                                            <Switch
                                                onValueChange={handleToggle}
                                                color="#00BFFF"
                                                // thumbColor={isMentor ? "#0096FF" : "#f4f3f4"}
                                                // trackColor={isMentor ? "#0096FF" : "grey"}
                                                value={isMentor}
                                            >
                                            </Switch>
                                            <Text> Are you interested to be Mentor?</Text>
                                        </View>
                                        <DividerElement height={15} />
                                    </View>
                                </ProgressStep>
                            </ProgressSteps>
                        </View>
                    </View>
                </ScrollView>
            </View>
        </ViewWithBack>
        /* <Portal>
            <Modal visible={isLoading} onDismiss={!isLoading} contentContainerStyle={containerStyle}>
                <ActivityIndicator animating={true} color="#0096FF" />
            </Modal>
        </Portal> */


    );
};


export default memo(Profile, (prevProps, nextProps) => {
    if (prevProps.theme !== nextProps.theme)
        return false;
    else
        return true;
});
