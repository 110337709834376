import 'react-native-gesture-handler';

import * as Device from 'expo-device';
import * as React from 'react';

import { Platform, View } from 'react-native';
import { StatusBar, Text } from 'react-native';
import { useEffect, useState } from 'react';

import ApplicationStorage from './utils/ApplicationStorage';
import { Avatar } from 'react-native-paper';
import CustomLoader from './components/elements/customLoader/CustomLoader';
import Main from './routers/Main';
import { Provider } from 'react-redux';
import store from './Store/Store';
import { usePreventScreenCapture } from 'expo-screen-capture';

export default function App() {

  const [theme] = useState('LIGHT');
  const [isRooted, setIsRooted] = useState(false);

  const checkRootedDevice = () => {
    Device.isRootedExperimentalAsync().then((isRooted) => {
      if (isRooted) {
        setIsRooted(true);
      }
    });
  };

  if (Platform.OS !== 'web') {
    usePreventScreenCapture();
    checkRootedDevice();
  }
  const [siteBlock, setSiteBlock] = useState(false);

  const properties = {
    theme,
    // setTheme
  };

  useEffect(() => {
    if (Platform.OS === 'web') {
      import('@microsoft/teams-js').then(microsoftTeams => {
        microsoftTeams.initialize(() => {
          setSiteBlock(true);
        });
      });
    }
    else {
      setSiteBlock(true);
    }
  }, [siteBlock]);


  return (
    <Provider store={store}>
      <StatusBar backgroundColor='#212644' barStyle='light-content' />
      {isRooted ? <RootedView /> : <View />}
      {siteBlock && isRooted == false ? <Main properties={properties} />
        : <NotAuthorisedView />
      }
      <CustomLoader />
    </Provider>
  );
}


const RootedView = () => {
  return (
    <View style={{ height: '100%', justifyContent: 'center', alignItems: 'center', backgroundColor: '#ffffff' }}>
      <Avatar.Icon size={50} icon='alert' style={{ backgroundColor: '#1c3e95' }} />
      <Text>{'\n'}</Text>
      <Text>This app is not allowed to run on your device!</Text>
    </View>
  );
};

const NotAuthorisedView = () => {
  return (
    <View style={{ height: '100%', justifyContent: 'center', alignItems: 'center', backgroundColor: '#ffffff' }}>
      <Avatar.Icon size={64} icon='alert' style={{ backgroundColor: 'transparent' }} color='#DA292F' />
      <Text>{'\n'}</Text>
      <Text>You are not authorized to access this portal</Text>
    </View>
  );
};