import { StyleSheet } from 'react-native';
import ColorConstant from '../../../../utils/ColorConstant.json';

export default StyleSheet.create({
    heightWeight100: {
        height: '100%',
        width: '100%'
    },
    icon: { backgroundColor: 'transparent' },
    noResultContainer: { alignItems: 'center' },
    noResultsText: { fontSize: 11, color: '#ADADAD' },
    loadingContainer: {
        alignItems: 'center'
    },
    loadingText: {
        fontSize: 11,
        color: '#CECECE',
        marginTop: 10
    },
    mandatory: { color: '#F47765' },
    dialogButtonRow: { flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'flex-end' },
    button: {
        backgroundColor: ColorConstant.LIGHT?.BACKGROUND_COLOR_MEDIUM_SHADE || 'white'
    },
    textStyle: { fontSize: 12 },
    header: { fontSize: 12, fontWeight: 'bold' },
    bottomSpace: { height: 80 },
    dialogView: {
        height: '100%',
        justifyContent: 'center'
    },
    dialogContainer: { backgroundColor: ColorConstant.LIGHT?.BACKGROUND_COLOR_MEDIUM_SHADE || 'white', width: 350, padding: 20 },
    historyContainer: { backgroundColor: '#f0f0f0', borderWidth: 0.3, borderColor: '#F8F8FF', borderRadius: 5, padding: 10, marginLeft: 5, width: '100%' },
    cardContainer: { padding: 5, alignItems: 'center' },
    commentsContainer: { backgroundColor: '#ffffff' },
    commentsHeader: { padding: 5, fontWeight: 'bold', backgroundColor: '#f5f5f5' },
    reviewJourneyView: { width: '100%', alignItems: 'center', height: '100%' },
    containerStyle: { height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center' },
});