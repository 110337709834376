import Alert from './Alert';
import React from 'react';
import { Searchbar } from 'react-native-paper';

const SearchbarElement = (props) => {
  return (
    <Searchbar
      style={props?.style}
      onSubmitEditing={() => {
        const reg = new RegExp('[^A-Za-z0-9 ]');
        if (props.value.search(reg) !== -1) {
          Alert.alert('info', 'Special characters are not allowed.');
        } else {
          props.onSubmitEditing();
        }
      }}
      placeholder={props.placeholder}
      onChangeText={props.onChangeText}
      value={props.value}
    />
  );
};

export default SearchbarElement;
