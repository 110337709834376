import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { TabBar, TabView } from 'react-native-tab-view';

import MenteeSurvey from './MenteeSurvey';
import MySurvey from './MySurvey';
import UserSurvey from './UserSurvey';
import ViewWithBack from '../elements/ViewWithBack';
import { getUserIdentity } from '../../utils/UserIdentity';
import { useNavigation } from '@react-navigation/native';

export default function Surveys() {
  const [index, setIndex] = useState(0);

  const [userRole, setUserRole] = useState('');
  const navigation = useNavigation();

  const routes = [
    { key: 'mysurvey', title: 'For my Mentor' },
    { key: 'menteesurvey', title: 'For me' },
  ];

  const adminRoutes = [
    { key: 'mysurvey', title: 'For my Mentor' },
    { key: 'menteesurvey', title: 'For me' },
    { key: 'usersurvey', title: 'For Users' },
  ];

  const renderScene = ({ route }) => {
    switch (route.key) {
      case 'mysurvey':
        return <MySurvey />;
      case 'menteesurvey':
        return <MenteeSurvey />;
      case 'usersurvey':
        return <UserSurvey />;
      default:
        return <View />;
    }
  };

  useEffect(() => {
    getUserIdentity().then((d) => {
      setUserRole(d.user_role);
    });
  }, []);

  return (
    <ViewWithBack
      pageName='Session Feedback'
      backAction={() => {
        navigation.goBack();
      }}>
      <View style={style.tabView}>
        <TabView
          key={userRole}
          navigationState={{ index, routes: userRole === 'admin' ? adminRoutes : routes }}
          renderScene={renderScene}
          onIndexChange={(index) => setIndex(index)}
          renderTabBar={(props) => <TabBar style={style.tabbar} {...props} />}
        />
      </View>
    </ViewWithBack>
  );
}

const style = StyleSheet.create({
  tabView: { flex: 1, backgroundColor: '#ffffff' },
  tabbar: { backgroundColor: '#3C436C' },
});
