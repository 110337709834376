import { createSlice } from '@reduxjs/toolkit';

const counterSlice = createSlice({
    name: 'learnerJourneys',
    initialState: {
        value: {
            'stats': {},
            'completedJourneys': [],
            'enrolledJourneys': [],
            'globalJourneys': [],
            'teamJourneys': []
        },
        allJourneys: []
    },
    reducers: {
        journeyReducer: (state, action) => {
            // console.log("Reducer called", action);
            const journeys = action.payload.journeys;
            state.value = journeys;
            state.allJourneys = Object.keys(journeys).length ? [...journeys.completedJourneys, ...journeys.enrolledJourneys, ...journeys.globalJourneys, ...journeys.teamJourneys] : [];

        }
    }

});

// Action creators are generated for each case reducer function
export const { journeyReducer } = counterSlice.actions;
export default counterSlice.reducer;
