import { Dimensions, Text, View, ImageBackground } from 'react-native';
import { useEffect, useState } from 'react';

import { ActivityIndicator } from 'react-native-paper';
import NotAvailable from '../elements/NotAvailable';
import { loadData } from '../utils/utils';
import style from './Style';
import { useIsFocused } from '@react-navigation/native';
const { height } = Dimensions.get('window');
import { SafeAreaView } from 'react-native';
import Back from '../../Background.jpg';

const Auth = (parentProps) => {
  const props = { ...parentProps, ...parentProps.route.params };
  const isFocused = useIsFocused();
  const [isNotAllowedCountry, setIsNotAllowedCountry] = useState(false);
  useEffect(() => {
    if (props.route && props.route.params && props.route.params.code) {
      loadData(props.route.params.code, props, setIsNotAllowedCountry, () => {});
    }
  }, [isFocused]);

  return isNotAllowedCountry ? (
    <NotAvailable />
  ) : (
    // <View style={{ marginTop: (height - 100) / 2, ...style.container }}>
    //     <ActivityIndicator animating={true} color="#000000" />
    //     <Text style={style.textStyle}> Authenticating...</Text>
    // </View>
    <SafeAreaView style={{ backgroundColor: '#212644', flex: 1 }}>
      <ImageBackground source={Back} resizeMode='cover' style={style.image}>
        <View style={{ marginTop: (height - 100) / 2, ...style.container }}>
          <ActivityIndicator animating={true} color='#ffffff' />
          <Text style={style.textStyle}> Authenticating...</Text>
        </View>
      </ImageBackground>
    </SafeAreaView>
  );
};
export default Auth;
