import AntDesign from 'react-native-vector-icons/AntDesign';
import ColorConstant from '../../utils/ColorConstant.json';
import { Dropdown } from 'react-native-element-dropdown';
import { Text } from 'react-native';
import { memo } from 'react';

const DropDownElement = (props) => {
    return (
        <Dropdown
            testID={props?.label || props?.placeholder}
            accessibilityLabel={props.placeholder || 'dropdown'}
            itemTestIDField={props.placeholder || 'dropdown'}
            style={props?.style}
            placeholderStyle={{ color: ColorConstant[props.theme]?.COLOR || '#000000' ,...props.placeholderStyle}}
            selectedTextStyle={{ color: ColorConstant[props.theme]?.COLOR || '#000000',...props.selectedTextStyle }}
            iconStyle={props?.iconStyle}
            data={props.data}
            maxHeight={props?.maxHeight}
            labelField="label"
            valueField="value"
            placeholder={props.placeholder ? props.placeholder : 'Select'}
            value={props.value}
            activeColor={ColorConstant[props.theme]?.DROPDOWN_BACKGROUND_COLOR}
            onChange={props.onChange}
            onFocus={props?.onFocus}
            onBlur={props?.onBlur}
            renderLeftIcon={() => (
                props.leftIconStyle ? (<AntDesign
                    style={props.leftIconStyle}
                    color={props.value.length ? ColorConstant[props.theme]?.DROPDOWN_ICON : 'black'}
                    name="Safety"
                    size={20}
                />) : (<Text></Text>)

            )}
        />
    );
};

export default memo(DropDownElement, (prev, next) => {
    return JSON.stringify(prev.value || []) == JSON.stringify(next.value || []);
});