import { memo, useState } from 'react';

import { FAB } from 'react-native-paper';

const Fab = (props) => {
  const [open, setOpen] = useState(false);
  return (
    <FAB.Group
      testID={props.testID || 'fab-icon'}
      accessibilityLabel={props.testID || 'fab-icon'}
      style={{
        position: 'absolute',
        // margin: 16,
        right: 0,
        bottom: '2%',
        zIndex: 3,
        elevation: 3,
      }}
      open={open}
      visible
      color='#ffffff'
      fabStyle={{ backgroundColor: '#2EADB7' }}
      icon={props.icon || (open ? 'arrow-up-circle-outline' : 'arrow-right-bold-circle-outline')}
      actions={props.actions || []}
      onStateChange={() => {}}
      onPress={
        props.onPress ||
        (() => {
          setOpen(!open);
        })
      }
    />
  );
};

export default memo(Fab, (prev, next) => {
  if (JSON.stringify(prev.data) === JSON.stringify(next.data)) return true;
  return false;
});
