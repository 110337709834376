import { createSlice } from '@reduxjs/toolkit';

const counterSlice = createSlice({
    name: 'assessmentScore',
    initialState: {
        value: {}
    },
    reducers: {
        assessmentReducer: (state, action) => {
            // console.log("Reducer called", action);
            state.value = action.payload.assessmentScore;
        }
    }

});

// Action creators are generated for each case reducer function
export const { assessmentReducer } = counterSlice.actions;
export default counterSlice.reducer;
