import { StyleSheet } from 'react-native';

export default StyleSheet.create({
   icon: { backgroundColor: 'transparent' },
   noResultContainer: { alignItems: 'center' },
   noResultsText: { fontSize: 11, color: '#CECECE', fontWeight: 'bold' },
   loadingContainer: { alignItems: 'center' },
   loadingText: { fontSize: 11, color: '#CECECE', marginTop: 10 },
   tableHeaderStyle: { fontWeight: 'bold' },
   tableContainer: { backgroundColor: '#ffffff', marginTop: 10, borderRadius: 5, margin: 2 },
   actionContainer: { flex: 1, display: 'flex', alignItems: 'center' },
   tableHeaderContainer: { flexDirection: 'row', padding: 5, borderBottomWidth: .5, borderColor: '#f5f5f5' },
   tableHeaderView: { backgroundColor: '#ffffff', marginTop: 10, borderRadius: 5, margin: 5 },
   tableRow: { flexDirection: 'row', padding: 5, borderBottomWidth: .5, borderColor: '#f5f5f5', height: 35 }
});


