/* eslint-disable react/no-unescaped-entities */

import { Image, SafeAreaView, StatusBar, View } from 'react-native';

import { Avatar } from 'react-native-paper';
import TextElement from '../elements/Text';
import TouchableElement from '../elements/TouchableElement';
import style from './styles/welcomeSlideStyle';


const WelcomeSlide3 = (props) => {

    const back = () => {
        props.navigation.navigate('Welcomeslide2');
    };

    const next = () => {
        props.navigation.navigate('Welcomeslide4');
    };

    return (
        <SafeAreaView style={[style.safeArea, { backgroundColor: '#212644' }]}>
            <StatusBar backgroundColor='#212644' />
            <View style={[style.verticalCenter, style.phalf]}>
                <TextElement theme="DARK" style={{ fontSize: 20, fontWeight: 'bold' }}>
                    Get Connected
                </TextElement>
            </View>
            <View style={[style.verticalCenter, style.pfour]}>
                <Image resizeMode={'stretch'} source={require('../../assets/get-connected.png')} style={style.profileImage} />
                <TextElement theme="DARK" style={style.textStyle}>
                    Once your profile is updated, click on "Find Mentor" in your Navigation Menu and use the search feature to find a mentor to match your needs.
                </TextElement>
            </View>
            <View style={[style.phalf, { display: 'flex', flexDirection: 'row', alignItems: 'center' }]}>
                <TouchableElement
                    onPress={back}
                    style={{ flex: .6, margin: 10 }}
                    id='skip'>
                    <Avatar.Icon icon={'arrow-left-bold-circle'} size={50} style={style.icon} />
                </TouchableElement>

                <View style={{ flex: 4.8 }} />

                <TouchableElement
                    onPress={next}
                    style={{ flex: .6, display: 'flex', flexDirection: 'row-reverse', margin: 10 }}
                    id='skip'>
                    <Avatar.Icon icon={'arrow-right-bold-circle'} size={50} style={style.icon} />
                </TouchableElement>
            </View>
        </SafeAreaView >
    );
};

export default WelcomeSlide3;