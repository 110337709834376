import { View } from 'react-native';

import { Appbar } from 'react-native-paper';
import { SafeAreaView } from 'react-native';

const ViewWithBack = (props) => {
    return (
        <SafeAreaView style={{ backgroundColor: props.topColor || '#212644', flex: 1 }}>
            <View style={{ flex: 1, backgroundColor: '#212644' }}>
                <Appbar.Header style={{ backgroundColor: '#212644' }}>
                    <Appbar.Action icon="keyboard-backspace" onPress={props.backAction || (() => props.navigation.goBack())} color="#ffffff" />
                    <Appbar.Content title={props.pageName} color="#ffffff" style={{ marginLeft: -10 }} />
                </Appbar.Header>
                {props.children}
            </View>
        </SafeAreaView>
    );
};

export default ViewWithBack;