import { Avatar, Modal, Portal, Provider } from 'react-native-paper';
import { KeyboardAvoidingView, LogBox } from 'react-native';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// import AvatarElement from '../elements/Avatar';
import ButtonElement from '../elements/Button';
// import ColorConstant from '../../utils/ColorConstant.json';
import ConnectionsService from '../../service/ConnectionsService';
import DividerElement from '../elements/Divider';
import ScrollViewElement from '../elements/ScrollView';
import TextElement from '../elements/Text';
import TextInputElement from '../elements/TextInput';
import TouchableElement from '../elements/TouchableElement';
import { View } from 'react-native';
import ViewElement from '../elements/ViewWIthScroll';
import { getUserIdentity } from '../../utils/UserIdentity';
// import icon from '../../asset/img/userImages/male6.jpg';
import { loaderReducer } from '../elements/customLoader/customLoaderSlice';
import { mentorReducer } from './MentorSlice';
import style from './Style';
import { useIsFocused } from '@react-navigation/native';

const getName = require('../../utils/NameMapping').getName;

LogBox.ignoreLogs(['EventEmitter.removeListener']);

// const Drop = (props) => {
//   const [show, setShow] = useState(false);

//   return (
//     <View>
//       <TouchableElement onPress={() => setShow(!show)} style={style.accountSwitch} id='switch'>
//         <Avatar.Icon size={40} icon='account-switch' style={style.icon} />
//       </TouchableElement>
//       {show ? (
//         <View style={style.drop}>
//           <TouchableElement
//             style={style.padding5}
//             onPress={() => {
//               props.setPreferredRole('Mentee');
//               setShow(false);
//             }}
//             id='mentee'>
//             <TextElement style={style.padding5}>Mentee</TextElement>
//           </TouchableElement>

//           {props.userIdentity && props.userIdentity.user_role === 'admin' ? (
//             <TouchableElement
//               style={style.padding5}
//               onPress={() => {
//                 props.setPreferredRole('');
//                 // props.navigation.navigate('HrAdmin');
//                 setShow(false);
//               }}
//               id='hr-admin'>
//               <TextElement style={style.padding5}>HR Admin</TextElement>
//             </TouchableElement>
//           ) : (
//             <View></View>
//           )}
//         </View>
//       ) : (
//         <View />
//       )}
//     </View>
//   );
// };

const MentorPage = (props) => {

  const dispatch = useDispatch();
  const isFocused = useIsFocused();
  const mentorStatsCache = useSelector((state) => state.mentor.stats);
  const mentorConnections = useSelector((state) => state.mentor.connections);
  // console.log('mentorConnections', mentorConnections);
  // console.log('mentorStatsCache', mentorStatsCache);
  // const [showMenu, setShowMenu] = React.useState(false);
  // const openMenu = () => setShowMenu(true);
  // const closeMenu = () => setShowMenu(false);
  const [userIdentity, setUserIdentity] = React.useState({});
  const [isApiDataLoaded, setIsApiDataLoaded] = useState(false);

  const [mentorData, setMentorData] = useState({
    accepted: [],
    pending: [],
    cancelled: [],
  });

  const connectionsService = new ConnectionsService(props);

  const fetchData = async () => {
    getUserIdentity().then((uIdentity) => {
      if (uIdentity) {
        setUserIdentity(uIdentity);
        if (mentorStatsCache && Object.keys(mentorStatsCache).length) {
          // console.log('mentorStatsCache found', mentorStatsCache);
          setMentorData(mentorConnections);
        }
        connectionsService
          .getMentorConnections(uIdentity.email_id, isApiDataLoaded)
          .then((response) => {
            // console.log('response', response);
            setMentorData(response);
            setIsApiDataLoaded(true);
            dispatch(mentorReducer({ mentorConnections: response }));
          });
      }
    });
  };
  useEffect(() => {
    if (isFocused) {
      fetchData();
    }
  }, [isFocused]);

  return (
    <Provider>
      <ViewElement pageName='Mentee' previousPath='/' {...props}>
        <View style={style.container}>
          {/* <View style={style.userInfo}>
            <View style={style.userViewRow}>
              <View style={style.userView}>
                <AvatarElement path={icon} size={60} />
                <TextElement theme={'DARK'} style={style.accountUser}>
                  Hello,{' '}
                  {userIdentity && userIdentity.email_id ? getName(userIdentity.email_id) : ''}
                </TextElement>
                <TextElement theme={'DARK'} style={style.accountText}>
                  {userIdentity && userIdentity.jobTitle ? `${userIdentity.jobTitle},` : ''}
                  {userIdentity && userIdentity.department ? ` ${userIdentity.department}, ` : ''}
                  {userIdentity && userIdentity.country ? userIdentity.country : ''}
                </TextElement>
                <TextElement theme={'DARK'} style={style.accountText}>
                  {userIdentity && userIdentity.email_id ? userIdentity.email_id : ''}
                </TextElement>
              </View>
              <View style={style.dropView}>
                <Drop {...props} userIdentity={userIdentity} />
              </View>
            </View>
          </View>
          <View style={style.divider10}></View> */}
          <Cards
            mentorResponse={mentorData}
            fetchData={fetchData}
            props={props}
            isApiDataLoaded={isApiDataLoaded}
            dispatch={dispatch}
          />
        </View>
      </ViewElement>
    </Provider>
  );
};

const Cards = ({ mentorResponse, fetchData, props, isApiDataLoaded, dispatch }) => {
  const [finalData, setFinalData] = useState({
    accepted: [],
    pending: [],
    cancelled: [],
  });
  const [show, setShow] = useState(false);
  const [dialogComponent, setDialogComponent] = useState(<View />);
  // const [data, setData] = useState(null);

  const handleShow = () => setShow(true);

  const connectionsService = new ConnectionsService(props);

  let approveRequest = async (mentee, mentor) => {
    dispatch(loaderReducer({ loaderProperties: { show: true } }));
    await connectionsService.acceptConnection(mentee, mentor);
    dispatch(loaderReducer({ loaderProperties: { show: false } }));
    return await fetchData();
  };

  let cancelRequest = async (mentee, mentor, cancelMessage) => {
    setDialogComponent(<View />);
    dispatch(loaderReducer({ loaderProperties: { show: true } }));
    await connectionsService.cancelConnection(mentee, mentor, cancelMessage);
    dispatch(loaderReducer({ loaderProperties: { show: false } }));
    return await fetchData();
  };

  const deleteRequest = async (mentee, mentor, deleteMessage, action) => {
    setDialogComponent(<View />);
    dispatch(loaderReducer({ loaderProperties: { show: true } }));
    await connectionsService.deleteConnection(mentee, mentor, deleteMessage, action);
    dispatch(loaderReducer({ loaderProperties: { show: false } }));
    return await fetchData();
  };

  const getUserData = async (connections) => {
    if (!isApiDataLoaded) dispatch(loaderReducer({ loaderProperties: { show: true } }));
    let userData = {};
    for (let connection of connections) {
      userData[connection.name] = await connectionsService.getUserProfileData(connection.name);
    }
    dispatch(loaderReducer({ loaderProperties: { show: false } }));
    return userData;
  };

  const getRecords = async (mentorData) => {
    try {
      let combinedData = {
        accepted: [],
        pending: [],
        cancelled: [],
      };

      let acceptedConnections = mentorData ? mentorData.accepted : [];
      let pendingConnections = mentorData ? mentorData.pending : [];
      let cancelledConnections = mentorData ? mentorData.cancelled : [];

      // console.log("acceptedConnections", acceptedConnections);
      // console.log("pendingConnections", pendingConnections);
      // console.log("cancelledConnections", cancelledConnections);

      const prepareData = async (acceptedConnections, pendingConnections, cancelledConnections) => {

        let connectionNames = [];
        const allConnections = [
          ...acceptedConnections,
          ...pendingConnections,
          ...cancelledConnections,
        ];

        if (allConnections.length > 0) {
          for (let index = 0; index < allConnections.length; index++) {
            const record = allConnections[index];
            let fullName = getName(record.user_type.split('-')[1]);
            connectionNames.push({
              name: fullName,
            });
          }
        }

        let userData = await getUserData(connectionNames);
        // console.log('final userdata', userData);

        if (acceptedConnections && acceptedConnections.length > 0) {
          for (let index = 0; index < acceptedConnections.length; index++) {
            const record = acceptedConnections[index];
            let fullName = getName(record.user_type.split('-')[1]);
            let userDataByName = userData && userData[fullName] ? userData[fullName][0] : {};

            combinedData.accepted.push({
              ...userDataByName,
              name: fullName,
              remark: record.remark ? record.remark : 'N/A',
              menteeEmail: record.user_type.split('-')[1],
              mentorEmail: record.email_id,
              department: userDataByName ? userDataByName?.department : 'N/A',
              location: userDataByName ? userDataByName?.location : 'N/A',
            });
          }
        }

        if (pendingConnections && pendingConnections.length > 0) {
          for (let index = 0; index < pendingConnections.length; index++) {
            const record = pendingConnections[index];
            let fullName = getName(record.user_type.split('-')[1]);
            let userDataByName = userData && userData[fullName] ? userData[fullName][0] : {};
            combinedData.pending.push({
              ...userDataByName,
              name: fullName,
              remark: record.remark ? record.remark : 'N/A',
              menteeEmail: record.user_type.split('-')[1],
              mentorEmail: record.email_id,
              department: userDataByName ? userDataByName?.department : 'N/A',
              location: userDataByName ? userDataByName?.location : 'N/A',
            });
          }
        }

        if (cancelledConnections && cancelledConnections.length > 0) {
          for (let index = 0; index < cancelledConnections.length; index++) {
            const record = cancelledConnections[index];
            let fullName = getName(record.user_type.split('-')[1]);

            let userDataByName = userData && userData[fullName] ? userData[fullName][0] : {};

            combinedData.cancelled.push({
              ...userDataByName,
              name: fullName,
              remark: record.remark ? record.remark : 'N/A',
              menteeEmail: record.user_type.split('-')[1],
              mentorEmail: record.email_id,
              department: userDataByName ? userDataByName?.department : 'N/A',
              location: userDataByName ? userDataByName?.location : 'N/A',
              reasonToCancel: record.reason_to_cancel || 'N/A',
            });
          }
        }

        // console.log('combinedData---- ', combinedData);
      };
      await prepareData(acceptedConnections, pendingConnections, cancelledConnections);
      setFinalData(combinedData);
    } catch (exception) {
      return exception;
    }
  };

  useEffect(() => {
    if (mentorResponse) {
      getRecords(mentorResponse).then(() => {
        // setData(mentorResponse);
      });
    }
  }, [mentorResponse]);

  useEffect(() => { }, [finalData.pending.length]);

  useEffect(() => { }, [finalData.cancelled.length]);

  useEffect(() => { }, [finalData.accepted.length]);

  useEffect(() => { }, [dialogComponent]);

  useEffect(() => { }, [show]);

  const Dialog = ({ show, title, hintInput, handleClose, menteeEmail, mentorEmail, action }) => {
    let remark = '';
    let onSubmit = '';

    if (action == 'DeleteConnection') {
      onSubmit = () => {
        deleteRequest(menteeEmail, mentorEmail, remark, action);
      };
    }

    if (action == 'CancelRequest') {
      onSubmit = () => {
        cancelRequest(menteeEmail, mentorEmail, remark);
      };
    }

    return (
      <Portal>
        <Modal visible={show} onDismiss={handleClose} contentContainerStyle={style.containerStyle}>
          <KeyboardAvoidingView behavior={'padding'} style={style.keyboard}>
            <View style={style.dialogView}>
              <TextElement theme={props.theme} text={title} />

              <TextInputElement
                theme={props.theme}
                editable={true}
                placeholder={hintInput}
                maxLength={100}
                onChangeText={(message) => {
                  remark = message;
                }}
              />
              <View style={style.dialogButtonView}>
                <ButtonElement
                  theme={props.theme}
                  color={'#0096FF'}
                  style={style.dialogButton}
                  onPress={handleClose}>
                  Cancel
                </ButtonElement>
                <ButtonElement
                  theme={props.theme}
                  color={'#0096FF'}
                  style={style.dialogButton}
                  onPress={onSubmit}>
                  Submit
                </ButtonElement>
              </View>
            </View>
          </KeyboardAvoidingView>
        </Modal>
      </Portal>
    );
  };

  return (
    <View>
      {dialogComponent}
      <View style={style.connectionView}>
        <TextElement theme={'DARK'} style={style.connectionHeader}>
          Connected Mentees
        </TextElement>
        <DividerElement theme={props.theme} height={15} />
        <ScrollViewElement>
          {finalData.accepted.length > 0 ? (
            finalData.accepted.map((record, index) => {
              return (
                <View key={index}>
                  <View style={style.accepted}>
                    <View style={style.centeredView}>
                      <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'flex-end', width: '100%', marginTop: -5 }}>

                        <TouchableElement
                          style={{ display: 'flex', justifyContent: 'flex-end' }}
                          id='see-details'
                          onPress={() => {
                            props.navigation.navigate('Profiledetails', record);
                          }}>
                          <Avatar.Icon size={35} icon='information' style={{ backgroundColor: '#E6E6E6' }} color="#212644" />
                        </TouchableElement>

                      </View>
                      <Avatar.Image
                        size={70}
                        source={require('../../asset/img/userImages/male2.png')}
                        style={[style.icon, { marginTop: -25 }]}
                      />
                      <View style={{ height: 5 }} />
                      <View style={style.centeredView}>
                        <TextElement style={style.name}>
                          {record.name.length > 21 ? record.name.substr(0, 21) + '.' : record.name}
                        </TextElement>
                        <TextElement style={style.textStyle}>Dept: {record.department}</TextElement>
                        <TextElement style={style.textStyle}>
                          Location: {record.location}
                        </TextElement>
                        <View style={style.divider5}></View>
                      </View>
                    </View>
                    <View style={style.removeButtonContainer1}>
                      <View style={style.removeButtonContainer2}>
                        <TouchableElement
                          style={style.removeButton}
                          onPress={() => {
                            let title = 'Deleting Connection';
                            let hintInput = 'Enter reason';

                            const component = (
                              <Dialog
                                show={handleShow}
                                title={title}
                                hintInput={hintInput}
                                handleClose={() => {
                                  setDialogComponent(<View />);
                                }}
                                menteeEmail={record.menteeEmail}
                                mentorEmail={record.mentorEmail}
                                action={'DeleteConnection'}
                              />
                            );
                            setDialogComponent(component);
                          }}
                          id={'remove-' + index}>
                          <TextElement style={style.remove}>Remove</TextElement>
                        </TouchableElement>
                      </View>
                    </View>
                  </View>
                </View>
              );
            })
          ) : (
            <View />
          )}
        </ScrollViewElement>
        {finalData.accepted.length === 0 ? (
          <View style={style.noRecords}>
            <TextElement theme='DARK' style={style.noRecordsText}>
              No Active Connections!
            </TextElement>
            <Avatar.Icon icon='email-open-outline' style={style.icon} color='#B8B8B8' size={50} />
          </View>
        ) : (
          <View />
        )}
      </View>
      <View style={style.divider10}></View>
      <View style={style.connectionView}>
        <TextElement theme={'DARK'} style={style.connectionHeader}>
          Pending Mentee Requests
        </TextElement>
        <DividerElement theme={props.theme} height={15} />
        <ScrollViewElement>
          {finalData.pending.length > 0 ? (
            finalData.pending.map((record, index) => {
              return (
                <View key={index} style={style.pendingCancel}>
                  <View style={style.pending}>
                    <View style={style.centeredView}>
                      <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'flex-end', width: '100%', marginTop: -5 }}>

                        <TouchableElement
                          style={{ display: 'flex', justifyContent: 'flex-end' }}
                          id='see-details'
                          onPress={() => {
                            props.navigation.navigate('Profiledetails', record);
                          }}>
                          <Avatar.Icon size={35} icon='information' style={{ backgroundColor: '#E6E6E6' }} color="#212644" />
                        </TouchableElement>

                      </View>
                      <Avatar.Image
                        size={70}
                        source={require('../../asset/img/userImages/male2.png')}
                        style={[style.icon, { marginTop: -25 }]}
                      />
                      <View style={{ height: 5 }} />
                      <View style={style.centeredView}>
                        <TextElement style={style.name}>
                          {record.name.length > 21 ? record.name.substr(0, 21) + '.' : record.name}
                        </TextElement>
                        <TextElement style={style.textStyle}>Dept: {record.department}</TextElement>
                        <TextElement style={style.textStyle}>
                          Location: {record.location}{' '}
                        </TextElement>
                      </View>

                      <View style={style.divider5}></View>
                    </View>
                    <View style={style.removeButtonContainer1}>
                      <View style={style.removeButtonContainer2}>
                        <View style={style.cancelView}>
                          <TouchableElement
                            id={'cancel-' + index}
                            style={style.cancelButton}
                            onPress={() => {
                              let title = 'Cancelling Connection';
                              let hintInput = 'Enter reason';

                              const component = (
                                <Dialog
                                  show={handleShow}
                                  title={title}
                                  hintInput={hintInput}
                                  handleClose={() => {
                                    setDialogComponent(<View />);
                                  }}
                                  menteeEmail={record.menteeEmail}
                                  mentorEmail={record.mentorEmail}
                                  action={'CancelRequest'}
                                />
                              );

                              setDialogComponent(component);
                            }}>
                            <TextElement style={style.decline}>Decline</TextElement>
                          </TouchableElement>
                          <TouchableElement
                            style={style.acceptButton}
                            onPress={() => {
                              approveRequest(record.menteeEmail, record.mentorEmail);
                            }}
                            id={'accept-' + index}>
                            <TextElement style={style.accept}>Accept</TextElement>
                          </TouchableElement>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              );
            })
          ) : (
            <View />
          )}
        </ScrollViewElement>
        {finalData.pending.length === 0 ? (
          <View style={style.noRecords}>
            <TextElement theme='DARK' style={style.noRecordsText}>
              No Pending Requests!
            </TextElement>
            <Avatar.Icon icon='email-open-outline' style={style.icon} color='#B8B8B8' size={50} />
          </View>
        ) : (
          <View />
        )}
      </View>
      <View style={style.divider10}></View>
      <View style={style.connectionView}>
        <TextElement theme={'DARK'} style={style.connectionHeader}>
          Cancelled Mentee Requests
        </TextElement>
        <DividerElement theme={props.theme} height={15} />
        <ScrollViewElement>
          {finalData.cancelled.length > 0 ? (
            finalData.cancelled.map((record, index) => {
              return (
                <View key={index} style={style.pendingCancel}>
                  <View style={style.cancelled}>
                    <View style={style.centeredView}>
                      <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'flex-end', width: '100%', marginTop: -5}}>

                        <TouchableElement
                          style={{ display: 'flex', justifyContent: 'flex-end' }}
                          id='see-details'
                          onPress={() => {
                            props.navigation.navigate('Profiledetails', record);
                          }}>
                          <Avatar.Icon size={35} icon='information' style={{ backgroundColor: '#E6E6E6' }} color="#212644" />
                        </TouchableElement>

                      </View>
                      <Avatar.Image
                        size={70}
                        source={require('../../asset/img/userImages/male2.png')}
                        style={{ marginTop: -25 }}
                      />
                      <View style={{ height: 5 }} />
                      <View style={style.centeredView}>
                        <TextElement style={style.name}>
                          {record.name.length > 21 ? record.name.substr(0, 21) + '.' : record.name}
                        </TextElement>
                        <TextElement style={style.textStyle}>Dept: {record.department}</TextElement>
                        <TextElement style={style.textStyle}>
                          Location: {record.location}
                        </TextElement>
                        <View style={style.divider5}></View>
                      </View>
                    </View>
                    <View style={style.removeButtonContainer1}>
                      <View style={style.removeButtonContainer2}>
                        <TouchableElement
                          style={style.removeButton}
                          onPress={() => {
                            let title = 'Deleting Connection';
                            let hintInput = 'Enter reason';

                            const component = (
                              <Dialog
                                show={handleShow}
                                title={title}
                                hintInput={hintInput}
                                handleClose={() => {
                                  setDialogComponent(<View />);
                                }}
                                menteeEmail={record.menteeEmail}
                                mentorEmail={record.mentorEmail}
                                action={'DeleteConnection'}
                              />
                            );
                            setDialogComponent(component);
                          }}
                          id={'remove' + index}>
                          <TextElement style={style.remove}>Remove</TextElement>
                        </TouchableElement>
                      </View>
                    </View>
                  </View>
                </View>
              );
            })
          ) : (
            <View />
          )}
        </ScrollViewElement>
        {finalData.cancelled.length === 0 ? (
          <View style={style.noRecords}>
            <TextElement theme='DARK' style={style.noRecordsText}>
              No Cancelled Requests!
            </TextElement>
            <Avatar.Icon icon='email-open-outline' style={style.icon} color='#B8B8B8' size={50} />
          </View>
        ) : (
          <View />
        )}
      </View>
    </View>
  );
};

export default memo(MentorPage, (prevProps, nextProps) => {
  if (prevProps.theme !== nextProps.theme) return false;
  else return true;
});
