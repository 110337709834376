import { createSlice } from '@reduxjs/toolkit';

const counterSlice = createSlice({
    name: 'feedbacks',
    initialState: {
        'value': []
    },
    reducers: {
        feedbackReducer: (state, action) => {
            // console.log("action", action)
            state.value = action.payload.feedbacks;
        }
    }
});

export const { feedbackReducer } = counterSlice.actions;
export default counterSlice.reducer;