import * as React from 'react';

import Alert from '../../elements/Alert';
import { DatePickerModal } from 'react-native-paper-dates';
import Elements from '../../elements';
import { ProgressBar } from 'react-native-paper';
import TextInputElement from '../../elements/TextInput';
import TouchableElement from '../../elements/TouchableElement';
import { View } from 'react-native';
import style from './styles/goalDetailsStyle';
import NewDropdown from '../../elements/NewDropdown';
import {
  goalStatusListAborted,
  goalStatusListCompleted,
  goalStatusListCreated,
  goalStatusListInProgress,
  goalStatusList,
} from './Metadata';

const moment = require('moment');

const GoalDetails = ({
  goal,
  editFlag,
  handleChangeTest,
  setVisible,
  visible,
  setExpectedCompletionDate,
  minDate,
  isMentor,
  handleStatusChange,
}) => {
  const todayMomentDate = moment().format('YYYY/MM/DD');
  const definitions = goal.definitions;
  // console.log('definitions', definitions);

  const completedDefnitions = definitions?.filter((d) => d.status === 'completed');
  // console.log('completedDefnitions', completedDefnitions);
  let totalWeightage = 0;
  completedDefnitions?.forEach((definition) => {
    totalWeightage = Number(totalWeightage) + Number(definition.weightage_in_percentage);
  });

  // let progressPercent = (completedDefnitions.length / definitions.length) * 100;
  let progress = totalWeightage / 100;
  // console.log(progress, totalWeightage);

  return (
    <View style={{ gap: 5 }}>
      <View style={style.progressView}>
        <Elements.Text>Current Progress</Elements.Text>
        <ProgressBar progress={progress} color='#48B2EB' style={style.progressBar} />
        <Elements.Text>{totalWeightage}% Completed</Elements.Text>
      </View>
      <View style={{ gap: 5 }}>
        <View style={style.rowContainer}>
          {goal.mentor_email_id != 'leadx@travelex.com' ? (
            <RenderTextInput
              editable={false}
              label={'Mentor Email ID'}
              value={goal ? goal.mentor_email_id : ''}
            />
          ) : (
            <View></View>
          )}
        </View>

        <RenderStatus
          // status={status}
          // setStatus={setStatus}
          isMentor={isMentor}
          goal={goal}
          handleStatusChange={handleStatusChange}
          editFlag={editFlag}
        />

        <View style={style.rowContainer}>
          <RenderTextInput
            editable={false}
            label={'Goal Name'}
            value={goal ? goal.goal_name.substr(0, goal.goal_name.lastIndexOf('_')) : ''}
          />
        </View>

        <View style={style.rowContainer}>
          <RenderTextInput
            disabled={true}
            label={'Start Date'}
            placeholder={'Start Date'}
            value={todayMomentDate}
            onChangeText={(e) => handleChangeTest(e, 'commencement_date')}
          />
        </View>

        <RenderExpectedDate
          goal={goal}
          editFlag={editFlag}
          minDate={minDate}
          isMentor={isMentor}
          setExpectedCompletionDate={setExpectedCompletionDate}
          setVisible={setVisible}
          visible={visible}
          handleChangeTest={handleChangeTest}
        />

        <View style={style.rowContainer}>
          <RenderTextInput
            reloadData={editFlag}
            editable={editFlag}
            label={'S.M.A.R.T. Objective'}
            placeholder={'S.M.A.R.T. Objective'}
            value={goal ? goal.remarks : ''}
            multiline={true}
            numberOfLines={4}
            maxLength={2005}
            onChangeText={(e) => {
              if (e.trim().length > 2000) {
                Alert.confirm(
                  'Confirm',
                  'S.M.A.R.T. Objective should be upto 2000 characters',
                  () => {
                    handleChangeTest(e.trim().substr(0, 2000), 'remarks');
                  }
                );
              } else {
                handleChangeTest(e.trim(), 'remarks');
              }
            }}
          />
        </View>
      </View>
    </View>
  );
};

const RenderTextInput = ({
  editable,
  label,
  value,
  disabled,
  placeholder,
  onChangeText,
  multiline,
  numberOfLines,
  maxLength,
  reloadData,
}) => {
  return (
    <View style={style.textInputContainer}>
      <TextInputElement
        reloadData={reloadData}
        editable={editable}
        label={label}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        onChangeText={onChangeText}
        multiline={multiline}
        numberOfLines={numberOfLines}
        maxLength={maxLength}
      />
    </View>
  );
};

const RenderExpectedDate = ({
  goal,
  editFlag,
  minDate,
  isMentor,
  setExpectedCompletionDate,
  setVisible,
  visible,
  handleChangeTest,
}) => {
  const calculateStartDate = () => {
    const today = new Date();
    const defaultStartDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    if (minDate && minDate !== '') {
      const minDateObject = new Date(moment.unix(minDate).format('YYYY/MM/DD'));
      // console.log('minDateObject', minDateObject);
      const startDate = new Date(minDateObject);
      // console.log('startDate', startDate);
      return startDate;
    }
    // console.log('defaultStartDate', defaultStartDate);
    return defaultStartDate;
  };

  const renderTouchableElement = (onPressHandler) => (
    <TouchableElement
      id='expected-completion-date'
      style={style.completionDateView}
      onPress={onPressHandler}>
      <Elements.Text>Expected Completion Date</Elements.Text>
      {goal && goal.expected_completion_date != null ? (
        <Elements.Text>
          {goal && goal.expected_completion_date != null
            ? moment.unix(goal.expected_completion_date).format('YYYY/MM/DD')
            : ''}
        </Elements.Text>
      ) : (
        <Elements.Text style={{ color: '#adadad' }}>Please select date</Elements.Text>
      )}
    </TouchableElement>
  );

  const renderDatePicker = () => (
    <DatePickerModal
      locale='en'
      mode='single'
      label='Select expected completion date'
      visible={visible}
      onDismiss={() => setVisible(false)}
      validRange={{
        startDate: calculateStartDate(),
      }}
      onConfirm={(date) => {
        if (date.date) {
          // console.log('date', date);
          setVisible(false);
          const timestamp = moment(date.date, 'YYYY-MM-DD HH:mm:ss').unix();
          setExpectedCompletionDate(timestamp);
          handleChangeTest(timestamp, 'expected_completion_date');
        } else {
          setVisible(false);
          Alert.alert('Bad input', 'Please select a valid date');
        }
      }}
    />
  );

  if (goal && goal.status === 'created' && !editFlag) {
    return (
      <View>
        {renderTouchableElement(() => {
          if (!isMentor) {
            setVisible(true);
          }
        })}
        {renderDatePicker()}
      </View>
    );
  } else if (editFlag) {
    return (
      <View>
        {renderTouchableElement(() => {
          if (!isMentor) {
            if (goal.status === 'created') {
              Alert.alert('Info', 'Please go back and start your goal first');
            } else if (goal.status === 'inprogress') {
              setVisible(true);
            }
          }
        })}
        {renderDatePicker()}
      </View>
    );
  } else {
    return (
      <View style={style.rowContainer}>
        <View style={style.textInputContainer}>
          <TextInputElement
            editable={false}
            label={'Expected Completion Date'}
            placeholder={'Expected Completion Date'}
            value={
              goal && goal.expected_completion_date != null
                ? moment.unix(goal.expected_completion_date).format('YYYY/MM/DD')
                : ''
            }
          />
        </View>
      </View>
    );
  }
};

const RenderStatus = ({ isMentor, goal, handleStatusChange, editFlag }) => {
  const disableCondition = isMentor
    ? { pointerEvents: 'none' }
    : editFlag
    ? {}
    : { pointerEvents: 'none' };

  return (
    <View>
      <View style={style.statusText}>
        <Elements.Text style={{ fontSize: 13 }}>Status</Elements.Text>
      </View>
      <View style={disableCondition}>
        <NewDropdown
          style={style.dropwdown}
          data={
            goal.status === 'created'
              ? goalStatusListCreated
              : goal.status === 'aborted'
              ? goalStatusListAborted
              : goal.status === 'completed'
              ? goalStatusListCompleted
              : goalStatusListInProgress
          }
          value={goalStatusList[goal.status]}
          setValue={(item) => {
            handleStatusChange(item);
          }}
        />
      </View>
    </View>
  );
};

export default GoalDetails;
