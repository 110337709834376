import React, { memo, useEffect, useState } from 'react';
import { ScrollView, View } from 'react-native';

import AddDefinitionModal from './AddDefinitionModal';
import Alert from '../../elements/Alert';
import { Avatar } from 'react-native-paper';
import ButtonElement from '../../elements/Button';
import ConnectionsService from '../../../service/ConnectionsService';
import CreateEditDefinitionModal from './CreateEditDefinitionModal';
import Elements from '../../elements';
import GoalsService from '../../../service/GoalsService';
import NewDropdown from '../../elements/NewDropdown';
import { Text } from 'react-native-paper';
import TextElement from '../../elements/Text';
import TextInputElement from '../../elements/TextInput';
import TouchableElement from '../../elements/TouchableElement';
import ViewDefinitionModal from '../viewGoals/ViewDefinitionModal';
import ViewWithBack from '../../elements/ViewWithBack';
import { getUserIdentity } from '../../../utils/UserIdentity';
import journeyConfig from '../../journey/JourneyConfig.json';
import { map } from 'lodash';
import style from './styles/createGoalStyle';
import { sumBy } from 'lodash';
import { useIsFocused } from '@react-navigation/native';

const moment = require('moment');


let totalWeightage = 0;
let userIdentity = null;

const CreateGoal = (parentProps) => {
  const props = { ...parentProps, ...parentProps.route.params };
  const { tabIndex, isMentor } = props;
  // console.log('tabIndex in CreateGoal', tabIndex, isMentor);

  const goalsService = new GoalsService(props);
  const isFocused = useIsFocused();
  const [goalsDefinition, setGoalsDefinition] = useState([]);
  const [showAddDefinitionModal, setShowAddDefinitionModal] = useState(false);
  const [showViewDefinitionModal, setShowViewDefinitionModal] = useState(false);
  const [showCreateEditDefinitionModal, setShowCreateEditDefinitionModal] = useState(false);
  const [mentorEmail, setMentorEmail] = useState('');
  const [menteeEmails, setMenteeEmails] = useState([]);
  const [menteeEmail, setMenteeEmail] = useState('');
  const [goalName, setGoalName] = useState('');
  const [goalRemark, setGoalRemark] = useState('');
  const [currentGoalIndex, setCurrentGoalIndex] = useState(0);
  const [weightage, setWeightage] = useState();
  const [isApiDataLoaded, setIsApiDataLoaded] = useState(false);

  const closeAddDefinitionModal = () => setShowAddDefinitionModal(false);
  const closeViewDefinitionModal = () => setShowViewDefinitionModal(false);
  const closeCreateEditDefinitionModal = () => setShowCreateEditDefinitionModal(false);

  const handleAddDefinitionModal = () => {
    if (!mentorEmail && tabIndex !== 1) {
      Alert.alert('Required', 'Missing mentor Email id');
    } else if (tabIndex == 1 && !menteeEmail) {
      Alert.alert('Required', 'Missing mentee Email id');
    } else if (!goalName) {
      Alert.alert('Required', 'Missing Goal name');
    } else if (totalWeightage === 100) {
      // console.log('totalWeightage', totalWeightage);
      onGoalPercentageExceed();
    } else {
      setShowAddDefinitionModal(true);
    }
  };

  const removeGoalDefinition = (index) => {
    Alert.confirm('Confirm', 'Deleted Learning Theme will not be reverted', () => {
      totalWeightage = parseInt(
        parseInt(totalWeightage) - parseInt(goalsDefinition[index].weightage_in_percentage)
      );
      const list = [...goalsDefinition];
      list.splice(index, 1);
      setGoalsDefinition(list);
    });
  };

  const handleGoalView = (index) => {
    setCurrentGoalIndex(index);
    setShowViewDefinitionModal(true);
  };

  const handleGoalCreateEdit = (index) => {
    setCurrentGoalIndex(index);
    setShowCreateEditDefinitionModal(true);
  };

  useEffect(() => {
    flush();
  }, [isFocused]);

  const fetchMentorData = async () => {
    userIdentity = await getUserIdentity();
    let response = await goalsService.getConnections(userIdentity.email_id, props.navigation);
    if (response?.accepted[0]?.user_type) {
      setMentorEmail(response.accepted[0].user_type.split('-')[1]);
    } else {
      Alert.alert(
        'Info',
        'No connected mentor found, please connect with a mentor to create a goal'
      );
    }
  };

  const fetchMentees = async () => {
    const connectionService = new ConnectionsService(props);
    userIdentity = await getUserIdentity();
    const response = await connectionService.getMentorConnections(
      userIdentity.email_id,
      isApiDataLoaded
    );
    setIsApiDataLoaded(true);
    if (response?.accepted?.length) {
      const menteeList = map(response.accepted, (item) => {
        return item.user_type.split('-')[1];
      });
      // console.log('menteeList', menteeList);
      setMenteeEmails(menteeList);
      setMenteeEmail(menteeList[0]);
    } else {
      Alert.alert(
        'Info',
        'No connected mentee found, please connect with a mentee to create a goal'
      );
    }
  };

  // console.log('menteeEmails', menteeEmails);

  useEffect(() => {
    if (isFocused) {
      if (tabIndex === 0 || tabIndex === 2) {
        fetchMentorData();
      }
      if (tabIndex === 1) {
        fetchMentees();
      }
    }
  }, [isFocused]);

  // console.log('goalsDefinition', goalsDefinition);
  // console.log('currentGoalIndex', currentGoalIndex);

  const addGoalDefinition = (definitionObject) => {
    // console.log('inside addGoalDefinition', definitionObject, 'goalsDefinition', goalsDefinition);

    totalWeightage = 0;
    const totalDefinitions = [...goalsDefinition, definitionObject];
    totalWeightage = sumBy(totalDefinitions, (o) => Number(o.weightage_in_percentage || 0));

    // console.log('totalWeightage', totalWeightage);

    if (totalWeightage <= 100) {
      setGoalsDefinition(totalDefinitions);
      closeAddDefinitionModal();
      return true;
    } else {
      totalWeightage = Number(Number(totalWeightage) - Number(weightage));
      // console.log("totalWeightage else",totalWeightage)
      onGoalPercentageExceed();
    }
  };

  const handleServiceUpdateDef = (item, index) => {
    // console.log('Inside handleServiceUpdateDef', item, index);
    const weightage = Number(item.weightage_in_percentage || 0);
    // console.log('weightage', weightage);
    if (item.definition && item.weightage_in_percentage && !isNaN(weightage)) {
      let tempDoalDefinitions = [...goalsDefinition];
      tempDoalDefinitions[index] = item;
      totalWeightage = sumBy(tempDoalDefinitions, (o) => Number(o.weightage_in_percentage || 0));
      // console.log('totalWeightage!!!!', totalWeightage);
      if (totalWeightage <= 100) {
        setGoalsDefinition(tempDoalDefinitions);
        closeCreateEditDefinitionModal();
        return true;
      } else onGoalPercentageExceed();
    } else if (!item.definition) Alert.alert('Required', 'Missing objective');
    else if (isNaN(weightage) || weightage > 100) Alert.alert('Required', 'Invalid Weightage');
    else if (!item.weightage_in_percentage) Alert.alert('Required', 'Missing Weightage');

    return false;
  };

  const addGoal = async () => {
    userIdentity = await getUserIdentity();

    if (!mentorEmail && tabIndex !== 1) {
      Alert.alert('Required', 'Missing mentor Email id');
    } else if (tabIndex == 1 && !menteeEmail) {
      Alert.alert('Required', 'Missing mentee Email id');
    } else if (!goalName) {
      Alert.alert('Required', 'Missing Goal name');
    } else if (goalsDefinition.length < 1) {
      Alert.alert('Required', 'Please add Learning Theme');
    } else if (totalWeightage !== 100) {
      Alert.alert('Invalid Weightage', 'Total Goal weightage should be 100%');
    } else if (tabIndex === 1) {
      let list = {
        email_id: menteeEmail,
        goal_name: goalName,
        mentor_email_id: userIdentity.email_id,
        remarks: goalRemark,
        definitions: goalsDefinition,
        created_by: userIdentity.email_id,
      };

      await goalsService.createGoal(list, true);
      Alert.alert('Success', 'Goal created successfully');
      props.navigation.navigate('Home', { screen: 'Goals' });
    } else {
      let list = {
        email_id: userIdentity.email_id,
        goal_name: goalName,
        mentor_email_id: mentorEmail,
        remarks: goalRemark,
        definitions: goalsDefinition,
      };

      await goalsService.createGoal(list);
      Alert.alert('Success', 'Goal created successfully');
      props.navigation.navigate('Home', { screen: 'Goals' });
    }
  };

  const onGoalPercentageExceed = () => {
    Alert.alert('Whoops', 'Total Goal weightage should not exceed 100%');
  };

  const flush = () => {
    setGoalsDefinition([]);
    setShowAddDefinitionModal(false);
    setWeightage('');
    setMentorEmail('');
    setGoalName('');
    setGoalRemark('');
    totalWeightage = 0;
  };

  let weightageList = [];
  let remarkList = [];
  let definitionNameList = [];

  goalsDefinition.map((goalDefinition) => {
    definitionNameList.push(goalDefinition.definition);
    weightageList.push(goalDefinition.weightage_in_percentage);
    remarkList.push(goalDefinition.remarks);
  });

  // console.log('currentgoalIndex', currentGoalIndex);
  // console.log('goalsDefinition', goalsDefinition);
  // console.log('totalWeightage', totalWeightage);

  const goalView = (
    <View style={style.container}>
      <GoalInputSection
        goalName={goalName}
        mentorEmail={mentorEmail}
        goalRemark={goalRemark}
        setGoalName={setGoalName}
        setGoalRemark={setGoalRemark}
        tabIndex={tabIndex}
        menteeEmails={menteeEmails}
        menteeEmail={menteeEmail}
        setMenteeEmail={setMenteeEmail}
      />

      <LearningThemesHeader handleAddDefinitionModal={handleAddDefinitionModal} />

      <LearningThemesSection
        handleGoalView={handleGoalView}
        handleGoalCreateEdit={handleGoalCreateEdit}
        removeGoalDefinition={removeGoalDefinition}
        goalsDefinition={goalsDefinition}
      />

      <AddButton addGoal={addGoal} />
    </View>
  );
  const addModal = (
    <AddDefinitionModal
      handleClose={closeAddDefinitionModal}
      addGoalDefinition={addGoalDefinition}
      pageName='Add Theme'
      isMentor={isMentor}
    />
  );
  const editModal = (
    <CreateEditDefinitionModal
      handleClose={closeCreateEditDefinitionModal}
      goalDefinition={goalsDefinition[currentGoalIndex]}
      pageName='Edit Theme'
      currentGoalIndex={currentGoalIndex}
      handleServiceUpdateDef={handleServiceUpdateDef}
    />
  );
  const viewModal = (
    <ViewDefinitionModal
      handleClose={closeViewDefinitionModal}
      goalDefinition={goalsDefinition[currentGoalIndex]}
      pageName='View Theme'
    />
  );
  let children = null;

  if (showAddDefinitionModal) {
    children = addModal;
  } else if (showViewDefinitionModal) {
    children = viewModal;
  } else if (showCreateEditDefinitionModal) {
    children = editModal;
  } else {
    children = goalView;
  }

  return (
    <ViewWithBack
      pageName={'Create Goal'}
      {...props}
      backAction={() => {
        if (goalName || mentorEmail || goalRemark || goalsDefinition.length) {
          Alert.confirm('Confirm', 'Are you sure you want to discard the changes?', () => {
            props.navigation.navigate('Home', { screen: 'Goals' });
          });
        } else {
          props.navigation.navigate('Home', { screen: 'Goals' });
        }
      }}>
      {children}
    </ViewWithBack>
  );
};

const GoalInputSection = ({
  goalName,
  mentorEmail,
  goalRemark,
  setGoalName,
  setGoalRemark,
  tabIndex,
  menteeEmails,
  menteeEmail,
  setMenteeEmail,
}) => {
  return (
    <View style={style.goalContainer}>
      <TextInputElement
        editable={true}
        label={
          <Text>
            Goal Name
            <Text style={style.mandatory}> *</Text>
          </Text>
        }
        placeholder='Goal Name'
        value={goalName}
        onChangeText={(e) => setGoalName(e)}
      />

      {tabIndex === 0 || tabIndex === 2 ? (
        <View style={style.rowContainer}>
          <View style={style.textInputView}>
            <TextInputElement editable={false} label={'Mentor Email ID'} value={mentorEmail} />
          </View>
        </View>
      ) : (
        <View>
          <Elements.Text style={style.menteeEmailId}>Mentee Email ID:</Elements.Text>
          <NewDropdown
            data={menteeEmails}
            value={menteeEmail || menteeEmails[0]}
            setValue={setMenteeEmail}
            disableCapitalize={true}
            style={{ backgroundColor: '#ffffff' }}
          />
        </View>
      )}

      <View style={style.rowContainer}>
        <View style={style.textInputView}>
          <TextInputElement
            editable={true}
            label={'S.M.A.R.T. Objective'}
            value={goalRemark}
            maxLength={2005}
            multiline={true}
            numberOfLines={4}
            onChangeText={(e) => {
              if (e.trim().length > 2000) {
                Alert.confirm(
                  'Confirm',
                  'S.M.A.R.T. Objective should be upto 2000 characters',
                  () => {
                    setGoalRemark(e.trim().substr(0, 2000));
                  }
                );
              } else {
                setGoalRemark(e.trim());
              }
            }}
          />
        </View>
      </View>
    </View>
  );
};

const LearningThemesHeader = ({ handleAddDefinitionModal }) => {
  return (
    <View style={style.rowContainer}>
      <View style={style.flex}>
        <TextElement
          theme={'DARK'}
          variant='displayLarge'
          text='Learning Themes'
          style={style.headerText}
        />
      </View>

      <View style={style.flex}>
        <ButtonElement
          testId='add-theme'
          icon='plus'
          color={'#0096FF'}
          text='Theme'
          style={style.addDefinitionButton}
          onPress={handleAddDefinitionModal}
        />
      </View>
    </View>
  );
};

const LearningThemesSection = ({
  handleGoalView,
  handleGoalCreateEdit,
  removeGoalDefinition,
  goalsDefinition,
}) => {
  return (
    <React.Fragment>
      <LearningThemesTableHeader />
      <ScrollView showsVerticalScrollIndicator={false} showsHorizontalScrollIndicator={false}>
        <View style={style.textInputView}>
          <View style={style.dataTable}>
            {goalsDefinition.map((singleGoal, index) => (
              <LearningThemesTableRow
                key={`learning-table-row-${index}`}
                singleGoal={singleGoal}
                index={index}
                handleGoalView={handleGoalView}
                removeGoalDefinition={removeGoalDefinition}
                handleGoalCreateEdit={handleGoalCreateEdit}
              />
            ))}
          </View>
        </View>
      </ScrollView>
    </React.Fragment>
  );
};

const LearningThemesTableHeader = () => {
  return (
    <View style={style.tableRow}>
      <View style={style.tableHeaderName}>
        <TextElement>Theme</TextElement>
      </View>
      <View style={style.tableHeaderTimeline}>
        <TextElement>Timeline</TextElement>
      </View>
      <View style={style.tableHeaderWeightage}>
        <TextElement>Weightage</TextElement>
      </View>
    </View>
  );
};

const LearningThemesTableRow = ({
  singleGoal,
  index,
  handleGoalView,
  removeGoalDefinition,
  handleGoalCreateEdit,
}) => {
  // console.log('singleGoal', singleGoal);
  return (
    <View key={index}>
      <TouchableElement
        onPress={() => {
          handleGoalView(index);
        }}
        style={{
          backgroundColor: '#FDFDFD',
          paddingVertical: 10,
          paddingHorizontal: 4,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
        id={'view-' + index}>
        {/* <View key={index} style={style.tableRow}>
          <View style={style.nameCell}> */}
        <TextElement style={{ flex: 3 }}>{singleGoal.definition}</TextElement>
        {/* </View>
          <View style={style.timelineCell}> */}
        <TextElement style={{ flex: 1.5 }}>
          {moment.unix(singleGoal.timeline).format('YYYY/MM/DD')}
        </TextElement>
        {/* </View> */}
        <View
          style={{
            flex: 1.5,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 2,
          }}>
          <TextElement>{singleGoal.weightage_in_percentage}</TextElement>
          <TouchableElement
            onPress={() => {
              handleGoalCreateEdit(index);
            }}
            id={'edit-' + index}>
            <Avatar.Icon
              size={25}
              icon='pencil'
              style={style.icon}
              color={journeyConfig.travelexColors.TRXBlue || '#0096FF'}
            />
          </TouchableElement>
          <TouchableElement onPress={() => removeGoalDefinition(index)} id={'delete-' + index}>
            <Avatar.Icon
              size={25}
              icon='trash-can'
              style={style.icon}
              color={journeyConfig.travelexColors.TRXBlue || '#0096FF'}
            />
          </TouchableElement>
        </View>
        {/* </View> */}
      </TouchableElement>

      <View style={style.tableDivider}></View>
    </View>
  );
};

const AddButton = ({ addGoal }) => {
  return (
    <View style={style.buttonContainer}>
      <ButtonElement testId='add-goal' mode='contained' style={style.addButton} onPress={addGoal}>
        <TextElement style={style.buttonText}>Save</TextElement>
      </ButtonElement>
    </View>
  );
};

export default memo(CreateGoal, (prevProps, nextProps) => {
  if (prevProps.theme !== nextProps.theme) return false;
  else return true;
});
