import { TouchableOpacity } from 'react-native';

const TouchableElement = (props) => {
    
    if(!props.id) {
        throw new Error('Please provide Touchable id');
    }

    return (
        <TouchableOpacity
            style={props.style}
            onPress={props.onPress}
            accessible={true}
            accessibilityLabel={props.id}
        >
            {props.children}
        </TouchableOpacity>
    );
};

export default TouchableElement;