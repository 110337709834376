import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  tabMenu: {
    flex: 3,
    padding: 7,
    alignItems: 'center',
    borderRightWidth: 1,
    borderColor: '#D8D8D8'
  },
  tabContainer: { flexDirection: 'row', backgroundColor: '#f5f5f5', borderColor: '#D8D8D8', borderBottomWidth: 1 },
  tabText: { padding: 7, fontWeight: 'bold' },
  statsContainer: { padding: 5 },
  statsHeader: { fontWeight: 'bold' },
  enrolled: {
    fontWeight: 'bold',
    fontSize: 30,
    color: '#A7C96A',
  },
  completed: { fontWeight: 'bold', fontSize: 30, color: '#A7C96A' },
  statsCount: {
    fontSize: 11,
  },
  statsRow: { flexDirection: 'row' },
  container: { flex: 2, alignItems: 'center', margin: 5, padding: 10, borderRadius: 5 },
  contentContainer: {
    flex: 1,
    // height: '95%',
  },
  sectionTitle: {
    padding: 5,
    fontSize: 14,
    fontWeight: 'bold',
  },
  loadingText: {
    fontSize: 11,
    color: '#ADADAD',
    marginTop: 10,
    alignItems: 'center',
  },
  centeredView: {
    alignItems: 'center',
  },
  noResultsText: {
    fontSize: 11,
    color: '#ADADAD',
  },
  separator: {
    height: 5,
  },
  marginBottomSpacer: {
    height: 60,
  },
  searchBarContainer: { padding: 7, backgroundColor: '#f5f5f5' },
  scrollView: { backgroundColor: '#f5f5f5', height: '90%' },
  allJourneysContainer: { flex:1 },
  allJourneyScrollView: { backgroundColor: '#f5f5f5' },
  icon: { backgroundColor: 'transparent' },
  activityContainer: { 
    flex: 1 },
  activitiyBottomSpacer: { height: 40 }
});