import ColorConstant from '../../../../utils/ColorConstant.json';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    width: '100%',
    gap: 3,
    flex: 1,
  },
  goalContainer: { width: '100%', gap: 3 },
  rowContainer: {
    flexDirection: 'row',
  },
  flex1: {
    flex: 1,
  },
  flex: {
    flex: 0.5,
  },
  dataTable: { backgroundColor: ColorConstant.LIGHT?.BACKGROUND_COLOR_MEDIUM_SHADE || '#f5f5f5' },
  icon: {
    backgroundColor: 'transparent',
  },
  mandatory: { color: '#F47765' },
  textInputView: { flex: 1 },
  headerText: { fontSize: 20, padding: 5, fontWeight: 'bold' },
  addDefinitionButton: { alignSelf: 'flex-end' },
  padding10: { padding: 10 },
  buttonContainer: {
    alignSelf: 'flex-end',
    paddingBottom: 10,
  },
  addButton: {
    marginLeft: 10,
    marginRight: 10,
    backgroundColor: ColorConstant.LIGHT?.BUTTON_BACKGROUND_COLOR,
  },
  buttonText: {
    color: ColorConstant.LIGHT?.BUTTON_TEXT,
  },
  keyboard: { justifyContent: 'center' },
  modalView: {
    backgroundColor: ColorConstant.LIGHT?.BACKGROUND_COLOR_MEDIUM_SHADE || 'white',
    height: 350,
    width: 300,
    padding: 20,
    borderRadius: 10,
  },
  cancelButton: { marginRight: 5 },
  buttonView: { flexDirection: 'row', marginBottom: 10 },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#f5f5f5',
    padding: 10,
    justifyContent: 'space-between', // Adjusted for proper alignment
    alignItems: 'center', // Adjusted for proper alignment },
  },
  nameCell: { flex: 3.5, display: 'flex', justifyContent: 'center' },
  timelineCell: { flex: 0.5, display: 'flex', alignItems: 'center', justifyContent: 'center' },
  weightageCell: {
    flexDirection: 'row',
    flex: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tableHeaderName: { flex: 3 },
  tableHeaderTimeline: { flex: 1.5 },
  tableHeaderWeightage: { flex: 1.5 },
  tableDivider: { height: 2, backgroundColor: '#DFD7D5' },
  completionDateTextInput: { width: '90%' },
  completionDateTouchable: { width: '100%' },
  completionDateView: {
    // marginLeft: 5,
    // marginRight: 5,
    flexDirection: 'row',
    backgroundColor: '#f7f7f7',
    alignItems: 'center',
  },
  menteeEmailId: {
    // marginLeft: 5,
    // marginRight: 5,
    paddingLeft: 5,
    paddingTop: 5,
    backgroundColor: '#ffffff',
  },
});
