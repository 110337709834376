import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    alignItemCenter: {
        alignItems: 'center'
    },
    loadingText: {
        fontSize: 11,
        color: '#ADADAD',
        marginTop: 10
    },
    icon: { backgroundColor: 'transparent' },
    noResultContainer: { alignItems: 'center' },
    noResultsText: { fontSize: 11, color: '#ADADAD' },
    moduleContainer: {
        backgroundColor: '#f5f5f5',
        padding: 10,
        borderWidth: 1,
        borderColor: '#ffffff',
    },
    textStyle: {
        fontSize: 12,
    },
    moduleText: {
        fontWeight: 'bold',
        fontSize: 15,
    },
    divider:{ height: 10 },
    rowContainer: { flexDirection: 'row' },
    divider5: { height: 5 },
    container: { flex: 5 },
    editButton: { flexDirection: 'row', alignItems: 'center' },
});
