import Alert from '../components/elements/Alert';
import Constants from '../utils/Constants';
import Request from '../dal/RequestNew';
import Store from '../Store/Store';
import { loaderReducer } from '../components/elements/customLoader/customLoaderSlice';

class DesignerService {
  constructor(props) {
    this.props = props;
  }

  async getAllUsersJourneys(data) {
    try {
      let url = Constants.getConfig('GET_ALL_USERS_JOURNEYS')
        .replace('${offset}', data.offset)
        .replace('${limit}', data.limit);
      // console.log('url', url);
      Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const request = new Request(this.props);
      const response = await request.postNew(url);
      // console.log('response', response);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      return response;
    } catch (exception) {
      // console.log('exception', exception);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([500, 400].includes(exception.status)) {
        const errorMessage = exception?.error?.body
          ? JSON.parse(exception.error.body).error
          : exception.error;
        Alert.alert('Whoops', errorMessage);
      }
      return [];
    }
  }

  async getMenteesJourneys(data) {
    try {
      let url = Constants.getConfig('GET_MENTESS_JOURNEYS')
        .replace('${offset}', data.offset)
        .replace('${limit}', data.limit);
      // console.log('url', url);
      Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const request = new Request(this.props);
      const params = {
        mentees: data.mentees,
      };
      // console.log('params', params);
      const response = await request.postNew(url, params);
      // console.log('response', response);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      return response;
    } catch (exception) {
      // console.log('exception', exception);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([500, 400].includes(exception.status)) {
        const errorMessage = exception?.error?.body
          ? JSON.parse(exception.error.body).error
          : exception.error;
        Alert.alert('Whoops', errorMessage);
      }
      return [];
    }
  }

  async saveJourney(journey, isApiDataLoaded) {
    try {
      let url = Constants.getConfig('CREATE_JOURNEY');
      url = url.replace(':spaceId', journey.space_id);
      url = url.replace(':moduleId', journey.module_id);
      // console.log("saveJourney url :: ", url);
      // console.log("journey  : ", journey);
      if (!isApiDataLoaded) Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const request = new Request(this.props);
      const response = await request.postNew(url, journey);
      // console.log("saveJourney response ---- :: ", response);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      return response;
    } catch (exception) {
      // console.log("exception", exception);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([500, 400, 409].includes(exception.status)) {
        Alert.alert('Whoops', exception.error);
      }
      throw exception;
    }
  }

  async updateJourney(journey, isApiDataLoaded) {
    try {
      // console.log("journey in updateJourney**: ", journey);
      let url = Constants.getConfig('UPDATE_JOURNEY');
      url = url.replace(':spaceId', journey.space_id);
      url = url.replace(':moduleId', journey.id.split('#')[0]);
      url = url.replace(':journeyId', journey.journey_id);
      // console.log("updateJourney url :: ", url);
      if (!isApiDataLoaded) Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const request = new Request(this.props);
      const response = await request.putNew(url, journey);
      // console.log("updateJourney response ---- :: ", response);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      return response;
    } catch (exception) {
      // console.log("exception", exception);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([500, 400].includes(exception.status)) {
        Alert.alert('Whoops', exception.error);
      }
      throw exception;
    }
  }

  async deleteJourney(journey, isApiDataLoaded) {
    try {
      // console.log('journey::', journey)
      let module_id = journey.id.split('#')[0];
      // let journey_id = journey["id"].split('#')[1]
      let url = Constants.getConfig('DELETE_JOURNEY');
      url = url.replace(':spaceId', journey.space_id);
      url = url.replace(':moduleId', module_id);
      url = url.replace(':journeyId', journey.journey_id);
      if (!isApiDataLoaded) Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const request = new Request(this.props);
      let response = await request.deleteNew(url);
      // console.log("deleteJourney response ---- :: ", response);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      return response;
    } catch (exception) {
      // console.log("exception", exception);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([500, 400].includes(exception.status)) {
        Alert.alert('Whoops', exception.error);
      }
      throw exception;
    }
  }

  async showJourneyHistory(journey, isApiDataLoaded) {
    try {
      // console.log("journey", journey)
      let url = Constants.getConfig('GET_JOURNEY_HISTORY');
      const module_id = journey.id.split('#')[0];
      url = url.replace(':spaceId', journey.space_id);
      url = url.replace(':moduleId', module_id);
      url = url.replace(':journeyId', journey.journey_id);
      if (!isApiDataLoaded) Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const request = new Request(this.props);
      let response = await request.getNew(url);
      const sortedResponse = response.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      // console.log("showJourneyHistory response ---- :: ", response);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      return sortedResponse;
    } catch (exception) {
      // console.log("exception showJourneyHistory", exception);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([500, 400].includes(exception.status)) {
        Alert.alert('Whoops', exception.error);
      }
      throw exception;
    }
  }

  async saveJourneyHistory(journey, journeyHistory, isApiDataLoaded) {
    try {
      // console.log("journey", journey)
      let url = Constants.getConfig('SAVE_JOURNEY_HISTORY');
      const module_id = journey.id.split('#')[0];
      url = url.replace(':spaceId', journey.space_id);
      url = url.replace(':moduleId', module_id);
      url = url.replace(':journeyId', journey.journey_id);
      if (!isApiDataLoaded) Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const request = new Request(this.props);
      let response = await request.postNew(url, journeyHistory);
      // console.log("saveJourneyHistory response ---- :: ", response);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      return response;
    } catch (exception) {
      // console.log("exception saveJourneyHistory", exception);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([500, 400].includes(exception.status)) {
        Alert.alert('Whoops', exception.error);
      }
      throw exception;
    }
  }

  async getSpaceModules(isApiDataLoaded) {
    try {
      // console.log("isApiDataLoaded", isApiDataLoaded);
      let url = Constants.getConfig('GET_SPACE_MODULES');
      if (!isApiDataLoaded) Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const request = new Request(this.props);
      let response = await request.getNew(url);
      // console.log("getSpaceModules response ---- :: ", response);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      return response;
    } catch (exception) {
      // console.log("exception", exception);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([500, 400].includes(exception.status)) {
        Alert.alert('Whoops', exception.error);
      }
      throw exception;
    }
  }

  //unused
  async getSpaces(isApiDataLoaded) {
    try {
      // console.log("isApiDataLoaded", isApiDataLoaded);
      let url = Constants.getConfig('GET_SPACE_MODULES');
      if (!isApiDataLoaded) Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const request = new Request(this.props);
      let response = await request.getNew(url);
      // console.log("getSpaces response ---- :: ", response);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      return response;
    } catch (exception) {
      // console.log("exception", exception);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([500, 400].includes(exception.status)) {
        Alert.alert('Whoops', exception.error);
      }
      throw exception;
    }
  }

  //unused
  async getSpace(isApiDataLoaded) {
    try {
      let url = Constants.getConfig('GET_SPACE');
      if (!isApiDataLoaded) Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const request = new Request(this.props);
      let response = await request.getNew(url);
      // console.log("getSpace response ---- :: ", response);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      return response;
    } catch (exception) {
      // console.log("exception", exception);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([500, 400].includes(exception.status)) {
        Alert.alert('Whoops', exception.error);
      }
      throw exception;
    }
  }

  async createSpace(isApiDataLoaded, space) {
    try {
      // console.log("isApiDataLoaded", isApiDataLoaded);
      let url = Constants.getConfig('CREATE_SPACE');
      if (!isApiDataLoaded) Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const request = new Request(this.props);
      let response = await request.postNew(url, space);
      // console.log("createSpace response ---- :: ", response);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      return response;
    } catch (exception) {
      // console.log("exception", exception);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([500, 400, 409].includes(exception.status)) {
        Alert.alert('Whoops', exception.error);
      }
      throw exception;
    }
  }

  async updateSpace(isApiDataLoaded, space) {
    try {
      // console.log("updateSpace", space);
      let url = Constants.getConfig('UPDATE_SPACE');
      url = url.replace(':scope_spaceid', space.scope + '_' + space.id);
      // console.log(url);
      let spaceBody = {
        display_name: space.display_name,
      };
      if (!isApiDataLoaded) Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const request = new Request(this.props);
      let response = await request.putNew(url, spaceBody);
      // console.log("updateSpace response ---- :: ", response);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      return response;
    } catch (exception) {
      // console.log("exception", exception);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([500, 400, 409].includes(exception.status)) {
        Alert.alert('Whoops', exception.error);
      }
      throw exception;
    }
  }

  async deleteSpace(isApiDataLoaded, space) {
    try {
      // console.log("isApiDataLoaded", isApiDataLoaded);
      let url = Constants.getConfig('DELETE_SPACE');
      url = url.replace(':scope_spaceid', space.scope + '_' + space.id);
      // console.log("url", url);
      if (!isApiDataLoaded) Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const request = new Request(this.props);
      let response = await request.deleteNew(url);
      // console.log("deleteSpace response ---- :: ", response);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      return response;
    } catch (exception) {
      // console.log("exception", exception);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([500, 400].includes(exception.status)) {
        Alert.alert('Whoops', exception.error);
      }
      throw exception;
    }
  }

  async searchSpaces(isApiDataLoaded, searchKeyword) {
    try {
      let url = Constants.getConfig('SEARCH_SPACE');
      url = url.replace('${searchColumnName}', 'display_name');
      url = url.replace('${searchKeyword}', searchKeyword);
      // console.log("url", url);
      if (!isApiDataLoaded) Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const request = new Request(this.props);
      let response = await request.getNew(url);
      // console.log("searchSpace response ---- :: ", response);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      return response;
    } catch (exception) {
      // console.log("exception", exception);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([500, 400].includes(exception.status)) {
        Alert.alert('Whoops', exception.error);
      }
      throw exception;
    }
  }

  async getModules(isApiDataLoaded, spaceId) {
    try {
      // console.log("getmodules space", space);
      // console.log("isApiDataLoaded", isApiDataLoaded);
      let url = Constants.getConfig('GET_MODULES');
      url = url.replace(':space_id', spaceId);
      // console.log("url", url);
      if (!isApiDataLoaded) Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const request = new Request(this.props);
      let response = await request.getNew(url);
      // console.log("getModules response ---- :: ", response);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      return response;
    } catch (exception) {
      // console.log("exception", exception);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([500, 400].includes(exception.status)) {
        Alert.alert('Whoops', exception.error);
      }
      throw exception;
    }
  }

  async createModule(isApiDataLoaded, module, spaceId) {
    try {
      // console.log("isApiDataLoaded", isApiDataLoaded);
      let url = Constants.getConfig('CREATE_MODULE');
      url = url.replace(':spaceId', spaceId);
      if (!isApiDataLoaded) Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const request = new Request(this.props);
      let response = await request.postNew(url, module);
      // console.log("createModule response ---- :: ", response);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      return response;
    } catch (exception) {
      // console.log("exception", exception);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([500, 400, 409].includes(exception.status)) {
        Alert.alert('Whoops', exception.error);
      }
      throw exception;
    }
  }

  async updateModule(isApiDataLoaded, module) {
    try {
      // console.log("updateModule", module);
      let url = Constants.getConfig('UPDATE_MODULE');
      url = url.replace(':spaceId', module.space_id);
      url = url.replace(':moduleId', module.id);
      // console.log('url::', url);
      let moduleBody = {};
      if (module.display_name) {
        moduleBody.display_name = module.display_name;
      }
      if (module.description) {
        moduleBody.description = module.description;
      }
      // console.log('moduleBody', moduleBody);
      if (!isApiDataLoaded) Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const request = new Request(this.props);
      let response = await request.putNew(url, moduleBody);
      // console.log("updateModule response ---- :: ", response);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      return response;
    } catch (exception) {
      // console.log("exception", exception);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([500, 400, 409].includes(exception.status)) {
        Alert.alert('Whoops', exception.error);
      }
      throw exception;
    }
  }

  async deleteModule(isApiDataLoaded, module) {
    try {
      let url = Constants.getConfig('DELETE_MODULE');
      url = url.replace(':spaceId', module.space_id);
      url = url.replace(':moduleId', module.id);
      // console.log("url", url);
      if (!isApiDataLoaded) Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const request = new Request(this.props);
      let response = await request.deleteNew(url);
      // console.log("deleteModule response ---- :: ", response);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      return response;
    } catch (exception) {
      // console.log("exception", exception);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([500, 400].includes(exception.status)) {
        Alert.alert('Whoops', exception.error);
      }
      throw exception;
    }
  }

  async createTopic(journey, topic, isApiDataLoaded) {
    try {
      let url = Constants.getConfig('CREATE_TOPIC');
      url = url.replace(':spaceId', journey.space_id);
      url = url.replace(':moduleId', journey.id.split('#')[0]);
      url = url.replace(':journeyId', topic.journey_id);
      // console.log("createTopic url :: ", url);
      if (!isApiDataLoaded) Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const request = new Request(this.props);
      let response = await request.postNew(url, topic);
      // console.log("createTopic response ---- :: ", response);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      return response;
    } catch (exception) {
      // console.log("createTopic exception", exception);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([500, 400, 409].includes(exception.status)) {
        Alert.alert('Whoops', exception.error);
      }
      throw exception;
    }
  }

  async updateTopic(journey, topic, isApiDataLoaded) {
    try {
      // console.log("updateTopic", topic);
      let url = Constants.getConfig('UPDATE_TOPIC');
      url = url.replace(':spaceId', journey.space_id);
      url = url.replace(':moduleId', journey.id.split('#')[0]);
      url = url.replace(':journeyId', journey.journey_id);
      url = url.replace(':topicId', topic.id);
      // console.log("updateTopic url :: ", url);
      if (!isApiDataLoaded) Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const request = new Request(this.props);
      let response = await request.putNew(url, topic);
      // console.log("updateTopic response ---- :: ", response);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      return response;
    } catch (exception) {
      // console.log("exception", exception);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([500, 400, 409].includes(exception.status)) {
        Alert.alert('Whoops', exception.error);
      }
      throw exception;
    }
  }

  async getTopics(journey, isApiDataLoaded) {
    try {
      // console.log("journey **", journey);
      let url = Constants.getConfig('GET_TOPICS');
      url = url.replace(':spaceId', journey.space_id);
      url = url.replace(':moduleId', journey.id.split('#')[0]);
      url = url.replace(':journeyId', journey.journey_id);
      if (!isApiDataLoaded) Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const request = new Request(this.props);
      let response = await request.getNew(url);
      // console.log("getTopics response ---- :: ", response);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      return response;
    } catch (exception) {
      // console.log("exception", exception);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([500, 400].includes(exception.status)) {
        Alert.alert('Whoops', exception.error);
      }
      // throw exception;
      return []; //topics is an array.
    }
  }

  async deleteTopic(isApiDataLoaded, topic, journey) {
    try {
      // console.log("topic to delete", topic)
      let url = Constants.getConfig('DELETE_TOPIC');
      url = url.replace(':spaceId', journey.space_id);
      url = url.replace(':moduleId', journey.id.split('#')[0]);
      url = url.replace(':journeyId', topic.journey_id);
      url = url.replace(':topicId', topic.id);
      if (!isApiDataLoaded) Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const request = new Request(this.props);
      let response = await request.deleteNew(url);
      // console.log("deleteTopic response ---- :: ", response);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      return response;
    } catch (exception) {
      // console.log("exception", exception);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([500, 400].includes(exception.status)) {
        Alert.alert('Whoops', exception.error);
      }
      throw exception;
    }
  }

  async getRoles(isApiDataLoaded) {
    try {
      // console.log("isApiDataLoaded", isApiDataLoaded);
      let url = Constants.getConfig('GET_ROLES');
      if (!isApiDataLoaded) Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const request = new Request(this.props);
      let response = await request.getNew(url);
      // console.log("getRoles response ---- :: ", response);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      return response;
    } catch (exception) {
      // console.log("exception", exception);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([500, 400].includes(exception.status)) {
        Alert.alert('Whoops', exception.error);
      }
      throw exception;
    }
  }

  async createRole(isApiDataLoaded, role) {
    try {
      // console.log("isApiDataLoaded", isApiDataLoaded);
      // console.log('role in createRole', role);
      let url = Constants.getConfig('CREATE_ROLE');
      if (!isApiDataLoaded) Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const request = new Request(this.props);
      let response = await request.postNew(url, role);
      // console.log("createRole response ---- :: ", response);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      return response;
    } catch (exception) {
      // console.log("exception", exception);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([500, 400, 409].includes(exception.status)) {
        Alert.alert('Whoops', exception.error);
      }
      throw exception;
    }
  }

  async updateRole(isApiDataLoaded, role) {
    try {
      // console.log("isApiDataLoaded", isApiDataLoaded);
      // console.log('role in updateRole', role);
      let url = Constants.getConfig('UPDATE_ROLE');
      if (!isApiDataLoaded) Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const request = new Request(this.props);
      let response = await request.putNew(url, role);
      // console.log("updateRole response ---- :: ", response);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      return response;
    } catch (exception) {
      // console.log("exception", exception);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([500, 400, 409].includes(exception.status)) {
        Alert.alert('Whoops', exception.error);
      }
      throw exception;
    }
  }

  async deleteRole(isApiDataLoaded, role) {
    try {
      // console.log("deleteRole", role)
      let url = Constants.getConfig('DELETE_ROLE');
      url = url.replace(':id', encodeURIComponent(role.id));
      // console.log("deleteRole url :: ", url);
      if (!isApiDataLoaded) Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const request = new Request(this.props);
      let response = await request.deleteNew(url);
      // console.log("deleteRole response ---- :: ", response);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      return response;
    } catch (exception) {
      // console.log("exception", exception);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([500, 400].includes(exception.status)) {
        Alert.alert('Whoops', exception.error);
      }
      throw exception;
    }
  }

  async searchRoles(isApiDataLoaded, searchKeyword) {
    try {
      let url = Constants.getConfig('SEARCH_ROLE');
      url = url.replace('${searchColumnName}', 'tags');
      url = url.replace('${searchKeyword}', searchKeyword);
      // console.log("url", url);
      if (!isApiDataLoaded) Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const request = new Request(this.props);
      let response = await request.getNew(url);
      // console.log("searchRoles response ---- :: ", response);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      return response;
    } catch (exception) {
      // console.log("exception", exception);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([500, 400].includes(exception.status)) {
        Alert.alert('Whoops', exception.error);
      }
      throw exception;
    }
  }

  async getUserRoles(isApiDataLoaded, roleId) {
    try {
      // console.log("isApiDataLoaded", isApiDataLoaded);
      let url = Constants.getConfig('GET_USER_ROLES');
      url = url.replace(':role_id', encodeURIComponent(roleId));
      //  // console.log("getUserRoles url ---- :: ", url);
      if (!isApiDataLoaded) Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const request = new Request(this.props);
      let response = await request.getNew(url);
      // console.log("getUserRoles response ---- :: ", response);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      return response;
    } catch (exception) {
      // console.log("exception", exception);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([500, 400].includes(exception.status)) {
        Alert.alert('Whoops', exception.error);
      }
      throw exception;
    }
  }

  async assignUserRole(isApiDataLoaded, userRole) {
    try {
      // console.log("userRole", userRole)
      let url = Constants.getConfig('CREATE_USER_ROLE');
      if (!isApiDataLoaded) Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const request = new Request(this.props);
      let response = await request.postNew(url, userRole);
      // console.log("assignUserRole response ---- :: ", response);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      return response;
    } catch (exception) {
      // console.log("exception", exception);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([500, 400, 409].includes(exception.status)) {
        Alert.alert('Whoops', exception.error);
      }
      throw exception;
    }
  }

  async removeUserRole(isApiDataLoaded, roleId, userEmail) {
    try {
      let url = Constants.getConfig('UPDATE_USER_ROLE');
      url = url.replace(':role_id', roleId);
      url = url.replace(':email_id', userEmail);
      // console.log("removeUserRole", url);

      if (!isApiDataLoaded) Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const request = new Request(this.props);
      let response = await request.putNew(url);
      // console.log("removeUserRole response ---- :: ", response);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      return response;
    } catch (exception) {
      // console.log("exception", exception);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([500, 400].includes(exception.status)) {
        Alert.alert('Whoops', exception.error);
      }
      throw exception;
    }
  }

  //To search within user roles by name or email //unused
  async searchUserRoles(isApiDataLoaded, searchKeyword) {
    try {
      let url = Constants.getConfig('SEARCH_USER_ROLES');
      url = url.replace('${searchColumnName}', 'email');
      url = url.replace('${searchKeyword}', searchKeyword);
      // console.log("url", url);
      if (!isApiDataLoaded) Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const request = new Request(this.props);
      let response = await request.getNew(url);
      // console.log("searchUserRoles response ---- :: ", response);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      return response;
    } catch (exception) {
      // console.log("exception", exception);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([500, 400].includes(exception.status)) {
        Alert.alert('Whoops', exception.error);
      }
      throw exception;
    }
  }
}
export default DesignerService;
