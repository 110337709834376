import * as React from 'react';

import { Text, View } from 'react-native';

import Alert from './Alert';
import ColorConstant from '../../utils/ColorConstant.json';
import Tags from 'react-native-tags';
import TouchableElement from './TouchableElement';
import { memo } from 'react';
import { useState } from 'react';

const TagInput = (props) => {
  const [tagText, setTagText] = useState('');
  // console.log("value", props.value);
  let initialValue = props.value.length ? props.value : [];

  return (
    <View key={JSON.stringify(props.value)} style={{ padding: 5 }}>
      <Tags
        key={JSON.stringify(props.value)}
        textInputProps={{
          accessible: true,
          testID: props.accessibilityLabel,
          accessibilityLabel: props.accessibilityLabel,
          onFocus: () => {
            setTagText('');
          },
          onBlur: () => {
            let texttag = tagText;
            setTagText('');
            let reg = new RegExp('[^A-Za-z0-9, ]', 'g');
            // console.log('onblur  ', tagText);
            if (texttag && !initialValue.includes(tagText) && tagText.search(reg) == -1) {
              props.setter([...initialValue, tagText]);
            }
          },
          onChange: (text) => {
            // console.log('onChange  ', tagText);
            let reg = new RegExp('[^A-Za-z0-9, ]', 'g');
            if (text.nativeEvent.text.search(reg) !== -1) {
              Alert.alert('info', 'Special characters are not allowed.');
            }
            const temp_text = text.nativeEvent.text.replace(/,/g, '');

            if (temp_text) {
              setTagText(temp_text.trim());
            }
          },
        }}
        createTagOnString={[',']}
        initialTags={[...initialValue]}
        onChangeTags={(tags) => {
          let reg = new RegExp('[^A-Za-z0-9, ]', 'g');
          // console.log('onChangeTags  ', tagText);
          //   console.log('onChangeTags', tags);
          const filterdTags = tags.filter((tag) => {
            const regex = /^,+$/;
            return tag !== '' && !regex.test(tag) && tag.search(reg) == -1;
          });
          //   console.log('filterdTags', filterdTags);
          // console.log('onCHangeTag', tagText);

          if (tagText.search(reg) == -1)
            props.setter(filterdTags);
        }}
        onTagPress={(index) => {
          // console.log(index, tagLabel, event);
          let temp = [...props.value];
          temp.splice(index, 1);
          // console.log("temp",temp);
          // props.setter([...props.value]);
          props.setter(temp);
          // return props.value;
        }}
        inputStyle={{
          backgroundColor: '#ffffff',
          borderStyle: 'solid',
          borderColor: '#899499',
          borderWidth: 1,
          borderRadius: 10,
        }}
        renderTag={({ tag, index, onPress }) => {
          let reg = new RegExp('[^A-Za-z0-9, ]', 'g');

          let result =
            tag != '' && tag.search(reg) == -1 ? (
              <TouchableElement
                key={`${tag}-${index}`}
                onPress={onPress}
                id={`${props.accessibilityLabel}-tag-${index}`}>
                <View>
                  {tag != '' || ',' ? (
                    <Text
                      style={{
                        backgroundColor: ColorConstant.LIGHT?.TAGINPUT_BACKGROUND_COLOR,
                        padding: 3,
                        marginRight: 2,
                        color: ColorConstant.LIGHT?.TAGINPUT_TEXT_COLOR,
                        fontSize: 14,
                        borderRadius: 5,
                      }}>
                      {tag}
                    </Text>
                  ) : (
                    <Text></Text>
                  )}
                </View>
              </TouchableElement>
            ) : (
              <View></View>
            );

          return result;
        }}
      />
    </View>
  );
};

export default memo(TagInput, (prev, next) => {
  return JSON.stringify(prev.value || []) == JSON.stringify(next.value || []);
});
