import { Dimensions, View } from 'react-native';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// import { Avatar } from 'react-native-paper';
// import AvatarElement from '../elements/Avatar';
import DividerElement from '../elements/Divider';
import { LogBox } from 'react-native';
import StatsService from '../../service/StatsService';
import TextElement from '../elements/Text';
// import TouchableElement from '../elements/TouchableElement';
import ViewElement from '../elements/View';
import { getUserIdentity } from '../../utils/UserIdentity';
import { hrAdminReducer } from './HRAdminSlice';
// import icon from '../../asset/img/userImages/male6.jpg';
import style from './Style';
import { useIsFocused } from '@react-navigation/native';

// const getName = require('../../utils/NameMapping').getName;
LogBox.ignoreLogs(['EventEmitter.removeListener']);
const { height } = Dimensions.get('window');

// const Drop = (props) => {
//   const [show, setShow] = useState(false);
//   return (
//     <View>
//       <TouchableElement id='switch' onPress={() => setShow(!show)} style={style.accountSwitch}>
//         <Avatar.Icon size={40} icon='account-switch' style={style.icon} />
//       </TouchableElement>

//       {show ? (
//         <View style={style.drop}>
//           <TouchableElement
//             id='mentee'
//             style={style.padding5}
//             onPress={() => {
//               props.setPreferredRole('Mentee');
//               setShow(false);
//             }}>
//             <TextElement style={style.padding5}>Mentee</TextElement>
//           </TouchableElement>

//           <TouchableElement
//             style={style.padding5}
//             onPress={() => {
//               props.setPreferredRole('Mentor');
//               setShow(false);
//             }}
//             id='mentor'>
//             <TextElement style={style.padding5}>Mentor</TextElement>
//           </TouchableElement>
//         </View>
//       ) : (
//         <View />
//       )}
//     </View>
//   );
// };

const HRAdminPage = (props) => {
  // console.log("hradmin props", props.setPreferredRole);
  const stats = useSelector((state) => state.hrAdmin.value);
  const statsReports = useSelector((state) => state.hrAdmin.statsReports);
  // console.log("stats from redux", stats);
  // console.log("statsReports from redux", statsReports);
  const dispatch = useDispatch();
  const [isApiDataLoaded, setIsApiDataLoaded] = useState(false);
  const statsService = new StatsService(props);
  const isFocused = useIsFocused();
  const [userIdentity, setUserIdentity] = React.useState({});

  const fetchData = async () => {
    if (stats.length) {
      setIsApiDataLoaded(true);
    }
    const uIdentity = await getUserIdentity();
    if (uIdentity) {
      let combinedStatsResp = {};
      setUserIdentity(uIdentity);
      for (const stats of statsReports) {
        statsService.getStats(stats, isApiDataLoaded).then((response) => {
          // console.log("stats response ", response);
          combinedStatsResp[response.name] = response;
          if (statsReports.length == Object.keys(combinedStatsResp).length) {
            // console.log("combinedStatsResp", combinedStatsResp);
            dispatch(hrAdminReducer({ stats: combinedStatsResp }));
            setIsApiDataLoaded(true);
          }
        });
      }
    }
  };
  useEffect(() => {
    if (isFocused) {
      fetchData();
    }
  }, [isFocused]);

  return (
    <ViewElement pageName='Dashboard' previousPath='/' height={height} {...props}>
      <View style={style.container}>
        {/* <View style={style.accountInfo}>
          <View style={style.accountView}>
            <View style={style.profileInfo}>
              <AvatarElement path={icon} size={60} />
              <TextElement theme={'DARK'} style={style.email}>
                Hello, {userIdentity && userIdentity.email_id ? getName(userIdentity.email_id) : ''}
              </TextElement>
              <TextElement theme={'DARK'} style={style.accountText}>
                {userIdentity && userIdentity.jobTitle ? `${userIdentity.jobTitle},` : ''}
                {userIdentity && userIdentity.department ? ` ${userIdentity.department}, ` : ''}
                {userIdentity && userIdentity.country ? userIdentity.country : ''}
              </TextElement>
              <TextElement theme={'DARK'} style={style.accountText}>
                {userIdentity && userIdentity.email_id ? userIdentity.email_id : ''}
              </TextElement>
            </View>
            <View style={style.iconAlign}>
                            <TouchableElement
                                id='survey-list'
                                onPress={() => props.navigation.navigate('SurveyList')} style={style.feedbackIcon}
                            >
                                <Avatar.Icon size={40} icon="clipboard-text-outline" style={style.icon} />
                            </TouchableElement>
                        </View>

            <View style={style.iconAlign}>
              <Drop {...props} />
            </View>
          </View>
        </View> */}
        <View style={style.padding10}>
          <View>
            <TextElement theme={'DARK'} style={style.analytics}>
              Mentor and Mentee Analytics
            </TextElement>

            <View style={style.analyticsRow}>
              <View style={style.card}>
                <TextElement theme={'LIGHT'} style={style.greenText}>
                  {stats?.getCountOfActiveMentors?.value || 0}{' '}
                </TextElement>
                <TextElement theme={'LIGHT'} style={style.textStyle}>
                  Mentors
                </TextElement>
              </View>
              <View style={style.card}>
                <TextElement theme={'LIGHT'} style={style.orangeText}>
                  {stats?.getCountOfActiveMentees?.value || 0}{' '}
                </TextElement>
                <TextElement theme={'LIGHT'} style={style.textStyle}>
                  Mentees
                </TextElement>
              </View>
              <View style={style.card}>
                <TextElement theme={'LIGHT'} style={style.blueText}>
                  {stats?.getCountOfActiveConnections?.value || 0}{' '}
                </TextElement>
                <TextElement theme={'LIGHT'} style={style.textStyle}>
                  Connections
                </TextElement>
              </View>
            </View>
          </View>

          <DividerElement theme={props.theme} />
          <DividerElement theme={props.theme} />

          <View>
            <TextElement theme={'DARK'} style={style.analytics}>
              Goals and Milestone Analytics
            </TextElement>

            <View style={style.analyticsRow}>
              <View style={style.card}>
                <TextElement theme='LIGHT' style={style.greenText}>
                  {stats?.getCountOfTotalGoals?.value || 0}{' '}
                </TextElement>
                <TextElement theme='LIGHT' style={style.textStyle}>
                  Total
                </TextElement>
              </View>
              <View style={style.card}>
                <TextElement theme='LIGHT' style={style.orangeText}>
                  {stats?.getCountOfCompletedGoals?.value || 0}{' '}
                </TextElement>
                <TextElement theme='LIGHT' style={style.textStyle}>
                  Achieved
                </TextElement>
              </View>
              <View style={style.card}>
                <TextElement theme='LIGHT' style={style.blueText}>
                  {stats?.getCountOfInProgressGoals?.value || 0}{' '}
                </TextElement>
                <TextElement theme='LIGHT' style={style.textStyle}>
                  In Progress
                </TextElement>
              </View>
            </View>
          </View>
        </View>
      </View>
    </ViewElement>
  );
};
export default memo(HRAdminPage, (prevProps, nextProps) => {
  if (prevProps.theme !== nextProps.theme) return false;
  else return true;
});
