import { AppRegistry, Dimensions, Platform, View } from 'react-native';

import App from './App';
import DatadogMonitoring from './utils/DatadogMonitoring';

const { width } = Dimensions.get('window');

const Layout = () => {
    if (width > 600) {
        return (
            <View style={{ display: 'flex', flexDirection: 'row', height: '100%', width: '100%', backgroundColor: '#212644' }}>
                <View style={{ flex: 1.2 }}>
                </View>
                <View style={{ flex: 3.6 }}>
                    <App />
                </View>
                <View style={{ flex: 1.2 }}>
                </View>
            </View>
        );
    }
    else {
        return <App />;
    }
};


AppRegistry.registerComponent('main', () => Layout);

if (Platform.OS === 'web') {
    console.log('WEB');
    AppRegistry.runApplication('main', {
        rootTag: document.getElementById('root'),
    });
    // new DatadogMonitoring().initializeDatadogWeb();
} else {
    console.log('Mobile');
    new DatadogMonitoring().initializeDatadog();
}

