const searchMappings = {
    'area_of_expertize': 'Area of Expertise',
    'mentor_status': 'Status',
    'preferences_communication_modes': 'Communication Modes',
    'mentor_mentoring_skills_soft_skills': 'Soft Skills',
    'mentor_mentoring_skills_tools': 'Tools',
    'mentor_mentoring_skills_technologies': 'Technologies',
    'mentor_mentoring_skills_technologies': 'Technologies',
    'name': 'Name',
    'location': 'Location'
};

export {
    searchMappings
};