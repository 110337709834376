import { KeyboardAvoidingView, ScrollView, View } from 'react-native';
import { getDate } from '../../../utils/utils';
import { useEffect } from 'react';

import Fab from '../../../elements/Fab';
import Modules from '../Module/Modules';
import TextElement from '../../../elements/Text';
import ViewWithBack from '../../../elements/ViewWithBack';
import { useIsFocused } from '@react-navigation/native';
import { useSelector } from 'react-redux';
import style from './styles/spaceStyle';

const getName = require('../../../../utils/NameMapping').getName;

const Space = (parentProps) => {
    const props = { ...parentProps, ...parentProps.route.params };
    const { spaceId } = props.route.params;
    const spaces = useSelector((state) => state.spaces.value);
    const space = spaces.filter(space => space.id == spaceId)[0];
    const isFocused = useIsFocused();
    // const [isApiDataLoaded, setIsApiDataLoaded] = useState(false);

    const fetchData = () => {
        if (space && Object.keys(space).length) {
            // setIsApiDataLoaded(true);
        }
    };

    useEffect(() => {
        if (isFocused) {
            fetchData();
        }
    }, [isFocused]);

    // console.log("space", space)
    return (
        <ViewWithBack pageName="Space"  {...props} backAction={() => { props.navigation.navigate('Spaces'); }} >
            <View style={style.divider}></View>
            <ScrollView
                showsVerticalScrollIndicator={false}
                showsHorizontalScrollIndicator={false}
            >
                <KeyboardAvoidingView behavior="position" style={style.container}>
                    <View style={style.spaceContainer}>
                        <View style={style.divider}></View>
                        <View style={style.headerContainer}>
                            <View style={style.spaceName}>
                                <TextElement theme={'DARK'} style={style.headerText}>Space : {space.display_name}</TextElement>
                            </View>
                            <TextElement style={style.createdByText}>Created by {space.created_by ? getName(space.created_by) : '-'} on {space.created_at ? getDate(space.created_at) : '-'}</TextElement>
                        </View>
                        <View style={style.divider}></View>
                    </View>
                    {/* <View> */}
                    <TextElement theme='DARK' style={style.moduleHeading}>Modules</TextElement>
                    <Modules spaceId={space.id}{...props} />
                    <View style={style.bottomSpace}></View>
                    {/* </View> */}
                </KeyboardAvoidingView>
            </ScrollView>
            <Fab icon="plus" onPress={() => {
                props.navigation.navigate('ModuleForm', {
                    type: 'Create',
                    spaceId: space.id
                });
            }} />
        </ViewWithBack>
    );
};

export default Space;