import { KeyboardAvoidingView, ScrollView, View } from 'react-native';
import { Modal, Portal, Provider } from 'react-native-paper';
import { getDate } from '../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import Alert from '../../elements/Alert';
import { Avatar } from 'react-native-paper';
import ButtonElement from '../../elements/Button';
import ColorConstant from '../../../utils/ColorConstant.json';
import DesignerService from '../../../service/DesignerService';
import Fab from '../../elements/Fab';
import JourneyConfig from '../JourneyConfig.json';
import JourneyService from '../../../service/JourneyService';
import TextElement from '../../elements/Text';
import TextInputElement from '../../elements/TextInput';
import TouchableElement from '../../elements/TouchableElement';
import ViewWithBack from '../../elements/ViewWithBack';
import { getUserIdentity } from '../../../utils/UserIdentity';
import { journeyReducer } from './JourneySlice';
import style from './styles/designerJourneyStyle';
import { useIsFocused } from '@react-navigation/native';
import { SceneMap, TabBar, TabView } from 'react-native-tab-view';

const getName = require('../../../utils/NameMapping').getName;

const DesignerJourney = (parentProps) => {
  const props = { ...parentProps, ...parentProps.route.params };
  // console.log("props", props);
  const designerJourneys = useSelector((state) => state.designerJourneys.value);
  // console.log("designerJourneys", designerJourneys);
  const dispatch = useDispatch();
  const [isApiDataLoaded, setIsApiDataLoaded] = useState(false);
  const isFocused = useIsFocused();
  const [dialogComponent, setDialogComponent] = useState(<View />);
  const [userRole, setUserRole] = useState('');

  const journeyService = new JourneyService(props);
  const designerService = new DesignerService(props);

  useEffect(() => {
    getUserIdentity().then((d) => {
      setUserRole(d.user_role);
    });
  }, []);

  const fetchData = () => {
    if (Object.keys(designerJourneys.stats).length) {
      setIsApiDataLoaded(true);
    }
    journeyService.fetchDesignerJourneys(isApiDataLoaded).then((journeyResult) => {
      // console.log("journeyResult 1", journeyResult);
      setIsApiDataLoaded(true);
      dispatch(journeyReducer({ journeys: journeyResult }));
    });
  };

  const deleteJourney = (journey, reasonToDelete) => {
    if (journey.journey_status == 'drafted') {
      // const journeyId = journey.id + '#' + journey.name;
      // console.log("journeyId-----designer Home", journeyId)
      Alert.confirm('Confirm', 'Deleted journey will not be reverted', () => {
        designerService.deleteJourney(journey, isApiDataLoaded).then(() => {
          // console.log("response", response);
          fetchData();
          setIsApiDataLoaded(true);
          Alert.alert('Success', 'Journey deleted');
        });
      });
    } else if (!reasonToDelete.length) {
      let missingFields = [];
      missingFields.push('reason to delete');
      // console.log("here", missingFields);
      setDialogComponent(<View />);
      const alertMessage = `Please enter ${missingFields}`;
      Alert.alert('Required', alertMessage);
    } else {
      setDialogComponent(<View />);
      // journey.journey_status = 'rejected';
      const journeyCopy = { ...journey };
      journeyCopy.reason_to_delete = reasonToDelete;
      // console.log("journeyCopy", journeyCopy)
      // const journeyId = journey.id + '#' + journey.name;
      designerService.deleteJourney(journeyCopy, isApiDataLoaded).then(() => {
        // console.log("response", response);
        fetchData();
        setIsApiDataLoaded(true);
        Alert.alert('Success', 'Journey deleted');
      });
    }
  };

  const menuProps = {
    userRole,
    deleteJourney,
    setDialogComponent,
    designerJourneys,
    isApiDataLoaded,
    stats: designerJourneys.stats,
  };

  const flush = () => {
    setIsApiDataLoaded(false);
  };

  useEffect(() => {
    if (isFocused) {
      fetchData();
    } else {
      flush();
    }
  }, [isFocused]);

  return (
    <Provider>
      <ViewWithBack
        pageName='Journey Dashboard'
        {...props}
        backAction={() => {
          props.navigation.navigate('DesignerDashboard');
        }}>
        {dialogComponent}
        <Menu {...menuProps} {...props} />
        <Fab
          icon='plus'
          onPress={() => {
            props.navigation.navigate('Designerhome', { type: 'Create' });
          }}
        />
      </ViewWithBack>
    </Provider>
  );
};

const Menu = (props) => {
  const { designerJourneys, userRole } = props;
  // console.log("designerJourneys", designerJourneys);
  const [value, setValue] = useState(0);

  const routes = [
    { key: 'drafted', title: 'Drafted' },
    { key: 'published', title: 'Published' },
  ];

  const jdRoutes = [
    { key: 'drafted', title: 'Drafted' },
    { key: 'published', title: 'Published' },
    { key: 'declined', title: 'Declined' },
  ];
  const adminRoutes = [
    { key: 'drafted', title: 'Drafted' },
    { key: 'published', title: 'Published' },
    { key: 'reviewed', title: 'Review' },
  ];

  const renderScene = SceneMap({
    drafted: () => <Drafted {...props} journeys={designerJourneys.drafted} isDelete={true} />,
    published: () => (
      <Published {...props} journeys={designerJourneys.published} isDelete={false} />
    ),
    declined: () => <Declined {...props} journeys={designerJourneys.rejected} isDelete={false} />,
    reviewed: () => <Review {...props} journeys={designerJourneys.review} isDelete={false} />,
  });

  return (
    <TabView
      key={userRole}
      navigationState={{
        index: value,
        routes: userRole === 'admin' ? adminRoutes : userRole === 'jd' ? jdRoutes : routes,
      }}
      renderScene={renderScene}
      onIndexChange={(index) => setValue(index)}
      renderTabBar={(props) => <TabBar style={{ backgroundColor: '#3C436C' }} {...props} />}
    />
  );
};

const Drafted = (props) => {
  return <JourneyRow {...props} />;
};

const Published = (props) => {
  return <JourneyRow {...props} />;
};

const Review = (props) => {
  return <JourneyRow {...props} isReview={true} />;
};

const Declined = (props) => {
  return <JourneyRow {...props} />;
};

const JourneyRow = (props) => {
  // console.log("journeyRow props", props.isApiDataLoaded);
  const { journeys, isDelete, isApiDataLoaded, isReview, deleteJourney, setDialogComponent } =
    props;

  const onClick = (journey) => {
    if (isReview) {
      // console.log("isReview", isReview)
      props.navigation.navigate('ReviewJourney', { type: 'Update', journeyId: journey.journey_id });
    } else {
      props.navigation.navigate('Designerhome', { type: 'Update', journeyId: journey.journey_id });
    }
  };

  return (
    <ScrollView
      style={style.designerJourneyScrollView}
      showsVerticalScrollIndicator={false}
      showsHorizontalScrollIndicator={false}>
      {
        <View>
          {isApiDataLoaded ? (
            <View>
              {journeys?.length ? (
                journeys.map((journey, index) => {
                  const journeyBackground = journey.background_color
                    ? JourneyConfig.colors[journey.background_color]
                    : JourneyConfig.colors.Blue;
                  return (
                    <TouchableElement
                      id={'journey-row-' + index}
                      key={index}
                      onPress={() => onClick(journey)}>
                      <View key={index} style={style.journeyRowView}>
                        <View style={style.journeyIconView}>
                          <Avatar.Icon
                            size={45}
                            icon={
                              journey.icon
                                ? JourneyConfig.icons[journey.icon]
                                : JourneyConfig.icons.Security
                            }
                            style={{ backgroundColor: journeyBackground }}
                          />
                        </View>
                        <View style={style.journeyInfoView}>
                          <TextElement style={style.journeyName} numberOfLines={1}>
                            {journey.display_name}
                          </TextElement>
                          <TextElement style={style.textStyle} numberOfLines={1}>
                            Description: {journey.description}
                          </TextElement>
                          <TextElement style={style.textStyle}>
                            Created by {journey.created_by ? getName(journey.created_by) : '-'} on{' '}
                            {journey.created_at ? getDate(journey.created_at) : '-'}
                          </TextElement>
                        </View>
                        {isDelete ? (
                          <TouchableElement
                            id={'drafted-list-' + index}
                            style={style.journeyActionView}
                            onPress={() => {
                              if (journey.journey_status == 'drafted') {
                                deleteJourney(journey);
                              } else {
                                const component = (
                                  <Dialog
                                    show={true}
                                    title='Deleting Journey'
                                    hintInput='Enter reason'
                                    handleClose={() => {
                                      setDialogComponent(<View />);
                                    }}
                                    journey={journey}
                                    {...props}
                                  />
                                );
                                setDialogComponent(component);
                              }
                            }}>
                            <Avatar.Icon
                              size={30}
                              icon='trash-can'
                              style={style.icon}
                              color={
                                JourneyConfig.travelexColors.TRXBlue ||
                                ColorConstant[props.theme]?.BUTTON_BACKGROUND_COLOR
                              }
                            />
                          </TouchableElement>
                        ) : (
                          <View></View>
                        )}
                      </View>
                    </TouchableElement>
                  );
                })
              ) : (
                <View style={style.noResultContainer}>
                  <Avatar.Icon
                    icon='email-open-outline'
                    color='#ADADAD'
                    size={70}
                    style={style.icon}
                  />
                  <TextElement style={style.noResultsText}>No Journeys Found!</TextElement>
                </View>
              )}
              <View style={style.bottomSpace}></View>
            </View>
          ) : (
            <View style={style.alignItemCenter}>
              <TextElement style={style.loadingText}>Loading...</TextElement>
            </View>
          )}
        </View>
      }
    </ScrollView>
  );
};

const Dialog = (props) => {
  const { show, title, hintInput, handleClose, journey, deleteJourney } = props;
  const containerStyle = {
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  };
  let remark = '';
  return (
    <Portal>
      <Modal visible={show} onDismiss={handleClose} contentContainerStyle={containerStyle}>
        <KeyboardAvoidingView behavior={'padding'} style={style.dialogView}>
          <View style={style.dialogContainer}>
            <TextElement theme={props.theme}>
              {title}
              <TextElement theme={props.theme} style={style.mandatory}>
                {' '}
                *
              </TextElement>
            </TextElement>

            <TextInputElement
              theme={props.theme}
              editable={true}
              placeholder={hintInput}
              maxLength={100}
              onChangeText={(message) => {
                remark = message;
              }}
            />

            <View style={style.dialogButtonRow}>
              <ButtonElement
                theme={props.theme}
                color={ColorConstant[props.theme]?.BUTTON_BACKGROUND_COLOR}
                style={style.dialogButton}
                onPress={handleClose}>
                Cancel
              </ButtonElement>
              <ButtonElement
                theme={props.theme}
                color={ColorConstant[props.theme]?.BUTTON_BACKGROUND_COLOR}
                style={style.dialogButton}
                onPress={() => {
                  // console.log("remark", remark);
                  deleteJourney(journey, remark);
                }}>
                Submit
              </ButtonElement>
            </View>
          </View>
        </KeyboardAvoidingView>
      </Modal>
    </Portal>
  );
};

export default DesignerJourney;
