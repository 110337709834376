import React, { useRef, useState } from 'react';
import { ScrollView, Text, TextInput, TouchableOpacity, View } from 'react-native';
import Markdown from 'react-native-markdown-display';
import { Avatar } from 'react-native-paper';
import EditorCommand from './EditorCommand';
import HeadingElement from './HeadingElement';
import style from './style';
import Alert from '../Alert';
import { TabBar, TabView } from 'react-native-tab-view';
let start, end = 0;


const EditorTab = ({ text, setText }) => {
    let ref = useRef(null);

    return (
        <View style={{ flex: 1 }}>
            <ToolBar text={text} setText={setText} textRef={ref} />
            <TextInput
                ref={ref}
                value={text}
                onChangeText={(txt) => {
                    if (txt.substr(txt.length - 1) == '\n') {
                        const getText = execueOperation(txt, 'enter');
                        setText(getText);
                    }
                    else
                        setText(txt);
                }}
                multiline={true}
                style={style.textArea}
                placeholder='Enter your content here'
                onSelectionChange={(e) => {
                    start = e.nativeEvent.selection.start;
                    end = e.nativeEvent.selection.end;
                }}
            />
        </View >
    );
};



const Preview = ({ text }) => {
    return (
        <View style={style.readerView}>
            <ScrollView>
                <Text style={[style.bold, { color: '#000000' }]}>Preview</Text>
                <Markdown
                    style={{
                        heading1: style.markdownHeading,
                        heading2: style.markdownHeading,
                        heading3: style.markdownHeading,
                        heading4: style.markdownHeading,
                        heading5: style.markdownHeading,
                        heading6: style.markdownHeading
                    }}
                >
                    {text}
                </Markdown>
            </ScrollView>
        </View>
    );
};


export default function Editor({ value, setValue }) {
    const text = value;
    const setText = setValue;

    const [index, setIndex] = React.useState(0);

    const [routes] = useState([
        { key: 'editor', title: 'Editor' },
        { key: 'preview', title: 'Preview' },
    ]);

    const renderScene = ({ route }) => {
        switch (route.key) {
            case 'editor':
                return <EditorTab setText={setText} text={text} />;
            case 'preview':
                return <Preview text={text} />;
            default:
                return <View />;
        }
    };

    return (
        <View style={style.flex1}>
            <TabView
                swipeEnabled={false}
                navigationState={{ index, routes }}
                renderScene={renderScene}
                onIndexChange={setIndex}
                renderTabBar={(props) => <TabBar style={{ backgroundColor: '#3C436C' }} {...props} />}

            />
        </View>
    );
}


const ToolBar = ({ text, setText, textRef }) => {
    return (
        <ScrollView horizontal={true} style={style.toolBarScroll}>
            <View style={style.toolBar}>
                <Text style={style.bold}>Formatter</Text>

                <TouchableOpacity
                    onPress={() => {
                        if (start != end) {
                            const textArr = text.split('');
                            textArr.splice(start, 0, '**');
                            textArr.splice(end + 1, 0, '**');
                            setText(textArr.join(''));
                        }
                        else
                            Alert.alert('Info', 'Please select the text');
                        textRef.current.focus();
                    }}
                    style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}
                >
                    <Text style={{ fontWeight: 'bold', color: '#FFFFFF' }}>B</Text>
                </TouchableOpacity>

                <HeadingElement text={text} setText={setText} command='h1' textRef={textRef} />

                <HeadingElement text={text} setText={setText} command='h2' textRef={textRef} />

                <HeadingElement text={text} setText={setText} command='h3' textRef={textRef} />

                {/* <HeadingElement text={text} setText={setText} command='h4' textRef={textRef} />

                <HeadingElement text={text} setText={setText} command='h5' textRef={textRef} />

                <HeadingElement text={text} setText={setText} command='h6' textRef={textRef} /> */}

                <TouchableOpacity
                    onPress={() => {
                        const getText = execueOperation(text, 'list');
                        textRef.current.focus();
                        setText(getText);
                    }}
                >
                    <Avatar.Icon
                        icon='format-list-bulleted'
                        size={30}
                        style={style.icon}
                    />
                </TouchableOpacity>

                <TouchableOpacity
                    onPress={() => {
                        const getText = execueOperation(text, 'link');
                        textRef.current.focus();
                        setText(getText);
                    }}
                    style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}
                >
                    <Avatar.Icon
                        icon='link'
                        size={30}
                        style={style.icon}
                    />
                </TouchableOpacity>

                <TouchableOpacity
                    onPress={() => {
                        if (start != end) {
                            const textArr = text.split('');
                            textArr.splice(start, 0, '_');
                            textArr.splice(end + 1, 0, '_');
                            setText(textArr.join(''));
                        }
                        else
                            Alert.alert('Info', 'Please select the text');
                        textRef.current.focus();
                    }}
                    style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                    <Text style={{ fontWeight: 'bold', color: '#FFFFFF', fontStyle: 'italic' }}>I</Text>
                </TouchableOpacity>

            </View>
        </ScrollView>

    );
};


export const execueOperation = (text, operation) => {

    const startToCurrent = text.substring(0, start);
    let restString = text.substr(start, text.length);

    const lines = startToCurrent.split('\n');
    let currentLine = lines[lines.length - 1];

    lines[lines.length - 1] = EditorCommand.execute(currentLine, operation);
    restString = restString == '\n' ? '' : restString;

    return lines.join('\n') + restString;
};