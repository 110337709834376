import ViewElement from '../elements/ViewWIthScroll';
import TextElement from '../elements/Text';
import style from './Style';

export default (parentProps) => {
    const props = { ...parentProps, ...parentProps.route.params };
    // console.log('props', props.route.params);
    const notification = props.route.params;
    return (
        <ViewElement pageName="Notification Details" {...props} header={true}>
            <TextElement style={style.detailTitle} theme="DARK">{notification.title}</TextElement>
            <TextElement style={style.desc} theme="DARK">{notification.description}</TextElement>
            <TextElement style={style.desc} theme="DARK">{notification.created_at}</TextElement>
        </ViewElement>
    );
};