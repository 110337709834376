/* eslint-disable react/no-unescaped-entities */

import { Image, SafeAreaView, StatusBar, View } from 'react-native';

import { Avatar } from 'react-native-paper';
import TextElement from '../elements/Text';
import TouchableElement from '../elements/TouchableElement';
import style from './styles/welcomeSlideStyle';

const WelcomeSlide = (props) => {
    const skipToProfile = () => {
        props.navigation.navigate('Home', { screen: 'Profile' });
    };
    const next = () => {
        props.navigation.navigate('Welcomeslide2');
    };


    return (
        <SafeAreaView style={style.safeArea}>
            <StatusBar backgroundColor='#212644' />
            <View style={[style.verticalCenter, style.phalf]}>
                <Image source={require('../../assets/tvx-small.png')} resizeMode="contain" style={{ width: 150, height: 50 }} />
            </View>
            <View style={[style.verticalCenter, style.pfour]}>
                <Image source={require('../../assets/white-new-logo.png')} resizeMode="stretch" style={style.logo} />
                <TextElement theme="DARK" style={style.welcomeText}>Welcome to LeadX</TextElement>
                <TextElement theme="DARK" style={style.welcomeText2}>A Travelex Global Mentorship Program</TextElement>
            </View>
            <View style={[style.phalf, { display: 'flex', flexDirection: 'row', alignItems: 'center' }]}>
                <TouchableElement
                    onPress={skipToProfile}
                    style={{ flex: .6, margin: 10 }}
                    id='skip'>
                    <Avatar.Icon icon={'location-exit'} size={50} style={style.icon} />
                </TouchableElement>

                <View style={{ flex: 4.8 }} />

                <TouchableElement
                    onPress={next}
                    style={{ flex: .6, display: 'flex', flexDirection: 'row-reverse', margin: 10 }}
                    id='skip'>
                    <Avatar.Icon icon={'arrow-right-bold-circle'} size={50} style={style.icon} />
                </TouchableElement>
            </View>
        </SafeAreaView >
    );
};

export default WelcomeSlide;