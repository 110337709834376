import TextElement from './Text';
import Logo from '../../assets/white-new-logo.png';
import { Dimensions, ImageBackground, View } from 'react-native';
const { height } = Dimensions.get('window');

const NotAvailable = () => {
    return (
        <View style={{ height, width: '100%', backgroundColor: '#222745', alignItems: 'center' }}>
            <View style={{ width: '100%', height }}>
                <View style={{ alignItems: 'center', justifyContent: 'center', height }}>
                    <ImageBackground source={Logo} style={{ height: 100, width: 230 }} />
                    <TextElement theme="DARK" style={{ fontSize: 13, fontWeight: 'bold' }}>LeadX is not available for your Region.</TextElement>
                </View>
            </View>
        </View>
    );
};

export default NotAvailable;