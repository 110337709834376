import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { Avatar } from 'react-native-paper';
import DesignerService from '../../../service/DesignerService';
import JourneyConfig from '../JourneyConfig.json';
import JourneyService from '../../../service/JourneyService';
import TextElement from '../../elements/Text';
import TouchableElement from '../../elements/TouchableElement';
import { View } from 'react-native';
import ViewWithBack from '../../elements/ViewWithBack';
import { getUserIdentity } from '../../../utils/UserIdentity';
import { journeyReducer } from './JourneySlice';
import { spaceReducer } from './Space/SpaceSlice';
import style from './styles/designerDashboardStyle';
import { useIsFocused, useNavigation } from '@react-navigation/native';

const Stats = (props) => {
    const { stats, userIdentity } = props;
    return (
        <View style={style.statsContainer}>
            <TextElement theme={'DARK'} style={style.title}>Journey Statistics</TextElement>
            <View style={style.divider}></View>
            <View style={style.statContainer}>
                <View style={style.statBox}>
                    <TextElement style={{ ...style.statNumber, color: JourneyConfig.colors.Teal }}>{stats?.drafted}</TextElement>
                    <TextElement style={style.statLabel} theme="DARK">Drafted</TextElement>
                </View>
                <View style={style.statBox}>
                    <TextElement style={{ ...style.statNumber, color: JourneyConfig.colors.Lime }}>{stats?.published}</TextElement>
                    <TextElement style={style.statLabel} theme="DARK">Published</TextElement>
                </View>
                <View style={style.statBox}>
                    <TextElement style={{ ...style.statNumber, color: JourneyConfig.colors.Yellow }}>{stats?.review}</TextElement>
                    <TextElement style={style.statLabel} theme="DARK">Review</TextElement>
                </View>
                {
                    userIdentity.user_role === 'jd' ?
                        <View style={style.statBox}>
                            <TextElement style={{ ...style.statNumber, color: JourneyConfig.colors.Pink }}>{stats?.rejected}</TextElement>
                            <TextElement style={style.statLabel} theme="DARK">Declined</TextElement>
                        </View>
                        : <View></View>
                }
            </View>
            <View style={style.seperator}></View>
        </View>
    );
};


const DesignerDashboard = () => {
    const navigation = useNavigation();
    const props = { navigation };
    
    const designerJourneys = useSelector(state => state.designerJourneys.value);
    let spaces = useSelector((state) => state.spaces.value);
    const dispatch = useDispatch();
    const [userIdentity, setUserIdentity] = useState({});
    const [isApiDataLoaded, setIsApiDataLoaded] = useState(false);
    const isFocused = useIsFocused();

    const journeyService = new JourneyService(props);
    const designerService = new DesignerService(props);

    const fetchData = () => {
        getUserIdentity().then((uIdentity) => {
            setUserIdentity(uIdentity);
        });
        if (designerJourneys && Object.keys(designerJourneys.stats).length) {
            setIsApiDataLoaded(true);
        }
        journeyService.fetchDesignerJourneys(isApiDataLoaded).then((journeyResult) => {
            // console.log("journeyResult 1", journeyResult);
            setIsApiDataLoaded(true);
            dispatch(journeyReducer({ 'journeys': journeyResult }));
        });
        if (spaces.length) {
            // console.log("spaces found", spaces);
            setIsApiDataLoaded(true);
        }
        designerService.getSpaceModules(true).then((response) => {
            // console.log("--spaces----fetchdata called", response);
            // const spaces = response.filter((space) => space.display_name.toLowerCase().trim() != "global");
            setIsApiDataLoaded(true);
            // console.log("--spaces", spaces);
            dispatch(spaceReducer({ 'spaces': response }));
        });
    };

    const flush = () => {
        setIsApiDataLoaded(false);
    };

    useEffect(() => {
        if (isFocused) {
            fetchData();
        } else {
            flush();
        }
    }, [isFocused]);

    return (
        <ViewWithBack pageName="Designer Dashboard" {...props} backAction={() => { props.navigation.goBack(); }} >
            <Stats {...props} stats={designerJourneys.stats} userIdentity={userIdentity} />
            <View style={style.divider}></View>
            <View style={style.container}>
                <TextElement theme={'DARK'} style={style.title}>Explore services</TextElement>
                <View style={style.seperator}></View>
                {
                    userIdentity.user_role == 'admin' ?
                        <View>
                            <TouchableElement
                                style={style.serviceButton}
                                onPress={() => { props.navigation.navigate('Spaces'); }}
                                id='spaces'
                            >

                                <TextElement style={style.serviceButtonText}>Spaces</TextElement>
                                <Avatar.Icon icon="arrow-right-thick" size={20} color="#000000" style={style.arrowIcon} />
                            </TouchableElement>

                            <TouchableElement
                                style={style.serviceButton}
                                onPress={() => { props.navigation.navigate('Adminjourneystats'); }}
                                id='Adminjourneystats'
                            >
                                <TextElement style={style.serviceButtonText}>Journey Statistics</TextElement>
                                <Avatar.Icon icon="arrow-right-thick" size={20} color="#000000" style={style.arrowIcon} />
                            </TouchableElement>

                            <TouchableElement
                                style={style.serviceButton}
                                onPress={() => { props.navigation.navigate('DesignerJourney'); }}
                                id='journey-dashboard'
                            >
                                <TextElement style={style.serviceButtonText}>Journey Dashboard</TextElement>
                                <Avatar.Icon icon="arrow-right-thick" size={20} color="#000000" style={style.arrowIcon} />
                            </TouchableElement>

                            <TouchableElement
                                style={style.serviceButton}
                                onPress={() => { props.navigation.navigate('Roles'); }}
                                id='roles'
                            >
                                <TextElement style={style.serviceButtonText}>Roles & Access Management</TextElement>
                                <Avatar.Icon icon="arrow-right-thick" size={20} color="#000000" style={style.arrowIcon} />
                            </TouchableElement>
                        </View>
                        :
                        <View>
                            <TouchableElement
                                style={style.serviceButton}
                                onPress={() => { props.navigation.navigate('Spaces'); }}
                                id='spaces'

                            >
                                <TextElement style={style.serviceButtonText}>Spaces</TextElement>
                                <Avatar.Icon icon="arrow-right-thick" size={20} color="#000000" style={style.arrowIcon} />
                            </TouchableElement>
                            <TouchableElement
                                style={style.serviceButton}
                                onPress={() => { props.navigation.navigate('DesignerJourney'); }}
                                id='designer-journey'
                            >
                                <TextElement style={style.serviceButtonText}>Journey Dashboard</TextElement>
                                <Avatar.Icon icon="arrow-right-thick" size={20} color="#000000" style={style.arrowIcon} />
                            </TouchableElement>
                        </View>
                }

            </View>
        </ViewWithBack >
    );
};

export default DesignerDashboard;