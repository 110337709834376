import { KeyboardAvoidingView, ScrollView, View } from 'react-native';
import { useEffect, useState } from 'react';

import Alert from '../../elements/Alert';
import { Avatar } from 'react-native-paper';
import Button from './Button';
import Constants from 'expo-constants';
import DesignerService from '../../../service/DesignerService';
import Table from './Table';
import TextElement from '../../elements/Text';
import TextInputElement from '../../elements/TextInput';
import TouchableElement from '../../elements/TouchableElement';
import ViewWithBack from '../../elements/ViewWithBack';
import { addTopicTableHeader } from './Metadata';
import { getUserIdentity } from '../../../utils/UserIdentity';
import style from './styles/addTopicStyle';
import { useIsFocused } from '@react-navigation/native';
import { useSelector } from 'react-redux';

const { GLOBALSPACEID } = Constants.expoConfig.extra;

const AddTopic = (parentProps) => {
    const props = { ...parentProps, ...parentProps.route.params };
    // console.log("AddTopic props", props);

    const { journeyId, type, topicIndex, backAction } = props.route.params;
    const allJourneys = useSelector(state => state.designerJourneys.allJourneys);
    const journey = allJourneys.filter((journey) => journey.journey_id == journeyId)[0];

    const [topicId, setTopicId] = useState('');
    const [topicName, setTopicName] = useState('');
    const [topicDesc, setTopicDesc] = useState('');
    const [createdAt, setCreatedAt] = useState('');
    const [createdBy, setCreatedBy] = useState('');
    const [userIdentity, setUserIdentity] = useState({});
    // const [topicWeightage, setTopicWeightage] = useState(0);
    // const [tags, setTags] = useState([]);
    const [subTopic, setSubTopic] = useState([]);
    const isFocused = useIsFocused();
    const [topics, setTopics] = useState([]);
    const [isApiDataLoaded, setIsApiDataLoaded] = useState(false);
    const [unsavedChanges, setUnsavedChanges] = useState(false);

    // console.log("topicName", topicName, "topicDesc", topicDesc);
    const topic = {
        // "weightage": topicWeightage,//set this
        'id': type == 'Update' ? topicId : '',
        'journey_id': journey.journey_id,
        'display_name': topicName.trim(),
        'description': topicDesc,
        'created_by': createdBy,
        'created_at': createdAt,
        'is_deleted': false,
        // "tags": tags.join(),
        'sub_topic': subTopic,
    };

    const designerService = new DesignerService(props);

    // console.log("topicIndex", topicIndex);

    const fetchData = async () => {
        if (!backAction) {
            getUserIdentity().then((uIdentity) => {
                setUserIdentity(uIdentity);
            });
            if (Object.keys(journey).length) {
                setIsApiDataLoaded(true);
            }

            designerService.getTopics(journey, isApiDataLoaded).then((topics) => {
                // console.log("fetchData getTopics response", topics);
                setIsApiDataLoaded(true);
                setTopics(topics);

                if (type == 'Update') {
                    const topicToUpdate = topics[topicIndex];
                    // console.log("topic to update", topicToUpdate);

                    const { id, display_name, description, sub_topic, created_at, created_by } = topicToUpdate;
                    setTopicId(id);
                    setTopicName(display_name);
                    setTopicDesc(description);
                    setCreatedBy(created_by);
                    setCreatedAt(created_at);

                    if (subTopic.length) {
                        // console.log("subTopic create", subTopic);
                        setSubTopic([...subTopic]);
                        setUnsavedChanges(true);
                    } else {
                        // console.log("sub_topic update", sub_topic);
                        setSubTopic([...sub_topic]);
                    }
                }
            });
        }
    };
    // console.log("topicname", topicName);

    const getUpdatedTopic = () => {
        const topicToUpdate = topics[topicIndex];
        for (let key of Object.keys(topicToUpdate)) {
            if (topicToUpdate[key] === topic[key] && key != 'journey_id' && key != 'id') {
                delete topic[key];
            }
        }
        // console.log("topic after getUpdatedTopic", topic);
    };

    const getUpdatedJourney = () => {
        let temp = { ...journey };
        for (let key of Object.keys(journey)) {
            if (!['space_id', 'id', 'journey_id', 'journey_status'].includes(key)) {
                delete temp[key];
            }
        }
        // console.log("journey after getUpdatedJourney", temp);
        return temp;
    };

    const saveJourney = () => {

        //operation type is always journey update, journey_status != drafted
        //jd status= declined, published
        //admin status= review, published

        let journey = getUpdatedJourney();
        // console.log("saveJourney called !! :: ");
        setUnsavedChanges(false);
        let alertMessage = '';

        if (userIdentity.user_role == 'admin') {
            if (journey.journey_status == 'published') {
                alertMessage = 'Topics updated & Journey published';
                saveTopic(alertMessage);
            } else {
                alertMessage = 'Topics updated';
                saveTopic(alertMessage);
            }
        } else if (userIdentity.user_role == 'jd') {
            const isGlobalSpace = journey.space_id === GLOBALSPACEID;
            journey.journey_status = isGlobalSpace ? 'review' : 'published';
            alertMessage = isGlobalSpace ? 'Topics updated & Journey submitted for review' : 'Topics updated & Journey published';
            updateJourneySaveTopic();
        }
        function updateJourneySaveTopic() {
            // console.log("updateJourneySaveTopic called!!");
            designerService.updateJourney(journey, isApiDataLoaded).then(() => {
                setIsApiDataLoaded(true);
                saveTopic(alertMessage);
            });
        }
    };
    
    const saveTopic = (alertMessage) => {
        // console.log("saveTopic type", type);
        const updateJourneyFlag = journey.journey_status != 'drafted';

        const pageNavigator = () => {
            if (updateJourneyFlag) {
                Alert.alert('Success',alertMessage);
                props.navigation.navigate('DesignerJourney');
            } else {
                props.navigation.navigate('Topic');
            }
        };

        if (type == 'Create') {
            let result = validateTopicName(topicName);
            if (result) {
                designerService.createTopic(journey, topic, isApiDataLoaded).then(() => {
                    // console.log("saveTopic response", response);
                    setIsApiDataLoaded(true);
                    pageNavigator();
                });
            }
        }
        if (type == 'Update') {
            getUpdatedTopic();
            if (Object.keys(topic).includes('display_name')) {
                // console.log("if topic.display_name");
                let result = validateTopicName(topicName);
                if (result) {
                    designerService.updateTopic(journey, topic, isApiDataLoaded).then(() => {
                        // console.log("saveTopic response", response);
                        setIsApiDataLoaded(true);
                        pageNavigator();
                    });
                }
            } else {
                // console.log("else");
                designerService.updateTopic(journey, topic, isApiDataLoaded).then(() => {
                    // console.log("saveTopic response", response);
                    setIsApiDataLoaded(true);
                    pageNavigator();
                });
            }
        }

    };

    const addContent = () => {
        if (!topicName) {
            const alertMessage = 'Please enter mandatory field: Topic Name';
            Alert.alert('Required', alertMessage);
        } else {
            props.navigation.navigate('Addcontent', { ...addContentProps });
        }
    };

    const validateTopicName = (topicName) => {
        topicName = topicName.trim();
        // console.log("topicName", topicName, "!");
        if (!topicName) {
            const alertMessage = 'Please enter mandatory field: Topic Name';
            Alert.alert('Required', alertMessage);
        } else if (topics.map(topic => topic.display_name).includes(topicName)) {
            const alertMessage = `Topic ${topicName} already exists`;
            Alert.alert('Whoops!', alertMessage);
        } else {
            return true;
        }
    };

    const deleteContent = async (content, index) => {
        Alert.confirm('Confirm', 'Deleted content will not be reverted', () => {
            const newSubTopics = subTopic.filter((element, idx) => {
                return index != idx;
            });
            // console.log("newSubTopics", newSubTopics);
            setSubTopic([...newSubTopics]);
            setUnsavedChanges(true);
        });
        // console.log("delete content", content, index);
        // console.log("topics are", topics)
        // console.log("subtopics are", subTopic);
    };


    const addContentProps = {
        subTopic,
        setSubTopic,
        type: 'Create'
    };

    const editContent = (index) => {
        // console.log("clicked on content", subTopic[index]);
        const editContentProps = {
            subTopic,
            setSubTopic,
            type: 'Update',
            'updateIndex': index
        };
        props.navigation.navigate('Addcontent', { ...editContentProps });
    };

    const contentTableProps = {
        dataList: subTopic,
        handleDelete: deleteContent,
        contentType: 'SubTopic',
        handleOnPress: editContent,
        isApiDataLoaded: true
    };


    useEffect(() => {
        if (isFocused) {
            fetchData();
        }
    }, [isFocused]);

    return (
        <ViewWithBack pageName={type == 'Create' ? 'Create Topic' : 'Update Topic'}  {...props} backAction={() => {
            if (unsavedChanges) {
                // console.log("unsavedChanges", unsavedChanges);
                Alert.confirm('Confirm', 'Are you sure you want to discard the changes?', () => { props.navigation.navigate('Topic'); });
            } else {
                props.navigation.navigate('Topic');
            }
        }} >
            <View style={style.container}>
                <View style={style.container1}>
                    <ScrollView
                        showsVerticalScrollIndicator={false}
                        showsHorizontalScrollIndicator={false}
                    >
                        <KeyboardAvoidingView behavior="position" style={style.keyboard}>
                            <View style={style.topicContainer}>
                                <View style={style.divider5}></View>
                                <TextInputElement theme="LIGHT"
                                    label="Topic Name"
                                    value={topicName}
                                    onChangeText={name => {
                                        if (topics.map(topic => topic.name).includes(name.trim())) {
                                            const alertMessage = 'Topic Name already exists!';
                                            setTopicName(name);
                                            setUnsavedChanges(true);
                                            Alert.alert('Whoops!', alertMessage);
                                        } else {
                                            setTopicName(name);
                                            setUnsavedChanges(true);
                                        }
                                    }} />
                                <View style={style.divider5}></View>
                                <TextInputElement
                                    theme="LIGHT"
                                    label="Description"
                                    value={topicDesc}
                                    multiline={true}
                                    numberOfLines={4}
                                    onChangeText={desc => {
                                        setTopicDesc(desc);
                                        setUnsavedChanges(true);
                                    }}
                                />
                                <View style={style.divider10}></View>
                            </View>

                            <View style={style.buttonRow}>
                                <TouchableElement
                                    onPress={addContent}
                                    id='add-content'
                                >
                                    <View style={style.addContentContainer}>
                                        <Avatar.Icon
                                            icon="plus"
                                            size={25}
                                            color="#ffffff"
                                            style={style.icon}
                                        />
                                        <TextElement theme="DARK" style={style.addContentButton}>Content</TextElement>
                                    </View>
                                </TouchableElement>
                                <View style={style.contentsView}>
                                    <TextElement style={style.header}>Contents</TextElement>
                                </View>
                            </View>

                            <Table {...contentTableProps} tableHeader={addTopicTableHeader} />
                            <View style={style.divider10}></View>

                            <View>
                                {
                                    journey.journey_status != 'drafted' ?
                                        <View style={style.buttonContainer} >
                                            <Button style={style.button} onPress={saveJourney}>Submit</Button>
                                        </View>

                                        // only update journey if its in !drafted state
                                        // save saves the topic, submit saves the topic and submits journey for review, only saving topics directly shows the updated topics in published journey
                                        :
                                        <View style={style.buttonContainer} >
                                            <Button style={style.button} onPress={() => { saveTopic(); }}>Save</Button>
                                        </View>
                                }
                            </View>
                            <View style={style.bottomSpace}></View>
                        </KeyboardAvoidingView>
                    </ScrollView>
                </View>
            </View>
        </ViewWithBack>
    );
};
export default AddTopic;