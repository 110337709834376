import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: { flex: 1 },
  icon: { backgroundColor: 'transparent' },
  accountSwitch: { display: 'flex', alignItems: 'flex-end' },
  padding5: { padding: 5 },
  padding10: { padding: 10 },
  drop: {
    padding: 5,
    backgroundColor: '#ffffff',
    borderRadius: 5,
    width: 100,
    display: 'flex',
    alignItems: 'center',
  },
  email: { fontWeight: 'bold', fontSize: 20, color: '#ffffff' },
  card: {
    flex: 2,
    backgroundColor: '#f5f5f5',
    paddingTop: 5,
    height: 120,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 15,
  },
  greenText: {
    fontSize: 50,
    fontWeight: 'bold',
    color: '#50C878',
    textAlign: 'center',
    paddingLeft: 10
  },
  orangeText: {
    fontSize: 50,
    fontWeight: 'bold',
    color: '#FFAA33',
    textAlign: 'center',
    paddingLeft: 10
  },
  blueText: {
    fontSize: 50,
    fontWeight: 'bold',
    color: '#0083FF',
    textAlign: 'center',
    paddingLeft: 10
  },
  analytics: { fontSize: 20, fontWeight: 'bold', marginBottom: 10 },
  textStyle: { fontSize: 15, textAlign: 'center' },
  accountInfo: {
    backgroundColor: '#7D8792',
    paddingLeft: 10,
    paddingBottom: 10,
    borderBottomStartRadius: 40,
    borderBottomEndRadius: 40,
    marginLeft: 2,
    marginRight: 2,
  },
  accountText: { color: '#ffffff' },
  iconAlign: { flex: 0.5, alignItems: 'flex-end' },
  profileInfo: { flex: 5 },
  analyticsRow: { flexDirection: 'row', gap: 10 },
  feedbackIcon: { display: 'flex', alignItems: 'flex-end' },
  accountView: { flexDirection: 'row', padding: 10 },
});
