import { memo } from 'react';
import { Divider } from 'react-native-paper';

const DividerElement = (props) => {
    const style = {
        ...props.style,
        backgroundColor: 'none',
        paddingBottom: 10,
        height: props.height || 10
    };
    return <Divider style={{ ...style }} />;
};

export default memo(DividerElement, () => true);