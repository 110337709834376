import Alert from '../components/elements/Alert';
import ApplicationStorage from '../utils/ApplicationStorage';
import Constants from 'expo-constants';
import Store from '../Store/Store';
import { loaderReducer } from '../components/elements/customLoader/customLoaderSlice';
var requestStart = 0;
const EXPO_CONFIG = Constants.expoConfig.extra;

const { Platform } = require('react-native');
const RETRY = 5;
class Request {
  constructor(props) {
    // console.log("request props", props)
    this.props = props;
  }
  // static isAuthenticated = true;

  async getNew(Url) {
    return await new Promise((resolve, reject) => {
      const method = 'GET';
      this.requestHandler(Url, method)
        .then((data) => {
          resolve(data);
        })
        .catch((ex) => {
          reject(ex);
        });
    });
  }

  async putNew(Url, data) {
    return await new Promise((resolve, reject) => {
      const method = 'PUT';
      this.requestHandler(Url, method, data)
        .then((data) => {
          resolve(data);
        })
        .catch((ex) => {
          reject(ex);
        });
    });
  }

  async postNew(Url, data) {
    return await new Promise((resolve, reject) => {
      const method = 'POST';
      this.requestHandler(Url, method, data)
        .then((data) => {
          resolve(data);
        })
        .catch((ex) => {
          // console.log("ex", ex)
          reject(ex);
        });
    });
  }

  async deleteNew(Url, data) {
    return await new Promise((resolve, reject) => {
      const method = 'DELETE';
      this.requestHandler(Url, method, data)
        .then((data) => {
          resolve(data);
        })
        .catch((ex) => {
          // console.log("ex", ex)
          reject(ex);
        });
    });
  }

  async requestHandler(url, method, data) {
    requestStart = 1;
    for (let index = 0; index < RETRY; index++) {
      try {
        let result = await this.request(url, method, data);
        return result;
      } catch (ex) {
        // console.log('ex',ex);
        // console.log('Request.isAuthenticated', Request.isAuthenticated);
        // if (!Request.isAuthenticated) {
        //   // console.log('no retry');
        //   // If session is expired, don't retry and throw an error
        //   this.props.navigation.navigate('Login');
        //   throw ex;
        // }
        if (ex.status == 504 || ex.status == 503) {
          if (index + 1 === RETRY) {
            throw ex;
          }
        } else if (ex.status == 408 || ex.status == 429) {
          if (index + 1 === RETRY) throw ex;
        } else if ([400, 404, 500, 409, 422].includes(ex.status)) {
          // custom errors
          throw ex;
        } else {
          // console.log('else');
          // Request.isAuthenticated = false;
          if (requestStart) {
            requestStart = 0;
            Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
            Alert.alert('Info', 'Session expired. Please login again');
            await ApplicationStorage.removeAll();
            this.props.navigation.navigate('Login');
          }

          throw ex;
        }
      }
    }
  }

  async request(url, method, data) {
    const redirectURI = Platform.OS == 'web' ?  EXPO_CONFIG.REDIRECT_URI_WEB : EXPO_CONFIG.REDIRECT_URI_MOBILE;

    return await new Promise((resolve, reject) => {
      ApplicationStorage.getItem('code').then((code) => {
        let params = {
          headers: {
            code: code,
            'Content-Type': 'application/json',
            applicationid: 'LeadX',
            'redirect-uri': redirectURI
            // "Cache-Control": "max-age=0" //This will invalidate the cache, permission needs to be tested
          },
          method: method,
          body: data ? JSON.stringify(data) : null,
        };
        fetch(url, params)
          .then((res) => {
            if (res.status == 200 || res.status == 201) {
              res.json().then((data) => {
                resolve(data);
              });
            } else {
              let status = res.status;
              res.json().then((data) => {
                data.status = status;
                reject(data);
              });
            }
          })
          .catch((ex) => {
            reject(ex);
          });
      });
    });
  }
}

export default Request;