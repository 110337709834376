const topicTableHeader = [
    {
        'key': 'display_name',
        'label': 'Name',
        'flex': 3.5
    },
    {
        'key': 'sub_topic',
        'label': 'Contents',
        'flex': 1.5,
        'align': 'center'
    }
];

const addTopicTableHeader = [
    {
        'key': 'name',
        'label': 'Title',
        'flex': 3.5,
        'align': 'flex-start'
    },
    {
        'key': 'content_type',
        'label': 'Type',
        'flex': 1.5,
        'align': 'center'
    }
];

const loungeLink = 'https://www.theloungetravelex.com/';


const assessmentOptions = {
    trueFalse: ['True', 'False'],
    singleSelect: ['Option A', 'Option B', 'Option C', 'Option D']
};

const assessmentQuestionTypes = ['Single Choice', 'True/False'];

const allContentTypes = ['Link', 'Content', 'Assessment'];
const filteredContentTypes = ['Link', 'Content'];

const lightColors = ['Yellow', 'Lime', 'Blue'];
const darkColors = ['Pink', 'Teal', 'Grey'];

export {
    topicTableHeader,
    addTopicTableHeader,
    loungeLink,
    assessmentOptions,
    assessmentQuestionTypes,
    allContentTypes,
    filteredContentTypes,
    lightColors,
    darkColors
};