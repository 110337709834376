import ColorConstant from '../../utils/ColorConstant.json';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({

  stats: { borderWidth: .5, borderColor: '#2E3456', padding: 15, borderRadius: 20, backgroundColor: '#f8f8f8', margin: 10 },
  statsHeader: { fontSize: 12 },
  statsGreen: { fontWeight: 'bold', fontSize: 25, color: '#A7C96A' },
  statsBlue: { fontWeight: 'bold', fontSize: 25, color: '#2EADB7' },
  statsRed: { fontWeight: 'bold', fontSize: 25, color: '#FC6170' },
  statsRow: { flexDirection: 'row', height: 80 },
  statsView: { flex: 2, backgroundColor: 'transparent', alignItems: 'center' },
  mentorshipStats: { fontWeight: 'bold', fontSize: 16 },
  divider10: { height: 10 },
  divider5: { height: 5 },
  divider80: { height: 80 },
  divider15: { height: 15 },
  icon: { backgroundColor: 'transparent' },
  remove: { color: '#48B2EB', fontSize: 12, padding: 2 },
  // removeButton: { borderRadius: 10, backgroundColor: 'transparent', alignItems: 'center', borderColor: '#48B2EB', borderWidth: 1, margin: 2, marginTop: 5, padding: 3 },
  connectionView: { borderWidth: .5, borderColor: '#2E3456', padding: 10, borderRadius: 5 },
  connectionHeader: { fontWeight: 'bold' },
  textStyle: { fontSize: 12, textAlign: 'center' },
  name: { fontSize: 10, fontWeight: 'bold', textAlign: 'center' },
  cancel: { color: '#48B2EB', fontSize: 12, padding: 5 },
  noRecords: { width: '100%', alignItems: 'center', justifyContent: 'center', padding: 5 },
  noRecordsText: { color: '#B8B8B8' },
  keyboard: { height: '100%', justifyContent: 'center' },
  dialogView: { backgroundColor: ColorConstant.LIGHT?.BACKGROUND_COLOR_MEDIUM_SHADE || 'white', width: 350, padding: 20 },
  dialogButton: {
    backgroundColor: ColorConstant.LIGHT?.BACKGROUND_COLOR_MEDIUM_SHADE || 'white'
  },
  dialogButtonView: { flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'flex-end' },
  warning: { color: '#F47765' },
  padding5: { padding: 5 },
  drop: { padding: 5, backgroundColor: '#ffffff', borderRadius: 5, width: 100, display: 'flex', alignItems: 'center' },
  userInfo: {
    backgroundColor: '#7D8792',
    paddingLeft: 10,
    paddingBottom: 10,
    borderBottomStartRadius: 40,
    borderBottomEndRadius: 40,
  },
  userViewRow: { flexDirection: 'row', padding: 10 },
  userView: { flex: 5 },
  accountText: { color: '#ffffff' },
  dropView: { flex: 1, display: 'flex', flexDirection: 'row-reverse' },
  accountUser: { fontWeight: 'bold', fontSize: 20, color: '#ffffff' },
  containerStyle: { height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center' },
  scrollview: { width: '100%' },
  accountSwitch: { display: 'flex', alignItems: 'flex-end' },
  centeredView: {display: 'flex', alignItems: 'center', width: '100%' },
  accepted: { width: 150, height: 190, backgroundColor: '#e9e9e9', borderRadius: 10, marginRight: 10, padding: 5,display: 'flex', alignItems: 'center' },
  acceptedView: { flexDirection: 'row' },
  pending: { width: 150, height: 190,backgroundColor: '#e9e9e9', borderRadius: 10, padding: 5,display: 'flex', alignItems: 'center' },
  cancelled: { width: 150, height: 190, backgroundColor: '#E6E6E6', borderRadius: 10, padding: 5, display: 'flex', alignItems: 'center' },
  cardInfo: { alignItems: 'center' },
  container: { justifyContent: 'center', alignItems: 'center', flex: 1 },
  menteeView1: { width: '100%', height: '100%' },
  reason: { fontSize: 10, textAlign: 'center' },
  cancelButton: { width: '70%', borderRadius: 5, backgroundColor: 'transparent', alignItems: 'center', borderColor: '#48B2EB', borderWidth: 1 },
  removeButton: {
    padding: 3,
    width: '70%',
    borderWidth: 1,
    borderColor: '#48B2EB',
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    // margin: 2, marginTop: 5
  },
  removeButtonContainer1: {
    width: '100%',
    position: 'absolute',
    bottom: 6,
  },
  removeButtonContainer2: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pendingCancel: { marginRight: 10 }
});