import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: { marginLeft: 0, marginRight: 0, padding: 0, gap: 5 },
  flex1: {
    flex: 1,
  },
  row: {
    backgroundColor: '#FDFDFD',
    paddingVertical: 10,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  separator: {
    height: 2,
    backgroundColor: 'transparent',
  },
  icon: {
    backgroundColor: 'transparent',
  },
  name: { flex: 2.8, color: '#f5f5f5' },
  timeline: { flex: 1.5, color: '#f5f5f5' },
  action: {
    flex: 1.7,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: -1,
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#f5f5f5',
    padding: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tableHeaderName: { flex: 2.8 },
  tableHeaderTimeline: { flex: 1.5, display: 'flex' },
  tableHeaderWeightage: { flex: 1.7, display: 'flex' },
  rowContainer: {
    flexDirection: 'row',
  },
  flex: {
    flex: 0.5,
  },
  headerText: { fontSize: 20, padding: 5, fontWeight: 'bold' },
  addDefinitionButton: { alignSelf: 'flex-end' },
  subItemTitle: { flex: 5, paddingLeft: 4 },
  subItemDesc: { fontSize: 12, fontWeight: 'bold', paddingLeft: 4 },
  subItemDescContainer: { backgroundColor: '#ffffff', padding: 2 },
  subItemPanel: { marginHorizontal: 2 },
  weightageText: { paddingRight: 5, color: '#f5f5f5' },
  markdownView: { paddingLeft: 4, paddingRight: 1 },
  main: {
    flex: 1,
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 100,
    backgroundColor: '#f5f5f5',
  },
});
