const colors = {
  created: '#FFAA33',
  inprogress: '#00BFFF',
  completed: '#50C878',
  aborted: '#CD2702',
};
const goalStatusList = {
  'created': 'Created',
  'inprogress': 'In progress',
  'completed': 'Completed',
  'aborted': 'Aborted',
};

const LIMIT = 10;
const INITIAL_OFFSET = 0;

module.exports = {
  colors,
  LIMIT,
  INITIAL_OFFSET,
  goalStatusList
};
