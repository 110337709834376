import React, { useEffect, useState } from 'react';
import { SURVEY_TYPE, USER_SURVEY_DEFAULT } from './Metadata';
import { StyleSheet, View } from 'react-native';
import { useIsFocused, useNavigation } from '@react-navigation/native';

import ConnectionsService from '../../service/ConnectionsService';
import Fab from '../elements/Fab';
import SurveyList from './SurveyList';
import SurveyService from '../../service/SurveyService';
import { get } from 'lodash';
import { getUserIdentity } from '../../utils/UserIdentity';

const MySurvey = () => {
  const navigation = useNavigation();
  const [userSurvey, setUserSurvey] = useState(USER_SURVEY_DEFAULT);
  const [isApiDataLoaded, setIsApiDataLoaded] = useState(false);
  const [userIdentity, setUserIdentity] = useState({});
  const [connectionId, setConnectionId] = useState('');

  const surveyService = new SurveyService({ navigation });
  const connectionsService = new ConnectionsService({ navigation });
  const isFocused = useIsFocused();

  const fetchData = () => {
    getUserIdentity().then((uIdentity) => {
      if (uIdentity) {
        setUserIdentity(uIdentity);
        const surveyUserType = SURVEY_TYPE;

        connectionsService.getMenteeConnections(uIdentity.email_id, false).then((response) => {
          // console.log('response mentee', response);
          const connId =
            get(response, 'accepted[0].user_type', '').split('@travelex.com-')[1] || '';
          setConnectionId(connId);

          if (connId) {
            surveyService
              .getSurveys(false, uIdentity.email_id, surveyUserType, connId)
              .then((response) => {
                // console.log('response', response);
                setUserSurvey(Object.keys(response).length ? response : USER_SURVEY_DEFAULT);
                setIsApiDataLoaded(true);
              });
          } else {
            setIsApiDataLoaded(true);
          }
        });
      }
    });
  };

  useEffect(() => {
    if (isFocused) {
      fetchData();
    }
  }, [isFocused]);

  return (
    <View style={style.container}>
      <SurveyList userSurvey={userSurvey} isApiDataLoaded={isApiDataLoaded} userSelected={true} />
      {connectionId ? (
        <Fab
          icon='plus'
          data={{ userSurvey: userSurvey }}
          onPress={() => {
            navigation.navigate('SurveyForm', {
              survey_ref: userSurvey.survey_ref,
              email_id: userIdentity.email_id,
              survey_assigned_date_time_iso: userSurvey.survey_assigned_date_time_iso,
            });
          }}
        />
      ) : (
        <View />
      )}
    </View>
  );
};

const style = StyleSheet.create({
  container: { flex: 1 },
});

export default MySurvey;
