import { Avatar } from 'react-native-paper';
import JourneyConfig from '../JourneyConfig.json';
import { MAX_JOURNEYS } from './Metadata';
import ScrollViewElement from '../../elements/ScrollView';
import TextElement from '../../elements/Text';
import TouchableElement from '../../elements/TouchableElement';
import { View } from 'react-native';
import style from './styles/cardScrollViewStyle';

const getName = require('../../../utils/NameMapping').getName;

const CardScrollView = (props) => {
  // console.log("csview props:", props);
  const { data, isApiDataLoaded, ...parentProps } = props;
  // console.log("CardScrollView type", props.type)

  if (isApiDataLoaded) {
    return data?.length ? (
      <ScrollViewElement>
        <View style={style.rowContainer}>
          <Card data={data.slice(0, MAX_JOURNEYS)} {...parentProps}></Card>
        </View>
      </ScrollViewElement>
    ) : (
      <View style={style.noResultsContainer}>
        <Avatar.Icon icon='email-open-outline' color='#CECECE' size={70} style={style.icon} />
        <TextElement style={style.noResultsText}>No Journeys Found!</TextElement>
      </View>
    );
  } else {
    return (
      <View style={style.loadingContainer}>
        <TextElement style={style.loadingText}>Loading...</TextElement>
      </View>
    );
  }
};

const Card = (props) => {
  const { type } = props;
  if (type === 'Designer') {
    return <DesignerCard {...props} />;
  } else {
    return <LearnerCard {...props} />;
  }
};

const LearnerCard = (props) => {
  const { data, type } = props;
  // console.log("type", type);
  const textolor = '#000000';
  const iconSize = 80;
  const pageToNavigate = data[0].pageToNavigate || 'Journeydetail';

  return data.map((journey, index) => {
    const journeyBackground = journey.background_color
      ? JourneyConfig.colors[journey.background_color]
      : JourneyConfig.colors.Blue;
    return (
      <View key={index} style={style.learnerCardStyle}>
        <TouchableElement
          onPress={() => {
            const navigationProps = { journeyId: journey.id };
            props.navigation.navigate(pageToNavigate, { ...navigationProps });
          }}
          id={'row-' + index}
          style={{ maxWidth: 180 }}>
          <View
            style={{
              ...style.cardIconContainer,
              backgroundColor: journeyBackground,
              borderRadius: 10,
            }}>
            <Avatar.Icon
              size={iconSize}
              icon={
                journey.icon === 'MenteeJourneys'
                  ? JourneyConfig.menteeJourney.icon
                  : JourneyConfig.icons[journey.icon] || JourneyConfig.icons.Security
              }
              style={style.icon}
            />
          </View>
          <View style={style.separator}></View>
          <View style={style.cardInfoLearner}>
            <TextElement style={{ ...style.journeyName, color: textolor }} numberOfLines={2}>
              {journey.display_name}
            </TextElement>
            {type == 'Learner' ? (
              <TextElement style={{ ...style.textStyle, color: textolor }} numberOfLines={2}>
                {journey.description || 'No description'}
              </TextElement>
            ) : (
              <TextElement style={{ ...style.textStyle, color: textolor }} numberOfLines={1}>
                {journey.description}
              </TextElement>
            )}
            <View style={style.rowContainer}>
              {!data[0].pageToNavigate ? (
                <View style={{ width: '100%' }}>
                  <TextElement style={{ ...style.createdBy, color: textolor }}>
                    Created by:{' '}
                  </TextElement>
                  <TextElement style={{ ...style.textStyle, color: textolor }}>
                    {journey.created_by ? getName(journey.created_by) : '-'}
                  </TextElement>
                </View>
              ) : (
                <View></View>
              )}
            </View>
          </View>
        </TouchableElement>
      </View>
    );
  });
};

const DesignerCard = (props) => {
  const { data, publishJourney, component, setDialogComponent } = props;
  // console.log("type", type);
  const textolor = '#ffffff';
  const iconSize = 130;
  const pageToNavigate = data[0].pageToNavigate || 'Designerhome';

  return data.map((journey, index) => {
    const journeyBackground = journey.background_color
      ? JourneyConfig.colors[journey.background_color]
      : JourneyConfig.colors.Blue;
    return (
      <View key={index}>
        <TouchableElement
          onPress={() => {
            const navigationProps = { type: 'Update', journeyId: journey.journey_id };
            props.navigation.navigate(pageToNavigate, { ...navigationProps });
          }}
          id={'row-' + index}
          style={{ maxWidth: 180 }}>
          <View
            style={{
              ...style.cardIconContainer,
              backgroundColor: journeyBackground,
              borderRadius: 10,
            }}>
            <Avatar.Icon
              size={iconSize}
              icon={
                journey.icon === JourneyConfig.icons[journey.icon] || JourneyConfig.icons.Security
              }
              style={style.icon}
            />
          </View>
          <View style={style.separator}></View>
          <View style={style.cardInfoDesigner}>
            <TextElement style={{ ...style.journeyName, color: textolor }} numberOfLines={2}>
              {journey.display_name}
            </TextElement>
            <TextElement style={{ ...style.textStyle, color: textolor }} numberOfLines={1}>
              {journey.description || 'No description'}
            </TextElement>
            <View style={style.rowContainer}>
              {!data[0].pageToNavigate ? (
                <View style={{ width: '100%' }}>
                  <TextElement style={{ ...style.createdBy, color: textolor }}>
                    Created by:{' '}
                  </TextElement>
                  <TextElement style={{ ...style.textStyle, color: textolor }}>
                    {journey.created_by ? getName(journey.created_by) : '-'}
                  </TextElement>
                </View>
              ) : (
                <View></View>
              )}
            </View>
            <View style={style.designerContainer}>
              <View style={style.separator}></View>
              <View style={style.buttonRow}>
                <TouchableElement
                  style={style.declineButton}
                  onPress={() => {
                    setDialogComponent(component);
                  }}
                  id={'decline-row-' + index}>
                  <TextElement style={style.decline}>Decline</TextElement>
                </TouchableElement>

                <TouchableElement
                  style={style.approveButton}
                  onPress={() => {
                    publishJourney(journey);
                  }}
                  id={'approve-row-' + index}>
                  <TextElement style={style.approve}>Approve</TextElement>
                </TouchableElement>
              </View>
            </View>
          </View>
        </TouchableElement>
      </View>
    );
  });
};

export default CardScrollView;
