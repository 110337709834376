import { StyleSheet, View, VirtualizedList } from 'react-native';
import { useEffect, useState } from 'react';
import { ActivityIndicator } from 'react-native-paper';
import Elements from '../../../elements';
import { useIsFocused } from '@react-navigation/native';

const Table = (props) => {
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [showSeeMore, setShowSeeMore] = useState(false);
  const [isApiDataLoaded, setIsApiDataLoaded] = useState(false);
  const isFocused = useIsFocused();

  useEffect(() => {
    // console.log('inside useEffect', isFocused, data, props);
    if (isFocused) {
      getData();
    } 
    else {
      setData([]);
      setIsApiDataLoaded(false);
    }
  }, [isFocused]);

  // console.log('data', data);

  const getItem = (data, index) => data[index];

  const getItemCount = (data) => data.length;

  const onRefresh = () => {
    // setRefresh(true);
    // getData(0);
  };

  const handleSeeMore = (data) => {
    if (data.length == 10) {
      setShowSeeMore(true);
    } else {
      setShowSeeMore(false);
    }
  };
  // console.log('page', page);
  //   console.log('isApiDataLoaded', isApiDataLoaded);
  const getData = (pageNumber = 0) => {
    // console.log('pageNumber', pageNumber);
    if (props.getData) {
      props.getData(pageNumber).then((d) => {
        // console.log('getdata result',d);
        if (d.length) {
          handleSeeMore(d);
          setData([...data, ...d]);
          setRefresh(false);
        } else {
          setShowSeeMore(false);
        }
        setIsApiDataLoaded(true);
      });
    }
  };

  // console.log('data', data.length);
  const Empty = () => {
    return isApiDataLoaded ? (
      <View style={[style.main, style.center]}>
        <Elements.Text style={{ color: '#212644' }}>No goals found!</Elements.Text>
      </View>
    ) : (
      <View></View>
    );
  };

  const Loading = () => {
    return isApiDataLoaded ? (
      <Empty />
    ) : (
      <View style={[style.main, style.center]}>
        <ActivityIndicator animating={true} color='#00000' />
        <Elements.Text style={style.loading}>Loading...</Elements.Text>
      </View>
    );
  };

  return (
    <View style={style.container}>
      <VirtualizedList
        initialNumToRender={10}
        renderItem={props.item}
        keyExtractor={() => Math.random()}
        getItemCount={getItemCount}
        getItem={getItem}
        data={data}
        onRefresh={onRefresh}
        refreshing={refresh}
        ListEmptyComponent={props.separator || (() => <Loading />)}
        ItemSeparatorComponent={props.separator || (() => <Separator />)}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
      />
      <View style={style.buttonRow}>
        {showSeeMore ? (
          props.isAdmin ? (
            <Elements.Touchable
              id='see-more'
              onPress={() => {
                setPage(page + 1);
                getData(page + 1);
              }}
              style={{ backgroundColor: 'transparent' }}>
              <Elements.Text style={{ color: '#48B2EB', fontWeight: 'bold', marginBottom: 5 }}>
                See more
              </Elements.Text>
            </Elements.Touchable>
          ) : (
            <View></View>
          )
        ) : isApiDataLoaded && page ? (
          <View style={[style.main, style.center]}>
            <Elements.Text style={{ color: '#212644' }}>No more goals present!</Elements.Text>
          </View>
        ) : (
          <View></View>
        )}
      </View>
    </View>
  );
};

const Separator = () => <View style={style.separator} />;

const style = StyleSheet.create({
  main: {
    flex: 1,
    padding: 5,
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  separator: {
    height: 1,
    backgroundColor: '#e1e1e1',
  },
  container: { display: 'flex', flex: 1 },
  buttonRow: { display: 'flex', flexDirection: 'row', justifyContent: 'center' },
  loading: { padding: 5 },
});

export default Table;
