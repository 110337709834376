import { createSlice } from '@reduxjs/toolkit';

const counterSlice = createSlice({
    name: 'users',
    initialState: {
        value: {},
        allUsersEmails: [],
        recommendedUsers: []

    },
    reducers: {
        userReducer: (state, action) => {
            // console.log('Reducer called', action);
            if (action.payload.roleId && action.payload.users) {
                state.value[action.payload.roleId] = action.payload.users;
                state.allUsersEmails = action.payload.users.map(user => user.email);
            }
            if (action.payload.recommendedUsers) {
                state.recommendedUsers = action.payload.recommendedUsers;
            }
        }
    }

});

// Action creators are generated for each case reducer function
export const { userReducer } = counterSlice.actions;
export default counterSlice.reducer;
