import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  questionView: {
    backgroundColor: '#ffffff',
    padding: 10,
    margin: 5,
    borderBottomWidth: 2,
    borderColor: '#DADADA',
  },
  questionsContainer: { backgroundColor: '#ffffff' },
  buttonContainer: { display: 'flex', flexDirection: 'row-reverse' },
  button: { backgroundColor: '#48B2EB', color: '#ffffff', marginTop: 10, marginBottom: 20 },
  buttonText: { fontWeight: 'bold' },
  divider: { height: 10 },
  rating: { padding: 5 },
  alignItemCenter: {
    alignItems: 'center',
  },
  loadingText: {
    fontSize: 11,
    color: '#ADADAD',
    marginTop: 10,
  },
  flex1: { flex: 1 },
  surveyDateView: {
    paddingVertical: 5,
    flexDirection: 'row',
    backgroundColor: '#ffffff',
    borderColor: '#ADADAD',borderWidth: 1
  },
});
