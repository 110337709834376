import { KeyboardAvoidingView, ScrollView, View } from 'react-native';
import { getDate, trim } from '../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import Alert from '../../elements/Alert';
import { Avatar } from 'react-native-paper';
import ColorConstant from '../../../utils/ColorConstant.json';
import DesignerService from '../../../service/DesignerService';
import Elements from '../../elements';
import Fab from '../../elements/Fab';
import TextElement from '../../elements/Text';
import TouchableElement from '../../elements/TouchableElement';
import ViewWithBack from '../../elements/ViewWithBack';
import journeyConfig from '../../journey/JourneyConfig.json';
import { roleReducer } from './RoleSlice';
import style from './styles/rolesStyle';
import { useIsFocused } from '@react-navigation/native';

const getName = require('../../../utils/NameMapping').getName;

const Roles = (parentProps) => {
  const props = { ...parentProps, ...parentProps.route.params };
  const roles = useSelector((state) => state.roles.value);
  // console.log("roles from redux", roles)
  const dispatch = useDispatch();
  const isFocused = useIsFocused();
  // const [isApiDataLoaded, setIsApiDataLoaded] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResult, setSearchResult] = useState([]);

  const designerService = new DesignerService(props);

  const fetchData = () => {
    if (roles.length) {
      // console.log("roles found", roles);
      // setIsApiDataLoaded(true);
    }
    designerService.getRoles(false).then((response) => {
      // console.log("--roles----fetchdata called", response);
      // setIsApiDataLoaded(true);
      dispatch(roleReducer({ roles: response }));
    });
  };

  const search = () => {
    // console.log("search query", searchQuery);
    designerService.searchRoles(false, searchQuery).then((response) => {
      // console.log("--roles----searchRoles called", response);
      // setIsApiDataLoaded(true);
      setSearchResult(response);
    });
  };

  const deleteRole = (role) => {
    // console.log("Delete role", role);
    designerService.deleteRole(false, role).then(() => {
      // console.log("response", response)
      // setIsApiDataLoaded(true);
      Alert.alert('Success', 'Role Deleted');
      fetchData();
    });
  };

  const flush = () => {
    setSearchResult([]);
    setSearchQuery('');
  };

  useEffect(() => {
    if (isFocused) {
      fetchData();
    } else {
      flush();
    }
  }, [isFocused]);

  const onChangeSearch = (query) => {
    if (query) {
      setSearchQuery(query);
    } else {
      setSearchQuery('');
      setSearchResult([]);
    }
  };
  // console.log("props", props);
  // console.log("searchResult", searchResult);

  return (
    <ViewWithBack
      pageName='Roles'
      {...props}
      backAction={() => {
        props.navigation.navigate('DesignerDashboard');
      }}>
      <Elements.Searchbar
        placeholder='Search Role'
        onSubmitEditing={search}
        onChangeText={onChangeSearch}
        value={searchQuery}
      />
      <ScrollView showsVerticalScrollIndicator={false} showsHorizontalScrollIndicator={false}>
        <KeyboardAvoidingView behavior='position' style={style.keyboard}>
          <View>
            {searchResult.length ? (
              <View>
                <TextElement theme='LIGHT' style={style.header}>
                  Search Result:{' '}
                </TextElement>
                <ListComponent dataList={searchResult} deleteRole={deleteRole} {...props} />
              </View>
            ) : (
              <ListComponent dataList={roles} deleteRole={deleteRole} {...props} />
            )}

            <View style={style.divider}></View>
            {/* <View style={{ alignItems: "flex-end" }} >
                                    <Button style={{ backgroundColor: journeyConfig.travelexColors.actionBlue, width: 90 }} onPress={() => { console.log("Create", space); }}>Create</Button>
                                </View> */}
            <View style={style.bottomSpace}></View>
          </View>
        </KeyboardAvoidingView>
      </ScrollView>
      <Fab
        icon='plus'
        onPress={() => {
          props.navigation.navigate('RoleForm', { type: 'Create' });
        }}
      />
    </ViewWithBack>
  );
};

const ListComponent = (props) => {
  const { dataList } = props;
  return dataList.length ? (
    <View>
      <View style={style.divider2}></View>
      {dataList.map((role, index) => {
        return (
          <View key={index} style={style.listContainer}>
            <TouchableElement
              key={index}
              onPress={() => {
                props.navigation.navigate('Role', {
                  roleId: role.id,
                });
              }}
              id={role.id}>
              <View style={style.rowContainer}>
                <View style={style.roleContainer}>
                  <TextElement style={style.tags} numberOfLines={1}>{role.tags}</TextElement>

                  <TextElement style={style.textStyle} numberOfLines={1}>{role.description}</TextElement>

                  <TextElement style={style.textStyle}>
                    Created by {role.created_by ? getName(role.created_by) : '-'} on{' '}
                    {role.created_at ? getDate(role.created_at) : '-'}
                  </TextElement>
                </View>
                <View style={style.editView}>
                  <TouchableElement
                    onPress={() => {
                      props.navigation.navigate('RoleForm', {
                        type: 'Update',
                        roleId: role.id,
                        roleToUpdate: {
                          roleName: `Role ${role.id}`,
                        },
                      });
                    }}
                    id='pencil'>
                    <Avatar.Icon
                      size={25}
                      icon='pencil'
                      style={style.icon}
                      color={
                        journeyConfig.travelexColors.TRXBlue ||
                        ColorConstant[props.theme]?.BUTTON_BACKGROUND_COLOR
                      }
                    />
                  </TouchableElement>
                </View>
              </View>
            </TouchableElement>
          </View>
        );
      })}
    </View>
  ) : (
    <View></View>
  );
};

export default Roles;
