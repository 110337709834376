import { Avatar, Surface } from 'react-native-paper';
import { StyleSheet, View } from 'react-native';

import { ActivityIndicator } from 'react-native-paper';
import Elements from '../elements/index';
import React from 'react';
import { getName } from '../../utils/NameMapping';
import { useNavigation } from '@react-navigation/native';

const moment = require('moment');

export default function SurveyList({ userSurvey, isApiDataLoaded, pageName, userSelected }) {
  return (
    <View style={style.flex1}>
      <Elements.CustomVList
        item={(props) => <Item {...props} userSurvey={userSurvey} />}
        data={userSurvey.submitted_surveys}
        empty={
          isApiDataLoaded ? Empty : <Loading userSelected={userSelected} pageName={pageName} />
        }
        separator={Separator}
      />
    </View>
  );
}

const Item = ({ index, item, userSurvey }) => {
  const navigation = useNavigation();

  const toSurveyDetails = () => {
    const navigateData = {
      ...item,
      email_id: userSurvey.email_id,
      mentor_email_id: userSurvey.connection_id.split('#')[1],
      assigned_date: userSurvey.survey_assigned_date_time_iso,
      survey_ref: userSurvey.survey_ref,
    };

    navigation.navigate('Surveydetails', navigateData);
  };

  return (
    <Surface style={style.item}>
      <Elements.Touchable id={'row-' + index} style={style.row} onPress={toSurveyDetails}>
        <View style={{ flex: 5.5, display: 'flex', gap: 4 }}>
          <Elements.Text>{userSurvey.survey_ref.split('#')[4]}</Elements.Text>
          <Elements.Text>
            {item.survey_date ? moment.unix(item.survey_date).format('YYYY/MM/DD') : ''}
          </Elements.Text>
          <Elements.Text style={style.mentor}>
            Mentor: {getName(userSurvey.connection_id.split('#')[1])}
          </Elements.Text>
        </View>
        <View style={{ flex: 0.5 }}>
          <Avatar.Icon
            icon='arrow-right-bold'
            size={25}
            color='#121212'
            style={{ backgroundColor: 'transparent' }}
          />
        </View>
      </Elements.Touchable>
    </Surface>
  );
};

const Loading = ({ userSelected, pageName }) => {
  if (userSelected) {
    return (
      <View style={[style.main, style.center]}>
        <ActivityIndicator animating={true} color='#00000' />
        <Elements.Text style={style.loading}>Loading...</Elements.Text>
      </View>
    );
  } else {
    return (
      <View style={[style.main, style.center]}>
        <Elements.Text style={style.loading}>{`Please select a ${pageName}.`}</Elements.Text>
      </View>
    );
  }
};

const Empty = () => {
  return (
    <Surface style={style.empty}>
      <Avatar.Icon
        icon='email-open-outline'
        style={{ backgroundColor: 'transparent' }}
        color='#DFDFDF'
      />
      <Elements.Text style={{ color: '#121212' }}>No Feedback found!</Elements.Text>
    </Surface>
  );
};

const Separator = () => {
  return <View style={{ backgroundColor: '#DFDFDF', height: 1 }} />;
};

const style = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
    paddingVertical: 10,
  },
  item: {
    paddingHorizontal: 2,
  },
  flex1: {
    flex: 1,
    marginHorizontal: 2,
  },
  heading: {
    backgroundColor: '#ffffff',
    fontSize: 16,
    fontWeight: 'bold',
  },
  empty: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    paddingVertical: 10,
    backgroundColor: '#ffffff',
  },
  mentor: {
    fontSize: 12,
    color: '#8A8A8A',
  },
  main: {
    flex: 1,
    padding: 10,
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loading: { padding: 5 },
});
