import ColorConstant from '../../utils/ColorConstant.json';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    centerAlign: { alignItems: 'center' },
    seeAll: { width: '100%', alignItems: 'flex-end' },
    icon: { backgroundColor: 'transparent' },
    info: { fontSize: 11, color: '#ADADAD' },
    buttonLabel: { color: '#48B2EB' },
    rating: { padding: 10 },
    buttonRow: { display: 'flex', flexDirection: 'row'},
    ratingView: { backgroundColor: '#ffffff', padding: 10, borderRadius: 5 },
    textStyle: { fontWeight: 'bold' },
    divider2: { height: 2 },
    divider5: { height: 5 },
    paragraph: { fontSize: 12 },
    boldText: { fontSize: 12, fontWeight: 'bold' },
    tile: { backgroundColor: '#ffffff', borderWidth: 0.3, borderColor: '#ffffff', borderRadius: 5, padding: 10, marginLeft: 5 },
    ratingTile: { backgroundColor: '#ffffff', borderWidth: 0.3, borderColor: '#ffffff', borderRadius: 5, padding: 10, marginLeft: 5, flexDirection: 'row', alignItems: 'center' },
    button: { backgroundColor: ColorConstant.LIGHT?.BUTTON_BACKGROUND_COLOR, marginRight: 4 },
    buttonText: { color: ColorConstant.LIGHT?.BUTTON_TEXT },
    buttonView: { alignItems: 'flex-end' },
    addFeedbackRating :{ width: 200, padding: 5 },
    addFeedBackDesc: { backgroundColor: '#ffffff', borderRadius: 10 },
    addFeedbackView: { display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 5 },
    infoFeedback: { fontSize: 11, color: '#ADADAD', marginTop: 10 },
    scrollView:{ height: '80%' },
    heading: { fontSize: 16, marginBottom: 10, marginTop: 10, paddingLeft: 10 },
    feedackSeeAll: { padding: 7, paddingRight: 2 },
    seeAllView: { flexDirection: 'row', alignItems: 'center' }
});