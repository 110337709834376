import { Platform } from 'react-native';
import Storagekeys from './Storagekeys.json';

class ApplicationStorage {
  static async setItem(key, value) {
    if (Platform.OS === 'web') {
      const SecureLS = await (await import('secure-ls')).default;
      const ls = new SecureLS({ encodingType: 'aes' });
      let appKey = Storagekeys[key];
      ls.set(appKey, value);
    } else {
      const secureStore = await import('expo-secure-store');
      const secureStoreOptions = {
        keychainAccessible: secureStore.WHEN_PASSCODE_SET_THIS_DEVICE_ONLY
      };

      let appKey = Storagekeys[key];
      Platform.OS === 'ios' ? await secureStore.setItemAsync(appKey, JSON.stringify(value), secureStoreOptions) : await secureStore.setItemAsync(appKey, JSON.stringify(value));
    }
  }

  static async getItem(key) {
    if (Platform.OS === 'web') {
      const SecureLS = await (await import('secure-ls')).default;
      const ls = new SecureLS({ encodingType: 'aes' });
      let appKey = Storagekeys[key];
      let temp = ls.get(appKey);
      // console.log(appKey, temp);
      // temp = temp && temp != 'null' ? JSON.parse(temp) : {};
      return temp;
    } else {
      const secureStore = await import('expo-secure-store');
      const secureStoreOptions = {
        keychainAccessible: secureStore.WHEN_PASSCODE_SET_THIS_DEVICE_ONLY
      };

      let appKey = Storagekeys[key];
      let temp = Platform.OS === 'ios' ? await secureStore.getItemAsync(appKey, secureStoreOptions) : await secureStore.getItemAsync(appKey);
      temp = temp && temp != 'null' ? JSON.parse(temp) : {};
      // console.log("temp", temp);
      return temp;
    }
  }

  static async removeAll() {
    if (Platform.OS === 'web') {
      const SecureLS = await (await import('secure-ls')).default;
      const ls = new SecureLS({ encodingType: 'aes' });
      ls.removeAll();
      // ls.set('leadx', JSON.stringify({}));
    } else {
      const secureStore = await import('expo-secure-store');
      const secureStoreOptions = {
        keychainAccessible: secureStore.WHEN_PASSCODE_SET_THIS_DEVICE_ONLY
      };
      const removeAll = async () => {
        const appKeys = Object.values(Storagekeys);
        for (const appKey of appKeys) {
          Platform.OS === 'ios' ? await secureStore.deleteItemAsync(appKey, secureStoreOptions) : await secureStore.deleteItemAsync(appKey);
        }
      };
      await removeAll();
      // code = await secureStore.getItemAsync('APPLICATION_CODE');
      // console.log("removeall code2", code);
    }
  }
}

export default ApplicationStorage;