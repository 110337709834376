import * as React from 'react';

import { useEffect, useState } from 'react';

import { Rating } from 'react-native-ratings';
import TextElement from '../elements/Text';
import TouchableElement from '../elements/TouchableElement';
import { View } from 'react-native';
import { getDate } from '../utils/utils';
import { useIsFocused } from '@react-navigation/native';

// import { getName } from '../utils/utils';

const getName = require('../../utils/NameMapping').getName;

const Tile = (props) => {
  return (
    <View
      style={{
        backgroundColor: '#ffffff',
        padding: 10,
        borderRadius: 5,
        margin: 5,
        display: 'flex',
        flexDirection: 'row-reverse',
      }}>

      <View style={{ display: 'flex', alignItems: 'flex-end', flex: 2, justifyContent: 'flex-end' }}>
          <Rating
            readonly={true}
            type='star'
            startingValue={props.rating}
            imageSize={15}
            style={{ padding: 5, backgroundColor: '#ff000000' }}
          />
      </View>
      <View style={{ alignItems: 'flex-start', flex: 4 }}>
      <TextElement style={{ fontSize: 14 }}>{props.paragraph}</TextElement>
        <TextElement style={{ fontSize: 10 }}>
          Posted: {props.created_by ? getName(props.created_by) : ''} on{' '}
          {props.created_at ? getDate(props.created_at) : '-'}
        </TextElement>
      </View>
    </View>
  );
};

const TopicRow = (props) => {
  // console.log("TopicRow props :: ", props);
  const [feedbackData, setFeedbackData] = useState([]);
  // const [index, setIndex] = useState('');
  const isFocused = useIsFocused();

  useEffect(() => {
    if (isFocused) {
      setFeedbackData(props.data);
      // setIndex(props.index);
    } else {
      // flush();
    }
  }, [isFocused]);
  // console.log("Feedback :: ", feedbackData);
  return (
    <View style={{ width: '100%' }}>
      {feedbackData ? (
        <TouchableElement
          onPress={() => {
            props.navigation.navigate('Viewfeedback', {
              data: feedbackData,
              userIdentity: props.userIdentity,
              isAdminUser: props.isAdminUser,
            });
          }}
          id={'row' + feedbackData.title}>
          <Tile
            title={feedbackData.title}
            paragraph={feedbackData.description}
            created_at={feedbackData.created_at}
            created_by={feedbackData.created_by}
            rating={feedbackData.rating}
            width='100%'
          />
        </TouchableElement>
      ) : (
        <View></View>
      )}
    </View>
  );
};

export default TopicRow;
