import { KeyboardAvoidingView, ScrollView, View } from 'react-native';
import { getDate, validateFieldName } from '../../../utils/utils';
import { useEffect, useState } from 'react';

import Alert from '../../../elements/Alert';
import Button from '../Button';
import DesignerService from '../../../../service/DesignerService';
import TextElement from '../../../elements/Text';
import TextInputElement from '../../../elements/TextInput';
import ViewWithBack from '../../../elements/ViewWithBack';
import style from './styles/moduleFormStyle';
import { useIsFocused } from '@react-navigation/native';
import { useSelector } from 'react-redux';

const getName = require('../../../../utils/NameMapping').getName;

const ModuleForm = (parentProps) => {
    const props = { ...parentProps, ...parentProps.route.params };
    const isFocused = useIsFocused();
    const { type, moduleId, spaceId } = props.route.params;
    // console.log("spaceId", spaceId);

    const spaces = useSelector((state) => state.spaces.value);
    // console.log("ModuleForm spaces", spaces);
    const space = spaces.filter(space => spaceId == space.id)[0];
    // console.log("ModuleForm space", space);
    const modules = Object.keys(space).length ? space.modules : [];
    const moduleToUpdate = moduleId ? modules.filter((module) => module.id == moduleId)[0] : {};
    // console.log("ModuleForm moduleToUpdate", moduleToUpdate);
    const moduleList = modules.map(module => module.display_name);
    const [moduleName, setModuleName] = useState('');
    const [moduleDesc, setModuleDesc] = useState('');
    const [unsavedChanges, setUnsavedChanges] = useState(false);

    const designerService = new DesignerService(props);

    const fetchData = () => {
        if (type == 'Update') {
            if (space && Object.keys(space).length) {
                // console.log("space found ModuleForm page", space);
                // setIsApiDataLoaded(true);
            }
            setModuleName(moduleToUpdate.display_name);
            setModuleDesc(moduleToUpdate.description);
        }
    };

    const createModule = () => {
        setUnsavedChanges(false);
        const module = {
            display_name: moduleName,
            description: moduleDesc
        };
        const validationResult = validateFieldName(moduleName, 'Module', moduleList);
        // console.log("validation result", validationResult);
        if (validationResult.status) {
            designerService.createModule(false, module, space.id).then(() => {
                // setIsApiDataLoaded(true);
                Alert.alert('Success', 'Module created');
                props.navigation.navigate('Space');
            });
        } else {
            Alert.alert(validationResult.alertType, validationResult.alertMessage);
        }
    };
    // console.log("unsavedChanges", unsavedChanges);

    const updateModule = () => {
        setUnsavedChanges(false);
        function getUpdatedModule() {
            const module = {
                space_id: space.id,
                id: moduleToUpdate.id
            };
            if (moduleToUpdate.display_name != moduleName) {
                module.display_name = moduleName;
            }
            if (moduleToUpdate.description != moduleDesc) {
                module.description = moduleDesc;
            }
            return module;
        }

        const module = getUpdatedModule();
        // console.log("module", module);

        let validationResult = {};
        if (module.display_name || module.display_name == '') {
            validationResult = validateFieldName(module.display_name, 'Module', moduleList);
        } else {
            validationResult.status = true;
        }
        // console.log("validationResult", validationResult);

        if (validationResult.status) {
            designerService.updateModule(false, module).then(() => {
                // setIsApiDataLoaded(true);
                Alert.alert('Success', 'Module updated');
                props.navigation.navigate('Space');
            });
        } else {
            Alert.alert(validationResult.alertType, validationResult.alertMessage);
        }
    };

    useEffect(() => {
        if (isFocused) {
            fetchData();
        }
    }, [isFocused]);

    return (
        <ViewWithBack pageName={type + ' ' + 'Module'}  {...props} backAction={() => {
            if (unsavedChanges) {
                Alert.confirm('Confirm', 'Are you sure you want to discard the changes?', () => {
                    props.navigation.navigate('Space');
                });
            } else {
                props.navigation.navigate('Space');
            }
        }} >
            <ScrollView
                showsVerticalScrollIndicator={false}
                showsHorizontalScrollIndicator={false}
            >
                <KeyboardAvoidingView behavior="position" style={style.keyboard}>
                    <View style={style.padding5}>
                        <View style={style.divider}></View>
                        <View style={style.infoContainer}>
                            <View style={style.spaceName}>
                                <TextElement theme={'DARK'} style={style.textStyle}>Space : {space.display_name}</TextElement>
                            </View>
                            {
                                type == 'Update' ?
                                    <TextElement style={style.text}>Created by {space.created_by ? getName(space.created_by) : '-'} on {space.created_at ? getDate(space.created_at) : '-'}</TextElement>
                                    :
                                    <View></View>
                            }
                        </View>
                        <View style={style.divider}></View>

                        <TextInputElement
                            theme="LIGHT"
                            label="Module Name"
                            value={moduleName}
                            onChangeText={(moduleName) => {
                                // console.log("moduleName", moduleName)
                                moduleName = moduleName.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
                                setModuleName(moduleName); setUnsavedChanges(true);
                            }}
                            labelStyle={style.labelStyle} />
                        {/* </View> */}

                        <View style={style.divider}></View>

                        <TextInputElement
                            theme="LIGHT"
                            label="Module Description"
                            multiline={true}
                            numberOfLines={6}
                            value={moduleDesc}
                            onChangeText={(desc) => { setModuleDesc(desc); setUnsavedChanges(true); }}
                            labelStyle={style.labelStyle}
                            />
                        <View style={style.divider}></View>

                        <View style={style.buttonContainer} >
                            {
                                unsavedChanges ?
                                    <Button style={style.button} onPress={type == 'Create' ? createModule : updateModule}>{type}</Button>
                                    : <View></View>
                            }
                        </View>
                        <View style={style.bottomSpace}></View>
                    </View>
                </KeyboardAvoidingView>
            </ScrollView>
        </ViewWithBack>
    );
};

export default ModuleForm;