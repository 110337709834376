import { Button } from 'react-native-paper';
import { StyleSheet } from 'react-native';
import ColorConstant from '../../utils/ColorConstant.json';
import { memo } from 'react';

const ButtonElement = (props) => {
    const styles = StyleSheet.create({
        style: {
            backgroundColor: props.icon ? '' : ColorConstant[props.theme]?.BUTTON_BACKGROUND_COLOR || '#ffffff',
            ...props.style
        },
    });
    return (
        <Button
            dark={true}
            testID={props?.testId} onPress={props.onPress}
            style={styles.style} color={props.color || ColorConstant[props.theme]?.BUTTON_TEXT || '#ffffff'}
            icon={props.icon} mode={props.mode} disabled={props.disabled}>
            {props.text}{props.children}
        </Button>
    );
};

export default memo(ButtonElement);