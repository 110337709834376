

const getName = (email) => {
    console.log('')
    if (!email) return '';
    let name = email?.split('@')[0]
        .replace(/\d.*\d/, '')  // Remove digits and everything in between
        .replace(/\./g, ' ')    // Replace dots with spaces
        .trim();

    return name?.toUpperCase();
};



module.exports = {
    getName
};