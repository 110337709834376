import ColorConstant from '../../../../utils/ColorConstant.json';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  safeArea: {
    flex: 1,
  },
  menu: {
    padding: 10,
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#f5f5f5',
    borderBottomWidth: 1,
    borderColor: '#DADADA'
  },
  menuContainer: { flexDirection: 'row', display: 'flex', flex: 4 },
  menuBubbon: {
    padding: 5,
    flex: 2,
    borderLeftColor: '#DADADA',
    borderLeftWidth: .6,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row'
  },
  headerStyle: {
    color: ColorConstant.LIGHT?.COLOR || 'black',
    fontWeight: 'bold',
    fontSize: 15,
  },
  divider5: { height: 5 },
  textStyle: {
    fontSize: 15,
    color: ColorConstant.LIGHT?.COLOR || 'black',
    justifyContent: 'center',
  },
  created: {
    backgroundColor: '#FFAA33',
    fontSize: 9,
    borderColor: '#ffffff',
    borderWidth: 2,
    borderRadius: 25,
    justifyContent: 'center',
    flex: 1.5,
  },
  inprogress: {
    backgroundColor: '#00BFFF',
    fontSize: 9,
    borderColor: '#ffffff',
    borderWidth: 2,
    borderRadius: 25,
    justifyContent: 'center',
    flex: 1.5
  },
  completed: {
    backgroundColor: '#50C878',
    fontSize: 9,
    borderColor: '#ffffff',
    borderWidth: 2,
    borderRadius: 25,
    justifyContent: 'center',
    flex: 1.5
  },
  aborted: {
    backgroundColor: '#CD2702',
    fontSize: 9,
    borderColor: '#ffffff',
    borderWidth: 2,
    borderRadius: 25,
    justifyContent: 'center',
    flex: 1.5
  },
  container: {
    // padding: 5,
    flex: 1,
    width: '100%'
  },
  statsRow: { flexDirection: 'row', borderWidth: .5, borderColor: '#2E3456', padding: 5 },
  statsCount: { fontWeight: 'bold', fontSize: 30 },
  statsView: { width: 95, borderRadius: 5, alignItems: 'center', justifyContent: 'center' },
  statsText: { fontWeight: 'bold', fontSize: 12 },
  statsCreated: { flex: 2, alignItems: 'flex-start' },
  statsInProgress: { flex: 2, alignItems: 'center' },
  statsCompleted: { flex: 2, alignItems: 'flex-end' },
  dataTable: { backgroundColor: ColorConstant.LIGHT?.BACKGROUND_COLOR_MEDIUM_SHADE || '#f5f5f5', flex: 1 },
  dataTableRow: { backgroundColor: '#ffffff' },
  flex3: { flex: 3.5 },
  flex2: { flex: 1.5 },
  flex1: { flex: 1 },
  icon: { backgroundColor: 'transparent' },
  noGoalsView: { alignItems: 'center' },
  noGoalsText: { color: '#000000', marginTop: 5 },
  goalStatus: { fontWeight: 'bold' },
  goalTableFooter: { height: 40, backgroundColor: '#212644' },
  center: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
});