// import moment from "moment";
// import Cookies from "js-cookie";

import ApplicationStorage from '../../utils/ApplicationStorage';
import Constants from '../../utils/Constants';
import DatadogMonitoring from '../../utils/DatadogMonitoring';
import { Dimensions } from 'react-native';
import Request from '../../dal/RequestNew';
import SurveyService from '../../service/SurveyService';
import { getUserIdentity } from '../../utils/UserIdentity';

const { width } = Dimensions.get('window');

const jp = require('jsonpath');

export function shuffleArray(array) {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }

  return array;
}

export function generateDateObject(selectedValue, isCustomDateFormat = false) {
  //generate from and to

  const formatter = 'YYYY-MM-DD hh:mm:ss';
  let stamp = {};
  if (isCustomDateFormat) {
    stamp = {
      selectedValue: 'custom',
      timeStamp: {
        from: moment(selectedValue[0]).format(formatter),
        to: moment(selectedValue[1]).format(formatter),
      },
    };
  } else {
    let value = selectedValue;
    let selectedValueArray = value.split('-');
    stamp = {
      selectedValue,
      timeStamp: {
        from: moment().subtract(selectedValueArray[0], selectedValueArray[1]).format(formatter),
        to: moment().format(formatter),
      }, //add autoincrement id alos
      // autoIncrementedId: 10
    };
  }
  return stamp;
}

export function setExpiration(expirationInMin = 15) {
  let code = Cookies.get('code');
  let expiresIn = Cookies.get('expiresIn');

  let isCookieExpiryTime = (expiresIn - new Date().getTime()) / 60000;

  if (isNaN(isCookieExpiryTime)) return false;
  if (isCookieExpiryTime < 2) {
    let expires = new Date(new Date().getTime() + expirationInMin * 60 * 1000);
    Cookies.set('code', code, {
      expires,
    });
    Cookies.set('expiresIn', new Date().getTime(), {
      expires,
    });
  }

  return true;

  // let expirationDate = new Date(new Date().getTime() + (60000 * expirationInMin)).getTime()

  // localStorage.setItem('expires_in', expirationDate)
}

//Encrypt a String with SHA256
export function encryptStringWithSHA256(str) {
  const PROTOCOL = 'SHA-256';
  const textEncoder = new TextEncoder();
  const encodedData = textEncoder.encode(str);
  return crypto.subtle.digest(PROTOCOL, encodedData);
}

export function getRandomString() {
  const randomItems = new Uint32Array(28);
  crypto.getRandomValues(randomItems);
  const binaryStringItems = randomItems.map((dec) => `0${dec.toString(16).substr(-2)}`);
  return binaryStringItems.reduce((acc, item) => `${acc}${item}`, '');
}

//Convert Hash to Base64-URL
export function hashToBase64url(arrayBuffer) {
  const items = new Uint8Array(arrayBuffer);
  const stringifiedArrayHash = items.reduce((acc, i) => `${acc}${String.fromCharCode(i)}`, '');
  const decodedHash = btoa(stringifiedArrayHash);

  const base64URL = decodedHash.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
  return base64URL;
}

export function getCommonElements(array1, array2) {
  return array1.filter((value) => array2.includes(value));
}

export function getURL(url) {
  let baseURL = '';
  if (process.env.REACT_APP_COMPLIANCE_API_BASE_PATH) {
    baseURL = process.env.REACT_APP_COMPLIANCE_API_BASE_PATH;
  } else {
    baseURL = 'https://api-compliance-ui-dev.middleware-dev.tvx-test.cloud/ui/v1/scenario/';
  }

  let urlToBePassed = baseURL + url;
  return new URL(urlToBePassed);
}

export function sleep(delay) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, delay);
  });
}

export function getTimeWithMode(time) {
  let hour = time ? time.split(':')[0] : '';
  let minute = time ? time.split(':')[1] : '';
  let mode = hour ? (hour < 12 ? 'AM' : 'PM') : '';
  hour = hour <= 12 ? hour : hour - 12;
  // console.log("hour", hour);
  // console.log("minute", minute);
  // console.log("mode", mode);
  hour = hour.toString().length > 1 || hour.toString().length == 0 ? hour : '0' + hour;
  minute = minute.toString().length > 1 || minute.toString().length == 0 ? minute : '0' + minute;

  if (hour && minute) {
    return `${hour}:${minute} ${mode}`;
  } else {
    return '';
  }
}

export function getName(email_id) {
  email_id = email_id.replace(/[\d]/g, '');

  let firstName = email_id?.replace('@travelex.com', '').split('.')[0];
  firstName = firstName?.charAt(0).toUpperCase() + firstName?.slice(1);
  let lastName = email_id?.replace('@travelex.com', '').split('.')[1];
  lastName = lastName?.charAt(0).toUpperCase() + lastName?.slice(1);

  return {
    firstName: firstName,
    lastName: lastName,
    fullName: firstName + ' ' + lastName,
  };
}

export function trim(data, length, extraLength = 0) {
  // console.log('data', data);
  length = extraLength && width < 500 ? length : length + extraLength;
  // console.log('length', length);
  return data?.length > length ? data.substring(0, length) + '...' : data;
}

export function getDate(date) {
  // console.log("date", date, typeof date)
  date = date?.toString().replace(/[\/, -]/g, '');
  // console.log("date", date, typeof date)
  // console.log("new date", date.slice(0, 4) + "/" + date.slice(4, 6) + "/" + date.slice(6, 8));
  // return date?.slice(0, 4) + "/" + date.slice(4, 6) + "/" + date.slice(6, 8);
  return date.slice(6, 8) + '/' + date.slice(4, 6) + '/' + date?.slice(0, 4);
}

// Removes special character, space, and makes capital letters smallcase
export function formatData(data) {
  // console.log("data",data)
  return data?.replace(/[^A-Z0-9]+/gi, '').toLowerCase();
}

export function validateFieldName(value, label, existingValues) {
  let alertMessage = '';
  let status = false;
  let alertType = '';
  // console.log("existingValues", existingValues);
  // console.log("value", value);

  if (!value) {
    alertMessage = `Please enter mandatory field: ${label} name`;
    alertType = 'Missing input';
  } else if (existingValues.includes(value)) {
    // else if (existingValues.includes(value) || existingValues.includes(value.toLowerCase())) {
    alertMessage = `${label} ${value} already exists`;
    alertType = 'Whoops';
  } else {
    // const regex1 = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/g; //special character
    const regex2 = /[ ]{2,}/g; //more than one space
    const regex3 = /^[ \t]+|[ \t]+$/g; //spaces at start and end

    // const regex1Result = regex1.test(value);
    const regex2Result = regex2.test(value);
    const regex3Result = regex3.test(value);

    // if (regex1Result) {
    //   alertMessage = `${label} name cannot include special characters`;
    //   alertType = 'Bad input';
    // } else

    if (regex2Result) {
      alertMessage = `Please remove extra spaces from ${label} name`;
      alertType = 'Bad input';
    } else if (regex3Result) {
      alertMessage = `Please remove extra spaces from ${label} name`;
      alertType = 'Bad input';
    } else {
      status = true;
    }
  }
  return {
    alertMessage,
    status,
    alertType,
  };
}

export function getFields(getByCol, getByValueList, getCol, dataArray) {
  if (dataArray.length) {
    const result = getByValueList.map((getByVal) => {
      const query = `$..[?(@.${getByCol} == '${getByVal}')].${getCol}`;
      // console.log("query", query);
      const queryResult = jp.query(dataArray, query);
      // console.log("queryResult", queryResult);
      return queryResult;
    });
    return result;
  } else {
    return '';
  }
}

export function filterData(dataToFilter, filterByColumn, dataArray) {
  const dataValues = dataArray.map((data) => data[filterByColumn]);
  const result = dataToFilter.filter((data) => dataValues.includes(data[filterByColumn]));
  return result;
}

function validateCountry(country) {
  // console.log('country', country);
  if (['QA', 'CN'].includes(country.toUpperCase())) {
    return false;
  } else {
    return true;
  }
}

async function getUIdentity(request) {
  //added alert
  //Call UserItendity Service to fetch UserEmailId
  try {
    const url = Constants.getConfig('USER_IDENTITY');
    const result = await request.getNew(url);
    let email = result.email.toLowerCase();
    let pattern = /[0-9]+/g;
    let extractEmail = email.replace(pattern, '').replace('@travelex.com', '');
    let names = extractEmail.split('.');
    let firstName = names[0];
    let lastName = names[1];

    const userIdentity = {
      email_id: result.email.toLowerCase(),
      given_name: result.given_name,
      firstName,
      lastName,
      name: result.name,
      user_role: result.user_role,
      department: result.department,
      jobTitle: result.jobtitle,
      country: result.country,
    };
    return userIdentity;
  } catch (ex) {
    throw ex;
  }
}

async function getProfileData(request, userIdentity) {
  try {
    let getProfile = Constants.getConfig('GET_PROFILE');
    getProfile = getProfile.replace('${emailID}', userIdentity.email_id);
    const profileData = await request.getNew(getProfile);
    // console.log("profileData", profileData);
    return profileData;
  } catch (ex) {
    // console.log("getProfileData Error", ex);
    throw ex;
  }
}

// async function getPendingRecords(props, userIdentity) {
//   try {
//     const surveyService = new SurveyService(props);
//     const pendingSurveys = await surveyService.getSurveys(false, userIdentity.email_id);
//     // console.log("utils pendingSurveys", pendingSurveys);
//     return pendingSurveys;
//   } catch (ex) {
//     // console.log("getPendingRecords Error", ex);
//     throw ex;
//   }
// }

export function loadData(code, props, setIsNotAllowedCountry, setLoadingOn) {
  const request = new Request(props);
  let uIdentity = {};
  let loggedInUser = false;
  if (code) {
    loggedInUser = true;
    // console.log('inside if loggedInUser', loggedInUser);

    ApplicationStorage.setItem('code', code)
      .then(() => {
        getUIdentity(request)
          .then((userIdentity) => {
            uIdentity = userIdentity;
            // console.log('userIdentity result', userIdentity);
            // console.log("uIdentity", uIdentity);
            new DatadogMonitoring().setUser(userIdentity.email_id);
            ApplicationStorage.setItem('userIdentity', JSON.stringify(userIdentity))
              .then(() => {
                // console.log('userIdentity set');
                const validationResult = validateCountry(userIdentity.country);
                // console.log('validationResult', validationResult);
                if (validationResult) {
                  loadProfileData(loggedInUser);
                } else {
                  setIsNotAllowedCountry(true);
                  ApplicationStorage.removeAll();
                }
              })
              .catch(() => {
                // console.log('error2', err);
                setLoadingOn(false);
              });
          })
          .catch(() => {
            // console.log('ex', ex);
            setLoadingOn(false);
          });
      })
      .catch(() => {
        // console.log('Error: ', ex);
        setLoadingOn(false);
        ApplicationStorage.removeAll()
          .then(() => {
            setLoadingOn(false);
          })
          .catch(() => {
            // console.log("error!!", error);
          });
      });
  } else {
    // console.log('inside else');
    ApplicationStorage.getItem('code').then((data) => {
      // console.log('code else', data);
      let keys = [];
      try {
        keys = Object.keys(data);
      } catch (error) {
        // console.log('Error occurred');
      }
      // console.log("keys", keys);
      setLoadingOn(true);
      if (data && data !== null && data !== 'null' && keys.length) {
        getUserIdentity().then((userIdentity) => {
          // console.log('userIdentity else', userIdentity);
          uIdentity = userIdentity;
          new DatadogMonitoring().setUser(userIdentity.email_id);
          // loadProfileData(loggedInUser);
          const validationResult = validateCountry(userIdentity.country);
          if (validationResult) {
            loadProfileData(loggedInUser);
          } else {
            setIsNotAllowedCountry(true);
            ApplicationStorage.removeAll();
          }
        });
      } else {
        // console.log("keys", keys);
        setLoadingOn(false);
      }
    });
  }

  const loadProfileData = (loggedInUser) => {
    // console.log('uIdentity', uIdentity);
    getProfileData(request, uIdentity)
      .then((profileData) => {
        // console.log('profileData', profileData);
        if (profileData && profileData?.consent && profileData?.consent.is_consent_agree) {
          // console.log("consent present");
          // getPendingRecords(props, uIdentity)
          //   .then((pendingSurveys) => {
          //     // console.log("pendingSurveys util", pendingSurveys);
          //     if (Object.values(pendingSurveys).length) {
          //       // console.log("if pendingSurveys", pendingSurveys.length);
          //       props.navigation.navigate('Surveys', { surveys: pendingSurveys });
          //     } else {
          //       // console.log("else pendingSurveys", pendingSurveys.length);
          //       props.navigation.navigate('Home', { screen: 'Profile' });
          //     }
          //   })
          //   .catch(() => {
          //     // console.log("error1", err)
          //     setLoadingOn(false);
          //   });
          props.navigation.navigate('Home', { screen: 'Profile' });
        } else {
          // console.log("consent not present");
          props.navigation.navigate('Welcome');
        }
      })
      .catch(() => {
        // console.log('catch error', err);
        if (loggedInUser) {
          // console.log('inside if loggedInUser', loggedInUser);
          ApplicationStorage.setItem('userIdentity', JSON.stringify(uIdentity))
            .then(() => {
              props.navigation.navigate('Welcome'); // When user logs in for first time profile data is not present
            })
            .catch(() => {
              setLoadingOn(false);
            });
        } else {
          // console.log('inside else loggedInUser', loggedInUser);
          setLoadingOn(false);
        }
      });
  };
}
