import ApplicationStorage from './ApplicationStorage';


export const getUserIdentity = () => {
    return new Promise((resolve) => {
        ApplicationStorage.getItem('userIdentity').then((uIdentity) => {
            // console.log("uIdentity utils", uIdentity);
            let userIdentity = uIdentity ? JSON.parse(uIdentity) : {};
            // console.log("userIdentity utils 2", userIdentity);
            resolve(userIdentity);
        }).catch(() => {
            // console.log("ex", ex);
            // reject(ex);
            resolve(null);
        });
    });
};

