import { Image, ScrollView, View } from 'react-native';
import React, { memo } from 'react';

import AvatarElement from '../elements/Avatar';
import { Chip } from 'react-native-paper';
import TextElement from '../elements/Text';
import ViewWithBack from '../elements/ViewWithBack';
import style from './Style';

const Header = (props) => {
    return (
        <View>
            <TextElement style={props.header.style ? props.header.style : style.header}>{props.header.value}</TextElement>
            {props.children}
        </View>
    );
};

const Tag = (props) => {
    // console.log("tags props", props);
    return (
        <View style={style.tagContainer}>
            {
                props.tags.length ?
                    props.tags.map((tag, index) => {
                        // console.log("tag", tag);
                        // console.log("props.tags.length", props.tags.length);
                        if (tag == '' && props.tags.length == 1) {
                            // console.log("if", tag, props.tags.length)
                            return (
                                <View key={index}>
                                    <TextElement style={style.tagText}>No data available</TextElement>
                                </View>
                            );
                        }
                        else if (tag != '') {
                            // console.log("else", tag, props.tags.length)

                            return (
                                <Chip icon="check" key={index} style={style.tagStyle} >{tag} </Chip>
                            );
                        }
                    })
                    : <View>
                        <TextElement style={style.tagText}>No data available</TextElement>
                    </View>
            }

        </View>

    );
};

const List = (props) => {
    return (
        props.list.map((listItem, index) => {
            return (
                <View style={style.listContainer} key={index}>
                    <AvatarElement path={listItem.icon} size={60} />
                    <View>
                        <TextElement style={style.listTitle}>{listItem.title}</TextElement>
                        <TextElement style={style.listDescription}>{listItem.description}</TextElement>
                    </View>
                </View>

            );
        })
    );
};

const Text = (props) => {
    return (
        <View style={style.textContainer}>
            <TextElement style={props.text.style ? props.text.style : style.headerData}>{props.text.value}</TextElement>
        </View>
    );
};

const Paragraph = (props) => {
    return (
        <View style={style.paragraphContainer}>
            <TextElement style={props.paragraph.style ? props.paragraph.style : style.paragraph}>{props.paragraph.value}</TextElement>
        </View>
    );
};

const ProfileImage = (props) => {
    return (
        <View style={style.profileImgContainer}>
            <AvatarElement style={props.profileImage.style ? props.profileImage.style : style.profileImage} path={props.profileImage.icon} size={60}></AvatarElement>
        </View>
    );
};

const ImageElement = (props) => {

    return (
        <View>
            <Image style={props.image.style ? props.image.style : style.image} source={props.image.icon} />
        </View>
    );
};
const Details = (parentProps) => {
    const props = { ...parentProps, ...parentProps.route.params };
    // console.log("Details props", props);
    const { pageName, backAction, payload } = props.route.params;
    // let payload = props.route.params.payload;

    return (
        <ViewWithBack pageName={pageName} {...props} backAction={() => { props.navigation.navigate(backAction); }} >

            <ScrollView
                showsVerticalScrollIndicator={false}
                showsHorizontalScrollIndicator={false}>
                {payload.map((element, index) => {
                    // console.log("index", index);
                    // console.log("element", element);
                    return (
                        <View key={index} >
                            {
                                element.type == 'header' ?
                                    element.data.component == 'text' ?
                                        <Header header={element.header}>
                                            <Text text={element.data.text} />
                                        </Header>
                                        :
                                        element.data.component == 'tag' ?
                                            <Header header={element.header}>
                                                <Tag tags={element.data.tag.value} />
                                            </Header>
                                            :
                                            element.data.component == 'list' ?
                                                <Header header={element.header}>
                                                    <List list={element.data.list} />
                                                </Header>
                                                :
                                                <View></View>
                                    :
                                    element.type == 'paragraph' ?
                                        <Paragraph paragraph={element.paragraph} />
                                        : element.type == 'tag' ?
                                            <Tag tags={element.tag.value} />
                                            : element.type == 'list' ?
                                                <List list={element.list} />
                                                : element.type == 'profileImage' ?
                                                    <ProfileImage profileImage={element.profileImage} />
                                                    : element.type == 'image' ?
                                                        <ImageElement image={element.image} />
                                                        : <View></View>
                            }
                        </View>
                    );
                })}
            </ScrollView>
        </ViewWithBack>

    );
};
export default memo(Details, (prevProps, nextProps) => {
    if (prevProps.theme !== nextProps.theme)
        return false;
    else
        return true;
});
