import * as React from 'react';

import { Dimensions, ScrollView, View } from 'react-native';
import { every, max } from 'lodash';
import { memo, useEffect, useState } from 'react';

import AddDefinitionModal from '../createGoal/AddDefinitionModal';
import Alert from '../../elements/Alert';
import DividerElement from '../../elements/Divider';
import Fab from '../../elements/Fab';
import GoalDetails from './GoalDetails';
import GoalFooter from './GoalFooter';
// import GoalHeader from './GoalHeader';
import GoalsDefinition from './GoalsDefinitions';
import GoalsService from '../../../service/GoalsService';
import { Text } from 'react-native-paper';
import UpdateDefinitionModal from '../updateGoal/UpdateDefinitionModal';
import ViewDefinitionModal from './ViewDefinitionModal';
import ViewWithBack from '../../elements/ViewWithBack';
import { getUserIdentity } from '../../../utils/UserIdentity';
import style from './styles/viewGoalStyle';
import { sumBy } from 'lodash';
import { useIsFocused } from '@react-navigation/native';

const { height } = Dimensions.get('window');
let totalWeight = 0;
let userIdentity = null;

const ViewGoals = (parentProps) => {
  // console.log('parentProps', parentProps);
  const props = { ...parentProps, ...parentProps?.route?.params };
  // console.log('props view goals', props);
  const goalsService = new GoalsService(props);
  const isFocused = useIsFocused();
  const goal_name = props?.route?.params ? props?.route?.params?.goalName : '';
  // console.log('goal_name', goal_name);
  const { isMentor } = props;

  // const [isFocus, setIsFocus] = useState(false);

  const [goal, setGoal] = useState('');
  const [currentGoalIndex, setCurrentGoalIndex] = useState(0);
  const [goalsDefinition, setGoalsDefinition] = useState([]);
  const [editFlag, setEditFlag] = useState(false);
  const [expectedCompletionDate, setExpectedCompletionDate] = useState('');
  const [isUpdated, setIsUpdated] = useState(false);

  // console.log('goalsDefinition in View goals', goalsDefinition);
  // Time Picker
  const [visible, setVisible] = React.useState(false);

  // Definition Modals
  const [showViewDefinitionModal, setShowViewDefinitionModal] = useState(false);
  const closeViewDefinitionModal = () => setShowViewDefinitionModal(false);

  const [showUpdateDefinitionModal, setShowUpdateDefinitionModal] = useState(false);
  const closeUpdateDefinitionModal = () => setShowUpdateDefinitionModal(false);

  const [showAddDefinitionModal, setShowAddDefinitionModal] = useState(false);
  const closeAddDefinitionModal = () => setShowAddDefinitionModal(false);

  const handleShowGoalDfn = (index) => {
    setShowViewDefinitionModal(true);
    setCurrentGoalIndex(index);
  };

  const handleUpdateGoalDfn = (index) => {
    setShowUpdateDefinitionModal(true);
    setCurrentGoalIndex(index);
  };

  // Function to track the changes made which are unsaved to give alert in backAction.
  const handleUpdate = () => {
    setIsUpdated(true);
    // console.log("isUpdated", isUpdated, "functionName", functionName);
  };

  const handleServiceRemove = (index) => {
    if (editFlag == true) {
      Alert.confirm('Confirm', 'Deleted Learning Theme will not be reverted', () => {
        handleUpdate('handleServiceRemove');
        const list = [...goalsDefinition];
        list.splice(index, 1);
        setGoalsDefinition(list);

        if (index != 0) {
          setCurrentGoalIndex(parseInt(index - 1));
        }
      });
    }
  };

  const handleServiceAddNew = (item) => {
    handleUpdate('handleServiceAddNew');
    const weightage = Number(item.weightage_in_percentage || 0);

    if (item.definition && weightage && !isNaN(weightage)) {
      let totalDefinitions = [...goalsDefinition, item];
      const totalWeight = sumBy(totalDefinitions, (o) => Number(o.weightage_in_percentage || 0));

      if (totalWeight <= 100) {
        setGoalsDefinition(totalDefinitions);
        closeAddDefinitionModal();
        return true;
      } else onGoalPercentageExceed();
    } else if (!item.definition) Alert.alert('Required', 'Missing objective');
    else if (isNaN(weightage) || weightage > 100) Alert.alert('Required', 'Invalid Weightage');
    else if (!item.weightage_in_percentage) Alert.alert('Required', 'Missing Weightage');

    return false;
  };

  const handleServiceUpdateDef = (item, index) => {
    // console.log('Inside handleServiceUpdateDef');
    const weightage = Number(item.weightage_in_percentage || 0);

    if (item.definition && item.weightage_in_percentage && !isNaN(weightage)) {
      handleUpdate('handleServiceUpdateDef');
      let tempDoalDefinitions = [...goalsDefinition];
      tempDoalDefinitions[index] = item;
      const totalWeight = sumBy(tempDoalDefinitions, (o) => Number(o.weightage_in_percentage || 0));

      if (totalWeight <= 100) {
        setGoalsDefinition(tempDoalDefinitions);
        closeUpdateDefinitionModal();
        return true;
      } else onGoalPercentageExceed();
    } else if (!item.definition) Alert.alert('Required', 'Missing objective');
    else if (isNaN(weightage) || weightage > 100) Alert.alert('Required', 'Invalid Weightage');
    else if (!item.weightage_in_percentage) Alert.alert('Required', 'Missing Weightage');

    return false;
  };

  const handleGoalEdit = () => {
    // console.log("goal", goal)
    // if (goal.status === 'created' && goal.expected_completion_date) {
    //   Alert.alert('Whoops', 'Please start the goal first');
    // } else {
    setEditFlag(true);
    // }
  };

  useEffect(() => {
    flush();

    const fetchData = async () => {
      userIdentity = await getUserIdentity();
      if (isMentor) {
        setGoal(props.goal);
        setGoalsDefinition(props.goal.definitions);
      } else {
        const response = await goalsService.viewGoal(userIdentity.email_id, goal_name);
        // console.log('viewgoal response', response);
        setGoal(response);
        setGoalsDefinition(response.definitions);
      }
    };
    if (isFocused) {
      fetchData();
    }
  }, [isFocused]);

  const handleStatusChange = (event) => {
    // console.log('Inisde handleStatusChange', event);
    // console.log('goal.status', goal.status, 'event', event);
    handleUpdate('handleStatusChange');
    if (event === '') {
      goal.status = goal.status;
    } else if (goal.status === 'inprogress' && event === 'completed') {
      completeGoalDefinitions();
      goal.status = event;
    } else {
      goal.status = event;
    }
  };

  const handleChangeTest = (event, field) => {
    // console.log('handleChangeTest', event, field);
    handleUpdate('handleChangeTest');
    goal[field] = event;
  };

  const handleAddNewDefinition = () => {
    handleUpdate('handleAddNewDefinition');
    // console.log('inside handleAddNewDefinition');
    totalWeight = 0;
    goalsDefinition.map((singleGoal) => {
      totalWeight = parseInt(totalWeight) + parseInt(singleGoal.weightage_in_percentage);
    });
    if (totalWeight < 100) {
      setShowAddDefinitionModal(true);
    } else {
      Alert.alert('Whoops', 'Total Goal weightage should not exceed 100%');
    }
  };

  const startGoal = async () => {
    userIdentity = await getUserIdentity();
    let goalDetails = {};

    if (!goal.expected_completion_date) {
      Alert.alert('Missing field', 'Please provide expected completion date');
    } else {
      updateGoalDefinitonTimeline();

      goalDetails = {
        email_id: userIdentity.email_id,
        goal_name: goal.goal_name,
        expected_completion_date: goal.expected_completion_date,
        definitions: goal.definitions,
      };
      // console.log('goalDetails', goalDetails, 'goal',goal);

      try {
        await goalsService.startGoal(goalDetails);
        Alert.alert('Success', 'Goal started');
        props.navigation.navigate('Home', { screen: 'Goals' });
      } catch (error) {
        // console.log('Exception occurred', error);
      }
    }
  };

  const updateGoalDefinitonTimeline = () => {
    goal.definitions.forEach((definition) => {
      if (!definition.timeline) {
        definition.timeline = goal.expected_completion_date;
      }
    });
  };

  const completeGoalDefinitions = () => {
    goal.definitions.forEach((definition) => {
      definition.status = 'completed';
    });
  };

  const updateCompletionDateIfNeeded = () => {
    // console.log('goal in updateCompletionDateIfNeeded', goal);

    if (goal.expected_completion_date) {
      // console.log('Expected completion date is already present.');
      return;
    }

    // Check if all timelines are present
    const allTimelinesPresent = every(
      goalsDefinition,
      (item) => item.timeline !== '' || item.timeline !== undefined
    );
    if (!allTimelinesPresent) {
      // console.log('Inside all timelines not present');
      return;
    }

    // console.log('Inside all timelines present');
    const timelines = goalsDefinition
      .filter((item) => item.timeline !== '' || item.timeline !== undefined)
      .map((item) => item.timeline);

    // Find the highest timeline
    const highestTimeline = timelines.length > 0 ? max(timelines) : null;
    if (highestTimeline) {
      goal.expected_completion_date = highestTimeline;
      setExpectedCompletionDate(highestTimeline);
      // goal.status = 'inprogress';
      // goal.commencement_date = min([moment().unix(), highestTimeline]);
    }
    // console.log('goal after', goal);
  };

  const updateGoal = async () => {
    // console.log("inside updategoal")
    userIdentity = await getUserIdentity();
    let pattern = /.+@travelex\.com/;

    let goalsDefinitionList = [...goalsDefinition];

    totalWeight = 0;
    goalsDefinitionList.map((singleGoal) => {
      totalWeight = parseInt(totalWeight) + parseInt(singleGoal.weightage_in_percentage);
    });

    if (pattern.test(goal.mentor_email_id) === true) {
      if (totalWeight != 100) {
        Alert.alert('Whoops', 'Total Goal weightage should be 100%');
      } else {
        try {
          if (!isMentor) {
            updateCompletionDateIfNeeded();
          }
          const updatedGoal = {
            email_id: goal.email_id,
            goal_name: goal.goal_name,
            mentor_email_id: goal.mentor_email_id,
            remarks: goal.remarks,
            definitions: goalsDefinition,
            status: goal.status,
            commencement_date: goal.commencement_date,
            expected_completion_date: goal.expected_completion_date,
            created_at: goal.created_at,
            created_by: goal.created_by,
            updated_by: userIdentity.email_id,
          };
          // console.log('updatedGoal', updatedGoal);
          await goalsService.updateGoal(updatedGoal, isMentor);
          onSuccess();
        } catch (error) {
          // console.log('Error occurred', error);
        }
      }
    } else {
      Alert.alert('Bad input', 'Please provide valid mentor email id');
    }
  };

  const onSuccess = () => {
    Alert.alert('Success', 'Goal updated');
    props.navigation.navigate('Home', { screen: 'Goals' });
  };

  const flush = () => {
    setGoal('');
    setGoalsDefinition([]);
    setEditFlag(false);
    setExpectedCompletionDate('');
    totalWeight = 0;
    setIsUpdated(false);
  };

  let weightageList = [];

  goalsDefinition.map((goalDefinition) => {
    weightageList.push(goalDefinition.weightage_in_percentage);
  });

  // console.log('definition', definition);

  const handleBackAction = () => {
    if (expectedCompletionDate || isUpdated) {
      Alert.confirm('Confirm', 'Are you sure you want to discard the changes?', () => {
        props.navigation.navigate('Home', { screen: 'Goals' });
      });
    } else if (editFlag) {
      setEditFlag(false);
    } else {
      props.navigation.navigate('Home', { screen: 'Goals' });
    }
  };

  // console.log('goalsDefinition', goalsDefinition);

  const maxTimelineFromDefinitions =
    goalsDefinition && goalsDefinition.length
      ? Math.max(...Object.values(goalsDefinition).map((item) => item.timeline || 0))
      : 0;

  // console.log('maxTimelineFromDefinitions', maxTimelineFromDefinitions);

  const minExpectedCompletionDate = goal
    ? Math.max(goal.expected_completion_date || 0, maxTimelineFromDefinitions)
    : '';
  // console.log('minExpectedCompletionDate', minExpectedCompletionDate);

  let goalView = (
    <View style={[style.container, { flex: 1 }]}>
      <View style={style.goalsContainer}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}>
          <View style={style.gap5}>
            {/* <DividerElement theme={props.theme} /> */}
            {/* <GoalHeader
                {...props}
                goal={goal}
                editFlag={editFlag}
                setIsFocus={setIsFocus}
                handleStatusChange={handleStatusChange}
                handleGoalEdit={handleGoalEdit}
                isFocus={isFocus}
                isMentor={isMentor}
              /> */}

            {goal ? (
              <GoalDetails
                {...props}
                goal={goal}
                editFlag={editFlag}
                handleChangeTest={handleChangeTest}
                setVisible={setVisible}
                visible={visible}
                setExpectedCompletionDate={setExpectedCompletionDate}
                minDate={minExpectedCompletionDate}
                isMentor={isMentor}
                handleStatusChange={handleStatusChange}
              />
            ) : (
              <Text></Text>
            )}

            <GoalsDefinition
              {...props}
              editFlag={editFlag}
              goalsDefinition={goalsDefinition}
              handleServiceRemove={handleServiceRemove}
              handleAddNewDefinition={handleAddNewDefinition}
              handleUpdateGoalDfn={handleUpdateGoalDfn}
              handleShowGoalDfn={handleShowGoalDfn}
            />
            {editFlag === true ? (
              <GoalFooter goal={goal} updateGoal={updateGoal} />
            ) : !isMentor ? (
              <GoalFooter goal={goal} startGoal={startGoal} />
            ) : (
              <View />
            )}
          </View>
          <DividerElement height={30} />
        </ScrollView>
      </View>
      {['inprogress', 'created'].includes(goal.status) && !editFlag && !showViewDefinitionModal ? (
        <Fab testID='edit-goal' icon='pencil' onPress={handleGoalEdit} />
      ) : (
        <View />
      )}
    </View>
  );

  const addModal = (
    <AddDefinitionModal
      handleClose={closeAddDefinitionModal}
      addGoalDefinition={handleServiceAddNew}
      pageName='Add Theme'
      expectedCompletionDate={goal.expected_completion_date}
      isMentor={isMentor}
    />
  );
  const updateModal = (
    <UpdateDefinitionModal
      handleClose={closeUpdateDefinitionModal}
      goalDefinition={goalsDefinition[currentGoalIndex]}
      currentGoalIndex={currentGoalIndex}
      pageName='Update Theme'
      handleServiceUpdateDef={handleServiceUpdateDef}
      expectedCompletionDate={goal.expected_completion_date}
      isMentor={isMentor}
      goalStatus={goal.status}
    />
  );
  const viewModal = (
    <ViewDefinitionModal
      handleClose={closeViewDefinitionModal}
      goalDefinition={goalsDefinition[currentGoalIndex]}
      pageName='View Theme'
    />
  );
  let children = null;

  if (showAddDefinitionModal) {
    children = addModal;
  } else if (showViewDefinitionModal) {
    children = viewModal;
  } else if (showUpdateDefinitionModal) {
    children = updateModal;
  } else {
    children = goalView;
  }

  return (
    <ViewWithBack pageName={'Goal Details'} {...props} backAction={handleBackAction}>
      {children}
    </ViewWithBack>
  );
};

const onGoalPercentageExceed = () => {
  Alert.alert('Whoops', 'Total goal definition weight must be 100%');
};

export default memo(ViewGoals, (prevProps, nextProps) => {
  if (
    prevProps.route.params &&
    nextProps.route.params &&
    prevProps.route.params.goalName !== nextProps.route.params.goalName
  )
    return false;
  else if (prevProps.theme !== nextProps.theme) return false;
  else return true;
});
