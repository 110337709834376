import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    moduleHeading: {
        color: '#f5f5f5',
        fontWeight: 'bold',
        fontSize: 18,
        padding: 5
    },
    container: { height: '100%', justifyContent: 'center' },
    headerContainer: {
        borderWidth: 0.5,
        borderColor: '#fff',
        padding: 10,
    },
    headerText: {
        fontWeight: 'bold',
        color: '#fff',
        fontSize: 16,
    },
    createdByText: {
        color: '#fff',
        lineHeight: 20,
        fontSize: 13,
    },
    modulesContainer: {
        padding: 5,
    },
    divider: { height: 10 },
    bottomSpace: { height: 70 },
    spaceContainer: { padding: 5 },
    spaceName: { flex: 4 }
});

