import { ScrollView, StyleSheet, View } from 'react-native';
import { useEffect, useState } from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';

import { Avatar } from 'react-native-paper';
import Elements from '../elements';
import SurveyService from '../../service/SurveyService';

const moment = require('moment');


const SurveyDetails = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const { email_id, survey_date, assigned_date, mentor_email_id } = route.params;

  return (
    <Elements.ViewWithBack pageName='Feedback Details' navigation={navigation}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <View style={style.main}>
          <ItemView label='Mentee Email' value={email_id} />
          <ItemView
            label='Session Date'
            value={survey_date ? moment.unix(survey_date).format('YYYY/MM/DD') : ''}
          />
          <ItemView label='Assigned Date' value={assigned_date?moment.unix(assigned_date).format('YYYY/MM/DD') : ''} />
          <ItemView label='Mentor Email' value={mentor_email_id} />
          <Response />
        </View>
        <View style={{ height: 30 }} />
      </ScrollView>
    </Elements.ViewWithBack>
  );
};

const Response = () => {
  const navigation = useNavigation();
  const [userResponse, setUserResponse] = useState([]);
  const [isApiDataLoaded, setIsApiDataLoaded] = useState(false);

  const route = useRoute();
  const { email_id, survey_ref, response_uuid } = route.params;

  const surveyService = new SurveyService({ navigation });

  useEffect(() => {
    const responseId = survey_ref + '#' + response_uuid;
    surveyService.getSurveyResponse(false, email_id, responseId).then((response) => {
      setUserResponse(response?.response || []);
      setIsApiDataLoaded(true);
    });
  }, []);

  return userResponse.map((item, index) => {
    switch (item.question_type) {
      case 'free_text':
        return (
          <ItemView
            key={`${item.question_type} ${index}`}
            label={item.question_content}
            value={item.userAnswer}
          />
        );
      case 'rating':
        return (
          <Ratings
            key={`${item.question_type} ${index}`}
            maxValue={item.range.to}
            label={item.question_content}
            value={item.userAnswer}
          />
        );
      case 'single_choice':
        return (
          <ItemView
            key={`${item.question_type} ${index}`}
            label={item.question_content}
            value={item.options[item.userAnswer]}
          />
        );
      default:
        return <View key={`${item.question_type} ${index}`} />;
    }
  });
};

const Ratings = ({ label, maxValue, value }) => {
  value = Number(value);
  maxValue = Number(maxValue);
  
  const Stars = Array(maxValue)
    .fill('')
    .map((d, index) =>
      index < value ? (
        <Avatar.Icon
          key={'star-' + index}
          size={32}
          color='#F6C042'
          icon='star'
          style={{ backgroundColor: 'transparent' }}
        />
      ) : (
        <Avatar.Icon
          key={'star-' + index}
          size={32}
          color='#121212'
          icon='star-outline'
          style={{ backgroundColor: 'transparent' }}
        />
      )
    );

  return (
    <View style={style.itemView}>
      <Elements.Text style={style.itemLabel}>{label} </Elements.Text>
      <View style={{ display: 'flex', flexDirection: 'row', gap: 2 }}>{Stars}</View>
    </View>
  );
};

const ItemView = ({ label, value }) => {
  return (
    <View style={style.itemView}>
      <Elements.Text style={style.itemLabel}>{label}</Elements.Text>
      <Elements.Text style={style.itemValue}>{value}</Elements.Text>
    </View>
  );
};

const style = StyleSheet.create({
  itemView: {
    backgroundColor: '#ffffff',
    display: 'flex',
    gap: 4,
    padding: 5,
    borderRadius: 1,
  },
  itemLabel: {
    fontSize: 14,
    color: '#808080',
  },
  main: {
    display: 'flex',
    gap: 5,
  },
});
export default SurveyDetails;
