import React, { useState } from 'react';

import { View, Text, TouchableOpacity, VirtualizedList } from 'react-native';
import style from './Style';
const Separator = () => (<View style={style.height2} />);


const CustomDropdown = (props) => {
    const [open, setIsOpen] = useState(false);

    const getItem = (dt, index) => dt[index];

    const getItemCount = () => props.data ? props.data.length : 0;

    const Item = (item) => {
        return (
            <TouchableOpacity style={style.itemView}
                onPress={() => {
                    props.setValue(item.value);
                    setIsOpen(false);
                }}
            >
                <Text style={{ color: item.value == props.value ? 'green' : '#212644' }}>{item.label}</Text>
            </TouchableOpacity>
        );
    };


    return (
        <View style={{ flex: 1 }}>

            <Text>{props.label || 'Select'}</Text>
            <TouchableOpacity
                onPress={() => setIsOpen(!open)}
                style={style.mainButton}>
                <View>
                    <Text>{(props.value || 'Select').toUpperCase()}</Text>
                </View>
            </TouchableOpacity>

            <View style={{ maxHeight: 300, height: open ? 130 : 0 }}>
                <VirtualizedList
                    initialNumToRender={4}
                    renderItem={({ item }) => <Item {...item} />}
                    keyExtractor={(item) => item.value + '' + Math.random()}
                    getItemCount={getItemCount}
                    getItem={getItem}
                    data={props.data || []}
                    ItemSeparatorComponent={() => <Separator />}
                />
            </View>

        </View>
    );
};

export default CustomDropdown;