import * as UID from 'react-native-uuid';

import { KeyboardAvoidingView, Platform, ScrollView, View } from 'react-native';
import { memo, useState } from 'react';

import Alert from '../../elements/Alert';
import { Avatar } from 'react-native-paper';
import { DatePickerModal } from 'react-native-paper-dates';
import Editor from '../../elements/Editor';
import Elements from '../../elements';
import NewDropdown from '../../elements/NewDropdown';
import { Text } from 'react-native-paper';
import { definitionStatusList } from './Metadata';
import { every } from 'lodash';
import style from './styles/updateDefinitionModalStyle';

const moment = require('moment');

const uuid = UID.default.v4;

const UpdateDefinitionModal = (props) => {
  const {
    handleClose,
    goalDefinition,
    pageName,
    currentGoalIndex,
    handleServiceUpdateDef,
    expectedCompletionDate,
    isMentor,
    goalStatus,
  } = props;
  // console.log('isMentor in update dfn modal', isMentor);
  // console.log('goalDefinition in update theme', goalDefinition, 'expectedCompletionDate', expectedCompletionDate);

  const today = new Date();

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [definition, setDefinition] = useState(goalDefinition?.definition || '');
  const [weightage, setWeightage] = useState(goalDefinition?.weightage_in_percentage || null);
  const [timeline, setTimeline] = useState(goalDefinition?.timeline || moment().unix());
  const [subItems, setSubItems] = useState(goalDefinition?.sub_items || []);
  const [status, setStatus] = useState(goalDefinition?.status || 'created');

  const addSubItems = () => {
    // console.log('here in addSubItems');
    const valid = areDefinitionAndSubItemsValid();
    // console.log('valid', valid);
    if (valid) {
      let temp = [...subItems];
      // console.log('temp', temp);
      temp.push({
        id: uuid(),
        title: '',
        description: '',
      });
      setSubItems(temp);
    }
  };

  const areDefinitionAndSubItemsValid = () => {
    return validateDefinition() && validateSubItems();
  };

  const validateDefinition = () => {
    // console.log('definition', definition, 'weightage', weightage);

    if (!definition.trim()) {
      Alert.alert('Required', 'Missing Learning Theme');
      return false;
    } else if (weightage) {
      if (Number(weightage)) {
        if (weightage > 100 || weightage < 0) {
          Alert.alert('Bad input', 'Invalid Weightage');
          return false;
        } else {
          return true; //definition and weightage is valid and present
        }
      } else {
        Alert.alert('Bad input', 'Please provide a valid number for Weightage');
        return false;
      }
    } else if (!weightage) {
      Alert.alert('Required', 'Missing Weightage');
      return false;
    }
  };

  const validateSubItems = () => {
    const titlePresent = every(subItems, (item) => item.title.trim() !== '');
    if (!titlePresent) {
      Alert.alert('Required', 'Missing Action Title');
    }
    return titlePresent;
  };

  const definitionObject = {
    id: goalDefinition.id,
    definition,
    remarks: '',
    timeline,
    weightage_in_percentage: weightage,
    status,
    sub_items: subItems,
  };

  // console.log('definitionObject in update', definitionObject);

  // console.log('subItems', subItems);

  return (
    <View style={style.container}>

      <PageHeader pageName={pageName} handleClose={handleClose} />
      <ScrollView
        style={style.scrollView}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}>
        <KeyboardAvoidingView style={{ flex: 1 }} behavior={Platform.OS === 'android' ? undefined : 'padding'} keyboardVerticalOffset={250}>
          <View style={style.scrollView}>
            <Elements.TextInput
              style={style.textInput}
              editable={true}
              label={
                <Text>
                  Learning Theme
                  <Text style={style.mandatory}> *</Text>
                </Text>
              }
              value={definition}
              onChangeText={(txt) => {
                setDefinition(txt);
              }}
            />

            <Elements.TextInput
              style={style.textInput}
              editable={true}
              label={
                <Text>
                  Weightage (%)
                  <Text style={style.mandatory}> *</Text>
                </Text>
              }
              keyboardType='numeric'
              value={weightage}
              onChangeText={(num) => {
                setWeightage(num);
              }}
            />

            <Elements.Touchable
              onPress={() => {
                if (!isMentor) {
                  setShowDatePicker(true);
                }
              }}
              id='timeline'>
              <Elements.TextInput
                style={style.timelineTextInput}
                label={'Timeline'}
                placeholder={'Timeline'}
                value={moment.unix(timeline).format('YYYY/MM/DD')}
                editable={false}
              />
            </Elements.Touchable>

            <DatePickerModal
              locale='en'
              mode='single'
              label='Select timeline'
              visible={showDatePicker}
              onDismiss={() => setShowDatePicker(false)}
              validRange={{
                startDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
                endDate: expectedCompletionDate
                  ? new Date(moment.unix(expectedCompletionDate).format('YYYY/MM/DD'))
                  : undefined,
              }}
              onConfirm={(date) => {
                if (date.date) {
                  // console.log('date', date);
                  setShowDatePicker(false);
                  const timestamp = moment(date.date, 'YYYY-MM-DD HH:mm:ss').unix();
                  setTimeline(timestamp);
                } else {
                  setShowDatePicker(false);
                  Alert.alert('Bad input', 'Please select a valid date');
                }
              }}
            />

            <Status
              status={status}
              setStatus={setStatus}
              isMentor={isMentor}
              goalStatus={goalStatus}
            />

            <SubItemsHeader onPressHandler={addSubItems} />

            <SubItemsSection subItems={subItems} setSubItems={setSubItems} />

            <UpdateButton
              definitionObject={definitionObject}
              currentGoalIndex={currentGoalIndex}
              handleServiceUpdateDef={handleServiceUpdateDef}
              areDefinitionAndSubItemsValid={areDefinitionAndSubItemsValid}
            />
          </View>
        </KeyboardAvoidingView>
      </ScrollView>
    </View>
  );
};

const PageHeader = ({ pageName, handleClose }) => {
  return (
    <View style={style.pageHeader}>
      <Elements.Text style={style.pageName}>{pageName}</Elements.Text>
      <Elements.Touchable onPress={handleClose} id='close'>
        <Avatar.Icon icon={'close'} size={35} color='#ffffff' style={style.icon} />
      </Elements.Touchable>
    </View>
  );
};

const SubItemsSection = ({ subItems, setSubItems }) => {
  if (subItems?.length) {
    const [expandPanel, setExpandPanel] = useState(new Array(subItems.length).fill(false));

    const handlePanelToggle = (index) => {
      const newExpandedPanel = [...expandPanel];
      newExpandedPanel[index] = !newExpandedPanel[index];
      setExpandPanel(newExpandedPanel);
    };

    const removeActionItem = (index) => {
      Alert.confirm('Confirm', 'Deleted Action Item will not be reverted', () => {
        let temp = [...subItems];
        temp.splice(index, 1);
        setSubItems(temp);
      });
    };

    return (
      <View style={style.narrowSection}>
        {subItems.map((subItem, index) => (
          <View key={index} style={style.fullWidth}>
            <View style={style.subItemsTitleView}>
              <Elements.TextInput
                style={{ ...style.flex5, ...style.textInput }}
                editable={true}
                label={
                  <Text>
                    Title
                    <Text style={style.mandatory}> *</Text>
                  </Text>
                }
                value={subItem.title}
                onChangeText={(txt) => {
                  let temp = [...subItems];
                  temp[index].title = txt;
                  setSubItems(temp);
                }}
              />

              <View>
                <Elements.Touchable
                  style={style.panelTouchable}
                  onPress={() => removeActionItem(index)}
                  id={`panel-delete-${index}`}>
                  <Avatar.Icon icon={'trash-can'} size={35} color='#282828' style={style.icon} />
                </Elements.Touchable>

                <Elements.Touchable
                  style={style.panelTouchable}
                  onPress={() => handlePanelToggle(index)}
                  id={`panel-description-${index}`}>
                  <Avatar.Icon
                    icon={expandPanel[index] ? 'chevron-up' : 'chevron-down'}
                    size={35}
                    color='#282828'
                    style={style.icon}
                  />
                </Elements.Touchable>
              </View>
            </View>

            <View>
              {expandPanel[index] && (
                <View style={style.panelDescriptionContainer}>
                  <View style={style.panelDescription}>
                    <Editor
                      setValue={(txt) => {
                        let temp = [...subItems];
                        temp[index].description = txt;
                        setSubItems(temp);
                      }}
                      value={subItem.description}
                    />
                  </View>
                </View>
              )}
            </View>

            {/* Apply margin to the next title when the current panel is expanded */}
            {expandPanel[index] && index < subItems.length - 1 && (
              <View style={style.marginTop5}></View>
            )}
          </View>
        ))}
      </View>
    );
  } else return <View />;
};

const Status = ({ status, setStatus, isMentor, goalStatus }) => {
  return (
    <View>
      <View style={style.statusText}>
        <Elements.Text>Status</Elements.Text>
        {!isMentor && goalStatus === 'created' ? (
          <Elements.Touchable
            id='information'
            onPress={() => {
              Alert.alert('Info', 'Please start the goal first, to edit the status');
            }}>
            <Avatar.Icon size={25} icon='information' style={style.icon} color='#212644' />
          </Elements.Touchable>
        ) : (
          <View />
        )}
      </View>
      <View style={isMentor || goalStatus === 'created' ? style.disabledDropdownContainer : {}}>
        <NewDropdown data={definitionStatusList} value={status} setValue={setStatus} />
      </View>
    </View>
  );
};

const SubItemsHeader = ({ onPressHandler }) => {
  return (
    <View style={style.rowContainer}>
      <View style={style.actionItemsHeaderView}>
        <Elements.Text style={style.actionItemsHeader}>Action Items</Elements.Text>
      </View>
      <Elements.Button
        testId='add-sub-items'
        icon='plus'
        color='#0096FF'
        text='Action'
        style={style.addSubItems}
        onPress={onPressHandler}
      />
    </View>
  );
};

const UpdateButton = ({
  definitionObject,
  currentGoalIndex,
  handleServiceUpdateDef,
  areDefinitionAndSubItemsValid,
}) => {
  return (
    <View style={style.updateGoalContainer}>
      <Elements.Button
        testId='update-goal'
        mode='contained'
        style={style.updateGoal}
        onPress={() => {
          const result = areDefinitionAndSubItemsValid();
          if (result) {
            handleServiceUpdateDef(definitionObject, currentGoalIndex);
          }
        }}>
        <Elements.Text style={style.buttonText}>Update Theme</Elements.Text>
      </Elements.Button>
    </View>
  );
};

export default memo(UpdateDefinitionModal, (prevProps, nextProps) => {
  if (prevProps.theme !== nextProps.theme) return false;
  else return true;
});
