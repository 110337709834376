import Alert from '../components/elements/Alert';
import Constants from '../utils/Constants';
import Request from '../dal/RequestNew';
import Store from '../Store/Store';
import { loaderReducer } from '../components/elements/customLoader/customLoaderSlice';

class JourneyService {
    constructor(props) {
        this.props = props;
    }

    async fetchLearnerJourneys(isApiDataLoaded) {
        try {
            let url = Constants.getConfig('ALL_JOURNEYS_LEARNER');
            // console.log("url", url)
            if (!isApiDataLoaded)
                Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
            const request = new Request(this.props);
            let response = await request.getNew(url);
            // console.log("fetchLearnerJourneys response ---- :: ", response);
            //  response = onLoadResponse;
            Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
            return response;

        } catch (exception) {
            // console.log("exception", exception);
            Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
            if ([500, 400].includes(exception.status)) {
                Alert.alert('Whoops',exception.error);  
            }
            return {};
        }
    }

    async fetchDesignerJourneys(isApiDataLoaded) {
        try {
            let url = Constants.getConfig('ALL_JOURNEYS_DESIGNER');
            // console.log("url", url);
            if (!isApiDataLoaded)
                Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
            const request = new Request(this.props);
            let response = await request.getNew(url);
            // console.log("fetchDesignerJourneys response ---- :: ", response);
            // console.log("fetchDesignerJourneys data ---- :: ", response.data);
            //  response = onLoadResponse;
            Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
            return response;

        } catch (exception) {
            // console.log("exception", exception);
            Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
            if ([500, 400].includes(exception.status)) {
                Alert.alert('Whoops',exception.error);  
            }
            return {};
        }
    }

    //unused
    async getJourney(journey, isApiDataLoaded) {
        try {
            // console.log("journey **", journey);
            let url = Constants.getConfig('GET_JOURNEY');
            url = url.replace(':journey_id', encodeURIComponent(journey.journey_id));
            // console.log("url", url);
            if (!isApiDataLoaded)
                Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
            const request = new Request(this.props);
            let response = await request.getNew(url);
            // console.log("getJourney response ---- :: ", response);
            Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
            return response;
        } catch (exception) {
            // console.log("exception", exception);
            Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
            if ([500, 400].includes(exception.status)) {
                Alert.alert('Whoops',exception.error);  
            }
            // throw exception;
            return [];
        }
    }

    async searchJourney(searchField, isApiDataLoaded) {
        try {
            // console.log("before", searchField, ".");
            const searchWord = searchField ? searchField.trim() : '';
            // console.log("searchWord", searchWord);
            if (searchWord && searchWord != '') {

                let url = Constants.getConfig('SEARCH_JOURNEY_LEARNER');
                url = url.replace('${searchColumnName}', 'display_name');
                url = url.replace('${searchKeyword}', searchWord);
                // console.log("searchJourney url :: ", url);
                if (!isApiDataLoaded)
                    Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
                const request = new Request(this.props);
                let response = await request.getNew(url);
                // console.log("response", response)
                // response.enrolledJourneys = []; // To avoid duplicate search results
                Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
                return response;
            } else {
                // console.log("searchJourney else ---- :: ", searchWord);
                return {};
            }
        } catch (exception) {
            // console.log("exception", exception)
            Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
            if ([500, 400].includes(exception.status)) {
                Alert.alert('Whoops',exception.error);  
            }
            return {};
        }
    }

    async fetchJourneyTopics(journey, isApiDataLoaded) {
        try {
            let url = Constants.getConfig('GET_TOPICS');
            url = url.replace(':spaceId', journey.space_id);
            url = url.replace(':moduleId', journey.id.split('#')[0]);
            url = url.replace(':journeyId', journey.journey_id);
            // url = url.replace(':journey_id', encodeURIComponent(journey.journey_id));
            // console.log("url", url);
            if (!isApiDataLoaded)
                Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
            const request = new Request(this.props);
            let response = await request.getNew(url);
            // console.log("response", response);
            Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
            return response;

        } catch (exception) {
            // console.log("exception", exception)
            Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
            if ([500, 400].includes(exception.status)) {
                Alert.alert('Whoops',exception.error);  
            }
            return []; //topics is an array.
        }
    }

    async fetchAssessmentScore(topicID, journeyID, spaceID, moduleID, isApiDataLoaded) {
        try {
            // console.log("topicID", topicID)
            // console.log(" encodeURI(topicID)",  encodeURIComponent(topicID))
            let url = Constants.getConfig('GET_ASSESSMENT_SCORE');
            // url = url.replace('${emailID}', emailID);
            // url = url.replace('${topicID}', encodeURIComponent(topicID));
            url = url.replace(':topicid', topicID);
            url = url.replace(':spaceId', spaceID);
            url = url.replace(':moduleId', moduleID);
            url = url.replace(':journeyId', journeyID);
            // console.log("url", url);
            if (!isApiDataLoaded)
                Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));

            const request = new Request(this.props);
            let response = await request.getNew(url);
            // console.log("fetchAssessmentScore response", response);
            Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
            return response;

        } catch (exception) {
            // console.log("exception", exception)
            Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
            if ([500, 400].includes(exception.status)) {
                Alert.alert('Whoops',exception.error);  
            }
            return {};
        }
    }

    async saveAssessmentScore(assessmentData, topicID, isApiDataLoaded, journeyId, spaceId, moduleId) {
        try {
            let url = Constants.getConfig('SAVE_ASSESSMENT_SCORE');
            // url = url.replace('${emailID}', emailID);
            // console.log("url", url);
            // console.log("assessmentData", assessmentData);
            url = url.replace(':topicid', topicID);
            url = url.replace(':spaceId', spaceId);
            url = url.replace(':moduleId', moduleId);
            url = url.replace(':journeyId', journeyId);
            url = url.replace('#', '-');
            // console.log("called", url);

            if (!isApiDataLoaded)
                Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
            const request = new Request(this.props);
            let response = await request.postNew(url, assessmentData);
            // console.log("saveAssessmentScore response", response);
            Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
            return response;
        } catch (exception) {
            // console.log("exception", exception);
            Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
            if ([500, 400].includes(exception.status)) {
                Alert.alert('Whoops',exception.error);  
            }
            return {};
        }
    }

    async enrollJourney(journeyData, isApiDataLoaded, enrolledJourney) {
        try {
            // console.log('journeyData', journeyData);
            let url = Constants.getConfig('ENROLL_JOURNEY');
            url = url.replace(':spaceId', journeyData.space_id);
            url = url.replace(':moduleId', journeyData.id.split('#')[0]);
            url = url.replace(':journeyId', journeyData.journey_id);
            // console.log('url', url);
            if (!isApiDataLoaded)
                Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
            const request = new Request(this.props);
            let response = await request.postNew(url, enrolledJourney);
            // console.log("enrollJourney response", response);
            Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
            return response;
        } catch (exception) {
            // console.log("exception", exception);
            Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
            if ([500, 400].includes(exception.status)) {
                Alert.alert('Whoops',exception.error);  
            }
            return {};
        }
    }

    async updateEnrolledJourney(journeyData, isApiDataLoaded, enrolledJourney) {
        try {
            // console.log('updateEnrolledJourney', enrolledJourney);
            let url = Constants.getConfig('UPDATE_ENROLLED_JOURNEY');
            url = url.replace(':spaceId', journeyData.space_id);
            url = url.replace(':moduleId', journeyData.id.split('#')[0]);
            url = url.replace(':journeyId', journeyData.journey_id);
            // console.log('url', url);
            if (!isApiDataLoaded)
                Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
            const request = new Request(this.props);
            let response = await request.putNew(url, enrolledJourney);
            // console.log('updateEnrolledJourney response', response);
            Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
            response.message = 'Journey Updated';
            return response;
        } catch (exception) {
            // console.log("exception", exception);
            Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
            if ([500, 400, 409].includes(exception.status)) {
                Alert.alert('Whoops',exception.error);  
                throw exception;
            }
            return {};
        }
    }

    async completeJourney(journeyData, isApiDataLoaded, enrolledJourney) {
        try {
            // console.log('enrolledJourney', enrolledJourney);
            let url = Constants.getConfig('COMPLETE_JOURNEY');
            url = url.replace(':spaceId', journeyData.space_id);
            url = url.replace(':moduleId', journeyData.id.split('#')[0]);
            url = url.replace(':journeyId', journeyData.journey_id);
            // console.log('url', url);
            if (!isApiDataLoaded)
                Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
            const request = new Request(this.props);
            let response = await request.putNew(url, enrolledJourney);
            // console.log("completeJourney response", response);
            Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
            return response;
        } catch (exception) {
            // console.log("exception", exception);
            Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
            if ([500, 400, 409].includes(exception.status)) {
                Alert.alert('Whoops',exception.error);  
                throw exception;
            }
            return {};
        }
    }

    async disenrollJourney(journey, isApiDataLoaded) {
        try {
            let url = Constants.getConfig('DISENROLL_JOURNEY');
            url = url.replace(':spaceId', journey.space_id);
            url = url.replace(':moduleId', journey.id.split('#')[0]);
            url = url.replace(':journeyId', journey.journey_id);
            // url = url.replace('${journeyID}', encodeURIComponent(journeyId));
            // console.log("url", url)
            // console.log("journeyData", journeyData)

            if (!isApiDataLoaded)
                Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
            const request = new Request(this.props);
            let response = await request.deleteNew(url);
            // console.log("disenrollJourney response", response);
            Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
            return response;
        } catch (exception) {
            // console.log("exception", exception);
            Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
            if ([500, 400].includes(exception.status)) {
                Alert.alert('Whoops',exception.error);  
            }
            return {};
        }
    }

    async checkEnrollment(journey, isApiDataLoaded, email) {
        try {
            let url = email ? Constants.getConfig('CHECK_ENROLLMENT_JOURNEY_WITH_EMAIL'): Constants.getConfig('CHECK_ENROLLMENT_JOURNEY'); 
            url = url.replace(':spaceId', journey.space_id);
            url = url.replace(':moduleId', journey.id.split('#')[0]);
            url = url.replace(':journeyId', journey.journey_id);
            if (email){
                url = url.replace('${email_id}', email);
            }
            // console.log('url', url);
            // url = url.replace('${journeyID}', encodeURIComponent(journeyId));
            if (!isApiDataLoaded)
                Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
            const request = new Request(this.props);
            let response = await request.getNew(url);
            // console.log('response', response);
            Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
            return response;
        } catch (exception) {
            // console.log("exception", exception);
            Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
            if ([500, 400].includes(exception.status)) {
                Alert.alert('Whoops',exception.error);  
            }
            return {};
        }
    }

}
export default JourneyService;