import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  heading: {
    fontWeight: 'bold',
    paddingLeft: 10,
    fontSize: 15,
    margin: 5,
    justifyContent: 'center',
  },
  assessmentQuestionContainer: {
    backgroundColor: 'white',
    padding: 10,
    margin: 5,
  },
  submitButtonContainer: {
    alignItems: 'flex-end',
    padding: 10,
  },
  submitButton: {
    backgroundColor: '#0096FF',
  },
  submitBottomSpace: {
    height: 70,
  },
  bottomSpace: {
    height: 80,
  },
  retakeButtonContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  retakeButton: {
    backgroundColor: '#0096FF',
  },
  assessmentResultContainer: {
    backgroundColor: '#ffffff',
    padding: 10,
    margin: 10,
  },
  scoreText: {
    fontWeight: 'bold',
    fontSize: 15,
    paddingTop: 5,
  },
  resultOptionsContainer: {
    padding: 10,
    margin: 5
  }
});



