import { StyleSheet } from 'react-native';
import { Platform } from 'react-native';

export default StyleSheet.create({
  verticalCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  phalf: {
    flex: 0.5
  },
  pfour: {
    flex: 4
  },
  safeArea: { flex: 1, backgroundColor: '#212644' },
  icon: { backgroundColor: 'transparent' },
  flex1: { flex: 1 },
  flex4: { flex: 4 },
  buttonContainer: { flexDirection: 'row', width: '100%', position: 'absolute', bottom: 60 },
  page3ButtonContainer: { flexDirection: 'row', position: 'absolute', bottom: Platform.OS === 'ios' ? '10%' : 30, width: '100%' },
  skip: { fontWeight: 'bold', fontSize: 15 },
  skipButton: { alignItems: 'center', justifyContent: 'center', marginTop: 15 },
  pageContainer: { width: '100%', flex: 1, alignItems: 'center', justifyContent: 'center' },
  image: { borderRadius: 20, width: 200, height: 200 },
  profileImage: { borderRadius: 20, height: 200, width: 200 },
  divider: { height: 10 },
  textContainer: { alignItems: 'center', marginTop: 70, borderRadius: 10, padding: 5, margin: 5 },
  header: { fontSize: 20, fontWeight: 'bold' },
  textStyle: { fontSize: 14, padding: 10, margin: 1, letterSpacing: 1.2, textAlign: 'center' },
  arrow: { alignItems: 'center' },
  logo: { borderRadius: 20, width: 200, height: 80 },
  welcomeText: { fontWeight: 'bold', fontSize: 14, lineHeight: 20 },
  welcomeText2: { fontWeight: 'bold', fontSize: 13, lineHeight: 20 },
  page2TextContainer: { alignItems: 'center', borderRadius: 10, padding: 5, margin: 5, maxWidth: 600, marginTop: 50 },
  menu: { fontWeight: 'bold', fontSize: 18, color: '#ffffff' }

});