import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 10,
        backgroundColor: '#FAFAFA',
        marginBottom: 1,
        borderColor: '#dadada',
        borderBottomWidth: 1
    },
    icon: {
        backgroundColor: 'transparent'
    },
    created: {
        fontSize: 12,
        color: '#CDCBCB',
        fontWeight: 'bold'
    },
    title: {
        fontSize: 14,
        fontWeight: 'bold',
        marginTop: 10
    },
    viewRow: {
        backgroundColor: '#ffffff',
    },
    detailTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        marginTop: 10,
        marginBottom: 10

    },
    desc: {

    },
    created_at: {
        display: 'flex',
        flexDirection: 'row-reverse',
        width: '100%'
    }
});