import { ScrollView } from 'react-native';
const ScrollViewElement = (props) => {
  return (
    <ScrollView horizontal
      showsVerticalScrollIndicator={false}
      showsHorizontalScrollIndicator={false}
    >
      {props.children}
    </ScrollView>
  );
};

export default ScrollViewElement;