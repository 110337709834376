import React, { useEffect, useState } from 'react';
import { SURVEY_TYPE, USER_SURVEY_DEFAULT } from './Metadata';
import { StyleSheet, View } from 'react-native';
import { useIsFocused, useNavigation } from '@react-navigation/native';

import Elements from '../elements';
import NewDropdown from '../elements/NewDropdown';
import SurveyList from './SurveyList';
import SurveyService from '../../service/SurveyService';
import { filter } from 'lodash';
import { getName } from '../utils/utils';
import { getUserIdentity } from '../../utils/UserIdentity';

const UserSurvey = () => {
  const navigation = useNavigation();
  const [userEmails, setUserEmails] = useState([]);
  const [userEmail, setUserEmail] = useState(userEmails[0]);
  const [userData, setUserData] = useState({});
  const [userSurvey, setUserSurvey] = useState(USER_SURVEY_DEFAULT);
  const [isEmailDataLoaded, setIsEmailDataLoaded] = useState(false);
  const [isApiDataLoaded, setIsApiDataLoaded] = useState(false);

  const surveyService = new SurveyService({ navigation });
  const isFocused = useIsFocused();

  const fetchData = () => {
    getUserIdentity().then((uIdentity) => {
      if (uIdentity) {
        surveyService.getSurveyUserEmails(false).then((response) => {
          let filteredResponse = filter(
            response.items,
            (item) => item.email_id != uIdentity.email_id
          );

          // console.log('filteredResponse', filteredResponse);

          let result = {
            users: [],
          };

          filteredResponse.forEach((item) => {
            result[item.email_id] = item.connection_id;
            result.users.push(item.email_id);
          });

          setUserEmails(result.users);
          setUserData(result);
          setIsEmailDataLoaded(true);
        });
      }
    });
  };

  const surveyUserType = SURVEY_TYPE;

  const fetchUserSurvey = (email_id, connectionId) => {
    surveyService.getSurveys(false, email_id, surveyUserType, connectionId).then((response) => {
      setUserSurvey(Object.keys(response).length ? response : USER_SURVEY_DEFAULT);
      setIsApiDataLoaded(true);
    });
  };

  useEffect(() => {
    if (isFocused) {
      fetchData();
    }
  }, [isFocused]);

  return (
    <View style={style.container}>
      <View style={style.dropdownContainer}>
        <Elements.Text style={style.dropdownTitle}>Select</Elements.Text>
        <View style={style.dropdownView}>
          <NewDropdown
            disableCapitalize={true}
            value={userEmail ? getName(userEmail).fullName : 'User'}
            data={userEmails}
            setValue={(email) => {
              setUserEmail(email);
              fetchUserSurvey(email, userData[email]);
            }}
          />
        </View>
      </View>
      <SurveyList
        userSurvey={userSurvey}
        isApiDataLoaded={isApiDataLoaded}
        pageName={'user'}
        userSelected={userEmail ? true : false}
      />
    </View>
  );
};

const style = StyleSheet.create({
  container: { flex: 1, gap: 2, backgroundColor: '#f5f5f5' },
  dropdownTitle: { paddingLeft: 5, fontSize: 15, backgroundColor: '#ffffff', flex: 1 },
  dropdownContainer: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#ffffff',
    alignItems: 'center',
  },
  dropdownView: { flex: 5 },
});

export default UserSurvey;
