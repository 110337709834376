import { INITIAL_OFFSET, LIMIT, colors, statusList } from './Metadata';
import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import DesignerService from '../../../service/DesignerService';
import Elements from '../../elements';
import { List } from 'react-native-paper';
import { getName } from '../../../utils/NameMapping';
import { useIsFocused } from '@react-navigation/native';

const moment = require('moment');

// import { getName } from '../../utils/utils';

const AdminStats = (props) => {
  return <Elements.CustomModal {...props} modalView={<ModalView />} children={<MainScreen />} />;
};

const ModalView = (props) => {
  const [status, setStatus] = useState('');
  return (
    <View>
      <Elements.Text
        style={{ width: '100%', textAlign: 'center', fontSize: 18, fontWeight: 'bold' }}>
        Apply Filters
      </Elements.Text>
      <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Elements.CustomDropdown
          data={statusList}
          value={status}
          setValue={setStatus}
          label='Select Status'
        />
      </View>
      <View style={{ height: 10 }} />

      <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Elements.TextInput onChange={() => {}} label='Serach User by email' />
      </View>
      <View style={{ height: 10 }} />

      <View style={{ display: 'flex', flexDirection: 'row-reverse' }}>
        <Elements.Button
          onPress={() => {
            props.openClose();
          }}
          style={{ backgroundColor: '#212644' }}>
          Apply
        </Elements.Button>
        <View style={{ width: 10 }} />
        <Elements.Button
          onPress={() => {
            props.openClose();
          }}
          style={{ backgroundColor: '#212644' }}>
          Close
        </Elements.Button>
      </View>
    </View>
  );
};

const MainScreen = (props) => {
  const [page, setPage] = useState(0);
  const [isApiDataLoaded, setIsApiDataLoaded] = useState(false);
  const [showSeeMore, setShowSeeMore] = useState(false);
  const [data, setData] = useState([]);
  const isFocused = useIsFocused();

  const getData = async (pageNumber = 0) => {
    const designerService = new DesignerService(props);
    const params = {
      offset: pageNumber ? pageNumber * LIMIT : INITIAL_OFFSET,
      limit: LIMIT,
    };
    const response = await designerService.getAllUsersJourneys(params);
    const userJourneys = response.items;
    // console.log('userJourneys', userJourneys, page);
    // console.log('userJourneys', userJourneys);
    if (userJourneys.length) {
      handleSeeMore(userJourneys);
      setData([...data, ...userJourneys]);
    } else {
      handleSeeMore(userJourneys);
    }
    setIsApiDataLoaded(true);
  };

  const handleSeeMore = (data) => {
    if (data.length == 10) {
      setShowSeeMore(true);
    } else {
      setShowSeeMore(false);
    }
  };

  const Empty = () => {
    return isApiDataLoaded ? (
      <View style={[style.main, style.center]}>
        <Elements.Text>No Journey Statistics present!</Elements.Text>
      </View>
    ) : (
      <View></View>
    );
  };

  const Item = (item) => {
    return (
      <List.Item
        title={getName(item.email_id)}
        description={() => <Description {...item} />}
        style={{ backgroundColor: '#ffffff', margin: 5, borderRadius: 5 }}
        right={() => <Right {...item} />}
      />
    );
  };

  useEffect(() => {
    if (isFocused) {
      // console.log('inside useffect', isFocused);
      getData();
    } else {
      setData([]);
    }
  }, [isFocused]);

  return (
    <Elements.ViewWithBack
      pageName={'Journey Statistics'}
      {...props}
      backAction={() => {
        props.navigation.goBack();
      }}>
      {/* <View style={{ backgroundColor: 'white', display: 'flex', alignItems: 'flex-end' }}>
        <Elements.Button
          theme='LIGHT'
          style={{ width: 30 }}
          icon='filter-menu'
          onPress={() => {
            props.openClose();
          }}
          color='#ffffff'
          disabled={true}
        />
      </View> */}
      <Elements.CustomVList
        data={data}
        item={({ item }) => <Item {...item} />}
        empty={Empty}
        separator={() => <View />}
      />
      <View style={style.buttonRow}>
        {showSeeMore ? (
          <Elements.Touchable
            id='see-more'
            onPress={() => {
              setPage(page + 1);
              getData(page + 1);
            }}
            style={{ backgroundColor: 'transparent' }}>
            <Elements.Text
              style={{ color: '#ffffff', fontWeight: 'bold', marginBottom: 5, fontSize: 18 }}>
              See more
            </Elements.Text>
          </Elements.Touchable>
        ) : isApiDataLoaded && page ? (
          <View style={[style.main, style.center]}>
            <Elements.Text style={{ color: '#212644' }}>
              No more Journey Statistics present!
            </Elements.Text>
          </View>
        ) : (
          <View></View>
        )}
      </View>
    </Elements.ViewWithBack>
  );
};

const Right = (item) => {
  return (
    <View style={style.center}>
      <Elements.Text style={style.rightText}>Enrolled on</Elements.Text>
      <Elements.Text theme='LIGHT' style={style.rightText}>
        {item.created_at ? moment.unix(item.created_at).format('YYYY/MM/DD') : '-'}
      </Elements.Text>
    </View>
  );
};

const Description = (props) => {
  return (
    <View style={{ lineHeight: 10, alignContent: 'space-around' }}>
      <Elements.Text style={style.nameStyle}>{props.display_name}</Elements.Text>
      <Elements.Text style={style.textStyle} numberOfLines={1}>
        {props.description || ''}
      </Elements.Text>
      <View style={{ height: 5 }} />
      <Elements.Text style={{ color: colors[props?.status] }}>
        {props.status.toUpperCase()}
      </Elements.Text>
    </View>
  );
};

const style = StyleSheet.create({
  rightText: {
    color: '#898888',
    fontSize: 12,
  },
  textStyle: { color: '#898888' },
  nameStyle: { color: '#898888', fontWeight: 'bold' },
  main: {
    flex: 1,
    padding: 5,
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ffffff',
  },
  buttonRow: { display: 'flex', flexDirection: 'row', justifyContent: 'center' },
});

export default AdminStats;
