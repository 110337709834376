import Alert from '../components/elements/Alert';
import Constants from '../utils/Constants';
import Request from '../dal/RequestNew';
import Store from '../Store/Store';
import { loaderReducer } from '../components/elements/customLoader/customLoaderSlice';

class SurveyService {
  constructor(props) {
    this.props = props;
  }

  async getSurvey(isApiDataLoaded, survey_type) {
    try {
      let url = Constants.getConfig('GET_SURVEY');
      url = url.replace(':survey_type', encodeURIComponent(survey_type));
      // console.log("url", url);
      if (!isApiDataLoaded) Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const request = new Request(this.props);
      let response = await request.getNew(url);
      // console.log("response ", response);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      return response;
    } catch (exception) {
      // console.log("exception", exception);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([500, 400].includes(exception.status)) {
        const errorMessage = exception?.error?.body
          ? JSON.parse(exception.error.body).error
          : exception.error;
        Alert.alert('Whoops', errorMessage);
      }
      return [];
    }
  }

  async getSurveys(isApiDataLoaded, email_id, surveyType, connectionId) {
    try {
      // console.log("email_id", email_id)
      let url = Constants.getConfig('GET_USER_SURVEY');
      url = url.replace(':email_id', email_id);
      url = url.replace('${surveyType}', encodeURIComponent(surveyType));
      url = url.replace('${connectionId}', connectionId);
      //   console.log('url', url);
      if (!isApiDataLoaded) Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const request = new Request(this.props);
      let response = await request.getNew(url);
      // console.log('response ', response);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      //   response = this.getCurrentPendingSurveys(response);
      return response;
    } catch (exception) {
      // console.log("exception", exception);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([500, 400].includes(exception.status)) {
        let errorMessage = exception?.error?.body
          ? JSON.parse(exception.error.body).error
          : exception.error;
        Alert.alert('Whoops', errorMessage);
      }
      return [];
    }
  }

  getCurrentPendingSurveys(surveys) {
    const today = new Date();
    let currentMonth = today.getMonth() + 1; //month index starts from 0
    currentMonth = currentMonth < 10 ? '0' + currentMonth : currentMonth;
    const currentSurvey = `${today.getFullYear()}${currentMonth}`;
    // console.log("currentSurvey", currentSurvey);
    let pendingSurveys = [];

    let curentDate = new Date(today).toISOString('YYYY-MM-DD');
    curentDate = curentDate.substring(0, curentDate.indexOf('T')).replace(/-/g, '');
    // console.log("curentDate", curentDate);

    surveys.forEach((survey) => {
      const surveys = survey.surveys;
      Object.keys(surveys).forEach((key) => {
        if (!surveys[key] && key.split('_')[1] <= currentSurvey) {
          if (survey?.remind_me_on_date_time_iso) {
            // console.log("survey.remind_me_on_date_time_iso", survey.remind_me_on_date_time_iso);
            if (curentDate >= survey.remind_me_on_date_time_iso) {
              addPendingSurvey(survey, key);
            }
          } else {
            addPendingSurvey(survey, key);
          }
        }
      });
    });

    // console.log("pendingSurveys 1", pendingSurveys);
    const pendingSurveysByType = getPendingSurveysByType(pendingSurveys);
    // console.log("pendingSurveysByType", pendingSurveysByType);
    return pendingSurveysByType;

    function addPendingSurvey(survey, validKey) {
      // console.log("validKey", validKey);
      // console.log("survey", survey);
      // const validKeys = ['email_id', 'survey_ref', 'connection_id', 'is_deleted', 'survey_assigned_date_time_iso', 'user_type'];
      let temp = { ...survey };
      temp.surveys = { ...survey.surveys };
      temp.surveys = Object.keys(temp.surveys).filter((key) => key == validKey);
      temp.year_month = validKey.split('_')[1];
      temp.surveyUserType =
        survey.survey_ref?.split('#')[0] + '#' + survey.survey_ref?.split('#')[1];
      temp.surveyName = survey.survey_ref?.split('#')[2];
      pendingSurveys = pendingSurveys.concat(temp);
    }

    function getPendingSurveysByType(surveys) {
      // console.log("inside getPendingSurveysByType", surveys);
      let surveysByType = {};
      surveys.forEach((survey) => {
        // console.log(survey.surveyUserType);
        // surveysByType[survey.surveyUserType] = surveysByType[survey.surveyUserType] ? surveysByType[survey.surveyUserType].concat(survey) : [survey];
        surveysByType[survey.surveyName] = surveysByType[survey.surveyName]
          ? surveysByType[survey.surveyName].concat(survey)
          : [survey];
      });
      // console.log("surveysByType", surveysByType);
      return surveysByType;
    }
  }

  async submitSurvey(isApiDataLoaded, emailId, surveyResp) {
    try {
      let url = Constants.getConfig('POST_USER_SURVEY');
      url = url.replace(':email_id', emailId);
      // console.log('url', url);
      // console.log('surveyResp', surveyResp);
      if (!isApiDataLoaded) Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const request = new Request(this.props);
      const response = await request.postNew(url, surveyResp);
      // console.log('response', response);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      return response;
    } catch (exception) {
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([400, 409, 422, 404, 500].includes(exception.status)) {
        let errorMessage = exception?.error?.body
          ? JSON.parse(exception.error.body).error
          : exception.error;
        Alert.alert('Whoops', errorMessage);
      }
      throw exception;
    }
  }

  async updateReminderDate(isApiDataLoaded, emailId, surveyData) {
    try {
      let url = Constants.getConfig('UPDATE_SURVEY_REMINDER');
      url = url.replace(':email_id', emailId);
      // console.log("url", url);
      if (!isApiDataLoaded) Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const request = new Request(this.props);
      let response = await request.putNew(url, surveyData);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      return response;
    } catch (exception) {
      // console.log("exception", exception);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([500, 400, 409].includes(exception.status)) {
        Alert.alert('Whoops', exception.error);
        throw exception;
      }
      return {};
    }
  }

  async getAllUsersSurveys(reportName, isApiDataLoaded, surveyYearMonth) {
    try {
      let url = Constants.getConfig('GET_ALL_USER_SURVEYS_COUNT');
      url = url.replace('${reportName}', reportName);
      //   console.log("url", url, surveyYearMonth);
      if (!isApiDataLoaded) Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const request = new Request(this.props);
      const params = {
        surveyYearMonth: surveyYearMonth,
      };
      // console.log("params", params)
      const response = await request.postNew(url, params);
      //   console.log("response getAllUsersSurveys", response);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      return response;
    } catch (exception) {
      // console.log("exception", exception);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([500, 404].includes(exception.status)) {
        Alert.alert('Whoops', exception.error);
      }
      const response = {
        name: reportName,
        value: 0,
      };
      return response;
    }
  }

  async getSurveyResponse(isApiDataLoaded, emailId) {
    try {
      let url = Constants.getConfig('GET_FEEDBACK_INDIVIDUAL');

      url = url.replace(':email_id', emailId.toLowerCase());
      // console.log("url :: ", url);
      if (!isApiDataLoaded) Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const request = new Request(this.props);

      let response = await request.getNew(url);
      // console.log("trest ;;;;;; ", response);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      return response;
    } catch (exception) {
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([500, 400].includes(exception.status)) {
        let errorMessage = exception?.error?.body
          ? JSON.parse(exception.error.body).error
          : exception.error;
        Alert.alert('Whoops', errorMessage);
      }
      return [];
    }
  }

  async searchSurveys(isApiDataLoaded, searchKeyword) {
    try {
      let url = Constants.getConfig('SEARCH_SPACE');
      url = url.replace('${searchColumnName}', 'display_name');
      url = url.replace('${searchKeyword}', searchKeyword);
      // console.log("url", url);
      if (!isApiDataLoaded) Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const request = new Request(this.props);
      let response = await request.getNew(url);
      // console.log("searchSpace response ---- :: ", response);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      return response;
    } catch (exception) {
      // console.log("exception", exception);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([500, 400].includes(exception.status)) {
        Alert.alert('Whoops', exception.error);
      }
      throw exception;
      // return {};
    }
  }

  async getSurveyUserEmails(isApiDataLoaded) {
    try {
      // console.log("email_id", email_id)
      let url = Constants.getConfig('GET_ALL_USER_SURVEYS_EMAIL');
      //   console.log('url', url);
      if (!isApiDataLoaded) Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const request = new Request(this.props);
      let response = await request.postNew(url);
      //   console.log('response ', response);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      return response;
    } catch (exception) {
      // console.log("exception", exception);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([500, 400].includes(exception.status)) {
        let errorMessage = exception?.error?.body
          ? JSON.parse(exception.error.body).error
          : exception.error;
        Alert.alert('Whoops', errorMessage);
      }
      return [];
    }
  }

  async getSurveyResponse(isApiDataLoaded, email_id, responseId) {
    try {
      // console.log("email_id", email_id)
      let url = Constants.getConfig('GET_SURVEY_RESPONSE');
      url = url.replace(':email_id', email_id);
      url = url.replace(':response_id', encodeURIComponent(responseId));
      // console.log('url', url);
      if (!isApiDataLoaded) Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const request = new Request(this.props);
      let response = await request.getNew(url);
      // console.log('response ', response);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      return response;
    } catch (exception) {
      // console.log("exception", exception);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([500, 400].includes(exception.status)) {
        let errorMessage = exception?.error?.body
          ? JSON.parse(exception.error.body).error
          : exception.error;
        Alert.alert('Whoops', errorMessage);
      }
      return [];
    }
  }

  async getMentorConnections(email_id, isApiDataLoaded) {

    try {
      if (!isApiDataLoaded)
        Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const url = Constants.getConfig('MENTOR_CONNECTIONS');
      const request = new Request(this.props);
      let response = await request.getNew(url + email_id);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      return response;

    } catch (exception) {
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if (exception.status == 500) {
        Alert.alert('Whoops', exception.error);
      }
      return {
        'accepted': [],
        'pending': [],
        'cancelled': []
      };
    }
  }
}

export default SurveyService;
