import adminFeedbackReducer from '../components/feedback/AdminFeedbackSlice';
import assessmentReducer from '../components/journey/learner/AssessmentSlice';
import { configureStore } from '@reduxjs/toolkit';
import designerJourneyReducer from '../components/journey/designer/JourneySlice';
import feedbackReducer from '../components/feedback/FeedbackSlice';
import goalReducer from '../components/goals/GoalSlice';
import hrAdminReducer from '../components/hrAdmin/HRAdminSlice';
import learnerJourneyReducer from '../components/journey/learner/JourneySlice';
import learnerJourneyTopicsReducer from '../components/journey/learner/JourneyTopicsSlice';
import loaderReducer from '../components/elements/customLoader/customLoaderSlice';
import menteeReducer from '../components/mentee/MenteeSlice';
import mentorReducer from '../components/mentor/MentorSlice';
import profileReducer from '../components/profile/ProfileSlice';
import roleReducer from '../components/userManagement/Role/RoleSlice';
import searchReducer from '../components/search/SearchSlice';
import spaceReducer from '../components/journey/designer/Space/SpaceSlice';
import userReducer from '../components/userManagement/User/UserSlice';

export default configureStore({
    reducer: {
        profile: profileReducer,
        goals: goalReducer,
        search: searchReducer,
        menteeStats: menteeReducer,
        mentor: mentorReducer,
        spaces: spaceReducer,
        designerJourneys: designerJourneyReducer,
        learnerJourneys: learnerJourneyReducer,
        learnerJourneyTopics: learnerJourneyTopicsReducer,
        roles: roleReducer,
        assessmentScore: assessmentReducer,
        users: userReducer,
        feedbacks: feedbackReducer,
        adminFeedbacks: adminFeedbackReducer,
        hrAdmin: hrAdminReducer,
        customLoader: loaderReducer
    }
});