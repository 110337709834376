import { ScrollView, StyleSheet, View } from 'react-native';

import Elements from '../../elements';
import { List } from 'react-native-paper';
import React from 'react';

const style = StyleSheet.create({
  main: {
    display: 'flex',
    flexDirection: 'column',
  },
  safeArea: {
    flex: 1,
    backgroundColor: '#f5f5f5',
  },
  name: {
    fontSize: 18,
    fontWeight: 'bold',
    backgroundColor: '#A7C96A',
    padding: 10,
    color: '#ffffff',
  },
  section: {
    padding: 10,
    flex: 1,
  },
  description: {
    backgroundColor: '#A7C96A',
    padding: 10,
    color: '#ffffff',
  },
  contentTitle: {
    fontSize: 15,
  },
  contentDescription: {
    fontSize: 14,
  },
});

export default function TableOfContent(props) {
  const data = props.route.params;
  return (
    <Elements.ViewWithBack pageName='Table of Content' backAction={() => props.navigation.goBack()}>
      <View style={style.safeArea}>
        <View style={style.main}>
          <Elements.Text style={style.name}>{data?.display_name}</Elements.Text>
          {/* <Elements.Text style={style.description}>{data.description}</Elements.Text> */}
        </View>
        <View style={style.section}>
          {/* <List.Section title='Table of Content' style={{ margin: 0, flex: 1 }}> */}
          <ScrollView showsVerticalScrollIndicator={false}>
            <Topics dataList={data.topics} />
          </ScrollView>
          {/* </List.Section> */}
        </View>
      </View>
    </Elements.ViewWithBack>
  );
}

const Topics = ({ dataList }) => {
  // Initialize an array to track the expanded state for each accordion item
  // const [expandedArray, setExpandedArray] = React.useState(new Array(dataList.length).fill(false));

  // const handlePress = (index) => {
  //   const newExpandedArray = [...expandedArray];
  //   newExpandedArray[index] = !newExpandedArray[index];
  //   setExpandedArray(newExpandedArray);
  // };

  return dataList.map((data, index) => {
    return (
      <View key={index}>
        <List.Accordion
          expanded={true}
          // onPress={() => handlePress(index)}
          title={data.display_name || 'N/A'}
          style={{ margin: 0, padding: 0 }}
          right={() => <View />}
          theme={{
            colors: {
              primary: '#212644',
            },
          }}
          description={data.description || 'No description'}
          left={(props) => <List.Icon {...props} icon='book' />}>
          {data.sub_topic.map((sub, index) => (
            <List.Item
              title={sub.name || 'N/A'}
              key={index}
              titleStyle={style.contentTitle}
              descriptionStyle={style.contentDescription}
              description={'Type: ' + sub.content_type || 'N/A'}
            />
          ))}
        </List.Accordion>
      </View>
    );
  });
};
