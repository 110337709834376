import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    keyboard: { flex: 1, justifyContent: 'center' },
    header: { fontWeight: 'bold', padding: 5, color: '#FFFFFF' },
    divider:{ height: 10 },
    bottomSpace: { height: 70 },
    rowContainer: { flexDirection: 'row' },
    roleContainer: { flex: 5 },
    textStyle: { fontSize: 12 },
    listContainer: { backgroundColor: '#f8f8f8', padding: 10, borderWidth: 0.3, borderColor: '#F8F8FF' },
    tags: { fontWeight: 'bold', fontSize: 15 },
    icon: { backgroundColor: 'transparent' },
    divider2: { height: 2 },
    editView: { flexDirection: 'row', alignItems: 'center' },
});
