import { StyleSheet } from 'react-native';
import { Text } from 'react-native-paper';
import ColorConstant from '../../utils/ColorConstant.json';
import { memo } from 'react';

const TextElement = (props) => {
    const styles = StyleSheet.create({
        style: {
            color: ColorConstant[props.theme]?.COLOR || '#000000',
            // fontWeight: "bold",
            ...props.style,
        },
    });
    return (
        <Text
            testID={props?.label || props?.placeholder}
            nativeID={props?.label || props?.placeholder}
            accessibilityLabel={props?.label || props?.placeholder}
            onPress={props.onPress}
            variant={props.variant}
            style={styles.style}
            numberOfLines={props.numberOfLines}
        >
            {props.text}{props.children}
        </Text>
    );
};

export default memo(TextElement, (prev, next) => {

    if (JSON.stringify(prev.style) == JSON.stringify(next.style) && (prev.text || prev.children) == (next.text || next.children))
        return true;
    else return false;
});