import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  tagContainer: {
    flexDirection: 'row',
    padding: 5,
    backgroundColor: '#ffffff',
    borderRadius: 5,
    margin: 5,
    flexWrap: 'wrap',
    gap: 5,
  },
  listContainer: { flexDirection: 'row', backgroundColor: '#ffffff', padding: 5 },
  textContainer: {
    padding: 5,
    backgroundColor: '#f5f5f5',
    borderRadius: 5,
    margin: 5,
  },
  paragraphContainer: {
    padding: 5,
    borderRadius: 5,
  },
  profileImgContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
  },
  header: { padding: 5, fontWeight: 'bold', fontSize: 16, color: '#ffffff' },
  headerData: {
    fontSize: 14,
    color: '#000000',
  },
  paragraph: {
    fontSize: 14,
    color: '#000000',
    // width: '98%',
    backgroundColor: '#f5f5f5',
    padding: 5,
  },
  listTitle: {
    fontWeight: 'bold',
    fontSize: 14,
    color: '#000000',
    marginLeft: 10,
  },
  listDescription: {
    fontSize: 12,
    color: '#000000',
    marginLeft: 10,
  },
  tagStyle: {
    backgroundColor: '#f5f5f5',
    padding: 1,
    marginLeft: 5,
  },
  tagText: {
    fontSize: 16,
    color: '#000000',
    padding: 10,
  },
  profileImage: {},
  image: {
    width: 200,
    height: 200,
  },
});
