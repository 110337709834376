import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    statsContainer: {
        borderWidth: 0.5,
        borderColor: '#2E3456',
        padding: 10,
        borderRadius: 5,
    },
    title: {
        fontWeight: 'bold',
        fontSize: 16,
    },
    statContainer: {
        flexDirection: 'row',
        height: 80,
    },
    statBox: {
        flex: 1.5,
        backgroundColor: 'transparent',
        borderRadius: 5,
        alignItems: 'center'
    },
    statNumber: {
        fontWeight: 'bold',
        fontSize: 40,
        alignItems: 'center',
    },
    statLabel: {
        fontWeight: 'bold',
        fontSize: 12,
        alignItems: 'center',
    },
    seperator: { height: 10 },
    container: {
        height: '100%',
        width: '100%',
        borderWidth: 0.5,
        borderColor: '#2E3456',
        padding: 5,
        borderRadius: 5,
    },
    serviceButtonText: {
        fontWeight: 'bold',
        flex: 5.5,
    },
    serviceButton: {
        backgroundColor: '#e1e1e1',
        borderRadius: 5,
        padding: 15,
        margin: 2,
        flexDirection: 'row',
        alignItems: 'center',
    },
    arrowIcon: {
        fontWeight: 'bold',
        flex: 0.5,
        backgroundColor: 'transparent',
    },
    divider: {
        height: 15,
    },

});





