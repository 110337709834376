import * as Linking from 'expo-linking';
import * as WebBrowser from 'expo-web-browser';

import { ImageBackground, Text, View } from 'react-native';
import { memo, useEffect, useState } from 'react';

import { ActivityIndicator } from 'react-native-paper';
import ApplicationStorage from '../../utils/ApplicationStorage';
import Back from '../../Background.jpg';
import ButtonElement from '../elements/Button';
import Constants from '../../utils/Constants';
import NotAvailable from '../elements/NotAvailable';
import { Platform } from 'react-native';
import { loadData } from '../utils/utils';
import { loaderReducer } from '../elements/customLoader/customLoaderSlice';
import style from './Style';
import { useDispatch } from 'react-redux';
import { useIsFocused } from '@react-navigation/native';

const Login = (parentProps) => {
    const props = { ...parentProps, ...parentProps.route.params };
    const [loadingOn, setLoadingOn] = useState(true);
    const [isNotAllowedCountry, setIsNotAllowedCountry] = useState(false);
    const isFocused = useIsFocused();
    const dispatch = useDispatch();

    // console.log('loadingOn!!', loadingOn);

    useEffect(() => {
        loadData(null, props, setIsNotAllowedCountry, setLoadingOn);
    }, []);

    useEffect(() => {
        setLoadingOn(false);
    }, [isFocused]);


    const loginCallBack = () => {
        if (Platform.OS === 'web') {
            let loginURLWeb = `/authpopup.html?url=${btoa(Constants.getLoginURL())}`;
            // console.log('loginURLWeb', loginURLWeb);
            setLoadingOn(true);
            import('@microsoft/teams-js').then(microsoftTeams => {
                microsoftTeams.initialize(() => {
                    // console.log("msteams initialized");
                    // alert('msteams initialized');
                    microsoftTeams.getContext(() => {
                        // alert(JSON.stringify(context))
                        microsoftTeams.authentication.authenticate({
                            // url: LOGIN_URL,
                            url: window.location.origin + loginURLWeb,
                            // isExternal: false,
                            // height: 600,
                            // width: 500,
                            successCallback: function (result) {
                                //sucess
                                loadData(result.code, props, setIsNotAllowedCountry, setLoadingOn);
                            },
                            failureCallback: function () {
                                //failure
                                // console.log("failureCallback", reason)
                                setLoadingOn(false);
                                ApplicationStorage.removeAll().then(() => {
                                    dispatch(loaderReducer({ loaderProperties: { show: false } }));
                                }).catch(() => {
                                    // console.log("error", error)
                                });
                            }
                        });
                    });
                });
            });
        }
        else {
            let LOGIN_URL = Constants.getLoginURL();
            let login = async () => {
                try {
                    if (Platform.OS === 'ios') {
                        Linking.addEventListener('url', () => {
                            WebBrowser.dismissBrowser();
                        });
                    }
                    WebBrowser.openBrowserAsync(LOGIN_URL, { showInRecents: true });
                    // console.log(JSON.stringify(result));
                } catch (error) {
                    // console.log(error);
                }

            };
            login();
        }
    };


    return (
        isNotAllowedCountry ?
            <NotAvailable />
            :
            <ImageBackground source={Back} resizeMode="cover" style={style.image}>
                <View style={style.container}>
                    {
                        loadingOn ?
                            <View>
                                <ActivityIndicator animating={true} color="#ffffff" />
                                <Text style={style.textStyle}> Authenticating...</Text>
                            </View> :
                            <View style={style.centerView}>
                                <View style={style.buttonConatiner}>
                                    <ButtonElement
                                        theme={props.theme}
                                        text="Login"
                                        onPress={loginCallBack}
                                    />
                                </View>
                            </View>


                    }
                </View>
            </ImageBackground>
    );
};
export default memo(Login, () => {
    return true;
});