// import { List } from 'react-native-paper';

import { Avatar, ProgressBar } from 'react-native-paper';
import { ScrollView, StyleSheet } from 'react-native';
import { useEffect, useState } from 'react';

import Elements from '../../../elements';
import { View } from 'react-native';
import ViewElement from '../../../elements/ViewWithBack';
import { capitalize } from 'lodash';
import { getName } from '../../../../utils/NameMapping';
import { useIsFocused } from '@react-navigation/native';

const moment = require('moment');

const parse = (item) => {
  try {
    return JSON.parse(item);
  } catch (error) {
    return item;
  }
};

const MenteeGoalDetails = (props) => {
  const data = props.route.params;
  let definitions = parse(data.definitions);

  // progress bar data
  const completedDefnitions = definitions?.filter((d) => d.status === 'completed');
  // console.log('completedDefnitions', completedDefnitions);
  let totalWeightage = 0;
  completedDefnitions.forEach((definition) => {
    totalWeightage = Number(totalWeightage) + Number(definition.weightage_in_percentage);
  });

  // let progressPercent = (completedDefnitions.length / definitions.length) * 100;
  let progress = totalWeightage / 100;
  // console.log(progress, totalWeightage);

  return (
    <ViewElement
      backAction={() => {
        props.navigation.navigate('Goals');
      }}
      pageName={'Goal Details'}>
      <ScrollView
        style={{ margin: 5 }}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}>
        <Divider />

        <View style={styles.progressView}>
          <Elements.Text>Current Progress</Elements.Text>
          <ProgressBar progress={progress} color='#48B2EB' style={styles.progressBar} />
          <Elements.Text>{totalWeightage}% Completed</Elements.Text>
        </View>

        <Divider />

        <ShowText value={data.goal_id?.substring(0, data.goal_id.indexOf('_'))} label='Name' />

        <Divider />
        <ShowText value={getName(data.email_id)} label='Created By' />

        <Divider />
        <ShowText value={capitalize(data.status)} label='Status' />

        <Divider />
        <ShowText value={data.remarks} label='S.M.A.R.T. Objective' />

        <Divider />
        {/* <ShowText
          value={
            data.expected_completion_date
              ? moment.unix(data.expected_completion_date).format('YYYY/MM/DD')
              : ''
          }
          label='Expected Completion Date'
        />

        <Divider /> */}
        <ShowText value={moment.unix(data.created_at).format('YYYY/MM/DD')} label='Created On' />

        <Divider />
        <ShowText
          value={moment.unix(data.updated_at).format('YYYY/MM/DD')}
          label='Last Updated At'
        />

        <Divider />
        <ShowText value={definitions?.length || 0} label='No of Themes' />

        <Divider />
        <Definitions definitions={definitions} />
      </ScrollView>
    </ViewElement>
  );
};

const ShowText = ({ value, label }) => {
  return (
    <View style={styles.textView}>
      <Elements.Text theme='light' style={styles.textLabel}>
        {label}
      </Elements.Text>
      <Elements.Text theme='light'>{value}</Elements.Text>
    </View>
  );
};

const Divider = () => <View style={{ height: 5 }} />;

const Definitions = ({ definitions }) => {
  return (
    <View>
      <Elements.Text theme='DARK' style={styles.definitionHeading}>
        Learning Themes
      </Elements.Text>
      <Divider />

      {definitions.map((definition, index) => {
        return (
          <View key={index}>
            <DefinitionItem definition={definition} index={index} />
            <Divider />
          </View>
        );
      })}
    </View>
  );
};

const DefinitionItem = ({ definition, index }) => {
  const [show, setShow] = useState(false);
  const isFocused = useIsFocused();

  useEffect(() => {
    if (!isFocused) setShow(false);
  }, [isFocused]);
  // console.log('definition', definition);

  return (
    <View style={styles.definitionView}>
      <Elements.Touchable
        id={'definition-item-' + index}
        style={styles.definitionRow}
        onPress={() => setShow(!show)}>
        <Elements.Text theme='LIGHT' style={{ flex: 5.5 }}>
          {definition.definition}
        </Elements.Text>

        <Avatar.Icon
          icon={show ? 'arrow-down' : 'arrow-right'}
          style={{ flex: 0.5, backgroundColor: 'transparent' }}
          size={30}
          color='#000000'
        />
      </Elements.Touchable>
      {show ? (
        <View style={styles.textView}>
          <Elements.Text style={styles.label}>Weightage</Elements.Text>
          <Elements.Text>{definition.weightage_in_percentage + '%'}</Elements.Text>
          <Divider />

          <Elements.Text style={styles.label}>Status</Elements.Text>
          <Elements.Text>
            {definition.status ? capitalize(definition.status) : 'Created'}
          </Elements.Text>
          <Divider />

          <Elements.Text style={styles.label}>Timeline</Elements.Text>
          <Elements.Text>
            {definition.timeline ? moment.unix(definition.timeline).format('YYYY/MM/DD') : ''}
          </Elements.Text>

          {/* <Elements.Text style={styles.label}>Theme Remarks</Elements.Text>
          <Elements.Text>{definition.remarks}</Elements.Text>
          <Divider /> */}
          <Divider />
        </View>
      ) : (
        <View />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  progressView: {
    padding: 15,
    backgroundColor: '#f5f5f5',
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
  },
  textView: {
    padding: 8,
    backgroundColor: '#f5f5f5',
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
  },
  textLabel: {
    fontSize: 12,
    color: '#807C7C',
  },
  definitionRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    padding: 8,
  },
  definitionView: {
    backgroundColor: '#f5f5f5',
    display: 'flex',
    borderRadius: 5,
  },
  definitionHeading: {
    fontSize: 16,
    fontWeight: 'bold',
    padding: 5
  },
  label: {
    color: '#807C7C',
    marginTop: -10,
  },
  progressBar: { height: 20, borderRadius: 5 },
});

export default MenteeGoalDetails;
