import { KeyboardAvoidingView, Platform } from 'react-native';
import { useEffect, useState } from 'react';
import { useIsFocused, useNavigation, useRoute } from '@react-navigation/native';

import Alert from '../../elements/Alert';
import { Avatar } from 'react-native-paper';
import ButtonElement from '../../elements/Button';
import { DatePickerModal } from 'react-native-paper-dates';
import Elements from '../../elements';
import { Rating } from 'react-native-ratings';
import { SURVEY_TYPE } from '../Metadata';
import SurveyService from '../../../service/SurveyService';
import { View } from 'react-native';
import ViewWithBack from '../../elements/ViewWIthScroll';
import { getUserIdentity } from '../../../utils/UserIdentity';
import style from './Style';

const moment = require('moment');

// const FREE_TEXT_LABEL = 'Your comments';

export default () => {
  const navigation = useNavigation();
  const route = useRoute();
  const { survey_ref, email_id, survey_assigned_date_time_iso } = route.params;

  const [isApiDataLoaded, setIsApiDataLoaded] = useState(false);
  const [userComments, setUserComments] = useState([]);
  const [ratings, setRatings] = useState([]);
  const [survey, setSurvey] = useState({});
  const [surveyResponse, setSurveyResponse] = useState([]);
  const [visible, setVisible] = useState(false);
  const [surveyDate, setSurveyDate] = useState('');

  const isFocused = useIsFocused();
  const surveyService = new SurveyService({ navigation });

  const updateSingleSelectAns = (userAnswer) => {
    setSurveyResponse((prevSurveyResponse) => {
      return prevSurveyResponse.map((surveyQuestion, index) => {
        if (index === parseInt(userAnswer.sequence)) {
          return { ...surveyQuestion, ...userAnswer };
        } else {
          return surveyQuestion;
        }
      });
    });
  };

  const updateTextAns = (userAnswer, currentIndex) => {
    if (!validateAndAlert(userAnswer)) {
      return;
    }
    setSurveyResponse((prevSurveyResponse) => {
      return prevSurveyResponse.map((surveyQuestion, index) => {
        if (index === currentIndex) {
          const answerObject = {
            userAnswer: userAnswer,
            sequence: currentIndex,
          };
          setUserComments((prevUserComments) => {
            let tempUserComments = [...prevUserComments];
            tempUserComments[currentIndex] = userAnswer;
            return tempUserComments;
          });
          return { ...surveyQuestion, ...answerObject };
        } else {
          return surveyQuestion;
        }
      });
    });
  };

  const updateRatingAns = (rating, currentIndex) => {
    // console.log('updateRatingAns', rating, currentIndex);
    setSurveyResponse((prevSurveyResponse) => {
      return prevSurveyResponse.map((surveyQuestion, index) => {
        if (index === currentIndex) {
          const answerObject = {
            userAnswer: rating,
            sequence: currentIndex,
          };
          return { ...surveyQuestion, ...answerObject };
        } else {
          return surveyQuestion;
        }
      });
    });
  };

  const validateAndAlert = (userAnswer) => {
    if (userAnswer.trim().length > 255) {
      Alert.alert('Whoops', 'Please keep your comments within 255 characters');
      return false;
    }
    return true;
  };

  const submitSurvey = () => {
    // console.log('surveyResponse', surveyResponse);
    const surveyResp = {
      survey_ref: survey_ref,
      survey_assigned_date_time_iso: survey_assigned_date_time_iso,
      survey_date: surveyDate,
      response: surveyResponse,
    };

    const unAnsweredQuestions = validateResponse();

    if (!surveyDate) {
      Alert.alert('Required', 'Please select a date for feedback');
    } else if (unAnsweredQuestions) {
      Alert.alert('Required', 'Please answer all the questions');
    } else {
      surveyService.submitSurvey(false, email_id, surveyResp).then((response) => {
        //   console.log('submitSurvey response', response);
        setIsApiDataLoaded(true);
        Alert.alert('Success', response.message);
        navigation.navigate('Surveys');
      });
    }
  };

  const validateResponse = () => {
    // console.log('surveyResponse', surveyResponse);
    const unAnsweredQuestions = surveyResponse.filter(
      (response) => response.userAnswer === undefined || response.userAnswer === ''
    ).length;
    // console.log('unAnsweredQuestions', unAnsweredQuestions, 'out of', surveyResponse.length);
    return unAnsweredQuestions;
  };

  const renderQuestion = (question, index) => {
    if (question.question_type === 'single_choice') {
      return (
        <SingleSelect {...question} key={index} index={index} updateAns={updateSingleSelectAns} />
      );
    }
    if (question.question_type === 'free_text') {
      return (
        <TextQuestion
          {...question}
          key={index}
          index={index}
          userComment={userComments[index]}
          updateAns={updateTextAns}
        />
      );
    }
    if (question.question_type === 'rating') {
      return (
        <RatingQuestion
          {...question}
          key={index}
          index={index}
          ratings={ratings}
          setRatings={(newRatings) => {
            setRatings(newRatings);
          }}
          updateAns={updateRatingAns}
        />
      );
    }
    return <View key={index}></View>;
  };

  const fetchData = () => {
    // console.log("inside fetchdata");
    getUserIdentity().then((uIdentity) => {
      if (uIdentity) {
        // setUserIdentity(uIdentity);
        const surveyUserType = SURVEY_TYPE;

        surveyService.getSurvey(false, surveyUserType).then((response) => {
          // console.log('getSurvey response', response);
          const surveyObject = response[0];
          setSurvey(surveyObject); //todo: need to update this according once more than one type surveys are present

          setUserComments(new Array(surveyObject.questions.length).fill(''));
          setRatings(new Array(surveyObject.questions.length).fill(0));
          setSurveyResponse(surveyObject.questions);
          setIsApiDataLoaded(true);
        });
      }
    });
  };

  // console.log('survey', survey);
  // console.log('surveyResponse', surveyResponse);
  // console.log('surveyDate', surveyDate);

  useEffect(() => {
    if (isFocused) {
      fetchData();
    }
  }, [isFocused]);

  return (
    <ViewWithBack
      pageName={survey.survey_name || 'Feedback'}
      header={true}
      backAction={() => {
        navigation.goBack();
      }}>
      {isApiDataLoaded ? (
        <KeyboardAvoidingView
          behavior='position'
          keyboardVerticalOffset={Platform.OS == 'android' ? -550 : -450}
          style={style.flex1}>
          <View style={style.flex1}>
            <SurveyDate
              visible={visible}
              setVisible={setVisible}
              surveyDate={surveyDate}
              setSurveyDate={setSurveyDate}
              survey_assigned_date_time_iso={survey_assigned_date_time_iso}
            />
            <View style={style.questionsContainer}>
              {survey.questions.map((question, index) => renderQuestion(question, index))}
            </View>
            <View style={style.buttonContainer}>
              <ButtonElement style={style.button} onPress={submitSurvey}>
                Submit
              </ButtonElement>
            </View>
          </View>
        </KeyboardAvoidingView>
      ) : (
        <View style={style.alignItemCenter}>
          <Elements.Text style={style.loadingText}>Loading...</Elements.Text>
        </View>
      )}
    </ViewWithBack>
  );
};

const SingleSelect = ({ index, options, question_content, updateAns }) => {
  return (
    <View key={index} style={style.questionView}>
      <Elements.Text>
        {index + 1}. {question_content}
      </Elements.Text>
      <Elements.RadioButtonElement
        sequence={index}
        data={options}
        handleRadioButton={updateAns}></Elements.RadioButtonElement>
    </View>
  );
};

const TextQuestion = ({ index, question_content, updateAns, userComment }) => {
  // console.log('userComment is inside', userComment);
  return (
    <View key={index} style={style.questionView}>
      <Elements.Text>
        {index + 1}. {question_content}
      </Elements.Text>
      <View style={style.divider}></View>
      <Elements.TextInput
        value={userComment}
        onChange={(text) => {
          // console.log('onChange text', text, index);
          updateAns(text, index);
        }}
        maxLength={260}
        numberOfLines={4}
        multiline={true}
      />
    </View>
  );
};

const RatingQuestion = ({ ratings, setRatings, range, updateAns, index, question_content }) => {
  const updateRating = (value) => {
    setRatings((prevRatings) => {
      const temp = [...prevRatings];
      temp[index] = value;
      return temp;
    });
    updateAns(value, index);
  };

  return (
    <View key={index} style={style.questionView}>
      <Elements.Text>
        {index + 1}. {question_content}
      </Elements.Text>
      <View style={style.divider}></View>

      <Rating
        type='star'
        startingValue={ratings[index]}
        onFinishRating={(newRating) => {
          updateRating(newRating);
        }}
        imageSize={25}
        style={style.ratings}
        ratingCount={range.to}
      />
    </View>
  );
};

const SurveyDate = ({
  visible,
  setVisible,
  surveyDate,
  setSurveyDate,
  survey_assigned_date_time_iso,
}) => {
  const today = new Date();

  return (
    <View>
      <Elements.Touchable
        style={[style.surveyDateView]}
        onPress={() => {
          setVisible(true);
        }}
        id='feedback-date'>
        <Elements.Text style={{ fontWeight: 'bold', paddingHorizontal: 10, paddingVertical: 5 }}>
          Feedback Date:
        </Elements.Text>
        {surveyDate ? (
          <Elements.Text style={{ paddingLeft: 10, paddingVertical: 5 }}>
            {moment.unix(surveyDate).format('YYYY/MM/DD')}
          </Elements.Text>
        ) : (
          <Avatar.Icon
            icon='calendar-month'
            color='#212644'
            size={32}
            style={{ backgroundColor: 'transparent' }}
          />
        )}
      </Elements.Touchable>

      <DatePickerModal
        locale='en'
        mode='single'
        label='Select feedback date'
        visible={visible}
        onDismiss={() => setVisible(false)}
        validRange={{
          startDate: new Date(moment.unix(survey_assigned_date_time_iso).format('YYYY/MM/DD')),
          endDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
        }}
        onConfirm={(date) => {
          if (date.date) {
            // console.log('date', date);
            setVisible(false);
            const timestamp = moment(date.date, 'YYYY-MM-DD HH:mm:ss').unix();
            setSurveyDate(timestamp);
          } else {
            setVisible(false);
            Alert.alert('Bad input', 'Please select a valid date');
          }
        }}
      />
    </View>
  );
};
