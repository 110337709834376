import Alert from '../components/elements/Alert';
import Constants from '../utils/Constants';
import Request from '../dal/RequestNew';
import Store from '../Store/Store';
import { loaderReducer } from '../components/elements/customLoader/customLoaderSlice';

class StatsService {
  constructor(props) {
    this.props = props;
  }

  async getStats(reportName, isApiDataLoaded) {
    try {
      if (!isApiDataLoaded) Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      let url = Constants.getConfig('GET_STATS');
      url = url.replace('${reportName}', reportName);
      // console.log("url", url, isApiDataLoaded)
      const request = new Request(this.props);
      const params = {};
      const response = await request.postNew(url, params);
      // console.log("response", response);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      return response;
    } catch (exception) {
      // console.log("exception", exception);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([500, 404].includes(exception.status)) {
        Alert.alert('Whoops', exception.error);
      }
      const response = {
        name: reportName,
        value: 0,
      };
      return response;
    }
  }

  // //unused
  // async fetchStats(statsObject, isApiDataLoaded) {
  //     try {
  //         if (!isApiDataLoaded)
  //             Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
  //         let response = {};
  //         // for (const statsObject of statsData) {
  //         response = await this.getStatsByName(statsObject.statsName, statsObject.operation);
  //         // }
  //         // console.log("response", response);
  //         Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
  //         return response;

  //     } catch (exception) {
  //         Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
  //         if ([500, 404].includes(exception.status)) {
  //             this.props.setAlertProperties(true, exception.error, () => { }, 'Whoops');
  //         }
  //         let response = {
  //             'active_mentee': 0,
  //             'active_mentor': 0,
  //             'total_connections': 0,
  //             'total_goals': 0,
  //             'active_goals': 0,
  //             'goals_completed': 0,
  //         };
  //         return response;
  //     }
  // }

  // //unused
  // async getStatsByName(statsName, operation) {
  //     if (!isApiDataLoaded)
  //         Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
  //     let url = Constants.getConfig('GET_STATS_OLD');
  //     url = url.replace('${reportName}', statsName);
  //     url = url.replace('${operation}', operation);
  //     const request = new Request(this.props);
  //     let response = await request.getNew(url);
  //     return response;
  // }
}

export default StatsService;
