import { KeyboardAvoidingView, ScrollView,  View } from 'react-native';
import { useEffect, useState } from 'react';

import Button from '../Button';
import TextInputElement from '../../../elements/TextInput';
import ViewWithBack from '../../../elements/ViewWithBack';
import journeyConfig from '../../JourneyConfig.json';
import { useIsFocused } from '@react-navigation/native';

//unused file
const SpaceForm = (parentProps) => {
    const props = { ...parentProps, ...parentProps.route.params };
    const isFocused = useIsFocused();
    const { type } = props.route.params;

    const [space, setSpace] = useState('');

    const fetchData = () => {
    };


    useEffect(() => {
        if (isFocused) {
            fetchData();
        }
    }, [isFocused]);

    return (
        <ViewWithBack pageName={type + ' ' + 'Space'}  {...props} backAction={() => { props.navigation.navigate('Spaces', { backAction: true }); }} >
            <ScrollView
                showsVerticalScrollIndicator={false}
                showsHorizontalScrollIndicator={false}
            >
                <KeyboardAvoidingView behavior="position" style={{ height: '100%', justifyContent: 'center' }}>
                    <View style={{ padding: 5 }}>

                        <View style={{ height: 10 }}></View>
                        <TextInputElement
                            theme="LIGHT"
                            label="Space Name"
                            value={space}
                            onChangeText={(space) => { setSpace(space); setUnsavedChanges(true); }}
                            labelStyle={{ fontSize: 16, color: '#7D8792', fontWeight: 'bold' }} />

                        <View style={{ height: 10 }}></View>
                        <View style={{ alignItems: 'flex-end' }} >
                            <View style={{ height: 10 }}></View>
                            <Button style={{ backgroundColor: journeyConfig.travelexColors.actionBlue, width: 90 }} onPress={() => { console.log('Create', space); }}>{type}</Button>
                        </View>
                        <View style={{ height: 70 }}></View>
                    </View>
                </KeyboardAvoidingView>
            </ScrollView>
        </ViewWithBack>
    );
};

export default SpaceForm;