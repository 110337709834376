import * as React from 'react';

import { memo, useEffect } from 'react';

import Alert from '../elements/Alert';
import ButtonElement from '../elements/Button';
import DividerElement from '../elements/Divider';
import FeedbackService from '../../service/FeedbackService';
import { Rating } from 'react-native-ratings';
import TextElement from '../elements/Text';
import TextInputElement from '../elements/TextInput';
import { View } from 'react-native';
import ViewWithBack from '../elements/ViewWithBack';
import style from './Style';
import { useIsFocused, useNavigation } from '@react-navigation/native';

const hash = require('object-hash');

let hashes = [];

const AddNewFeedback = () => {
    const navigation = useNavigation();
    const props = { navigation };
    const feedbackService = new FeedbackService(props);
    const isFocused = useIsFocused();
    const [feedbackType] = React.useState('Feedback');
    // const [feedbackTitle, setFeedbackTitle] = React.useState('');
    const [feedbackDescription, setFeedbackDescription] = React.useState('');
    const [feedbackRating, setFeedbackRating] = React.useState(0);

    useEffect(() => {
        flush();
    }, [isFocused]);

    const flush = () => {
        // setFeedbackType("");
        // setFeedbackTitle('');
        setFeedbackDescription('');
        setFeedbackRating(0);
    };
    const ratingCompleted = (rating) => {
        // console.log("Rating is: " + rating);
        setFeedbackRating(rating);
    };

    const submitFeedback = async () => {

        if (feedbackDescription.trim().length >= 3) {
            const list = {
                'title': feedbackDescription.substring(0, 50),
                'type': feedbackType,
                'description': feedbackDescription.trim(),
                'rating': feedbackRating
            };

            if (feedbackDescription.trim().length > 255) {
                Alert.alert('Whoops', 'Feedback description should be 255 characters');
            } else {
                const feedbackHash = hash(list);
                if (!hashes.includes(feedbackHash)) {
                    hashes.push(feedbackHash);
                    await feedbackService.postFeedback(list, props.navigation);
                    Alert.alert('Success', 'Feedback submitted');
                    props.navigation.goBack();
                }
            }
        } else if (!feedbackDescription.trim().length) {
            Alert.alert('Missing field', 'Feedback description is required');
        } else {
            Alert.alert('Whoops', 'Feedback description should\'ve at least three characters');
        }
    };

    return (
        <ViewWithBack pageName={'Add Feedback'} {...props} backAction={() => { props.navigation.goBack(); }}>
            <DividerElement theme={props.theme} height={15} />
            <View style={style.addFeedbackView}>
                <TextElement theme='DARK' style={style.textStyle}>Ratings</TextElement>
                <Rating
                    type='star'
                    startingValue={feedbackRating}
                    onFinishRating={ratingCompleted}
                    imageSize={30}
                    style={style.addFeedbackRating}
                    selectedColor="#FFC300"
                    tintColor="#212644"
                />
            </View>
            <TextInputElement theme={props.theme}
                onChangeText={(description) => {
                    setFeedbackDescription(description);
                }}
                value={feedbackDescription}
                numberOfLines={5}
                multiline={true}
                maxLength={260}
                label="Enter your valuable feedback"
                style={style.addFeedBackDesc}
            />
            <DividerElement theme={props.theme} height={15} />
            <View style={style.buttonView}>
                <ButtonElement theme={props.theme} mode="contained" style={style.button} onPress={submitFeedback}>
                    <TextElement theme={props.theme} style={style.buttonText} >Post</TextElement>
                </ButtonElement>
            </View>
        </ViewWithBack>
    );
};
export default memo(AddNewFeedback, () => {
    return true;
});