import JourneyConfig from '../../JourneyConfig.json';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  learnerCardStyle: { width: 185, borderRadius: 10, padding: 5, margin: 2, backgroundColor: '#f5f5f5', borderWidth: 1, borderColor: '#f1f1f1' },
  icon: { backgroundColor: 'transparent' },
  noResultsContainer: { alignItems: 'center' },
  noResultsText: { fontSize: 11, color: '#CECECE' },
  loadingContainer: { alignItems: 'center' },
  loadingText: { fontSize: 11, color: '#CECECE', marginTop: 10 },
  rowContainer: { flexDirection: 'row', width: '100%' },
  separator: { height: 5 },
  declineButton: {
    padding: 3,
    borderWidth: 1,
    borderColor: JourneyConfig.travelexColors.TRXRed,
    borderRadius: 5,
    alignItems: 'center',
    margin: 10,
  },
  decline: { color: JourneyConfig.travelexColors.TRXRed },
  approveButton: {
    padding: 3,
    borderWidth: 1,
    borderColor: JourneyConfig.colors.Lime,
    borderRadius: 5,
    alignItems: 'center',
    margin: 10,
  },
  approve: { color: JourneyConfig.colors.Lime },
  cardIconContainer: { alignItems: 'center', padding: 10, justifyContent: 'center' },
  cardInfoDesigner: { padding: 5 },
  cardInfoLearner: { padding: 5 },
  journeyName: { fontSize: 14, fontWeight: 'bold' },
  createdBy: { fontSize: 12, fontWeight: 'bold' },
  textStyle: { fontSize: 12, width: '100%' },
  designerContainer: { alignItems: 'center' },
  buttonRow: { flexDirection: 'row', alignItems: 'center' },
});

