import Alert from '../components/elements/Alert';
import Constants from '../utils/Constants';
import Request from '../dal/RequestNew';
import Store from '../Store/Store';
import { loaderReducer } from '../components/elements/customLoader/customLoaderSlice';

class FeedbackService {
  constructor(props) {
    this.props = props;
  }

  // async getFeedbacks(isApiDataLoaded) {
  //     try {
  //         const url = Constants.getConfig('GET_FEEDBACK');
  //         if (!isApiDataLoaded)
  //              Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
  //         const request = new Request(this.props);
  //         let response = await request.getNew(url);
  //         // console.log("response", response);
  //          Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
  //         return response;

  //     } catch (exception) {
  //          Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
  //         if ([500, 400].includes(exception.status)) {
  //             let errorMessage = exception?.error?.body ? JSON.parse(exception.error.body).error : exception.error;
  //             this.props.setAlertProperties(true, errorMessage, () => { }, 'Whoops');
  //         }
  //         return [];

  //     }
  // }

  async getFeedbacks(reportName, isApiDataLoaded, rating) {
    try {
      let url = Constants.getConfig('GET_ALL_FEEDBACKS');
      url = url.replace('${reportName}', reportName);
      if (!isApiDataLoaded) Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const request = new Request(this.props);
      let params = {};
      if (rating != undefined) {
        params.rating = rating;
      }
      // console.log("params", params);
      console;
      let response = await request.postNew(url, params);
      // console.log("response", response);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      return response;
    } catch (exception) {
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([500, 400].includes(exception.status)) {
        const errorMessage = exception?.error?.body
          ? JSON.parse(exception.error.body).error
          : exception.error;
        Alert.alert('Whoops', errorMessage);
      }
      return [];
    }
  }

  async getIndividualFeedbacks(isApiDataLoaded, emailId) {
    try {
      let url = Constants.getConfig('GET_FEEDBACK_INDIVIDUAL');

      url = url.replace(':email_id', emailId.toLowerCase());
      // console.log("url :: ", url);
      if (!isApiDataLoaded) Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const request = new Request(this.props);

      let response = await request.getNew(url);
      // console.log("response : ", response);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      return response;
    } catch (exception) {
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([500, 400].includes(exception.status)) {
        const errorMessage = exception?.error?.body
          ? JSON.parse(exception.error.body).error
          : exception.error;
        Alert.alert('Whoops', errorMessage);
      }
      return [];
    }
  }

  async getConnections(emailId) {
    try {
      Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const url = Constants.getConfig('MENTEE_CONNECTIONS');
      const request = new Request(this.props);
      let response = await request.getNew(url + emailId);
      // console.log("response", response)
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      // return { body: response };
      return response;
    } catch (exception) {
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if (exception.status == 500) {
        const errorMessage = exception?.error?.body
          ? JSON.parse(exception.error.body).error
          : exception.error;
        Alert.alert('Whoops', errorMessage);
      }
      const body = {
        accepted: [],
        pending: [],
        cancelled: [],
      };
      return body;
    }
  }

  async postFeedback(data) {
    try {
      Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const url = Constants.getConfig('POST_FEEDBACK');
      const request = new Request(this.props);
      let response = await request.postNew(url, data);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      return response;
    } catch (exception) {
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([400, 409, 422, 404, 500].includes(exception.status)) {
        const errorMessage = exception?.error?.body
          ? JSON.parse(exception.error.body).error
          : exception.error;
        Alert.alert('Whoops', errorMessage);
      }
      throw exception;
    }
  }
}

export default FeedbackService;
