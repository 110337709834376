import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    scrollview: { width: '100%', height: '100%' },
    keyboard: { width: '100%', height: '100%', justifyContent: 'center' },
    divider:{ height: 10 },
    textStyle: { color: '#fff', lineHeight: 20, fontSize: 13 },
    header: { fontWeight: 'bold', color: '#fff', fontSize: 16 },
    divider5: { height: 5 },
    labelStyle: { fontSize: 16, color: '#7D8792', fontWeight: 'bold' },
    roleContainer: { borderWidth: .5, borderColor: '#fff', padding: 10 },
    infoContainer: { flex: 4 },
    main: { padding: 5 }
});
