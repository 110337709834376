import Alert from '../components/elements/Alert';
import Constants from '../utils/Constants';
import Request from '../dal/RequestNew';
import Store from '../Store/Store';
import { loaderReducer } from '../components/elements/customLoader/customLoaderSlice';

class GoalsService {
  constructor(props) {
    this.props = props;
  }

  async getGoals(email_id, isApiDataLoaded) {
    try {
      const url = Constants.getConfig('GET_GOALS');
      if (!isApiDataLoaded) Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const request = new Request(this.props);
      let response = await request.getNew(url + email_id);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      return response;
    } catch (exception) {
      // console.log("exception", exception);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([500, 400].includes(exception.status)) {
        const errorMessage = exception?.error?.body
          ? JSON.parse(exception.error.body).error
          : exception.error;
        Alert.alert('Whoops', errorMessage);
      }
      return [];
    }
  }

  async getMenteeGoals(data, isApiDataLoaded) {
    try {
      if (!isApiDataLoaded) {
        Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      }
      let url = Constants.getConfig('GET_MENTEE_GOALS').replace(
        '${mentor_email_id}',
        data.mentor_email_id
      );
      url = data.page ? url.replace('${page}', data.page) : url.replace('?page=${page}', '');
      // console.log('url', url);
      const request = new Request(this.props);
      let response = await request.getNew(url);
      // console.log('response', response);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      return response;
    } catch (exception) {
      // console.log('exception', exception);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([500, 400].includes(exception.status)) {
        const errorMessage = exception?.error?.body
          ? JSON.parse(exception.error.body).error
          : exception.error;
        Alert.alert('Whoops', errorMessage);
      }
      return [];
    }
  }

  async getAllUsersGoals(data, isApiDataLoaded) {
    try {
      if (!isApiDataLoaded) {
        Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      }
      let url = Constants.getConfig('GET_ALL_USERS_GOALS')
        .replace('${offset}', data.offset)
        .replace('${limit}', data.limit);
      // console.log('url', url);
      const request = new Request(this.props);
      let response = await request.postNew(url);
      // console.log('response11', response);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      return response;
    } catch (exception) {
      // console.log('exception', exception);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([500, 400].includes(exception.status)) {
        const errorMessage = exception?.error?.body
          ? JSON.parse(exception.error.body).error
          : exception.error;
        Alert.alert('Whoops', errorMessage);
      }
      return [];
    }
  }

  async getConnections(emailId) {
    try {
      Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const url = Constants.getConfig('MENTEE_CONNECTIONS');
      const request = new Request(this.props);
      let response = await request.getNew(url + emailId);
      // console.log("response", response)
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      // return { body: response };
      return response;
    } catch (exception) {
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if (exception.status == 500) {
        const errorMessage = exception?.error?.body
          ? JSON.parse(exception.error.body).error
          : exception.error;
        Alert.alert('Whoops', errorMessage);
      }
      const body = {
        accepted: [],
        pending: [],
        cancelled: [],
      };
      return body;
    }
  }

  async createGoal(data, isMentor) {
    try {
      Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const url = isMentor ?  Constants.getConfig('CREATE_MENTEE_GOAL').replace('${email_id}', data.email_id) : Constants.getConfig('CREATE_GOAL').replace('${email_id}', data.email_id);
      const request = new Request(this.props);
      let response = await request.putNew(url, data);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      return response;
    } catch (exception) {
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([400, 409, 422, 404, 500].includes(exception.status)) {
        const errorMessage = exception.error;
        Alert.alert('Whoops', errorMessage);
      }
      throw exception;
    }
  }

  async viewGoal(email_id, goal_name) {
    try {
      Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const url = Constants.getConfig('VIEW_GOAL');
      const request = new Request(this.props);
      let response = await request.getNew(url + email_id + '/' + goal_name);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      // console.log("viewGoal response :: ", response.body, " --- ", response.status);
      return response;
    } catch (exception) {
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([400, 409, 422, 404, 500].includes(exception.status)) {
        const errorMessage = exception?.error?.body
          ? JSON.parse(exception.error.body).error
          : exception.error;
        Alert.alert('Whoops', errorMessage);
      }
      return {
        definitions: [],
        mentor_email_id: '',
        updation_datetime_iso: '',
        status: '',
        remarks: '',
        email_id: '',
        commencement_date: '',
        expected_completion_date: '',
        goal_name: '',
      };
    }
  }

  async startGoal(data) {
    try {
      Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const url = Constants.getConfig('START_GOAL');
      const request = new Request(this.props);
      let response = await request.postNew(url, data);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      // console.log("createGoal response :: ", response.body, " --- ", response.status);
      return response;
    } catch (exception) {
      // console.log("startGoal catch", exception);
      // exception.error_message = "Something went wrong, please try again!!";
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([404, 500].includes(exception.status)) {
        const errorMessage = exception?.error?.body
          ? JSON.parse(exception.error.body).error
          : exception.error;
        Alert.alert('Whoops', errorMessage);
      }
      throw exception;
    }
  }

  async updateGoal(data, isMentor) {
    try {
      Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const url = isMentor ?  Constants.getConfig('EDIT_MENTEE_GOAL').replace('${email_id}', data.email_id) : Constants.getConfig('EDIT_GOAL').replace('${email_id}', data.email_id);
      const request = new Request(this.props);
      let response = await request.putNew(url, data);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      return response;
    } catch (exception) {
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([400, 409, 422, 404, 500].includes(exception.status)) {
        const errorMessage = exception?.error?.body
          ? JSON.parse(exception.error.body).error
          : exception.error;
        Alert.alert('Whoops', errorMessage);
      }
      throw exception;
    }
  }

  async deleteGoal(email_id, goal_name) {
    try {
      Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const url = Constants.getConfig('DELETE_GOAL');
      const request = new Request(this.props);
      let response = await request.deleteNew(url + email_id + '/' + goal_name);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      // console.log("deleteGoal response :: ", response.body, " --- ", response.status);
      return response;
    } catch (exception) {
      // console.log("deleteGoal catch", exception);
      // exception.error_message = "Something went wrong, please try again!!";
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([400, 404, 500].includes(exception.status)) {
        const errorMessage = exception?.error?.body
          ? JSON.parse(exception.error.body).error
          : exception.error;
        Alert.alert('Whoops', errorMessage);
      }
      throw exception;
    }
  }
}

export default GoalsService;
