import { KeyboardAvoidingView, ScrollView, View } from 'react-native';

import TextElement from '../../../elements/Text';
import ViewWithBack from '../../../elements/ViewWithBack';
import { getDate } from '../../../utils/utils';
import style from './styles/moduleStyle';
import { useEffect } from 'react';
import { useIsFocused } from '@react-navigation/native';
import { useSelector } from 'react-redux';

const getName = require('../../../../utils/NameMapping').getName;

const Module = (parentProps) => {
    const props = { ...parentProps, ...parentProps.route.params };
    const { moduleId, spaceId } = props.route.params;
    const spaces = useSelector((state) => state.spaces.value);
    const space = spaces.filter(space => spaceId == space.id)[0];
    const modules = Object.keys(space).length ? space.modules : [];
    const module = moduleId ? modules.filter((module) => module.id == moduleId)[0] : {};
    // console.log("module", module)
    // console.log("space", space);
    const isFocused = useIsFocused();

    const fetchData = () => {
        if (space && Object.keys(space).length) {
            // console.log("space found Module page", space);
            // setIsApiDataLoaded(true);
        }
    };

    useEffect(() => {
        if (isFocused) {
            fetchData();
        }
    }, [isFocused]);

    return (
        <ViewWithBack pageName="Module"  {...props} backAction={() => {
            // props.navigation.goBack();
            props.navigation.navigate('Space');
        }}>
            <ScrollView
                showsVerticalScrollIndicator={false}
                showsHorizontalScrollIndicator={false}
            >
                <KeyboardAvoidingView behavior="position" style={style.container}>
                    <View style={style.padding5}>
                        <View style={style.divider}></View>
                        <View style={style.infoContainer}>
                            <View style={style.spaceModuleName}>
                                <TextElement theme={'DARK'} style={style.textStyle}>Space : {space.display_name}</TextElement>
                                <View style={style.divider5}></View>
                                <TextElement theme={'DARK'} style={style.textStyle}>Module : {module.display_name}</TextElement>
                            </View>
                            <View style={style.divider5}></View>
                            <TextElement style={style.text}>{module.description}</TextElement>

                            <TextElement style={style.text}>Created by {module.created_by ? getName(module.created_by) : '-'} on {module.created_at ? getDate(module.created_at) : '-'}</TextElement>

                        </View>
                        <View style={style.divider}></View>
                        <View style={style.bottomSpace}></View>
                    </View>
                </KeyboardAvoidingView>
            </ScrollView>
        </ViewWithBack>
    );
};

export default Module;