import { createSlice } from '@reduxjs/toolkit';

const counterSlice = createSlice({
  name: 'mentorStats',
  initialState: {
    stats: {},
    connections: [],
    mentees: []
  },
  reducers: {
    mentorReducer: (state, action) => {
      // console.log('mentor reducer called', action);
      state.stats = getStats(action.payload.mentorConnections);
      state.connections = action.payload.mentorConnections;
      state.mentees = [...action.payload.mentorConnections.accepted].map((mentee) => mentee.created_by);
    },
  },
});

const getStats = (result) => {
  return {
    accepted: result.accepted.length,
    pending: result.pending.length,
    cancelled: result.cancelled.length,
  };
};

export const { mentorReducer } = counterSlice.actions;
export default counterSlice.reducer;