import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    alignItemCenter: {
        alignItems: 'center'
    },
    loadingText: {
        fontSize: 11,
        color: '#ADADAD',
        marginTop: 10
    },
    createdBy: { fontSize: 12 },
    icon: { backgroundColor: 'transparent' },
    noResultContainer: { alignItems: 'center' },
    noResultsText: { fontSize: 11, color: '#ADADAD' },
    spaceName: {
        fontWeight: 'bold',
        fontSize: 15,
    },
    listContainer: {
        backgroundColor: '#F5F5F5',
        padding: 10,
        borderWidth: 1.5,
        borderColor: '#ffffff',
    },
    divider5: { height: 5 },
    bottomSpace: { height: 70 },
    spaceRow: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    cancelButton: {
        width: '15%',
        backgroundColor: '#f5f5f5',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    buttonText: {
        fontSize: 14,
        color: '#7D8792',
        fontWeight: 'bold',
    },
    addButtonContainer: {
        alignItems: 'flex-end',
        marginBottom: 5,
    },
    addButton: {
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 10,
    },
    addText: {
        fontWeight: 'bold',
        fontSize: 16,
    },
    searchResultText: {
        fontWeight: 'bold',
        padding: 5,
        color: '#FFFFFF',
    },
    rowContainer: { flexDirection: 'row' },
    scrollview: { backgroundColor: '#ffffff' },
    keyboard: { height: '100%', justifyContent: 'center' },
    divider2: { height: 2 },
    spaceName: { fontWeight: 'bold', fontSize: 15},
    spaceNameContainer: { width: '85%' },
    flex5: { flex: 5 },
    spaceNameText: { width: '100%', height: 50 },
    centerAlign: { alignItems: 'center' },
    info: { fontSize: 11, color: '#ADADAD' },
});


