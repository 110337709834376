import * as React from 'react';

import { Dimensions, ImageBackground, View } from 'react-native';

import ApplicationStorage from '../../utils/ApplicationStorage';
import Back from '../../Background.jpg';
import ButtonElement from '../elements/Button';
import Constants from '../../utils/Constants';
import Request from '../../dal/RequestNew';
import ViewWithBack from '../elements/ViewWithBack';
import { loaderReducer } from '../elements/customLoader/customLoaderSlice';
import { memo } from 'react';
import style from './Style';
import { useDispatch } from 'react-redux';

const { height } = Dimensions.get('window');

const Logout = (parentProps) => {
    // console.log("parentProps", parentProps)
    const props = { ...parentProps, ...parentProps.route.params };
    const dispatch = useDispatch();
    // console.log("props", props)
    return (
        <ViewWithBack pageName={'Logout'}
            backAction={() => {
                props.navigation.navigate('Home', { screen: 'Profile' });
            }}
        >
            <ImageBackground source={Back} resizeMode="cover" style={style.image}>

                <View style={{ marginTop: (height - 100) / 2, ...style.container }}>
                    <ButtonElement
                        theme={'LIGHT'}
                        onPress={() => {
                            dispatch(loaderReducer({ loaderProperties: { show: true } }));
                            const logoutURL = Constants.getConfig('USER_LOGOUT');
                            const requestNew = new Request(props);
                            requestNew.getNew(logoutURL).then(() => {

                                ApplicationStorage.removeAll().then(() => {
                                    dispatch(loaderReducer({ loaderProperties: { show: false } }));
                                    props.navigation.navigate('Login');
                                }).catch(() => {
                                    // console.log("error", error);
                                });
                            }).catch(() => {
                                ApplicationStorage.removeAll().then(() => {
                                    dispatch(loaderReducer({ loaderProperties: { show: false } }));
                                    props.navigation.navigate('Login');
                                }).catch(() => {
                                    // console.log("error", error);
                                });
                                dispatch(loaderReducer({ loaderProperties: { show: false } }));
                            });
                        }}>Logout</ButtonElement>
                </View>
            </ImageBackground>
        </ViewWithBack>
    );
};

export default memo(Logout, () => {
    return true;
});