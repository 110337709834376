// import { chartConfig, chartData } from './Metadata';

import React, { useState } from 'react';

import Fab from '../../elements/Fab';
import GoalsTable from './GoalsTable';
import { View } from 'react-native';
import ViewElement from '../../elements/View';
import { memo } from 'react';
import style from './styles/goalStyle';

const Goals = (parentProps) => {
  // console.log("Goals props", parentProps)
  const props = parentProps.route.params;
  const [tabIndex, setTabIndex] = useState(0);
  // console.log("parentProps", parentProps)
  //   console.log('tabIndex in goals', tabIndex);
  const is_mentor = tabIndex === 1 ? true : false;

  return (
    <ViewElement pageName='Goals' previousPath='/' {...props}>
      <View style={style.container}>
        {/* <View style={style.goalsView}> */}
        {/* <View style={style.divider15}></View> */}
        {/* <TextElement theme={'DARK'} style={style.headerText}>Goals Statistics</TextElement> */}
        {/* <View style={style.divider15}></View> */}
        <GoalsTable {...props} {...parentProps} tabIndex={tabIndex} setTabIndex={setTabIndex} isMentor={is_mentor} />
      </View>
      <Fab
        testID='create-goal'
        icon='plus'
        data={{ tabIndex: tabIndex }}
        onPress={() => {
          parentProps.navigation.navigate('Creategoal', { tabIndex: tabIndex, isMentor: is_mentor });
        }}
      />
    </ViewElement>
  );
};

export default memo(Goals, (prevProps, nextProps) => {
  if (prevProps.theme !== nextProps.theme) return false;
  else return true;
});
