import * as React from 'react';
import { Image, Platform, View } from 'react-native';
import Constants from 'expo-constants';
import { useSelector } from 'react-redux';

const statusBarHeight = Constants.statusBarHeight;

const CustomLoader = () => {
    const loaderProperties = useSelector((state) => state.customLoader.value);
    // console.log("loaderProperties", loaderProperties);
    const containerStyle = {
        height: 5,
        width: '100%',
        position: 'absolute',
        top: Platform.OS === 'ios' ? statusBarHeight : 0,
        // elevation: 3
    };

    return (
        loaderProperties.show ?
            <Image
                style={containerStyle}
                source={require('../../../assets/progress.gif')}
            />
            : <View></View>
    );
};

export default CustomLoader;