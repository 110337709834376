import ColorConstant from '../../utils/ColorConstant.json';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  containerStyle: { padding: 20, top: '10%', height: '30%', width: '100%', alignItems: 'center' },
  container: {
    flexDirection: 'row',
    alignContent: 'space-around',
    zIndex: 9,
  },
  searchbar: {
    width: '88%',
    marginRight: 5,
  },
  filterButton: {
    borderRadius: 5,
    backgroundColor: '#f5f5f5',
    alignItems: 'center',
  },
  filterIcon: {
    backgroundColor: 'transparent',
  },
  closeIcon: {
    alignItems: 'flex-end',
    backgroundColor: 'transparent',
  },
  searchView: { flex: 1 },
  divider10: { height: 10 },
  cardContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    width: 157,
    backgroundColor: '#E6E6E6',
    borderRadius: 10,
    padding: 2,
    margin: 2,
    alignItems: 'center',
    height: 195,
  },
  name: {
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  textStyle: { fontSize: 12 },
  divider5: { height: 5 },
  connectButton: {
    padding: 5,
    backgroundColor: '#222745',
    borderRadius: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: 120,
  },
  divider100: { height: 100 },
  divider22: { height: 22 },
  noMentorAvailable: { color: ColorConstant.LIGHT?.COLOR || '#000000' },
  drawerView: { backgroundColor: '#ffffff', height: '100%', marginBottom: 300 },
  input: {
    backgroundColor: ColorConstant.LIGHT?.BACKGROUND_COLOR_LIGHT_SHADE || '#ffffff',
    borderRadius: 10,
    marginBottom: 10,
  },
  sectionTitle: {
    flexShrink: 1,
    padding: 10,
    fontWeight: 'bold',
    fontSize: 16,
  },
  inputTitle: {
    flexShrink: 1,
    padding: 10,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  button: { padding: 10, marginRight: 20, margin: 10, alignItems: 'center', borderRadius: 5 },
  buttonRow: { flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'flex-end' },
  keyboard: { height: '100%', justifyContent: 'center' },
  addConnectionModal: {
    backgroundColor: ColorConstant.LIGHT?.BACKGROUND_COLOR_MEDIUM_SHADE || 'white',
    width: 350,
    padding: 20,
  },
  modalButton: {
    backgroundColor: ColorConstant.LIGHT?.BACKGROUND_COLOR_MEDIUM_SHADE || 'white',
  },
  mandatoryContainer: { paddingLeft: 10, width: '100%', borderRadius: 10 },
  mandatory: { color: '#F47765' },
  cardStyle: { backgroundColor: ColorConstant.LIGHT?.BACKGROUND_COLOR_MEDIUM_SHADE || '#f5f5f5' },
  searchNavigationContainer: {
    flex: 1,
    // justifyContent: 'center',
    backgroundColor: '#212644',
    display: 'flex',
    flexDirection: 'row',
  },
  // searchNavigatioMenu: {
  //     backgroundColor: '#f5f5f5',
  //     borderBottomRightRadius: 5,
  //     borderTopRightRadius: 5,
  //     height: '100%',
  //     flex: 2.5
  // },
  advancedSearchContainer: { flexDirection: 'row' },
  closeContainer: { flex: 1, alignItems: 'flex-end', padding: 5 },
  mainScreenContainer: { 
    backgroundColor: '#212644', 
  flex: 1 },
  centerAlign: { alignItems: 'center' },
  infoFeedback: { fontSize: 11, color: '#ADADAD', marginTop: 10 },
  safeArea: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#212644',
  },
  connectButtonContainer1: {
    width: '100%',
    position: 'absolute',
    bottom: 2,
    // backgroundColor: 'pink'
  },
  connectButtonContainer2: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  infoIcon: { backgroundColor: '#E6E6E6' },
  info: { display: 'flex', justifyContent: 'flex-end' },
  infoContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    width: '100%',
  },
  profileImage: { marginTop: -25 }
});