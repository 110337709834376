import * as React from 'react';

import { ScrollView, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { Avatar } from 'react-native-paper';
import CardScrollView from './CardScrollView';
import Elements from '../../elements';
import Fab from '../../elements/Fab';
import JourneyService from '../../../service/JourneyService';
import TextElement from '../../elements/Text';
import ViewElement from '../../elements/View';
import { getUserIdentity } from '../../../utils/UserIdentity';
import { journeyReducer } from './JourneySlice';
import { menteeJourneyCard } from './Metadata';
import style from './styles/homeStyle';
import { useIsFocused } from '@react-navigation/native';
import { SceneMap, TabBar, TabView } from 'react-native-tab-view';

const Stats = () => {
  const learnerJourneys = useSelector((state) => state.learnerJourneys.value);
  return (
    <View style={style.statsContainer}>
      <TextElement style={style.statsHeader}>Statistics</TextElement>
      <View style={style.statsRow}>
        <View style={style.container}>
          <TextElement style={style.enrolled}>{learnerJourneys?.stats?.enrolled || 0}</TextElement>
          <TextElement style={style.statsCount}>Enrolled</TextElement>
        </View>
        <View style={style.container}>
          <TextElement style={style.completed}>
            {learnerJourneys?.stats?.completed || 0}
          </TextElement>
          <TextElement style={style.statsCount}>Completed</TextElement>
        </View>
      </View>
    </View>
  );
};

const HomeJourney = (parentProps) => {
  const props = parentProps;
  const learnerJourneys = useSelector((state) => state.learnerJourneys.value);
  const profile = useSelector((state) => state.profile.value);
  // console.log("learnerJourneys", learnerJourneys);
  const dispatch = useDispatch();
  // console.log("journey", props.applicationData)
  const [activeTab, setActiveTab] = useState(0);
  const [userIdentity, setUserIdentity] = React.useState({});
  const [isApiDataLoaded, setIsApiDataLoaded] = useState(false);
  const [isSearchDataLoaded, setIsSearchDataLoaded] = useState(false);
  const isFocused = useIsFocused();

  const [journeySearchData, setJourneySerachData] = useState([]);

  const journeyService = new JourneyService(props);

  const routes = [
    { key: 'activity', title: 'Activity' },
    { key: 'all', title: 'All' },
  ];

  const renderScene = SceneMap({
    activity: () => <ActivityScreen
      learnerJourneys={learnerJourneys}
      {...props}
      isApiDataLoaded={isApiDataLoaded}
    />,
    all: () => <AllJourneyScreen
      learnerJourneys={learnerJourneys}
      {...props}
      search={search}
      journeySearchData={journeySearchData}
      isApiDataLoaded={isApiDataLoaded}
      setJourneySerachData={setJourneySerachData}
      isSearchDataLoaded={isSearchDataLoaded}
      setIsSearchDataLoaded={setIsSearchDataLoaded}
      isMentor={profile.is_mentor}
    />,
  });



  const fetchData = () => {
    getUserIdentity().then((uIdentity) => {
      setUserIdentity(uIdentity);
    });
    if (learnerJourneys && Object.keys(learnerJourneys.stats).length) {
      setIsApiDataLoaded(true);
    }
    journeyService.fetchLearnerJourneys(isApiDataLoaded).then((journeyResult) => {
      // console.log("journeyResult", journeyResult);
      setIsApiDataLoaded(true);
      dispatch(journeyReducer({ journeys: journeyResult }));
    });
  };

  const search = (searchField) => {
    // console.log("search query", searchField)
    journeyService.searchJourney(searchField, false).then((journeyResult) => {
      // console.log("search result", journeyResult);
      setIsSearchDataLoaded(true);
      let journeys = [];
      if (Object.keys(journeyResult).length) {
        const { teamJourneys, globalJourneys } = journeyResult;
        journeys = [...teamJourneys, ...globalJourneys];
      }
      // console.log("journeys", journeys);
      // setJourneySerachData(journeys);
      setJourneySerachData(journeys);
    });
  };

  useEffect(() => {
    if (isFocused) {
      fetchData();
    }
  }, [isFocused]);


  return (
    <ViewElement>
      
      <TabView
        navigationState={{ index: activeTab, routes }}
        renderScene={renderScene}
        onIndexChange={(index) => setActiveTab(index)}
        renderTabBar={props => <TabBar style={{backgroundColor: '#3C436C'}} {...props} />}
      />


      {userIdentity.user_role === 'admin' || userIdentity.user_role === 'jd' ? (
        <Fab
          icon='pencil-plus'
          onPress={() => {
            props.navigation.navigate('DesignerDashboard');
          }}
        />
      ) : (
        <View />
      )}
    </ViewElement>
  );
};

const ActivityScreen = (props) => {
  // console.log("ActivityScreen props", props.learnerJourneys);

  const { learnerJourneys, isApiDataLoaded, ...homeJourneyProps } = props;
  return (
    <View style={style.activityContainer}>
      <ScrollView
        style={style.scrollView}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}>
        <Stats />
        <TextElement theme='LIGHT' style={style.sectionTitle}>
          Enrolled Journeys
        </TextElement>
        <CardScrollView
          data={learnerJourneys?.enrolledJourneys}
          isApiDataLoaded={isApiDataLoaded}
          type='Learner'
          {...homeJourneyProps}
        />

        <View style={style.separator}></View>
        <View>
          <TextElement theme='LIGHT' style={style.sectionTitle}>
            Completed Journeys
          </TextElement>
          <CardScrollView
            data={learnerJourneys?.completedJourneys}
            isApiDataLoaded={isApiDataLoaded}
            type='Learner'
            {...homeJourneyProps}
          />
        </View>

        <View style={style.separator}></View>

        <View>
          <TextElement theme='LIGHT' style={style.sectionTitle}>
            Top Picks for you
          </TextElement>
          <CardScrollView
            data={learnerJourneys?.teamJourneys}
            isApiDataLoaded={isApiDataLoaded}
            type='Learner'
            {...homeJourneyProps}
          />
          {/** Top Picks for you should display Global Journeys for only phase 1 */}
          {/* <CardScrollView data={learnerJourneys?.globalJourneys} isApiDataLoaded={isApiDataLoaded} type="Learner" {...homeJourneyProps} /> */}
        </View>
        {/* <View style={style.activitiyBottomSpacer}></View> */}
      </ScrollView>
    </View>
  );
};

const AllJourneyScreen = (props) => {
  const {
    learnerJourneys,
    search,
    journeySearchData,
    isApiDataLoaded,
    setJourneySerachData,
    isSearchDataLoaded,
    setIsSearchDataLoaded,
    isMentor,
    ...homeJourneyProps
  } = props;

  // console.log('isMentor', isMentor);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchClicked, setSearchClicked] = useState(false);

  const onChangeSearch = (query) => {
    // console.log("onchnage search called", query);
    if (query) {
      setSearchQuery(query);
    } else {
      setSearchQuery('');
      setJourneySerachData([]);
      setSearchClicked(false);
      setIsSearchDataLoaded(false);
    }
  };

  return (
    <View style={style.allJourneysContainer}>
      <View style={style.searchBarContainer}>
        <Elements.Searchbar
          placeholder='Search'
          onSubmitEditing={() => {
            setSearchClicked(true);
            search(searchQuery);
          }}
          onChangeText={onChangeSearch}
          value={searchQuery}
        />
      </View>
      <ScrollView
        style={style.allJourneyScrollView}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}>
        {/* {console.log("height:", height, "height-293:", height - 293, "% 61:", height * (50 / 100))} */}
        {/* <TextElement theme="LIGHT" style={{ fontWeight: 'bold', padding: 5 }}>Search by tag(*beta)</TextElement>

                <ScrollViewElement>
                    <View style={{ flexDirection: 'row', padding: 5 }}>
                        {
                            categories.map((category, index) => {
                                return <Chip key={index} style={styles.tagStyle} textStyle={styles.tagTextStyle} onPress={() => {
                                    search(category);
                                }}>{category} </Chip>;
                            })
                        }
                    </View>
                </ScrollViewElement> */}

        {searchClicked ? (
          isSearchDataLoaded ? (
            journeySearchData.length ? (
              <View>
                <TextElement theme='LIGHT' style={style.sectionTitle}>
                  Search Result
                </TextElement>
                <CardScrollView
                  data={journeySearchData}
                  isApiDataLoaded={isApiDataLoaded}
                  {...homeJourneyProps}
                  type='Learner'
                />
              </View>
            ) : (
              <View>
                <TextElement theme='LIGHT' style={style.sectionTitle}>
                  Search Result
                </TextElement>
                <View style={style.centeredView}>
                  <Avatar.Icon
                    icon='email-open-outline'
                    color='#ADADAD'
                    size={70}
                    style={style.icon}
                  />
                  <TextElement style={style.noResultsText}>No Journeys Found!</TextElement>
                </View>
              </View>
            )
          ) : (
            <View style={style.centeredView}>
              <TextElement style={style.loadingText}>Loading...</TextElement>
            </View>
          )
        ) : (
          <View></View>
        )}

        <View style={style.separator}></View>

        {isMentor ? (
          <View>
            <TextElement theme='LIGHT' style={style.sectionTitle}>
              {'Mentee\'s Journeys'}
            </TextElement>
            <CardScrollView
              data={[menteeJourneyCard]}
              isApiDataLoaded={isApiDataLoaded}
              {...homeJourneyProps}
              type='Mentor'
            />
          </View>
        ) : (
          <View></View>
        )}

        <View>
          <TextElement theme='LIGHT' style={style.sectionTitle}>
            Global Journeys
          </TextElement>
          <CardScrollView
            data={learnerJourneys?.globalJourneys}
            isApiDataLoaded={isApiDataLoaded}
            {...homeJourneyProps}
            type='Learner'
          />
        </View>
        <View style={style.separator}></View>

        <View>
          <TextElement theme='LIGHT' style={style.sectionTitle}>
            Individual Learning Pathway
          </TextElement>
          <CardScrollView
            data={learnerJourneys?.teamJourneys}
            isApiDataLoaded={isApiDataLoaded}
            {...homeJourneyProps}
            type='Learner'
          />
        </View>
        <View style={style.separator}></View>

        {/* <View style={style.marginBottomSpacer}></View> */}
      </ScrollView>
    </View>
  );
};

export default HomeJourney;
