import * as React from 'react';

import ButtonElement from '../../elements/Button';
import { Text } from 'react-native-paper';
import TextElement from '../../elements/Text';
import { View } from 'react-native';
import style from './styles/goalFooterStyle';

const GoalFooter = ({ goal, updateGoal, startGoal }) => {
  return (
    <View>
      {updateGoal ? (
        <View style={style.updateGoalContainer}>
          <ButtonElement
            testId='update-goal'
            mode='contained'
            style={style.updateGoal}
            onPress={() => updateGoal()}>
            <TextElement style={style.buttonText}>Update Goal</TextElement>
          </ButtonElement>
        </View>
      ) : (
        <View>
          {goal && goal.status === 'created' ? (
            <View style={style.startGoalContainer}>
              <ButtonElement
                testId='start-goal'
                mode='contained'
                style={style.startGoal}
                onPress={() => startGoal()}>
                <TextElement style={style.buttonText}>Start Goal</TextElement>
              </ButtonElement>
            </View>
          ) : (
            <Text></Text>
          )}
        </View>
      )}
    </View>
  );
};

export default GoalFooter;
