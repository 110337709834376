import Alert from '../components/elements/Alert';
import Constants from '../utils/Constants';
import Request from '../dal/RequestNew';
import Store from '../Store/Store';
import { btoa } from 'react-native-quick-base64';
import { convertToJSON } from './../dal/Parser';
import { loaderReducer } from '../components/elements/customLoader/customLoaderSlice';

class ConnectionsService {

    constructor(props) {
        this.props = props;
    }

    async getMenteeConnections(email_id, isApiDataLoaded) {

        try {
            if (!isApiDataLoaded)
                Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
            const url = Constants.getConfig('MENTEE_CONNECTIONS');
            const request = new Request(this.props);
            let response = await request.getNew(url + email_id);
            // console.log("getMenteeConnections response :: ", response);
            Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
            return response;

        } catch (exception) {
            // console.log("exception", exception);
            Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
            if (exception.status == 500) {
                Alert.alert('Whoops',exception.error);  
            }
            return {
                'accepted': [],
                'pending': [],
                'cancelled': []
            };
        }
    }

    async getMentorConnections(email_id, isApiDataLoaded) {

        try {
            if (!isApiDataLoaded)
                Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
            const url = Constants.getConfig('MENTOR_CONNECTIONS');
            const request = new Request(this.props);
            let response = await request.getNew(url + email_id);
            Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
            return response;

        } catch (exception) {
            Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
            if (exception.status == 500) {
              Alert.alert('Whoops',exception.error);  
            }
            return {
                'accepted': [],
                'pending': [],
                'cancelled': []
            };
        }
    }

    async getUserProfileData(connectionName) {
        try {
            const url = Constants.getConfig('USER_PROFILE');

            const searchInput = {
                areaOfExpertise: '',
                skills_softSkill: '',
                skills_tools: '',
                skills_technologies: '',
                communicationModes: '',
                name: connectionName,
                status: '',
                location: '',
                email: ''
            };

            let jsonData = convertToJSON(searchInput);
            jsonData = btoa(JSON.stringify(jsonData));
            const request = new Request(this.props);
            let response = await request.getNew(url + jsonData);
            return response;
        } catch (exception) {
            if (exception.status == 500) {
                Alert.confirm('Whoops!',typeof exception.error == 'string' ? exception.error : 'Sorry, something unexpected happened.');
            }
            return {};
        }
    }

    async deleteConnection(mentee, mentor, deleteMessage, action) {
        try {
            let url = Constants.getConfig('DELETE_CONNECTION');
            url = url.replace('${mentee}', mentee);
            url = url.replace('${mentor}', mentor);
            const data = {
                'reason_to_delete': deleteMessage
            };
            const request = new Request(this.props);
            let response = await request.deleteNew(url, data);
            let alertMessage;
            if (action == 'DeleteConnection') {
                alertMessage = 'Connection deleted.';
            }
            if (action == 'DeleteRequest') {
                alertMessage = 'Connection request deleted.';
            }
            if (action == 'CancelRequest') {
                alertMessage = 'Connection request cancelled.';
            }

            Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
            Alert.alert('Success',alertMessage);
            return response;

        } catch (exception) {
            // console.log("exception", exception)
            Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
            if (exception.status == 500) {
              Alert.alert('Whoops',exception.error);  
            }
            if (exception.status == 404) {
              Alert.alert('Whoops',exception.error);  
            }
            return {};
        }
    }

    async acceptConnection(mentee, mentor) {
        try {
            let url = Constants.getConfig('ACCEPT_CONNECTION');
            url = url.replace('${mentee}', mentee);
            url = url.replace('${mentor}', mentor);
            const data = {
                'status': 'accepted'
            };
            const request = new Request(this.props);
            let response = await request.putNew(url, data);
            Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
            const alertMessage = 'Connection request approved.';
            Alert.alert('Success',alertMessage);
            return response;
        } catch (exception) {
            Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
            if ([500, 400, 422].includes(exception.status)) {
              Alert.alert('Whoops',exception.error);  
            }
            return {};
        }
    }

    async cancelConnection(mentee, mentor, cancelMessage) {
        try {
            let url = Constants.getConfig('CANCEL_CONNECTION');
            url = url.replace('${mentee}', mentee);
            url = url.replace('${mentor}', mentor);

            const data = {
                'status': 'cancelled',
                'reason_to_cancel': cancelMessage
            };
            const request = new Request(this.props);
            let response = await request.putNew(url, data);
            Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
            const alertMessage = 'Connection Request cancelled.';
            Alert.alert('Success',alertMessage);
            return response;
        } catch (exception) {
            Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
            if ([500, 400, 422].includes(exception.status)) {
              Alert.alert('Whoops',exception.error);  
            }
            return {};
        }
    }

}

export default ConnectionsService;