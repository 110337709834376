import { StyleSheet } from 'react-native';
import journeyConfig from '../../JourneyConfig.json';

export default StyleSheet.create({
    container: {
        height: '100%',
        justifyContent: 'center',
    },
    contentContainer: {
        padding: 5,
    },
    header: {
        flexDirection: 'row-reverse',
        alignItems: 'center',
    },
    addButton: {
        backgroundColor: 'transparent',
    },
    addButtonLabel: {
        fontWeight: 'bold',
        fontSize: 16,
    },
    topicsHeader: {
        flex: 4,
    },
    topicsHeaderText: {
        color: '#fff',
        fontWeight: 'bold',
        fontSize: 16,
    },
    journeyName: { fontWeight: 'bold', color: '#fff', fontSize: 16 },
    description: { color: '#fff', lineHeight: 15, fontSize: 14 },
    createdBy: { color: '#fff', lineHeight: 20, fontSize: 13 },
    saveButtonContainer: {
        alignItems: 'flex-end',
    },
    saveButton: {
        backgroundColor: journeyConfig.travelexColors.actionBlue,
        width: 90,
    },
    divider: { height: 10 },
    divider5: { height: 5 },
    journeyInfo: { borderWidth: .5, borderColor: '#fff', padding: 10 },
    addTopiccontainer: { flexDirection: 'row', alignItems: 'center' },
    bottomSpace: { height: 70 }
});


