const colors = {
    'created': '#FFAA33',
    'inProgress': '#00BFFF',
    'completed': '#50C878',
    'aborted': '#CD2702'
};
const orderedDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
const chartDataLabels = ['Created', 'In Progress', 'Completed', 'Aborted'];


const jobLevelList = [
    {
        label: 'Senior Executive',
        value: '1',
    },
    {
        label: 'Executive',
        value: '2',
    },
    {
        label: 'Director/Head of Department',
        value: '3',
    },
    {
        label: 'Senior Manager/Senior SME',
        value: '4',
    },
    {
        label: 'Manager/SME',
        value: '5',
    },
    {
        label: 'Senior colleague',
        value: '6',
    },
    {
        label: 'Intermediate colleague',
        value: '7',
    },
    {
        label: 'Associate colleague',
        value: '8'
    }
];

const daysList = [
    { value: 'Monday', label: 'Monday' },
    { value: 'Tuesday', label: 'Tuesday' },
    { value: 'Wednesday', label: 'Wednesday' },
    { value: 'Thursday', label: 'Thursday' },
    { value: 'Friday', label: 'Friday' },
    { value: 'Saturday', label: 'Saturday' },
    { value: 'Sunday', label: 'Sunday' }
];
const commList = [
    { value: 'Face To Face', label: 'Face To Face' },
    { value: 'Teams', label: 'Teams' },
];



export {
    colors,
    jobLevelList,
    chartDataLabels,
    orderedDays,
    daysList,
    commList
};