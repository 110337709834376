import { StyleSheet } from 'react-native';
import journeyConfig from '../../../JourneyConfig.json';

export default StyleSheet.create({
    keyboard: { height: '100%', justifyContent: 'center' },
    textStyle: { fontWeight: 'bold', color: '#fff', fontSize: 16 },
    text: { color: '#fff', lineHeight: 20, fontSize: 13 },
    divider:{ height: 10 },
    labelStyle: { fontSize: 16, color: '#7D8792', fontWeight: 'bold' },
    bottomSpace: { height: 70 },
    buttonContainer: { alignItems: 'flex-end' },
    button: { backgroundColor: journeyConfig.travelexColors.actionBlue, width: 90 },
    infoContainer:{ borderWidth: .5, borderColor: '#fff', padding: 10 },
    padding5: { padding: 5 },
    spaceName: { flex: 4 },

});
