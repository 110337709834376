import { StyleSheet, Text, TouchableOpacity } from 'react-native';
import { execueOperation } from './index';

const style = StyleSheet.create({
    bold: {
        fontWeight: 'bold',
        color: '#ffffff'
    }
});

const HeadingElement = ({ text, setText, command, textRef }) => {
    return (
        <TouchableOpacity
            onPress={() => {
                const getText = execueOperation(text, command);
                textRef.current.focus();
                setText(getText);
            }}>
            <Text style={style.bold}>{command.toUpperCase()}</Text>
        </TouchableOpacity>
    );
};

export default HeadingElement;