import { ScrollView, View } from 'react-native';

import { Appbar } from 'react-native-paper';
import { SafeAreaView } from 'react-native';
import { TextInput } from 'react-native';
import { useState } from 'react';

const ViewWithScroll = (props) => {
    const [searchText, setSearchText] = useState('');
    const [isSearch, setIsSearch] = useState(false);
    return (
        <SafeAreaView style={{ backgroundColor: props.topColor || '#212644', flex: 1, width: '100%' }}>
            {
                props.header ? (
                    <Appbar.Header style={{ backgroundColor: '#212644', padding: 5 }}>
                        {
                            props.noBackAction ?
                                <View></View>
                                :
                                <Appbar.Action icon="keyboard-backspace" onPress={props.backAction || (() => props.navigation.goBack())} color="#ffffff" />
                        }
                        {
                            isSearch ? (
                                <TextInput
                                    onChangeText={(text) => setSearchText(text)}
                                    value={searchText}
                                    style={{ backgroundColor: '#f8f8f8', width: '75%', height: 40, borderRadius: 5 }}
                                    placeholder='  Search here'
                                    onSubmitEditing={() => {
                                        if (props.searchSubmit)
                                            props.searchSubmit(searchText);
                                    }}
                                />
                            )
                                :
                                (
                                    <Appbar.Content title={props.pageName} color="#ffffff" style={{ marginLeft: -10 }} />
                                )
                        }

                        {
                            props.search ? (
                                <Appbar.Action icon="magnify" onPress={() => {
                                    setIsSearch(!isSearch);
                                    setSearchText('');
                                }} />
                            ) : <View />
                        }


                    </Appbar.Header>
                ) :
                    (
                        <View />
                    )
            }

            <View style={{ flex: 1, alignItems: 'center' }}>
                <ScrollView style={{ width: '100%', backgroundColor: props.innerColor || 'transparent' }}
                    showsVerticalScrollIndicator={false}
                    showsHorizontalScrollIndicator={false}>
                    {props.children}
                </ScrollView>
            </View>
        </SafeAreaView>
    );
};

export default ViewWithScroll;