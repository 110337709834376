import * as React from 'react';

import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Avatar } from 'react-native-paper';
import Fab from '../elements/Fab';
import FeedbackService from '../../service/FeedbackService';
import { ScrollView } from 'react-native';
import TextElement from '../elements/Text';
import TopicRow from './TopicRow';
import TouchableElement from '../elements/TouchableElement';
import { View } from 'react-native';
import ViewWithBack from '../elements/ViewWithBack';
import { feedbackReducer } from './FeedbackSlice';
import { getUserIdentity } from '../../utils/UserIdentity';
import style from './Style';
import { useIsFocused, useNavigation } from '@react-navigation/native';

const Feedback = () => {
    const navigation = useNavigation();
    const props = { navigation };
    
    const dispatch = useDispatch();
    const feedbacks = useSelector((state) => state.feedbacks.value);
    // console.log("feedbacks", feedbacks);
    const feedbackService = new FeedbackService(props);

    const [userIdentity, setUserIdentity] = React.useState({});
    const isFocused = useIsFocused();
    const [isApiDataLoaded, setIsApiDataLoaded] = useState(false);
    const [isAdminUser, setIsAdminUser] = useState(false);

    const fetchIndividualFeedback = async (emailId) => {
        if (feedbacks.length) {
            setIsApiDataLoaded(true);
        }
        // console.log("adminFetchData")
        let response = await feedbackService.getIndividualFeedbacks(isApiDataLoaded, emailId);
        if (response) {
            // console.log("response", response);
            setIsApiDataLoaded(true);
            dispatch(feedbackReducer({ 'feedbacks': response.records }));
        }
    };

    useEffect(() => {
        if (isFocused) {
            // props.navigation.closeDrawer();
            getUserIdentity().then((uIdentity) => {
                if (uIdentity) {
                    setUserIdentity(uIdentity);
                    if (uIdentity.user_role == 'admin') {
                        setIsAdminUser(true);
                    }
                    fetchIndividualFeedback(uIdentity.email_id);
                }
            });
        } else {
            flush();
        }
    }, [isFocused]);


    const flush = () => {
        setIsApiDataLoaded(false);
    };

    return (
        <ViewWithBack pageName={'Feedbacks'} {...props} backAction={() => { props.navigation.navigate('Home', { screen: 'Profile' }); }}>
            <ScrollView
                showsVerticalScrollIndicator={false}
                showsHorizontalScrollIndicator={false}
            >
                {/* <View style={{ minHeight: height * 90 / 100 }}> */}
                <View>
                    {
                        userIdentity && userIdentity.user_role === 'admin' ?
                            <View>
                                <View style={style.divider5}></View>
                                <View style={style.buttonView}>
                                    <TouchableElement
                                        onPress={() => {
                                            props.navigation.navigate('AdminFeedbacks', { userIdentity: userIdentity });
                                        }}
                                        id='see-all'
                                    >
                                        <View style={style.seeAllView}>
                                            <TextElement theme="DARK" style={style.feedackSeeAll}>See All</TextElement>
                                            <Avatar.Icon
                                                testID="see-all-feedbacks"
                                                icon="arrow-right"
                                                size={25}
                                                color="#E1E1E1"
                                                style={style.icon}
                                            />
                                        </View>
                                    </TouchableElement>
                                </View>
                                <View style={style.divider5}></View>
                                <ListFeedbacks
                                    feedbackData={feedbacks}
                                    userIdentity={userIdentity}
                                    isAdminUser={isAdminUser}
                                    isApiDataLoaded={isApiDataLoaded}
                                    {...props}
                                />
                            </View>
                            :
                            <ListFeedbacks feedbackData={feedbacks} userIdentity={userIdentity} isApiDataLoaded={isApiDataLoaded} {...props} />
                    }
                </View>
            </ScrollView>
            <Fab icon="plus" onPress={() => {
                props.navigation.navigate('Addnewfeedback');
            }} />
        </ViewWithBack>
    );
};

const ListFeedbacks = (props) => {
    const { feedbackData, userIdentity, isAdminUser, isApiDataLoaded } = props;
    return (
        isApiDataLoaded ?
            feedbackData?.length ? (
                <View>
                    <TextElement style={style.heading} theme="DARK">Your Feedbacks</TextElement>
                    {
                        feedbackData.map((feedback, index) =>
                            <TopicRow key={index + feedback.created_at} index={index} data={feedback} userIdentity={userIdentity} isAdminUser={isAdminUser} {...props} />
                        )
                    }
                </View>
            ) : (
                <View style={style.centerAlign}>
                    <Avatar.Icon icon="email-open-outline" color="#ADADAD" size={70} style={style.icon} />
                    <TextElement style={style.info}>No Feedbacks Found!</TextElement>
                </View>
            )
            :
            (
                <View style={style.centerAlign}>
                    <TextElement style={style.infoFeedback}>Loading...</TextElement>
                </View>
            )
    );
};

export default memo(Feedback, () => {
    return true;
});