import Constants from 'expo-constants';
import { Platform } from 'react-native';

const { DD_APPLICATION_ID, DD_CLIENT_TOKEN, APP_ENVIRONMENT } = Constants.expoConfig.extra;

class DatadogMonitoring {
  // async initializeDatadogWeb() {
  //   console.log('initializeDatadogWeb');
  //   const { datadogRum } = await import('@datadog/browser-rum');
  //   // console.log('process.env.DD_APPLICATION_ID', DD_APPLICATION_ID);
  //   datadogRum.init({
  //     applicationId: DD_APPLICATION_ID,
  //     clientToken: DD_CLIENT_TOKEN,
  //     env: APP_ENVIRONMENT,
  //     sessionSampleRate: 100, //100 for all, 0 for none.
  //     sessionReplaySampleRate: 100, //100 for all, 0 for none.
  //     trackResources: true,
  //     trackLongTasks: true,
  //     trackUserInteractions: true,
  //   });
  //   // datadogRum.stopSessionReplayRecording();
  // }

  async initializeDatadog() {
    const { DdSdkReactNative, DdSdkReactNativeConfiguration, SdkVerbosity } =
      await this.getExpoDatadog();

    const config = new DdSdkReactNativeConfiguration(
      DD_CLIENT_TOKEN,
      APP_ENVIRONMENT,
      DD_APPLICATION_ID,
      true,
      true,
      true
    );

    config.nativeCrashReportEnabled = true;
    config.sessionSamplingRate = 80;
    config.resourceTracingSamplingRate = 80;
    config.firstPartyHosts = ['https://mentorship.tvx-test.cloud/'];
    config.verbosity = SdkVerbosity.DEBUG;
    config.trackBackgroundEvents = true;

    DdSdkReactNative.initialize(config)
      .then((d) => {
        console.log('worked ::: ', d);
      })
      .catch((e) => {
        console.log('error ::: ', e);
      });
  }

  async setUser(email) {
    if (Platform.OS == 'web') {
      // console.log('called');
      // const { datadogRum } = await import('@datadog/browser-rum');
      // datadogRum.setUser({
      //   id: this.mask(email),
      //   name: `${this.mask(email)}`,
      // });
      // console.log('web user set');
    } else {
      const { DdSdkReactNative } = await import('expo-datadog');
      console.log('User has been set.');
      DdSdkReactNative.setUser({
        id: this.mask(email),
        name: `${this.mask(email)}`,
        platform: Platform.OS,
        platformVersion: Platform.Version,
        platformBrand: Platform.constants.Brand,
        platformOSVersion: Platform.constants.osVersion,
      });
    }
  }

  async getExpoDatadog() {
    const { DdSdkReactNative, DdSdkReactNativeConfiguration, SdkVerbosity } = await import(
      'expo-datadog'
    );
    return { DdSdkReactNative, DdSdkReactNativeConfiguration, SdkVerbosity };
  }

  mask(value) {
    const tmpValueArray = value.split('');
    const year = new Date().getFullYear();
    let ASCITotal = 0;
    // let maskName = '';

    // trimming alternate character and summing the ASCI code value
    for (let index = 0; index < tmpValueArray.length; index++) {
      const ele = tmpValueArray[index];
      if (index % 2 === 0) {
        // maskName += ele;
        ASCITotal += ele.charCodeAt(0);
      }
    }
    return ASCITotal * year;
  }
}

export default DatadogMonitoring;
