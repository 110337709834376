import { ScrollView, View } from 'react-native';
import { Text } from 'react-native-paper';
import { memo, useState } from 'react';

import { Avatar } from 'react-native-paper';
import Elements from '../../elements';
import Markdown from 'react-native-markdown-display';
import NewDropdown from '../../elements/NewDropdown';
import { definitionStatusList } from './Metadata';
import style from './styles/viewDefinitionModalStyle';

const moment = require('moment');

const ViewDefinitionModal = (props) => {
  const { handleClose, goalDefinition, pageName } = props;
  // console.log('goalDefinition in view definition modal', goalDefinition);

  const { definition, timeline, weightage_in_percentage, status, sub_items } = goalDefinition;

  return (
    <View style={style.container}>
      <PageHeader pageName={pageName} handleClose={handleClose} />
      <ScrollView
        style={style.scrollView}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}>
        <View style={style.scrollView}>
          <Elements.TextInput
            style={style.textInput}
            editable={false}
            label={
              <Text>
                Learning Theme
                <Text style={style.mandatory}> *</Text>
              </Text>
            }
            value={definition}
          />

          <Elements.TextInput
            style={style.textInput}
            editable={false}
            label={
              <Text>
                Weightage (%)
                <Text style={style.mandatory}> *</Text>
              </Text>
            }
            keyboardType='numeric'
            value={weightage_in_percentage}
          />

          <Elements.TextInput
            style={style.timelineTextInput}
            editable={false}
            label='Timeline'
            placeholder='Timeline'
            value={moment.unix(timeline).format('YYYY/MM/DD')}
          />

          <Status status={status} />

          <View style={style.actionItemsHeaderView}>
            <Elements.Text style={style.actionItemsHeader}>Action Items</Elements.Text>
          </View>

          <SubItemsSection subItems={sub_items} />
        </View>
      </ScrollView>
    </View>
  );
};

const PageHeader = ({ pageName, handleClose }) => {
  return (
    <View style={style.pageHeader}>
      <Elements.Text style={style.pageName}>{pageName}</Elements.Text>
      <Elements.Touchable onPress={handleClose} id='close'>
        <Avatar.Icon icon={'close'} size={35} color='#ffffff' style={style.icon} />
      </Elements.Touchable>
    </View>
  );
};

const SubItemsSection = ({ subItems }) => {
  if (subItems?.length) {
    const [expandPanel, setExpandPanel] = useState(new Array(subItems.length).fill(false));

    const handlePanelToggle = (index) => {
      const newExpandedPanel = [...expandPanel];
      newExpandedPanel[index] = !newExpandedPanel[index];
      setExpandPanel(newExpandedPanel);
    };

    return (
      <View style={style.narrowSection}>
        {subItems.map((subItem, index) => (
          <View key={index} style={style.fullWidth}>
            <View style={style.subItemsTitleView}>
              <Elements.TextInput
                style={{ ...style.flex5, ...style.textInput }}
                editable={false}
                label={
                  <Text>
                    Title
                    <Text style={style.mandatory}> *</Text>
                  </Text>
                }
                value={subItem.title}
              />
              <Elements.Touchable
                style={style.panelTouchable}
                onPress={() => handlePanelToggle(index)}
                id={`panel-description-${index}`}>
                <Avatar.Icon
                  icon={expandPanel[index] ? 'chevron-up' : 'chevron-down'}
                  size={35}
                  color='#000000'
                  style={style.icon}
                />
              </Elements.Touchable>
            </View>

            <View>
              {expandPanel[index] && (
                <View style={style.panelDescriptionContainer}>
                  <View style={style.panelDescription}>
                    <Markdown
                      style={{
                        heading1: style.markdownHeading,
                        heading2: style.markdownHeading,
                        heading3: style.markdownHeading,
                        heading4: style.markdownHeading,
                        heading5: style.markdownHeading,
                        heading6: style.markdownHeading,
                      }}>
                      {subItem.description}
                    </Markdown>
                  </View>
                </View>
              )}
            </View>

            {/* Apply margin to the next title when the current panel is expanded */}
            {expandPanel[index] && index < subItems.length - 1 && (
              <View style={style.marginTop5}></View>
            )}
          </View>
        ))}
      </View>
    );
  } else return <View />;
};

const Status = ({ status }) => {
  return (
    <View style={style.statusContainer}>
      <View style={style.statusText}>
        <Elements.Text>Status</Elements.Text>
      </View>
      <NewDropdown data={definitionStatusList} value={status || 'created'} />
    </View>
  );
};

export default memo(ViewDefinitionModal, (prevProps, nextProps) => {
  if (prevProps.theme !== nextProps.theme) return false;
  else return true;
});
