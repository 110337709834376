import { StyleSheet } from 'react-native';
import journeyConfig from '../../JourneyConfig.json';

export default StyleSheet.create({
    bottomSpace: { height: 70 },
    divider5: { height: 5 },
    divider10: { height: 10 },
    icon: { backgroundColor: 'transparent' },
    keyboard: { height: '100%', justifyContent: 'center' },
    container: { width: '100%', alignItems: 'center', height: '100%' },
    container1: { width: '100%', height: '100%' },
    topicContainer: { padding: 5 },
    buttonRow: { flexDirection: 'row-reverse', alignItems: 'center' },
    buttonContainer: { alignItems: 'flex-end' },
    button: { backgroundColor: journeyConfig.travelexColors.actionBlue, width: 90 },
    addContentContainer: { flexDirection: 'row', alignItems: 'center', marginRight: 10 },
    addContentButton: { fontWeight: 'bold', fontSize: 16 },
    header: { color: '#fff', fontWeight: 'bold', fontSize: 16 },
    contentsView: { flex: 4, marginLeft: 5 },
});


