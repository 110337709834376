import { createSlice } from '@reduxjs/toolkit';

const counterSlice = createSlice({
    name: 'journeyTopic',
    initialState: {
        'value': {}
    },
    reducers: {
        journeyTopicReducer: (state, action) => {
            // console.log("Reducer called", action);
            const journey_id = action.payload.journeyId;
            // console.log("journey_id", journey_id);
            state.value[journey_id] = action.payload.topics;
        }
    }

});

// Action creators are generated for each case reducer function
export const { journeyTopicReducer } = counterSlice.actions;
export default counterSlice.reducer;
