import { useEffect, useState } from 'react';

import { Avatar } from 'react-native-paper';
import TextElement from '../elements/Text';
import TouchableElement from '../elements/TouchableElement';
import { View } from 'react-native';
import { filterData } from '../utils/utils';
import journeyConfig from '../journey/JourneyConfig.json';

const MultiSelect = (props) => {
    const { data, value, onChange, label, mandatory } = props;
    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState([]);

    const valueList = value ? value.split(',') : [];

    const getTextColor = (selectedValue) => {
        // console.log(color)
        const color = valueList.includes(selectedValue) ? journeyConfig.travelexColors.actionBlue : '#000000';
        return color;
    };

    useEffect(() => {
        const updatedSelectedData = filterData(selected, 'id', data);
        // console.log("SELECTEDDATA", updatedSelectedData);
        setSelected(updatedSelectedData);
    }, [data]); //need to update selected data, if data changes

    const getValue = () => {
        const result = value ? value.split(',').includes('All') ? 'All' : value : 'Select';
        return result;
    };

    return (
        <View style={{ backgroundColor: '#f7f7f7' }}>
            <View style={{ backgroundColor: '#f7f7f7' }}>
                <TextElement style={{ padding: 5, paddingBottom: 0, fontSize: 14, color: '#7D8792', lineHeight: 20, fontWeight: 'bold' }}>{label || 'Select'}<TextElement theme={props.theme} style={{ color: '#F47765' }}>{mandatory ? ' *' : ''}</TextElement></TextElement>

                <TouchableElement
                    style={{ padding: 5, flexDirection: 'row', alignItems: 'center', borderBottomWidth: 2, borderColor: '#7D8792' }}
                    onPress={() => setIsOpen(!isOpen)}
                    id='arrow-down-drop-circle'
                >
                    <TextElement style={{ flex: 5.7, fontSize: 16 }}>{getValue()}</TextElement>
                    <Avatar.Icon icon="arrow-down-drop-circle-outline" size={35} style={{ backgroundColor: 'transparent' }} color="#000000" />

                </TouchableElement>
            </View>
            {
                isOpen ?
                    <View style={{ margin: 2 }}>
                        {
                            (data || []).map((selectedValue, index) => {
                                return (
                                    <TouchableElement
                                        id={'multiselect-checkbox-' + index}
                                        key={selectedValue.id}
                                        style={{ flexDirection: 'row', alignItems: 'center', padding: 5, backgroundColor: '#f9f9f9' }}
                                        disabled={false}
                                        onPress={() => {
                                            if (onChange) {
                                                if (valueList.includes(selectedValue.display_name)) {
                                                    if (selectedValue.display_name == 'All') {
                                                        const temp = [];
                                                        setSelected(temp);
                                                        const result = {
                                                            selectedObjects: temp
                                                        };
                                                        onChange(result);
                                                    } else {
                                                        const temp = selected.filter(obj => obj.id != selectedValue.id && obj.id != '*');
                                                        setSelected(temp);
                                                        const result = {
                                                            selectedObjects: temp
                                                        };
                                                        onChange(result);
                                                    }
                                                }
                                                else if (selectedValue.display_name == 'All') {
                                                    const temp = data;
                                                    setSelected(temp);
                                                    const result = {
                                                        selectedObjects: temp
                                                    };
                                                    onChange(result);
                                                } else {
                                                    const temp = [...selected, selectedValue];
                                                    setSelected(temp);
                                                    const result = {
                                                        selectedObjects: temp
                                                    };
                                                    onChange(result);
                                                }
                                            }
                                        }}
                                    >
                                        <Avatar.Icon size={30} icon={valueList.includes(selectedValue.display_name) ? 'checkbox-marked' : 'checkbox-blank-outline'} style={{ backgroundColor: 'transparent' }} color="#000000" />
                                        {
                                            <TextElement style={{
                                                fontSize: 14,
                                                color: getTextColor(selectedValue.display_name),
                                                fontWeight: valueList.includes(selectedValue.display_name) ? 'bold' : '400',
                                                paddingLeft: 10
                                            }}> {selectedValue.display_name} </TextElement>
                                        }

                                    </TouchableElement>
                                );
                            })
                        }
                    </View>
                    :
                    <View></View>
            }
        </View >
    );
};
export default MultiSelect;