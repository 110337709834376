import ColorConstant from '../../../../utils/ColorConstant.json';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    tabMenu: {
        flex: 3,
        padding: 7,
        alignItems: 'center',
        borderRightWidth: 1,
        borderColor: '#D8D8D8'
    },
    tabBadge: {
        fontWeight: 'bold',
        color: '#2EADB7',
        fontSize: 16
    },
    heightWeight100: {
        height: '100%',
        width: '100%'
    },
    menuChildView: {
        flexDirection: 'row',
        backgroundColor: '#f5f5f5',
        padding: 5,
        borderBottomColor: '#DADADA',
        borderBottomWidth: 2
    },
    statsView: {
        borderWidth: .5,
        borderColor: '#2E3456',
        padding: 10,
        borderRadius: 5
    },
    designerJourneyScrollView: {
        backgroundColor: '#f1f1f1',
        height: '100%'
    },
    journeyRowView: {
        flexDirection: 'row',
        backgroundColor: '#E6E6E6',
        borderRadius: 10,
        padding: 5,
        marginRight: 10,
        marginBottom: 10,
        width: '100%'
    },
    journeyIconView: { justifyContent: 'center' },
    journeyInfoView: { justifyContent: 'center', paddingLeft: 10, width: '80%' },
    journeyActionView: { flex: 1, justifyContent: 'center', alignItems: 'flex-end' },
    alignItemCenter: {
        alignItems: 'center'
    },
    loadingText: {
        fontSize: 11,
        color: '#ADADAD',
        marginTop: 10
    },
    dialogView: {
        height: '100%',
        justifyContent: 'center'
    },
    dialogContainer: { backgroundColor: ColorConstant.LIGHT?.BACKGROUND_COLOR_MEDIUM_SHADE || 'white', width: 350, padding: 20 },
    dialogButtonRow: { flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'flex-end' },
    dialogButton: {
        backgroundColor: ColorConstant.LIGHT?.BACKGROUND_COLOR_MEDIUM_SHADE || 'white'
    },
    journeyContainer: { height: '90%' },
    journeyName: { fontSize: 12, fontWeight: 'bold' },
    textStyle: { fontSize: 12 },
    icon: { backgroundColor: 'transparent' },
    noResultContainer: { alignItems: 'center' },
    noResultsText: { fontSize: 11, color: '#ADADAD' },
    bottomSpace: { height: 80 },
    menuLabel: { fontWeight: 'bold' },
    mandatory: { color: '#F47765' }
});