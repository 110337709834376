import { KeyboardAvoidingView, ScrollView, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import Alert from '../../elements/Alert';
import { Avatar } from 'react-native-paper';
import Button from './Button';
import Constants from 'expo-constants';
import DesignerService from '../../../service/DesignerService';
import JourneyService from '../../../service/JourneyService';
import Table from './Table';
import TextElement from '../../elements/Text';
import TouchableElement from '../../elements/TouchableElement';
import ViewWithBack from '../../elements/ViewWithBack';
import { getDate } from '../../utils/utils';
import { getUserIdentity } from '../../../utils/UserIdentity';
import { journeyReducer } from './JourneySlice';
import style from './styles/topicStyle';
import { topicTableHeader } from './Metadata';
import { useIsFocused } from '@react-navigation/native';

const getName = require('../../../utils/NameMapping').getName;
const { GLOBALSPACEID } = Constants.expoConfig.extra;

const Topic = (parentProps) => {
  // console.log("Topic props", props);
  const props = { ...parentProps, ...parentProps.route.params };
  const { journeyId, created_journey, type, ShowPublishbutton } = props.route.params;

  const isFocused = useIsFocused();
  const [topics, setTopics] = useState([]);
  // console.log("topics", topics);
  const [isApiDataLoaded, setIsApiDataLoaded] = useState(false);
  const [isTopicApiDataLoaded, setIsTopicApiDataLoaded] = useState(false);
  const [userIdentity, setUserIdentity] = useState({});

  const designerService = new DesignerService(props);
  const journeyService = new JourneyService(props);
  const dispatch = useDispatch();
  const allJourneys = useSelector((state) => state.designerJourneys.allJourneys);
  // console.log("allJourneys", allJourneys);
  let journey = {};
  if (type == 'Create') {
    const { space_id, module_id, display_name } = created_journey;
    journey = allJourneys.filter(
      (journey) =>
        journey.space_id == space_id &&
        journey.id.includes(module_id) &&
        journey.display_name == display_name
    )[0];
  }
  if (type == 'Update') {
    journey = allJourneys.filter((journey) => journey.journey_id == journeyId)[0];
  }
  // console.log("journey", journey);

  const fetchData = () => {
    getUserIdentity().then((uIdentity) => {
      setUserIdentity(uIdentity);
    });
    if (journey && Object.keys(journey).length) {
      designerService.getTopics(journey, isTopicApiDataLoaded).then((response) => {
        setIsTopicApiDataLoaded(true);
        setTopics(response);
      });
    } else {
      journeyService.fetchDesignerJourneys(isApiDataLoaded).then((journeyResult) => {
        // console.log("journeyResult 1 fetchDesignerJourneys", journeyResult);
        setIsApiDataLoaded(true);
        designerService.getTopics(journey, isTopicApiDataLoaded).then((response) => {
          setIsTopicApiDataLoaded(true);
          setTopics(response);
        });
        dispatch(journeyReducer({ journeys: journeyResult }));
      });
    }
  };

  const getUpdatedJourney = () => {
    let temp = { ...journey };
    for (let key of Object.keys(journey)) {
      if (!['space_id', 'id', 'journey_id', 'journey_status'].includes(key)) {
        delete temp[key];
      }
    }
    console.log('journey after getUpdatedJourney', temp);
    return temp;
  };

  const saveJourney = () => {
    //operation type is always journey update, journey_status != drafted
    //jd status= declined, published
    //admin status= review, published

    let journey = getUpdatedJourney();
    // console.log('saveJourney called !! :: ');

    let alertMessage = '';

    if (userIdentity.user_role == 'admin') {
      if (journey.journey_status == 'published') {
        alertMessage = 'Topic deleted & Journey published';
      } else {
        alertMessage = 'Topic deleted';
      }
      Alert.alert('Success', alertMessage);
    } else if (userIdentity.user_role == 'jd') {
      const isGlobalSpace = journey.space_id === GLOBALSPACEID;
      journey.journey_status = isGlobalSpace ? 'review' : 'published';
      alertMessage = isGlobalSpace
        ? 'Topic deleted & Journey submitted for review'
        : 'Topic deleted & Journey published';
      updateJourney();
    }

    function updateJourney() {
      //   console.log("updateJourney called!!");
      designerService.updateJourney(journey, isApiDataLoaded).then(() => {
        setIsApiDataLoaded(true);
        Alert.alert('Success', alertMessage);
        props.navigation.navigate('DesignerJourney');
      });
    }
  };

  const deleteTopic = async (topic) => {
    const updateJourneyFlag = journey.journey_status != 'drafted';
    Alert.confirm('Confirm', 'Deleted topic will not be reverted', () => {
      designerService.deleteTopic(false, topic, journey).then(() => {
        fetchData();
        setIsApiDataLoaded(true);
        if (updateJourneyFlag) {
          saveJourney();
        }
      });
    });
  };

  useEffect(() => {
    if (isFocused) {
      fetchData();
    }
  }, [isFocused]);

  const editTopic = (index) => {
    const editTopicProps = {
      journeyId: journey.journey_id,
      type: 'Update',
      topicIndex: index,
    };
    props.navigation.navigate('Addtopic', { ...editTopicProps });
  };

  const topicTableProps = {
    dataList: topics,
    handleDelete: deleteTopic,
    contentType: 'Topic',
    handleOnPress: editTopic,
    isApiDataLoaded: isTopicApiDataLoaded,
  };

  // console.log("Topic journey", journey);
  // console.log("isTopicApiDataLoaded", isTopicApiDataLoaded, "isApiDataLoaded", isApiDataLoaded);

  return (
    <ViewWithBack
      pageName='Journey Topics'
      {...props}
      backAction={() => {
        props.navigation.navigate('Designerhome', { backAction: true });
      }}>
      <ScrollView showsVerticalScrollIndicator={false} showsHorizontalScrollIndicator={false}>
        <KeyboardAvoidingView behavior='position' style={style.container}>
          <View style={style.contentContainer}>
            <View style={style.header}>
              <ShowPublishbutton />
            </View>
            <View style={style.divider}></View>
            <View style={style.journeyInfo}>
              <TextElement style={style.journeyName}>Name: {journey?.display_name}</TextElement>
              <View style={style.divider5}></View>
              <TextElement style={style.description}>
                Description: {journey?.description}{' '}
              </TextElement>
              <TextElement style={style.createdBy}>
                Created by {journey?.created_by ? getName(journey.created_by) : '-'} on{' '}
                {journey?.created_at ? getDate(journey.created_at) : '-'}
              </TextElement>
            </View>
            <View style={style.divider}></View>
            <View style={style.header}>
              <TouchableElement
                id='add-topic'
                onPress={() => {
                  props.navigation.navigate('Addtopic', {
                    journeyId: journey.journey_id,
                    type: 'Create',
                  });
                }}>
                <View style={style.addTopiccontainer}>
                  <Avatar.Icon icon='plus' size={25} color='#ffffff' style={style.addButton} />
                  <TextElement theme='DARK' style={style.addButtonLabel}>
                    Topic
                  </TextElement>
                </View>
              </TouchableElement>
              <View style={style.topicsHeader}>
                <TextElement style={style.topicsHeaderText}>Topics</TextElement>
              </View>
            </View>
            <Table {...topicTableProps} tableHeader={topicTableHeader} />
            <View style={style.divider}></View>

            <View style={style.saveButtonContainer}>
              <Button
                style={style.saveButton}
                onPress={() => {
                  props.navigation.navigate('DesignerJourney');
                }}>
                Save
              </Button>
            </View>
            <View style={style.bottomSpace}></View>
          </View>
        </KeyboardAvoidingView>
      </ScrollView>
    </ViewWithBack>
  );
};

export default Topic;
