import { View } from 'react-native';

import { Avatar } from 'react-native-paper';
import TextElement from '../../elements/Text';
import style from './styles/tableStyle';
import TouchableElement from '../../elements/TouchableElement';

const Table = (props) => {
    // console.log("Table  props", props);
    const { dataList, handleDelete, contentType, handleOnPress, isApiDataLoaded, tableHeader } = props;
    // console.log("isApiDataLoaded", isApiDataLoaded);
    // console.log("dataList table", dataList);
    // console.log("handleDelete table", handleDelete);
    if (isApiDataLoaded) {
        return (
            dataList.length ?
                <View style={style.tableContainer}>
                    <TableHeader tableHeader={tableHeader} />
                    {dataList.map((data, index) => {
                        return <View key={'row1' + index} style={style.tableRow}>

                            {(tableHeader || []).map((col, innerIndex) => {
                                return (
                                    <TouchableElement
                                        style={{ flex: col.flex || 1, display: 'flex', alignItems: col.align || 'flex-start', justifyContent: 'center', width: '100%' }} key={'row' + index + innerIndex}
                                        onPress={() => {
                                            handleOnPress(index);
                                        }}
                                        id={'sub-topic-' + innerIndex}
                                    >
                                        <TextElement numberOfLines={1}>{col.key === 'sub_topic' ? data[col.key].length : data[col.key]}</TextElement>
                                    </TouchableElement>
                                );
                            })}

                            <View style={style.actionContainer}>
                                <TouchableElement
                                    id={'trash-topic-' + index}
                                    onPress={() => handleDelete(data, index)}
                                >
                                    <Avatar.Icon size={25} icon="trash-can" style={style.icon} color="#212644" />
                                </TouchableElement>
                            </View>
                        </View>;
                    })}
                </View>
                :
                <View>
                    <View style={style.tableHeaderView}>
                        <TableHeader tableHeader={tableHeader} />
                    </View>
                    <View style={style.noResultContainer}>
                        <TextElement style={style.noResultsText}>No {contentType == 'Topic' ? 'Topics' : 'Contents'} Found!</TextElement>
                    </View>
                </View>

        );
    } else {
        return (
            <View style={style.loadingContainer}>
                <TextElement style={style.loadingText}>Loading...</TextElement>
            </View>
        );
    }
};

const TableHeader = ({ tableHeader }) => {

    return (
        <View style={style.tableHeaderContainer} >
            {
                (tableHeader || []).map((header, index) => {
                    return (
                        <View style={{ flex: header.flex || 1, display: 'flex', alignItems: header.align || 'flex-start' }} key={'header' + index}>
                            <TextElement style={style.tableHeaderStyle}>{header.label}</TextElement>
                        </View>
                    );
                })
            }
            <View style={style.actionContainer}>
                <TextElement style={style.tableHeaderStyle}>Action</TextElement>
            </View>
        </View >

    );
};



export default Table;