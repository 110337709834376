import { Avatar, Checkbox } from 'react-native-paper';
import ColorConstant from '../../utils/ColorConstant.json';
import { memo } from 'react';
import { Platform, View } from 'react-native';
import Text from './Text';
import TouchableElement from './TouchableElement';

const checkBoxElement = (props) => {
    return (
        <View style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center', 
            gap: 5
        }}>
            <TouchableElement
                id={'check' + props.label}
                onPress={props.handleCheckBox}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                <Avatar.Icon
                    size={40}
                    color='#000000'
                    style={{
                        backgroundColor: 'transparent'
                    }}
                    icon={props.value ? 'checkbox-marked' : 'checkbox-blank-outline'}
                />
            </TouchableElement>

            <Text
                style={{ color: '#48B2EB' }}
            >{props.label}</Text>
            {/* <Checkbox
                style={{ borderColor: 'red', borderWidth: 1, width: 90, height: 80, alignItems: 'center', justifyContent: 'center' }}
                status={props.value ? 'checked' : 'unchecked'}
                onPress={props.handleCheckBox}
                theme={{ colors: { primary: ColorConstant[props.theme]?.CHECKBOX_COLOR } }}
                color={ColorConstant[props.theme]?.CHECKBOX_COLOR}
            /> */}
        </View>

    );
};

export default memo(checkBoxElement, (prev, next) => {
    return prev.value == next.value;
});