import Alert from '../components/elements/Alert';
import Constants from '../utils/Constants';
import Request from '../dal/RequestNew';
import Store from '../Store/Store';
import { btoa } from 'react-native-quick-base64';
import { loaderReducer } from '../components/elements/customLoader/customLoaderSlice';

class SearchService {
  constructor(props) {
    this.props = props;
  }

  async fetchSearchData(jsonData) {
    try {
      let url = Constants.getConfig('SEARCH_MENTORS');
      url = url + btoa(JSON.stringify(jsonData));
      Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const request = new Request(this.props);
      let response = await request.getNew(url);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      return response;
    } catch (exception) {
      // console.log("exception in search", exception)
      let error =
        typeof exception.error == 'string'
          ? exception.error
          : 'Sorry, something unexpected happened.';
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([500, 400].includes(exception.status)) {
        Alert.alert('Whoops',error);
      }
      return [];
    }
  }

  async sendMentorRequest(mentee, mentor, addMessage) {
    try {
      let url = Constants.getConfig('SEND_CONNECTION');
      url = url.replace('${mentee}', mentee);
      url = url.replace('${mentor}', mentor);
      let sendParams = {
        url: url,
        data: {
          status: 'pending',
          remarks: addMessage,
        },
      };
      Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const request = new Request(this.props);
      let response = await request.putNew(sendParams.url, sendParams.data);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      Alert.alert('Request sent' , response.message);
      return response;
    } catch (exception) {
      // console.log('exception',exception);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([500, 400, 422].includes(exception.status)) {
        // console.log('if exception',exception);
        Alert.alert('Whoops',exception.error);
      }
    }
  }
}

export default SearchService;
