import { Switch, Platform } from 'react-native';
import ColorConstant from '../../utils/ColorConstant.json';
import { memo } from 'react';

const ToggleElement = (props) => {
    return (
        <Switch
            testID={props.label || 'toggle'}
            onValueChange={props.handleToggle}
            value={props.isMentor}
            thumbColor={[Platform.OS == 'ios' ? '#ffffff' : (props.isMentor ? ColorConstant[props.theme]?.TOGGLE_COLOR : '#ffffff')]}
            trackColor={{ true: ColorConstant[props.theme]?.TOGGLE_COLOR, false: Platform.OS == 'ios' ? '#fbfbfb' : '#d3d3d3' }}
            ios_backgroundColor="#fbfbfb"
        >
        </Switch>
    );
};

export default memo(ToggleElement, (prev, next) => prev.isMentor == next.isMentor);