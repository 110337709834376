import { StyleSheet } from 'react-native';

const style = StyleSheet.create({
    flex1: {
        flex: 1
    },
    textArea: {
        padding: 10,
        borderWidth: 2,
        borderColor: '#ADADAD',
        flex: 1
    },
    flexhalf: {
        maxHeight: 400,
        flex: 1
    },
    toolBar: {
        display: 'flex',
        flexDirection: 'row',
        padding: 2,
        gap: 10,
        alignItems: 'center'
    },
    bold: {
        fontWeight: 'bold',
        color: '#ffffff'
    },
    toolBarScroll: {
        backgroundColor: '#A7C96A',
        maxHeight: 40,
    },
    icon: {
        backgroundColor: 'transparent',
        marginTop: -5
    },
    readerView: { backgroundColor: '#F0F0F0', padding: 5, flex: 1, height: '100%' },
    markdownHeading: { fontWeight: 'bold' }
});

export default style;