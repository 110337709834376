import { getDate, trim } from '../../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import Alert from '../../../elements/Alert';
import { Avatar } from 'react-native-paper';
import DesignerService from '../../../../service/DesignerService';
import TextElement from '../../../elements/Text';
import TouchableElement from '../../../elements/TouchableElement';
import { View } from 'react-native';
import journeyConfig from '../../JourneyConfig.json';
import { spaceReducer } from '../Space/SpaceSlice';
import style from './styles/modulesStyle';
import { useIsFocused } from '@react-navigation/native';
import { sortBy } from 'lodash';

const getName = require('../../../../utils/NameMapping').getName;

const Modules = (props) => {
    const { spaceId } = props.route.params;
    // console.log("spaceId", spaceId);
    const spaces = useSelector((state) => state.spaces.value);
    const space = spaces.filter(space => spaceId == space.id)[0];
    const modules = Object.keys(space).length ? space.modules : [];

    const dispatch = useDispatch();
    const [isApiDataLoaded, setIsApiDataLoaded] = useState(false);
    const designerService = new DesignerService(props);
    const isFocused = useIsFocused();

    const deleteModule = (module) => {
        // console.log("deleteModule", module);
        designerService.deleteModule(false, module).then(() => {
            setIsApiDataLoaded(true);
            fetchData();
            Alert.alert('Success', 'Module deleted');
        });
    };

    const fetchData = () => {
        if (space && Object.keys(space).length) {
            // console.log("space found Modules page", space);
            setIsApiDataLoaded(true);
        }
        designerService.getSpaceModules(isApiDataLoaded).then((response) => {
            // console.log("--mdoules----fetchdata called", response);
            setIsApiDataLoaded(true);
            dispatch(spaceReducer({ 'spaces': response }));
        });
    };

    useEffect(() => {
        if (isFocused) {
            fetchData();
        }
    }, [isFocused]);
    // console.log("modules in --", modules);

    return (
        <ListComponent dataList={modules} spaceData={space} deleteModule={deleteModule} isApiDataLoaded={isApiDataLoaded} {...props} />
    );
};

const ListComponent = (props) => {
    const { dataList, spaceData, isApiDataLoaded } = props;
    // console.log("dataList", dataList)
    // console.log("isApiDataLoaded", isApiDataLoaded)

    const sortList = (item) => item.display_name;

    if (isApiDataLoaded) {
        return (
            dataList.length ?
                <View>
                    <View style={style.divider}></View>
                    {
                        (sortBy(dataList, sortList)).map((data, index) => {
                            // console.log("data", data);
                            return (
                                <View key={index} style={style.moduleContainer}>
                                    <TouchableElement
                                        id={'row' + index}
                                        key={index}
                                        onPress={() => {
                                            props.navigation.navigate('Module', {
                                                spaceId: spaceData.id,
                                                moduleId: data.id
                                            });
                                        }}
                                    >
                                        <View style={style.rowContainer}>
                                            <View style={style.container}>
                                                <TextElement style={style.moduleText}>{data.display_name}</TextElement>

                                                <TextElement style={style.textStyle} numberOfLines={1}>{data.description}</TextElement>

                                                <TextElement style={style.textStyle}>Created by {data.created_by ? getName(data.created_by) : '-'} on {data.created_at ? getDate(data.created_at) : '-'}</TextElement>
                                            </View>
                                            <View style={style.editButton}>
                                                <TouchableElement
                                                    onPress={() => {
                                                        props.navigation.navigate('ModuleForm', {
                                                            type: 'Update',
                                                            spaceId: spaceData.id,
                                                            moduleId: data.id
                                                        });
                                                    }}
                                                    id={'edit' + index}
                                                >
                                                    <Avatar.Icon size={25} icon="pencil" style={style.icon} color={journeyConfig.travelexColors.TRXBlue || ColorConstant[props.theme]?.BUTTON_BACKGROUND_COLOR} />
                                                </TouchableElement>
                                            </View>
                                        </View>
                                    </TouchableElement>
                                </View>

                            );
                        })
                    }
                </View>
                : <View style={style.noResultContainer}>
                    <Avatar.Icon icon="email-open-outline" size={50} style={style.icon} />
                    <View style={style.divider5}></View>
                    <TextElement style={style.noResultsText}>No Modules Found!</TextElement>
                </View >
        );
    } else {
        return (
            <View style={style.alignItemCenter}>
                <TextElement style={style.loadingText}>Loading...</TextElement>
            </View>
        );
    }
};

export default Modules;