const statusList = [
    {
        label: 'Created',
        value: 'created'
    },
    {
        label: 'In Progress',
        value: 'inprogress'
    },
    {
        label: 'Aborted',
        value: 'aborted'
    },
    {
        label: 'Completed',
        value: 'completed'
    }
];

const colors = {
    'enrolled': '#00BFFF',
    'completed': '#50C878'
};

const LIMIT=10;
const INITIAL_OFFSET =0;


module.exports ={
    statusList,
    colors,
    LIMIT,
    INITIAL_OFFSET
};