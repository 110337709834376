import * as React from 'react';
import { Text } from 'react-native';
import { Avatar } from 'react-native-paper';
import Elements from '../../elements';
import Markdown from 'react-native-markdown-display';
import TextElement from '../../elements/Text';
import { View } from 'react-native';
import moment from 'moment';
import style from './styles/goalsDefinitionStyle';
import { useState } from 'react';

const GoalsDefinition = (props) => {
  //   console.log('GoalsDefinition props', props);
  const {
    editFlag,
    goalsDefinition,
    handleServiceRemove,
    handleAddNewDefinition,
    handleUpdateGoalDfn,
    handleShowGoalDfn,
  } = props;
  return (
    <View style={style.container}>
      <LearningThemesHeader handleAddDefinitionModal={handleAddNewDefinition} editFlag={editFlag} />
      <LearningThemesTableHeader />
      <Elements.CustomVList
        item={(itemProps) => (
          <Definition
            {...itemProps}
            handleServiceRemove={handleServiceRemove}
            handleUpdateGoalDfn={handleUpdateGoalDfn}
            handleShowGoalDfn={handleShowGoalDfn}
            editFlag={editFlag}
          />
        )}
        data={goalsDefinition}
        separator={() => <View style={style.separator} />}
      />
    </View>
  );
};

// Rendering

const Definition = ({
  index,
  item,
  handleServiceRemove,
  handleUpdateGoalDfn,
  handleShowGoalDfn,
  editFlag,
}) => {
  const [show, setShow] = useState(false);
  const toggleShow = () => {
    setShow(!show);
  };

  return (
    <View>
      {/* Rendering Definition Clickable row   */}
      <Elements.Touchable
        style={[style.row, { backgroundColor: '#2EADB7' }]}
        id={'row-' + index}
        onPress={toggleShow}>
        <Elements.Text style={style.name}>{item.definition}</Elements.Text>
        <Elements.Text style={style.timeline}>
          {moment.unix(item.timeline).format('YYYY/MM/DD')}
        </Elements.Text>

        <View style={style.action}>
          <Elements.Text style={style.weightageText}>{item.weightage_in_percentage}</Elements.Text>
          <Elements.Touchable
            id={'edit' + index}
            onPress={() => {
              if (editFlag) {
                handleUpdateGoalDfn(index);
              }
            }}>
            <Avatar.Icon
              icon='pencil'
              size={25}
              color={editFlag ? '#f5f5f5' : '#8C8D90'}
              style={style.icon}
            />
          </Elements.Touchable>
          <Elements.Touchable
            id={'delete' + index}
            onPress={() => {
              if (editFlag) {
                handleServiceRemove(index);
              }
            }}>
            <Avatar.Icon
              icon='delete'
              size={25}
              color={editFlag ? '#f5f5f5' : '#8C8D90'}
              style={style.icon}
            />
          </Elements.Touchable>
          <Elements.Touchable
            id={'view' + index}
            onPress={() => {
              handleShowGoalDfn(index);
            }}>
            <Avatar.Icon icon='eye' size={25} color={'#f5f5f5'} style={style.icon} />
          </Elements.Touchable>
        </View>
      </Elements.Touchable>

      {/* Separator  */}
      {show ? <View style={style.separator} /> : <View />}

      {/* Rendering Subitems   */}
      <View style={style.subItemPanel}>
        <SubItemPanel show={show} sub_items={item.sub_items} />
      </View>
    </View>
  );
};

const LearningThemesHeader = ({ handleAddDefinitionModal, editFlag }) => {
  return (
    <View style={style.rowContainer}>
      <View style={style.flex}>
        <Elements.Text
          theme={'DARK'}
          variant='displayLarge'
          text='Learning Themes'
          style={style.headerText}
        />
      </View>
      {editFlag ? (
        <View style={style.flex}>
          <Elements.Button
            testId='add-theme'
            icon='plus'
            color={'#0096FF'}
            text='Theme'
            style={style.addDefinitionButton}
            onPress={handleAddDefinitionModal}
          />
        </View>
      ) : (
        <View />
      )}
    </View>
  );
};

// Table Header
const LearningThemesTableHeader = () => {
  return (
    <View>
      <View style={style.tableRow}>
        <View style={style.tableHeaderName}>
          <TextElement>Theme</TextElement>
        </View>
        <View style={style.tableHeaderTimeline}>
          <TextElement>Timeline</TextElement>
        </View>
        <View style={style.tableHeaderWeightage}>
          <TextElement>Weightage</TextElement>
        </View>
      </View>
    </View>
  );
};

// Listing of SubItems
const SubItemPanel = ({ show, sub_items }) => {
  if (show)
    return (
      <Elements.CustomVList
        item={(props) => <SubItem {...props} />}
        data={sub_items}
        separator={() => <View style={style.separator} />}
        empty={Empty}
      />
    );
  else return <View />;
};

const Empty = () => (
  <View style={[style.main, style.center]}>
    <Text style={{ color: '#000000' }}>No data found</Text>
  </View>
);

// Rendering SubItem one by one

const SubItem = ({ index, item }) => {
  const [show, setShow] = useState(false);

  const toggleShow = () => {
    setShow(!show);
  };

  return (
    <View>
      <Elements.Touchable
        style={[style.row, { backgroundColor: '#f0f0f0' }]}
        id={'row-' + index}
        onPress={toggleShow}>
        <Elements.Text style={style.subItemTitle}>{item.title}</Elements.Text>
        <Avatar.Icon
          icon={show ? 'arrow-down-bold' : 'arrow-right-bold'}
          size={20}
          style={style.icon}
          color='#000000'
        />
      </Elements.Touchable>

      {show ? (
        <View style={style.subItemDescContainer}>
          <Elements.Text style={style.subItemDesc}>Description</Elements.Text>
          <View style={style.markdownView}>
            <Markdown>{item.description}</Markdown>
          </View>
        </View>
      ) : (
        <View />
      )}
    </View>
  );
};

export default GoalsDefinition;
