const colors = {
  created: '#FFAA33',
  inprogress: '#00BFFF',
  completed: '#50C878',
  aborted: '#CD2702',
};

// const goalStatusListInProgress = [
//   {
//     label: 'IN PROGRESS',
//     value: 'inprogress',
//   },
//   {
//     label: 'COMPLETED',
//     value: 'completed',
//   },
//   {
//     label: 'ABORTED',
//     value: 'aborted',
//   },
// ];

// const goalStatusListCreated = [
//   {
//     label: 'CREATED',
//     value: 'created',
//   },
//   {
//     label: 'ABORTED',
//     value: 'aborted',
//   },
// ];

// const goalStatusListAborted = [
//   {
//     label: 'ABORTED',
//     value: 'aborted',
//   },
// ];

// const goalStatusListCompleted = [
//   {
//     label: 'COMPLETED',
//     value: 'completed',
//   },
// ];

// const goalStatus = {
//   created: 'CREATED',
//   inprogress: 'IN PROGRESS',
//   completed: 'COMPLETED',
//   aborted: 'ABORTED',
// };

const goalStatusListInProgress = ['inprogress', 'completed', 'aborted'];

const goalStatusListCreated = ['created', 'aborted'];

const goalStatusListAborted = ['aborted'];

const goalStatusListCompleted = ['completed'];

const goalStatusList = {
  inprogress: 'In Progress',
  completed: 'Completed',
  aborted: 'Aborted',
  created: 'Created',
};

const goalStatus = {
  created: 'CREATED',
  inprogress: 'IN PROGRESS',
  completed: 'COMPLETED',
  aborted: 'ABORTED',
};
const definitionStatusList = ['created', 'inprogress', 'completed'];

module.exports = {
  colors,
  goalStatusListInProgress,
  goalStatusListCreated,
  goalStatus,
  goalStatusListAborted,
  goalStatusListCompleted,
  definitionStatusList,
  goalStatusList,
};
