import React, { useEffect, useState } from 'react';
import { ScrollView, Text, View } from 'react-native';
import { TabBar, TabView } from 'react-native-tab-view';
import { colors, goalStatusList } from './Metadata';
import { useDispatch, useSelector } from 'react-redux';

import Alert from '../../elements/Alert';
import { Avatar } from 'react-native-paper';
import ColorConstant from '../../../utils/ColorConstant.json';
import { DataTable } from 'react-native-paper';
import GoalsService from '../../../service/GoalsService';
import MenteeGoals from './MenteeGoals';
import TextElement from '../../elements/Text';
import TouchableElement from '../../elements/TouchableElement';
import { getUserIdentity } from '../../../utils/UserIdentity';
import { goalsReducer } from '../GoalSlice';
import journeyConfig from '../../journey/JourneyConfig.json';
import style from './styles/goalsTableStyle';
import { useIsFocused } from '@react-navigation/native';

let userIdentity = null;
const GoalsTable = (props) => {
  const { tabIndex, setTabIndex, isMentor } = props;

  // const is_mentor = tabIndex === 1 ? true : false;
  // console.log('tabIndex in goalstable', tabIndex, 'is_mentor', is_mentor, 'isMentor', isMentor);
  const dispatch = useDispatch();
  const goalsCache = useSelector((state) => state.goals.value);
  const goalsService = new GoalsService(props);
  const isFocused = useIsFocused();
  const [goalsData, setGoalsData] = useState([]);
  // const [goalsStatus, setGoalsStatus] = useState("");
  const [isApiDataLoaded, setIsApiDataLoaded] = useState(false);
  const [userRole, setUserRole] = useState('');
  const adminRoutes = [
    { key: 'mygoal', title: 'My GOALS' },
    { key: 'mentee', title: 'Mentee\'s GOALS' },
    { key: 'user', title: 'User\'s GOALS' }
  ];

  const routes = [
    { key: 'mygoal', title: 'My GOALS' },
    { key: 'mentee', title: 'Mentee\'s GOALS' }
  ];


  const renderScene = ({ route }) => {
    switch (route.key) {
      case 'mygoal':
        return (
          <Table
            props={props}
            goalsData={goalsData}
            isApiDataLoaded={isApiDataLoaded}
            onDelete={onDelete}
            isMentor={isMentor}
          />
        );
      case 'mentee':
        return <MenteeGoals {...props} isMentor={true} />;
      case 'user':
        return <MenteeGoals {...props} isAdmin={true} />;
      default:
        return <View />;
    }
  };


  useEffect(() => {
    getUserIdentity().then(d => {
      setUserRole(d.user_role);
    });
  }, []);

  const fetchData = async () => {
    userIdentity = await getUserIdentity();



    if (goalsCache && goalsCache.length) {
      // console.log("goalsCache found", goalsCache);
      setIsApiDataLoaded(true);
      setGoalsData(goalsCache);
    }
    const response = await goalsService.getGoals(userIdentity.email_id, isApiDataLoaded);
    if (response) {
      dispatch(goalsReducer({ goals: response }));
      setGoalsData(response);
      // setGoalsStatus(response.status);
      setIsApiDataLoaded(true);
    }
  };

  useEffect(() => {
    if (isFocused) {
      fetchData();
    } else {
      // flush();
    }
  }, [isFocused]);

  const onDelete = async (goal) => {
    try {
      Alert.confirm('Confirm', 'Deleted goal will not be reverted', () => {
        goalsService.deleteGoal(userIdentity.email_id, goal).then(() => {
          fetchData().then(() => {
            Alert.alert('Success', 'Goal deleted');
          });
        });
      });
    } catch (error) {
      // console.log("Exception occurred", error);
    }
  };

  return (
      <TabView
        style={style.container}
        key={userRole}
        navigationState={{ index: tabIndex, routes: userRole === 'admin' ? adminRoutes : routes }}
        renderScene={renderScene}
        onIndexChange={(index) => setTabIndex(index)}
        renderTabBar={(props) => <TabBar style={{ backgroundColor: '#3C436C' }} {...props} />}
      />
  );
};

const Table = ({ props, goalsData, isApiDataLoaded, onDelete, isMentor }) => {
  return goalsData.length > 0 ? (
    <DataTable style={style.dataTable}>
      <DataTable.Header>
        <DataTable.Title style={style.flex3}>
          <Text style={style.headerStyle}>Title</Text>
        </DataTable.Title>
        <DataTable.Title style={style.flex2}>
          <Text style={style.headerStyle}>Status</Text>
        </DataTable.Title>
        <DataTable.Title style={[style.flex1, style.center]}>
          <Text style={style.headerStyle}>Action</Text>
        </DataTable.Title>
      </DataTable.Header>

      <ScrollView showsVerticalScrollIndicator={false} showsHorizontalScrollIndicator={false}>
        {goalsData.map((goalData, index) => (
          <DataTable.Row style={style.dataTableRow} key={index}>
            <DataTable.Cell
              testID={'goalName-' + index}
              style={style.flex3}
              onPress={() => {
                props.navigation.navigate('Viewgoal', {
                  goalName: goalData.goal_name,
                  isMentor: isMentor,
                });
              }}>
              <Text style={style.textStyle}>
                {goalData.goal_name.substr(0, goalData.goal_name.lastIndexOf('_'))}
              </Text>
            </DataTable.Cell>

            <DataTable.Cell
              testID={'status-' + index}
              style={style.flex2}
              onPress={() => {
                props.navigation.navigate('Viewgoal', {
                  goalName: goalData.goal_name,
                  isMentor: isMentor,
                });
              }}>
              <Text style={{ ...style.goalStatus, color: colors[goalData.status.toString()] }}>
                {/* {goalData.status.toString().toUpperCase()} */}
                {goalStatusList[goalData.status.toString()]}
              </Text>
            </DataTable.Cell>

            <DataTable.Cell style={[style.flex1, style.center]}>
              <TouchableElement onPress={() => onDelete(goalData.goal_name)} id={'delete-' + index}>
                <Avatar.Icon
                  size={25}
                  icon='trash-can'
                  style={style.icon}
                  color={
                    journeyConfig.travelexColors.TRXBlue ||
                    ColorConstant[props.theme]?.BUTTON_BACKGROUND_COLOR
                  }
                />
              </TouchableElement>
              {/* <ButtonElement theme={props.theme} icon="trash-can" color={ColorConstant[props.theme]?.BUTTON_BACKGROUND_COLOR} onPress={() => onDelete(goalData.goal_name)} /> */}
            </DataTable.Cell>
          </DataTable.Row>
        ))}
        {/* <View style={style.goalTableFooter}></View> */}
      </ScrollView>
    </DataTable>
  ) : (
    <View style={style.noGoalsView}>
      <TextElement theme={props.theme} style={style.noGoalsText}>
        {' '}
        {isApiDataLoaded == true ? 'No goals found!' : ''}{' '}
      </TextElement>
    </View>
  );
};

export default GoalsTable;
