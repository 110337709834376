import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    height2: {
        height: 2
    },
    mainButton: {
        borderWidth: 1,
        borderColor: '#ADADAD',
        padding: 10,
        flexDirection: 'row',
        display: 'flex'
    },
    itemView: {
        paddingLeft: 10,
        paddingTop: 5
    }
});