import { createSlice } from '@reduxjs/toolkit';

const counterSlice = createSlice({
    name: 'goals',
    initialState: {
        'value': []
    },
    reducers: {
        goalsReducer: (state, action) => {
            // console.log("action", action)
            state.value = action.payload.goals;
        }
    }
});

export const { goalsReducer } = counterSlice.actions;
export default counterSlice.reducer;