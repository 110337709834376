import * as React from 'react';

import { Rating } from 'react-native-ratings';
import TextElement from '../elements/Text';
import { View } from 'react-native';
import ViewWithBack from '../elements/ViewWithBack';
import { getDate } from '../utils/utils';
import { memo } from 'react';
import style from './Style';

const getName = require('../../utils/NameMapping').getName;


const Tile = (props) => {
    return (
        <View style={style.tile}>
            <TextElement style={style.boldText} theme={props.trans ? 'DARK' : 'LIGHT'}>{props.title}</TextElement>
            <View style={style.divider2}></View>
            <TextElement
                style={style.paragraph}
                theme={props.trans ? 'DARK' : 'LIGHT'}
                multiline={true}
                textAlignVertical={'auto'}
            >
                {props.paragraph}
            </TextElement>
        </View>
    );
};

const RatingTile = (props) => {
    return (
        <View style={style.ratingTile}>
            <TextElement style={style.boldText} theme={props.trans ? 'DARK' : 'LIGHT'}>Rating: </TextElement>
            <View style={style.ratingView}>
                <Rating
                    readonly={true}
                    type='star'
                    startingValue={props.rating || 0}
                    imageSize={15}
                />
            </View>
        </View>
    );
};

const ViewFeedback = (parentProps) => {
    const props = { ...parentProps, ...parentProps.route.params };
    const [feedbackData] = React.useState(props.route.params.data);
    const [userIdentity] = React.useState(props.route.params.userIdentity);

    return (
        <ViewWithBack pageName="Feedback Details" {...props} backAction={() => { props.navigation.goBack(); }}>
            {
                userIdentity && userIdentity.user_role === 'admin' ?
                    props.route.params.isAdminUser ?
                        <View>
                            <View style={style.divider5}></View>
                            <Tile title="Description" paragraph={feedbackData.description} width="100%" />
                            <View style={style.divider5}></View>
                            <Tile title="Created At" paragraph={feedbackData.created_at ? getDate(feedbackData.created_at) : '-'} width="100%" />
                            <View style={style.divider5}></View>
                            <RatingTile rating={feedbackData.rating || 0} />
                        </View> :
                        <View>
                            <View style={style.divider5}></View>
                            <Tile title="Description" paragraph={feedbackData.description} width="100%" />
                            <View style={style.divider5}></View>
                            <Tile title="Created By" paragraph={feedbackData.created_by ? getName(feedbackData.created_by) : ''} width="100%" />
                            <View style={style.divider5}></View>
                            <Tile title="Created At" paragraph={feedbackData.created_at ? getDate(feedbackData.created_at) : '-'} width="100%" />
                            <View style={style.divider5}></View>
                            <RatingTile rating={feedbackData.rating || 0} />
                        </View>
                    :
                    <View>
                        <View style={style.divider5}></View>
                        <Tile title="Description" paragraph={feedbackData.description} width="100%" />
                        <View style={style.divider5}></View>
                        <Tile title="Created At" paragraph={feedbackData.created_at ? getDate(feedbackData.created_at) : '-'} width="100%" />
                        <View style={style.divider5}></View>
                        <RatingTile rating={feedbackData.rating || 0} />
                    </View>
            }
        </ViewWithBack>
    );
};
export default memo(ViewFeedback, () => {
    return true;
});