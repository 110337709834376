import { KeyboardAvoidingView, ScrollView, View } from 'react-native';
import { Modal, Portal, Provider } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import Alert from '../../elements/Alert';
import { Avatar } from 'react-native-paper';
import Constants from 'expo-constants';
import DesignerService from '../../../service/DesignerService';
import Dropdown from './Dropdown';
import TextElement from '../../elements/Text';
import TextInputElement from '../../elements/TextInput';
import TouchableElement from '../../elements/TouchableElement';
import ViewWithBack from '../../elements/ViewWithBack';
import date from 'date-and-time';
import { getUserIdentity } from '../../../utils/UserIdentity';
import journeyConfig from '../JourneyConfig.json';
import { sortBy } from 'lodash';
import { spaceReducer } from './Space/SpaceSlice';
import style from './styles/designerHomeStyle';
import { useIsFocused, useNavigation, useRoute } from '@react-navigation/native';

const { GLOBALSPACEID } = Constants.expoConfig.extra;

const DesignerHome = () => {
  
  const navigation  = useNavigation();
  const route = useRoute();
  const props = { route, navigation };

  const spaces = useSelector((state) => state.spaces.value);
  // console.log('spaces', spaces);
  const allJourneys = useSelector((state) => state.designerJourneys.allJourneys);
  // console.log("allJourneys", allJourneys);

  const { type, journeyId, backAction } = props.route.params;
  // console.log("journeyId", journeyId);
  const journeyToUpdate = allJourneys.filter((journey) => journey.journey_id == journeyId)[0];
  const disableCondition =
    type == 'Create' ? {} : type == 'Update' ? { pointerEvents: 'none' } : '';
  const [space, setSpace] = useState('');
  const [spaceId, setSpaceId] = useState('');
  const [spaceList, setSpaceList] = useState([]);
  const [module, setModule] = useState('');
  const [moduleList, setModuleList] = useState([]);
  const [journeyName, setJourneyName] = useState('');
  const [journeyDesc, setJourneyDesc] = useState('');
  const [icon, setIcon] = useState('Security');
  const [color, setColor] = useState('Blue');
  const [status, setStatus] = useState('drafted');
  // const [tags, setTags] = useState([]);
  const [createdBy, setCreatedBy] = useState('');
  const [createdOn, setCreatedOn] = useState('');

  const [userIdentity, setUserIdentity] = useState({});
  const isFocused = useIsFocused();
  const [isApiDataLoaded, setIsApiDataLoaded] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [topics, setTopics] = useState([]);
  const [isTopicApiDataLoaded, setIsTopicApiDataLoaded] = useState(false);

  const [history, setHistory] = useState([]);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const closeHistory = () => {
    // console.log("closeHistory", showHistoryModal);
    setShowHistoryModal(false);
  };

  const containerStyle = {
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  };
  const designerService = new DesignerService(props);
  const dispatch = useDispatch();

  const fetchData = async () => {
    getUserIdentity().then((uIdentity) => {
      setUserIdentity(uIdentity);
    });

    if (type == 'Create') {
      // console.log("fetchdata", type, "journey", journey);
      if (!backAction) {
        if (spaces.length) {
          setSpaceAndModule(spaces);
          setIsApiDataLoaded(true);
        } else {
          designerService.getSpaceModules(true).then((response) => {
            setIsApiDataLoaded(true);
            dispatch(spaceReducer({ spaces: response }));
          });
        }
        setCreatedBy(userIdentity?.email_id);
        setCreatedOn(date.format(new Date(), 'YYYYMMDD'));
      }
    } else if (type == 'Update') {
      // console.log('fetchdata', type, journeyToUpdate, unsavedChanges);
      if (!backAction) {
        const {
          background_color,
          created_at,
          created_by,
          description,
          display_name,
          icon,
          id,
          journey_status,
          space_id,
        } = journeyToUpdate;
        designerService.getTopics(journeyToUpdate, isTopicApiDataLoaded).then((response) => {
          setIsTopicApiDataLoaded(true);
          setTopics(response);
        });

        const setSpaceModule = (spaces) => {
          const selectedSpace = spaces.filter((spaceObj) => spaceObj.id == space_id)?.[0];
          // console.log("selectedSpace", selectedSpace);
          const selectedModule = selectedSpace.modules.filter(
            (moduleObj) => moduleObj.id == id.split('#')[0]
          )?.[0];
          // console.log("selectedModule", selectedModule);
          setSpace(selectedSpace.display_name);
          setModule(selectedModule.display_name);
        };

        if (spaces.length) {
          // console.log("spaces found!!!", spaces);
          setSpaceModule(spaces);
          setIsApiDataLoaded(true);
        }

        if (journeyToUpdate) {
          // console.log("journeyToUpdate found!!", journeyToUpdate);
          setIsApiDataLoaded(true);
        }
        setJourneyName(display_name);
        setJourneyDesc(description);
        setIcon(icon);
        setColor(background_color);
        setStatus(journey_status);
        setCreatedBy(created_by);
        setCreatedOn(created_at);
        setSpaceId(space_id);
        // setTags(tags)
      }
    }
  };

  // console.log("topics", topics);

  const setSpaceAndModule = (spaces, selectedSpace) => {
    // console.log("setSpaceAndModule---", type);
    let spaceList = [];
    let spaceModules = {};
    spaces.forEach((space) => {
      spaceList = [...spaceList, space.display_name];
      const obj = {};
      obj[space.display_name] = space.modules.map((module) => module.display_name);
      spaceModules = { ...spaceModules, ...obj };
    });

    if (spaceList && spaceModules) {
      const space = selectedSpace ? selectedSpace : spaceList[0];
      // console.log("space is ---", space);
      setSpace(space);
      setModuleList(spaceModules[space]);
      setModule(spaceModules[space][0]);
      setSpaceList(spaceList);
    }
  };

  useEffect(() => {
    if (isFocused) {
      fetchData();
    }
    // else {
    //     flush();
    // }
  }, [isFocused, spaces]);

  const journey = {
    created_by: createdBy ? createdBy : userIdentity?.email_id,
    created_at: createdOn,
    description: journeyDesc,
    display_name: journeyName,
    icon: icon,
    is_deleted: false,
    journey_status: status,
    // tags: tags.join(),
    background_color: color,
    space_id: spaceId,
  };

  const validateJourneyName = (journeyName) => {
    let spaceId;
    let journeyList = {};
    allJourneys.forEach((journey) => {
      journeyList[journey.space_id] = journeyList[journey.space_id]
        ? journeyList[journey.space_id].concat([journey.display_name])
        : [journey.display_name];
    });
    // console.log("journeyList", journeyList);
    if (type == 'Create') {
      const selectedSpace = spaces.filter((spaceObj) => spaceObj.display_name == space)?.[0];
      // console.log("selectedSpace", selectedSpace);
      spaceId = selectedSpace?.id;
    } else if (type == 'Update') {
      spaceId = journeyToUpdate.space_id;
    }
    // console.log("journeyList", journeyList)
    // console.log("spaceId", spaceId);

    if (Object.keys(journeyList).includes(spaceId)) {
      if (journeyList[spaceId].includes(journeyName)) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const getUpdatedJourney = () => {
    journey.space_id = journeyToUpdate.space_id;
    journey.id = journeyToUpdate.id;
    journey.journey_id = journeyId;

    for (let key of Object.keys(journeyToUpdate)) {
      if (journeyToUpdate[key] == journey[key] && !['space_id', 'id', 'journey_id'].includes(key)) {
        delete journey[key];
      }
    }
    // console.log("journey after getUpdatedJourney", journey);
  };

  const saveJourney = (isContinue) => {
    // console.log("saveJourney called !! :: ", isContinue);
    // console.log("isContinue", isContinue);
    let missingFields = [];
    if (type == 'Create') {
      const selectedSpace = spaces.filter((spaceObj) => spaceObj.display_name == space)?.[0];
      // console.log("selectedSpace", selectedSpace);
      const selectedModule = selectedSpace.modules.filter(
        (moduleObj) => moduleObj.display_name == module
      )?.[0];
      // console.log("selectedModule", selectedModule);
      journey.space_id = selectedSpace?.id;
      journey.module_id = selectedModule?.id;

      if (!journey.space_id) {
        missingFields.push('Space');
      }
      if (!journey.module_id) {
        missingFields.push('Module');
      }
      if (!journeyName) {
        missingFields.push('Journey Name');
      }
    } else if (type == 'Update') {
      if (!journeyName) {
        missingFields.push('Journey Name');
      }
    }

    // console.log("space", space, "module", module, "journeyName", journeyName);
    // console.log("spaceId", journey.space_id, "moduleId", journey.module_id);
    if (missingFields.length) {
      const alertMessage = `Please enter mandatory fields: ${missingFields}`;
      Alert.alert('Required', alertMessage);
    } else {
      setUnsavedChanges(false);
      // console.log("journey saveJourney", journey);
      let alertMessage = '';
      if (userIdentity.user_role == 'admin' && type === 'Update' && status == 'published') {
        alertMessage = 'Journey published';
        // console.log("journey 2", journey);
      } else if (userIdentity.user_role == 'jd' && type === 'Update' && status != 'drafted') {
        const isGlobalSpace = journey.space_id === GLOBALSPACEID;
        journey.journey_status = isGlobalSpace ? 'review' : 'published';
        alertMessage = isGlobalSpace ? 'Journey submitted for review' : 'Journey published';
      }
      if (type == 'Create') {
        designerService.saveJourney(journey, false).then((response) => {
          // console.log("response", response);
          setIsApiDataLoaded(true);
          if (isContinue) {
            continueJourney();
          } else {
            Alert.alert('Success', alertMessage ? alertMessage : response.message);
            props.navigation.navigate('DesignerJourney');
          }
        });
      }
      if (type == 'Update') {
        // console.log("Update :: ", status)
        // console.log("Update topics", topics)
        if (!topics.length && status == 'published') {
          Alert.alert('Whoops!', 'Please add topics before publishing the journey');
        } else {
          getUpdatedJourney();
          // console.log("getUpdatedJourney() :: ", getUpdatedJourney());
          designerService.updateJourney(journey, false).then((response) => {
            setIsApiDataLoaded(true);
            if (userIdentity.user_role == 'jd' && type === 'Update' && status != 'drafted') {
              const isGlobalSpace = journey.space_id === GLOBALSPACEID;
              isGlobalSpace ? setStatus('review') : setStatus('published');
            }
            if (isContinue) {
              continueJourney();
            } else {
              // console.log("alertMessage", alertMessage);
              Alert.alert('Success', alertMessage ? alertMessage : response.message);
              props.navigation.navigate('DesignerJourney');
            }
          });
        }
      }
    }
  };

  const continueJourney = () => {
    // console.log("continueJourney :: ")
    const topicProps = {
      journeyId: type == 'Create' ? '' : journeyToUpdate.journey_id,
      type,
      ShowPublishbutton,
    };
    if (type == 'Create') {
      topicProps.created_journey = journey;
    }
    props.navigation.navigate('Topic', { ...topicProps });
  };

  const publishJourney = () => {
    // console.log("userIdentity", userIdentity.user_role)
    setUnsavedChanges(false);
    designerService.getTopics(journeyToUpdate, isTopicApiDataLoaded).then((fethedTopics) => {
      // console.log("getTopics result in pj", fethedTopics);
      if (!fethedTopics.length) {
        Alert.alert('Whoops!', 'Please add topics before publishing the journey');
      } else {
        publish();
      }
    });

    function publish() {
      // console.log("inside publish!!");
      let alertMessage = '';
      if (userIdentity.user_role == 'jd') {
        const isGlobalSpace = journey.space_id === GLOBALSPACEID;
        // console.log('isGlobalSpace', isGlobalSpace);
        journey.journey_status = isGlobalSpace ? 'review' : 'published';
        alertMessage = isGlobalSpace ? 'Journey submitted for review' : 'Journey published';
      } else if (userIdentity.user_role == 'admin') {
        if (status == 'review') {
          alertMessage = 'Journey approved';
        } else {
          alertMessage = 'Journey published';
        }
        journey.journey_status = 'published';
      }
      // console.log("journey publishJourney", journey);
      getUpdatedJourney();
      designerService.updateJourney(journey, false).then(() => {
        // console.log("publishJourney response", response, userIdentity.user_role);
        setIsApiDataLoaded(true);
        if (userIdentity.user_role == 'admin') {
          setStatus('published');
        } else if (userIdentity.user_role == 'jd') {
          const isGlobalSpace = journey.space_id === GLOBALSPACEID;
          isGlobalSpace ? setStatus('review') : setStatus('published');
        }
        Alert.alert('Success', alertMessage);
        props.navigation.navigate('DesignerJourney');
      });
    }
  };

  const getHistory = () => {
    // console.log("journeyToUpdate dh", journeyToUpdate);
    designerService.showJourneyHistory(journeyToUpdate, false).then((response) => {
      // console.log("showJourneyHistory response", response, userIdentity.user_role);
      if (response.length) {
        setHistory(response);
      }
      setShowHistoryModal(true);
      setIsApiDataLoaded(true);
    });
  };

  const ShowPublishbutton = () => {
    // console.log(" ShowPublishbutton :: ", type, " -- ", status );
    if (type == 'Update' && status == 'drafted') {
      return (
        <TouchableElement id='publish' onPress={publishJourney}>
          <View style={style.buttonContainer}>
            <Avatar.Icon
              icon='cloud-upload'
              size={20}
              color={journeyConfig.colors.Lime}
              style={style.icon}
            />
            <TextElement theme='DARK' style={style.buttonTextLime}>
              Publish
            </TextElement>
          </View>
          <View style={style.spaceAfterButton}></View>
        </TouchableElement>
      );
    }
  };

  const historyProps = {
    type,
    status,
    userIdentity,
    getHistory,
  };

  const sortList = (item) => item;

  return (
    <Provider>
      <ViewWithBack
        pageName={type + ' ' + 'Journey'}
        {...props}
        backAction={() => {
          // console.log("unsavedChanges", unsavedChanges, type);
          if (unsavedChanges) {
            Alert.confirm('Confirm', 'Are you sure you want to discard the changes?', () => {
              props.navigation.navigate('DesignerJourney');
            });
          } else {
            props.navigation.navigate('DesignerJourney');
          }
        }}>
        <ScrollView showsVerticalScrollIndicator={false} showsHorizontalScrollIndicator={false}>
          <KeyboardAvoidingView behavior='padding' style={style.keyboard}>
            {isApiDataLoaded ? (
              <View style={style.paddingContainer}>
                <View style={style.buttonRow}>
                  <ShowPublishbutton />
                  <ShowHistorybutton {...historyProps} {...props} />
                </View>

                <View {...disableCondition}>
                  <View>
                    <Dropdown
                      data={spaceList}
                      onChange={(space) => {
                        setSpaceAndModule(spaces, space);
                        setUnsavedChanges(true);
                      }}
                      value={space}
                      label='Select Space'
                    />
                    <View style={style.divider}></View>
                    <Dropdown
                      data={sortBy(moduleList, sortList)}
                      onChange={(module) => {
                        setModule(module);
                        setUnsavedChanges(true);
                      }}
                      value={module}
                      label='Select Module'
                    />
                  </View>
                  <View style={style.divider}></View>
                </View>
                <TextInputElement
                  theme='LIGHT'
                  label='Journey Name'
                  value={journeyName}
                  onChangeText={(journeyName) => {
                    journeyName = journeyName.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                      letter.toUpperCase()
                    );
                    const validationCheck = validateJourneyName(journeyName);
                    if (validationCheck) {
                      setJourneyName(journeyName);
                      setUnsavedChanges(true);
                    } else {
                      setJourneyName(journeyName);
                      const alertMessage = `Journey ${journeyName} already exists`;
                      Alert.alert('Whoops!', alertMessage);
                      setUnsavedChanges(false);
                    }
                  }}
                  labelStyle={style.labelText}
                />

                <View style={style.divider}></View>
                <TextInputElement
                  theme='LIGHT'
                  label='Description'
                  multiline={true}
                  numberOfLines={4}
                  value={journeyDesc}
                  onChangeText={(desc) => {
                    setJourneyDesc(desc);
                    setUnsavedChanges(true);
                  }}
                  labelStyle={style.labelText}
                />
                <View style={style.divider}></View>
                <Dropdown
                  data={Object.keys(journeyConfig.icons)}
                  onChange={(icon) => {
                    setIcon(icon);
                    setUnsavedChanges(true);
                  }}
                  value={icon}
                  label='Select Icon'
                  showScroller={true}
                />
                <View style={style.divider}></View>

                <Dropdown
                  data={Object.keys(journeyConfig.colors)}
                  onChange={(color) => {
                    setColor(color);
                    setUnsavedChanges(true);
                  }}
                  value={color}
                  label='Select Colour'
                />
                <View style={style.divider25}></View>
                {type == 'Update' ? (
                  <View>
                    {/* {console.log(" Test unsavedChanges :: ", unsavedChanges)} */}
                    {unsavedChanges ? (
                      <View style={style.buttonRowView}>
                        <TouchableElement
                          style={style.actionButton}
                          onPress={() => {
                            saveJourney();
                          }}
                          id='submit'>
                          <TextElement style={style.buttonText}>Submit</TextElement>
                        </TouchableElement>

                        <View style={style.buttonSpace}></View>

                        <TouchableElement
                          style={style.actionButton}
                          onPress={() => {
                            saveJourney(true);
                          }}
                          id='continue'>
                          <TextElement style={style.buttonText}>Continue</TextElement>
                        </TouchableElement>
                      </View>
                    ) : (
                      <View style={style.buttonRowView}>
                        <TouchableElement
                          style={style.actionButton}
                          onPress={continueJourney}
                          id='continue-2'>
                          <TextElement style={style.buttonText}>Continue</TextElement>
                        </TouchableElement>
                      </View>
                    )}
                  </View>
                ) : (
                  <View style={style.buttonRowView}>
                    <TouchableElement
                      style={style.actionButton}
                      onPress={() => {
                        saveJourney();
                      }}
                      id='save'>
                      <TextElement style={style.buttonText}>Save</TextElement>
                    </TouchableElement>

                    <View style={style.buttonSpace}></View>

                    <TouchableElement
                      style={style.continueButton}
                      onPress={() => {
                        saveJourney(true);
                      }}
                      id='save-and-continue'>
                      <TextElement style={style.buttonText}>Save & Continue</TextElement>
                    </TouchableElement>
                  </View>
                )}
                {showHistoryModal ? (
                  <Portal>
                    <Modal
                      visible={showHistoryModal}
                      onDismiss={closeHistory}
                      contentContainerStyle={containerStyle}>
                      <View style={style.historyModalContainer}>
                        <View style={style.closeButtonContainer}>
                          <TouchableElement onPress={closeHistory} id='close'>
                            <Avatar.Icon size={40} icon='close' color='black' style={style.icon} />
                          </TouchableElement>
                        </View>

                        <View style={style.historyHeaderContainer}>
                          <TextElement style={style.historyHeader} text='History' />
                        </View>

                        <ScrollView showsVerticalScrollIndicator={false}>
                          {history.map((obj, index) => {
                            return (
                              <View key={index} style={style.historyConatiner}>
                                <View style={style.historyItemContainer}>
                                  <TextElement>{obj.comments}</TextElement>
                                  <View style={style.historyRow}>
                                    <View style={style.createdByContainer}>
                                      <TextElement style={style.historyText}>
                                        {obj.created_by}
                                      </TextElement>
                                    </View>
                                    <View style={style.createdAt}>
                                      <TextElement style={style.historyText}>
                                        {obj.created_at.substr(0, 10)}
                                      </TextElement>
                                    </View>
                                  </View>
                                </View>
                                <View style={style.historyDivider}></View>
                              </View>
                            );
                          })}
                        </ScrollView>
                      </View>
                    </Modal>
                  </Portal>
                ) : (
                  <View />
                )}
                <View style={style.bottomSpace}></View>
              </View>
            ) : (
              <View style={style.alignItemCenter}>
                <TextElement style={style.loadingText}>Loading...</TextElement>
              </View>
            )}
          </KeyboardAvoidingView>
        </ScrollView>
      </ViewWithBack>
    </Provider>
  );
};

const ShowHistorybutton = (props) => {
  const { type, status, userIdentity, getHistory } = props;
  if (type == 'Update' && status == 'rejected' && userIdentity.user_role === 'jd') {
    return (
      <TouchableElement onPress={getHistory} id='comments'>
        <View style={style.historyButtonContainer}>
          <TextElement theme='DARK' style={style.buttonTextDark}>
            {' '}
            Comments{' '}
          </TextElement>
        </View>
        <View style={style.spaceAfterButton}></View>
      </TouchableElement>
    );
  }
};

export default DesignerHome;
