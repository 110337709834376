import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    paragraphView: {
        padding: 10,
        backgroundColor: '#f5f5f5',
        borderRadius: 10,
        margin: 1,
    },
    textView:{ padding: 6, fontWeight: 'bold', fontSize: 12}
});