import * as React from 'react';

import { Animated, Dimensions, Easing, SafeAreaView, View } from 'react-native';
import { chartDataLabels, jobLevelList, orderedDays } from './Metadata';
import { getName, getTimeWithMode } from '../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useIsFocused, useNavigation } from '@react-navigation/native';

import { Avatar } from 'react-native-paper';
import ConnectionsService from '../../service/ConnectionsService';
import Constants from 'expo-constants';
import GoalsService from '../../service/GoalsService';
import { LineChart } from 'react-native-chart-kit';
import ProfileService from '../../service/ProfileService';
import { ScrollView } from 'react-native';
import ScrollViewElement from '../elements/ScrollView';
import TextElement from '../elements/Text';
import TouchableElement from '../elements/TouchableElement';
import ViewElement from '../elements/View';
import { getUserIdentity } from '../../utils/UserIdentity';
import { goalsReducer } from '../goals/GoalSlice';
import { mentorReducer } from '../mentor/MentorSlice';
import { profileReducer } from './ProfileSlice';
import style from './Style';

// import { hrAdminReducer } from '../hrAdmin/HRAdminSlice';
// import StatsService from '../../service/StatsService';
const { width } = Dimensions.get('window');
let openMenu = false;

const widthAnimation = new Animated.Value(0);
const spin = widthAnimation.interpolate({
  inputRange: [0, 1],
  outputRange: [0, 220],
});

const menuHandler = (isFocused = true) => {
  // console.log('inside menuHandler', openMenu, 'isFocused', isFocused);
  Animated.timing(widthAnimation, {
    toValue: isFocused ? (!openMenu ? 1 : 0) : 0,
    duration: 210,
    easing: Easing.ease,
    useNativeDriver: false,
  }).start();
  openMenu = !openMenu;
  // console.log('openMenu after', openMenu);
};

const sortDays = (daysArray) => {
  let sortedDays = [];
  if (daysArray.length) {
    sortedDays = orderedDays.filter((day) => {
      if (daysArray.includes(day)) {
        return day;
      }
    });
  }
  return sortedDays;
};

const Tile = (props) => {
  return (
    <View style={props.width ? style.tileContainer : { ...style.tileContainer, width: 150 }}>
      <View style={style.tileView}>
        <View style={style.tileTextContainer}>
          <TextElement style={style.tileTitle} theme={props.trans ? 'DARK' : 'LIGHT'}>
            {props.title}
          </TextElement>
          <TextElement style={style.paragraph} theme={props.trans ? 'DARK' : 'LIGHT'}>
            {props.paragraph}
          </TextElement>
        </View>
      </View>
    </View>
  );
};

const MenuScreen = (props) => {
  const { userIdentity } = props;
  return (
    <View>
      <View style={style.profileContainer}>
        <Avatar.Image source={require('../../asset/img/userImages/male2.png')} />
        <View style={style.divider5}></View>
        <TextElement theme='LIGHT' numberOfLines={1}>
          {userIdentity && userIdentity.email_id ? getName(userIdentity.email_id).fullName : ''}
        </TextElement>
        <TextElement style={style.paragraph} theme='LIGHT' numberOfLines={1}>
          {userIdentity?.email_id}
        </TextElement>
        <View style={style.divider15}></View>
      </View>

      <View>
        <MenuButton pageName='Session Feedback' navigateToPage='Surveys' />
        <MenuButton pageName='Application Feedback' navigateToPage='Feedback' />
        <MenuButton pageName='Help' />
        <MenuButton pageName='Terms & Conditions' />
        <MenuButton pageName='Logout' />
      </View>
    </View>
  );
};

const MenuButton = ({ pageName, navigateToPage }) => {
  const navigation = useNavigation();

  return (
    <TouchableElement
      onPress={() => {
        navigation.navigate(navigateToPage ? navigateToPage : pageName);
      }}
      id={pageName}
      style={style.menuItem}>
      <TextElement numberOfLines={1} style={style.menuItemText}>
        {pageName}
      </TextElement>
    </TouchableElement>
  );
};

const MainScreen = ({ isFocused }) => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state.profile.value);
  const goals = useSelector((state) => state.goals.value);
  // const statsReports = useSelector((state) => state.hrAdmin.statsReports);

  const profileService = new ProfileService({ navigation });
  const goalsService = new GoalsService({ navigation });
  // const statsService = new StatsService(props);
  const connectionsService = new ConnectionsService({ navigation });

  const [goalsData, setGoalsData] = React.useState({});
  const [fromTime, setFromTime] = React.useState('');
  const [toTime, setToTime] = React.useState('');
  const [softSkills, setSoftSkills] = React.useState([]);
  const [tools, setTools] = React.useState([]);
  const [technologies, settechnologies] = React.useState([]);
  const [aoe, setAOE] = React.useState([]);
  const [knowledge, setKnowledge] = React.useState([]);
  const [others, setOthers] = React.useState([]);
  const [userIdentity, setUserIdentity] = React.useState({});
  const [communicationMode, setCommunicationMode] = React.useState([]);
  const [days, setDays] = React.useState([]);
  const [status, setStatus] = React.useState('');
  const [jobLevel, setJobLevel] = React.useState('');
  const [isApiDataLoaded, setIsApiDataLoaded] = useState(false);
  const [aboutMe, setAboutMe] = React.useState('');
  const [currentProfile, setCurrentProfile] = React.useState('');

  // console.log("isApiDataLoaded", isApiDataLoaded);

  const fetchData = () => {
    // console.log("fetchdata");

    getUserIdentity().then((uIdentity) => {
      if (uIdentity) {
        // let feedbackResultDB;
        setUserIdentity(uIdentity);

        if (profileData && Object.keys(profileData).length) {
          // console.log('*****profileData found', profileData);
          setIsApiDataLoaded(true);
          setProfile(profileData, uIdentity);
        }

        if (goals && goals.length) {
          // console.log("*****goals found", goals);
          setIsApiDataLoaded(true);
          setGoals(goals, uIdentity);
        }

        // console.log("*****fetching profile data from db***");
        profileService.fetchProfile(uIdentity.email_id, isApiDataLoaded).then((profileResult) => {
          // console.log('profileResult', profileResult);
          setIsApiDataLoaded(true);
          setProfile(profileResult, uIdentity);
          dispatch(profileReducer({ profile: profileResult }));
        });

        // console.log("*****fetching goals data from db***");
        goalsService.getGoals(uIdentity.email_id, isApiDataLoaded).then((goals) => {
          // console.log("goals", goals);
          setIsApiDataLoaded(true);
          setGoals(goals);
          dispatch(goalsReducer({ goals: goals }));
        });

        // if (uIdentity.user_role == 'admin') {
        //   // console.log("profile stats data loaded as admin")
        //   let combinedStatsResp = {};
        //   for (const stats of statsReports) {
        //     statsService.getStats(stats, isApiDataLoaded).then((response) => {
        //       // console.log("stats response ", response);
        //       combinedStatsResp[response.name] = response;
        //       if (statsReports.length == Object.keys(combinedStatsResp).length) {
        //         // console.log("combinedStatsResp pp", combinedStatsResp);
        //         dispatch(hrAdminReducer({ stats: combinedStatsResp }));
        //       }
        //     });
        //   }
        // }

        connectionsService
          .getMentorConnections(uIdentity.email_id, isApiDataLoaded)
          .then((response) => {
            // console.log('response mentor', response);
            setIsApiDataLoaded(true);
            dispatch(mentorReducer({ mentorConnections: response }));
          });
      }
    });
  };

  const setProfile = (profileResult) => {
    if (profileResult.error_message) {
      // console.log("error_message", profileResult.error_message);
    } else {
      setJobLevel(profileResult.job_level ? profileResult.job_level.toString() : '');
      const mode = profileResult.preferences
        ? profileResult.preferences.communication_modes
          ? profileResult.preferences.communication_modes
          : []
        : [];
      setCommunicationMode(mode);
      setStatus(
        profileResult.mentor
          ? profileResult.mentor.status
            ? profileResult.mentor.status
            : 'OPEN'
          : ''
      );
      let days = profileResult.preferences
        ? profileResult.preferences.availability
          ? profileResult.preferences.availability.days
            ? profileResult.preferences.availability.days.map((day) => day.substring(0, 3)) || []
            : []
          : []
        : [];
      days = sortDays(days);
      setDays(days);
      setSoftSkills(
        profileResult.skills
          ? profileResult.skills.soft_skills
            ? profileResult.skills.soft_skills
            : []
          : []
      );
      setTools(
        profileResult.skills ? (profileResult.skills.tools ? profileResult.skills.tools : []) : []
      );
      settechnologies(
        profileResult.skills
          ? profileResult.skills.technologies
            ? profileResult.skills.technologies
            : []
          : []
      );
      setKnowledge(
        profileResult.skills
          ? profileResult.skills.industry_knowledge
            ? profileResult.skills.industry_knowledge
            : []
          : []
      );
      setAOE(profileResult.area_of_expertize ? profileResult.area_of_expertize.split(',') : []);
      setOthers(
        profileResult.skills ? (profileResult.skills.others ? profileResult.skills.others : []) : []
      );
      setFromTime(profileResult?.preferences?.availability?.times?.[0]?.from);
      setToTime(profileResult?.preferences?.availability?.times?.[0]?.to);
      setAboutMe(profileResult.about_me);
      setCurrentProfile(profileResult.is_mentor ? 'Mentor' : 'Mentee');
    }
  };

  const setGoals = (goals) => {
    const createdGoals = goals.filter((goal) => goal.status == 'created');
    const inProgressGoals = goals.filter((goal) => goal.status == 'inprogress');
    const completedGoals = goals.filter((goal) => goal.status == 'completed');
    const abortedGoals = goals.filter((goal) => goal.status == 'aborted');
    const goalsResult = {
      totalGoals: goals,
      createdGoals,
      inProgressGoals,
      completedGoals,
      abortedGoals,
    };
    setGoalsData(goalsResult);
  };

  useEffect(() => {
    // console.log("in useffect", isFocused)
    if (isFocused) {
      fetchData();
    }
  }, [isFocused]);

  const trim = (data) => {
    // console.log("data", data)
    if (data.length) {
      data = data.filter((value) => value != '');
      // console.log("inside if", data, data.length)
      return data.join().length > 15 ? data.join().substring(0, 15) + '...' : data.join();
    } else {
      return '-';
    }
  };

  const chartData = {
    labels: chartDataLabels,
    datasets: [
      {
        data: [
          goalsData.createdGoals?.length || 0,
          goalsData.inProgressGoals?.length || 0,
          goalsData.completedGoals?.length || 0,
          goalsData.abortedGoals?.length || 0,
        ],
      },
    ],
  };

  const getHeaderTag = (label, value) => {
    return {
      type: 'header',
      header: {
        value: label,
      },
      data: {
        component: 'tag',
        tag: {
          value: value,
        },
      },
    };
  };

  const preferencesDetails = {
    pageName: 'Preferences',
    backAction: 'Profile',
    payload: [
      getHeaderTag('Communication', communicationMode),
      getHeaderTag('Available On', days),
      getHeaderTag('Available From', [
        fromTime && toTime ? getTimeWithMode(fromTime) + ' - ' + getTimeWithMode(toTime) : '',
      ]),
      getHeaderTag('Profile Status', [
        status ? status.toString().charAt(0) + status.toString().slice(1).toLowerCase() : '',
      ]),
      getHeaderTag('Current Profile', [currentProfile]),
    ],
  };
  // console.log('preferencesDetails', preferencesDetails);

  const skillsDetails = {
    pageName: 'Skills',
    backAction: 'Profile',
    payload: [
      getHeaderTag('Technologies', technologies),
      getHeaderTag('Tools', tools),
      getHeaderTag('Soft skills', softSkills),
      getHeaderTag('Industry Knowledge', knowledge),
      getHeaderTag('Other Info', others),
      getHeaderTag('Area Of Expertise', aoe),
    ],
  };

  return (
    <ViewElement>
      <ScrollView
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        style={style.scrollView}>
        <View style={style.headerContainer}>
          <View style={style.viewElement}>
            <TextElement style={style.name} theme='DARK'>
              {userIdentity && userIdentity.email_id ? getName(userIdentity.email_id).fullName : ''}
            </TextElement>
            <TextElement style={style.paragraph} theme='DARK'>
              {userIdentity && userIdentity.email_id ? userIdentity.email_id : ''}
            </TextElement>
          </View>
          <View style={style.headerIconsContainer}>
            {isApiDataLoaded ? (
              <TouchableElement
                onPress={() => {
                  navigation.navigate('Profileedit');
                }}
                id='profile-edit'>
                <Avatar.Icon
                  testID='account-edit'
                  icon='account-edit'
                  size={40}
                  color='#E1E1E1'
                  style={style.icon}
                />
              </TouchableElement>
            ) : (
              <View></View>
            )}
          </View>
          <View style={style.headerCogIconContainer}>
            <TouchableElement
              id='open-drawer'
              onPress={() => {
                menuHandler();
              }}>
              <Avatar.Icon icon='cog-outline' size={40} style={style.icon} color='#E1E1E1' />
            </TouchableElement>
          </View>
        </View>
        <View style={style.goalsContainer}>
          <View style={style.goalsItemContainer}>
            <TextElement style={style.goalsCountCreated}>
              {goalsData.createdGoals?.length}
            </TextElement>
            <TextElement style={style.goalsTypeText} theme='DARK'>
              {'Created Goals'}
            </TextElement>
          </View>
          <View style={style.goalsItemContainer}>
            <TextElement style={style.goalsCountInProgress}>
              {goalsData.inProgressGoals?.length}
            </TextElement>
            <TextElement style={style.goalsTypeText} theme='DARK'>
              {'In Progress Goals'}
            </TextElement>
          </View>
          <View style={style.goalsItemContainer}>
            <TextElement style={style.goalsCountCompleted}>
              {goalsData.completedGoals?.length}
            </TextElement>
            <TextElement style={style.goalsTypeText} theme='DARK'>
              {'Completed Goals'}
            </TextElement>
          </View>
        </View>
        <View style={style.divider5}></View>
        <View style={style.chartContainer}>
          <LineChart
            data={chartData}
            width={width > 600 ? (width * 40) / 100 : width}
            height={220}
            chartConfig={style.chartConfig}
            bezier
            style={style.chart}
          />
        </View>

        <View>
          <View style={style.preferencesHeaderContainer}>
            <View style={style.preferencesHeaderTextContainer}>
              <TextElement style={style.preferencesHeaderText} theme='DARK'>
                Preferences
              </TextElement>
            </View>
            <View style={style.preferencesSeeAllContainer}>
              {isApiDataLoaded ? (
                <TouchableElement
                  onPress={() => {
                    navigation.navigate('Details', preferencesDetails);
                  }}
                  id='see-all'>
                  <View style={style.seeAllView}>
                    <TextElement theme='DARK' style={style.seeAll}>
                      See All
                    </TextElement>
                    <Avatar.Icon
                      testID='preferences-see-all'
                      icon='arrow-right'
                      size={25}
                      color='#E1E1E1'
                      style={style.icon}
                    />
                  </View>
                </TouchableElement>
              ) : (
                <View></View>
              )}
            </View>
          </View>

          <ScrollViewElement>
            <View style={style.scrollViewRow}>
              <Tile title='Communication' paragraph={trim(communicationMode)} />
              <Tile title='Available On' paragraph={trim(days)} />
              <Tile
                title='Available From'
                paragraph={
                  fromTime && toTime
                    ? getTimeWithMode(fromTime) + ' - ' + getTimeWithMode(toTime)
                    : ''
                }
              />
              <Tile
                title='Profile Status'
                paragraph={
                  status
                    ? status.toString().charAt(0) + status.toString().slice(1).toLowerCase()
                    : ''
                }
              />
              <Tile title='Current Profile' paragraph={currentProfile} />
            </View>
          </ScrollViewElement>
        </View>

        <View>
          <View style={style.skillsHeaderContainer}>
            <View style={style.skillsHeaderTextContainer}>
              <TextElement style={style.skillsHeaderText} theme='DARK'>
                Skills
              </TextElement>
            </View>
            <View style={style.skillsSeeAllContainer}>
              {isApiDataLoaded ? (
                <TouchableElement
                  onPress={() => {
                    navigation.navigate('Details', skillsDetails);
                  }}
                  id='see-all-2'>
                  <View style={style.seeAllView}>
                    <TextElement theme='DARK' style={style.seeAll}>
                      See All
                    </TextElement>
                    <Avatar.Icon
                      testID='skills-see-all'
                      icon='arrow-right'
                      size={25}
                      color='#E1E1E1'
                      style={style.icon}
                    />
                  </View>
                </TouchableElement>
              ) : (
                <View></View>
              )}
            </View>
          </View>

          <ScrollViewElement>
            <View style={style.scrollViewRow}>
              <Tile title='Technical Skills' paragraph={trim(technologies)} />
              <Tile title='Tools' paragraph={trim(tools)} />
              <Tile title='Soft Skills' paragraph={trim(softSkills)} />
              <Tile title='Industry Knowledge' paragraph={trim(knowledge)} />
              <Tile title='Other Info' paragraph={trim(others)} />
              <Tile title='Area of Expertise' paragraph={trim(aoe)} />
            </View>
          </ScrollViewElement>
        </View>
        <TextElement style={style.personalDetailsHeader} theme='DARK'>
          Personal Details
        </TextElement>
        <View style={style.personalDetailsContainer}>
          {/* <Tile title="Email" paragraph={userIdentity?.email_id} width="100%" />
                         <View style={style.divider5}></View> */}
          <Tile title='Job Title' paragraph={userIdentity?.jobTitle} width='100%' />
          <View style={style.divider5}></View>
          <Tile
            title='Job Level'
            paragraph={
              jobLevel ? jobLevelList.filter((joblevel) => joblevel.value == jobLevel)[0].label : ''
            }
            width='100%'
          />
          <View style={style.divider5}></View>
          <Tile title='Location' paragraph={userIdentity?.country} width='100%' />
          <View style={style.divider5}></View>
          <Tile title='Department' paragraph={userIdentity?.department} width='100%' />
          <View style={style.divider5}></View>
          <Tile title='About me' paragraph={aboutMe} width='100%' />
          <View style={style.divider5}></View>
        </View>
        {/* <View style={style.divider80}></View> */}
      </ScrollView>
    </ViewElement>
  );
};

const Profile = () => {
  const [uIdentityLoaded, setUIdentityLoaded] = React.useState(false);
  const [userIdentity, setUserIdentity] = React.useState({});
  const isFocused = useIsFocused();

  useEffect(() => {
    getUserIdentity().then((uIdentity) => {
      setUserIdentity(uIdentity);
      setUIdentityLoaded(true);
    });
  }, [uIdentityLoaded]);

  useEffect(() => {
    if (!isFocused) {
      // console.log('in useffect', isFocused);
      menuHandler(isFocused);
    }
  }, [isFocused]);

  return (
    <SafeAreaView style={style.safeArea}>
      <Animated.View
        style={{
          width: spin,
          backgroundColor: '#f5f5f5',
          flex: 1,
          zIndex: 1,
          position: 'absolute',
          opacity: spin,
          bottom: 0,
          top: 0,
          paddingTop: Constants.statusBarHeight,
        }}>
        <MenuScreen userIdentity={userIdentity} />
      </Animated.View>

      <View style={style.mainScreenContainer}>
        <MainScreen isFocused={isFocused} />
      </View>
    </SafeAreaView>
  );
};

export default Profile;
