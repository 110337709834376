import { createSlice } from '@reduxjs/toolkit';

const counterSlice = createSlice({
    name: 'loaderProperties',
    initialState: {
        value: {
            show: false,
        },
    },
    reducers: {
        loaderReducer: (state, action) => {
            // console.log('Reducer called', action.payload.loaderProperties);
            state.value = action.payload.loaderProperties;
        }
    }
});

// Action creators are generated for each case reducer function
export const { loaderReducer } = counterSlice.actions;
export default counterSlice.reducer;
