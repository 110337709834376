import { Alert, Platform } from 'react-native';

const alert = (title = '',msg) => {
  if (Platform.OS == 'web') window.alert(msg);
  else Alert.alert(title, msg);
};

const confirm = (title, msg, okay = () => {}, cancel = () => {}) => {
  if (Platform.OS == 'web') {
    const res = window.confirm(msg);
    if (res) okay();
    else cancel();
  } else {
    Alert.alert(title, msg, [
      {
        text: 'Cancel',
        onPress: () => cancel(),
        style: 'cancel',
      },
      {
        text: 'OK',
        onPress: () => okay(),
      },
    ]);
  }
};

export default {
  alert,
  confirm,
};
