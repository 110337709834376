import React, { memo, useState, useEffect } from 'react';
import { SafeAreaView, View } from 'react-native';
import HRAdminPage from '../hrAdmin/HRAdminPage';
import MenteePage from '../mentee/MenteePage';
import MentorPage from '../mentor/MentorPage';
import { useNavigation } from '@react-navigation/native';
import { TabBar, TabView } from 'react-native-tab-view';
import { getUserIdentity } from '../../utils/UserIdentity';

const Dashboard = () => {
  const navigation = useNavigation();
  const props = { navigation };
  const [index, setIndex] = useState(0);
  const [userRole, setUserRole] = useState('');

  const routes = [
    { key: 'Mentee', title: 'Mentee' },
    { key: 'Mentor', title: 'Mentor' },
  ];

  const adminRoutes = [
    { key: 'Mentee', title: 'Mentee' },
    { key: 'Mentor', title: 'Mentor' },
    { key: 'Admin', title: 'Admin' },
  ];

  useEffect(() => {
    getUserIdentity().then((d) => {
      setUserRole(d.user_role);
    });
  }, []);

  const getStats = (result) => {
    return {
      accepted: result.accepted.length,
      pending: result.pending.length,
      cancelled: result.cancelled.length,
    };
  };

  const mentorMenteeProps = {
    getStats,
  };

  const renderScene = ({ route }) => {
    switch (route.key) {
      case 'Mentee':
        return <MenteePage {...props} {...mentorMenteeProps} />;
      case 'Mentor':
        return <MentorPage {...props} {...mentorMenteeProps} />;
      case 'Admin':
        return <HRAdminPage {...props} />;
      default:
        return <View />;
    }
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: '#212644' }}>
      <TabView
        key={userRole}
        navigationState={{ index, routes: userRole === 'admin' ? adminRoutes : routes }}
        renderScene={renderScene}
        onIndexChange={(index) => setIndex(index)}
        renderTabBar={(props) => <TabBar style={{ backgroundColor: '#3C436C' }} {...props} />}
      />
    </SafeAreaView>
  );
};

export default memo(Dashboard, (prevProps, nextProps) => {
  if (prevProps.theme !== nextProps.theme) return false;
  else return true;
});
