function convertToJSON(searchFields) {
  let groupCounter = 1;
  const {
    areaOfExpertise,
    skills_softSkill,
    skills_tools,
    skills_technologies,
    communicationModes,
    name,
    status,
    location,
    email,
    department,
    is_mentor,
  } = searchFields;
  let JSONArray = [];
  let result = [];
  // console.log('email::', email)

  // console.log('is_mentor', is_mentor)

  JSONArray.push(createGroup('and', groupCounter));
  groupCounter++;
  if (email) {
    JSONArray.push(createGroup('not', groupCounter));
    result = insertRecords(email, 'email_id', JSONArray, groupCounter);
    JSONArray.push(endGroup(result.groupCounter));
    result.groupCounter++;
  }

  if (
    areaOfExpertise?.length > 0 ||
    skills_softSkill?.length > 0 ||
    skills_tools?.length > 0 ||
    skills_technologies?.length > 0
  ) {
    result.JSONArray.push(createGroup('or', result.groupCounter));
    result = insertRecords(
      areaOfExpertise,
      'area_of_expertize',
      result.JSONArray,
      result.groupCounter
    );
    result = insertRecords(
      skills_softSkill,
      'mentor_mentoring_skills_soft_skills',
      result.JSONArray,
      result.groupCounter
    );
    result = insertRecords(
      skills_tools,
      'mentor_mentoring_skills_tools',
      result.JSONArray,
      result.groupCounter
    );
    result = insertRecords(
      skills_technologies,
      'mentor_mentoring_skills_technologies',
      result.JSONArray,
      result.groupCounter
    );
    result.JSONArray.push(endGroup(result.groupCounter));
    result.groupCounter++;
  }
  // result.groupCounter++;
  // console.log('group counter::', groupCounter)
  if (communicationModes && communicationModes.length > 0) {
    // console.log("result", result)
    result = insertRecords(
      communicationModes,
      'preferences_communication_modes',
      result.JSONArray,
      result.groupCounter
    );
    result.groupCounter++;
  }
  if (status && status.length > 0) {
    result = insertRecords(status, 'mentor_status', result.JSONArray, result.groupCounter);
    result.groupCounter++;
  }

  if (name && name.length > 0) {
    if (result.JSONArray && result.groupCounter) {
      result = insertRecords(name, 'name', result.JSONArray, result.groupCounter);
      result.groupCounter++;
    } else {
      result = insertRecords(name, 'name', JSONArray, groupCounter);
      result.groupCounter++;
    }
  }
  if (location && location.length > 0) {
    result = insertRecords(location, 'location', result.JSONArray, result.groupCounter);
    result.groupCounter++;
  }
  if (department) {
    result = insertRecords(department, 'department', result.JSONArray, result.groupCounter);
    result.groupCounter++;
  }
  if (is_mentor) {
    // console.log('in loop');
    result = insertRecords(is_mentor, 'is_mentor', result.JSONArray, result.groupCounter);
    result.groupCounter++;
  }

  result.JSONArray.push(endGroup(1));
  // console.log('check--', result.JSONArray);
  return result.JSONArray;
}

function createGroup(operator, groupId) {
  return {
    recordType: 'group',
    operator: operator,
    group: 'start',
    groupId: groupId,
  };
}

function endGroup(groupId) {
  return {
    recordType: 'group',
    group: 'end',
    groupId: groupId,
  };
}

function insertRecords(fieldData, fieldName, JSONArray, groupCounter) {
  if (typeof fieldData == 'string' && fieldData.trim() != '') {
    fieldData = fieldData.includes(' ') ? fieldData.split(' ') : fieldData;
    if (typeof fieldData == 'string') {
      JSONArray.push({
        recordType: 'field',
        key: fieldName,
        value: fieldData,
      });
      return { JSONArray, groupCounter };
    }
  } else if (typeof fieldData == 'number') {
    JSONArray.push({
      recordType: 'field',
      fieldType: 'number',
      key: fieldName,
      value: fieldData,
    });
    return { JSONArray, groupCounter };
  }

  // console.log('field data', fieldData);
  if (fieldData && fieldData.length > 1) {
    JSONArray.push(createGroup('or', groupCounter));
    fieldData.forEach((element) => {
      if (element.trim().length > 0) {
        JSONArray.push({ recordType: 'field', key: fieldName, value: element.trim() });
      }
    });
    JSONArray.push(endGroup(groupCounter));
    // groupCounter++;
  }

  if (fieldData && fieldData.length == 1) {
    JSONArray.push({
      recordType: 'field',
      key: fieldName,
      value: fieldData[0],
    });
  }

  return { JSONArray, groupCounter };
}

module.exports = { convertToJSON, createGroup, endGroup, insertRecords };
