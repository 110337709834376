import TextElement from '../../elements/Text';
import TouchableElement from '../../elements/TouchableElement';

const Button = (props) => {
    return (
        <TouchableElement
            style={{ backgroundColor: props.bgColor || '#48B2EB', padding: 10, alignItems: 'center', borderRadius: 5, ...props.style, }}
            onPress={props.onPress || (() => { })}
            id={props.text || props.children}
        >
            <TextElement style={{ color: props.txtColor || '#ffffff', fontWeight: 'bold' }}>{props.text}{props.children}</TextElement>
        </TouchableElement>
    );
};
export default Button;