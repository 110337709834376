import { StyleSheet } from 'react-native';
import journeyConfig from '../../JourneyConfig.json';

export default StyleSheet.create({
    buttonContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        padding: 7,
        borderRadius: 5,
        borderWidth: 1,
        borderColor: journeyConfig.colors.Lime
    },
    buttonTextLime: {
        fontSize: 13,
        color: journeyConfig.colors.Lime,
    },
    buttonTextDark: {
        fontWeight: '700',
        textDecorationLine: 'underline',
    },
    spaceAfterButton: {
        height: 10,
    },
    buttonRow: {
        flexDirection: 'row-reverse',
        alignItems: 'center',
    },
    actionButton: {
        backgroundColor: journeyConfig.travelexColors.actionBlue,
        padding: 10,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
        width: 90,
    },
    continueButton: {
        backgroundColor: journeyConfig.travelexColors.actionBlue,
        padding: 10,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
        width: 150,
    },
    buttonSpace: {
        width: 10,
    },
    historyModalContainer: {
        backgroundColor: 'white',
        width: 350,
        padding: 10,
        width: '100%',
        height: '100%',
    },
    divider: { height: 5 },
    divider25: { height: 25 },
    closeButtonContainer: {
        width: '100%',
        flexDirection: 'row-reverse',
        marginTop: -10,
    },
    buttonText: { color: '#ffffff', fontWeight: 'bold' },
    icon: { backgroundColor: 'transparent' },
    historyConatiner: { width: '100%' },
    historyItemContainer: {
        backgroundColor: '#f5f5f5',
        padding: 10,
    },
    historyRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    createdAt: {
        width: '30%',
        fontSize: 11,
        flexDirection: 'row-reverse',
    },
    historyDivider: {
        height: 5,
        backgroundColor: '#ffffff',
    },
    historyHeaderContainer: { marginTop: -10, marginBottom: 10 },
    historyHeader: { fontWeight: 'bold' },
    historyText: {
        fontSize: 11,
    },
    createdByContainer: {
        width: '70%',
    },
    keyboard: { height: '100%', width: '100%' },
    paddingContainer: {
        padding: 5,
    },
    labelText: {
        fontSize: 16,
        color: '#7D8792',
        fontWeight: 'bold',
    },
    buttonRowView: { flexDirection: 'row', justifyContent: 'flex-end' },
    alignItemCenter: {
        alignItems: 'center'
    },
    loadingText: {
        fontSize: 11,
        color: '#ADADAD',
        marginTop: 10
    },
    historyButtonContainer: { flexDirection: 'row', alignItems: 'center', padding: 7, borderRadius: 5 },
    bottomSpace: { height: 150 }



});





