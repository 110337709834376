import Constants from 'expo-constants';
import { Platform } from 'react-native';
import URL from './URL.json';
let ENV = Constants.expoConfig.extra;

class Constant {
    getConfig = (key) => {
        return ENV.BASE_API_URL + URL[key];
    };
    getLoginURL = () => {
        const clientID = ENV.COGNITO_CLIENT_ID;
        const redirectURI = Platform.OS == 'web' ?  ENV.REDIRECT_URI_WEB : ENV.REDIRECT_URI_MOBILE;
        
        let url = ENV.COGNITO_BASE_LOGIN_URL +  '/oauth2/authorize?response_type=code&client_id=' + clientID + '&redirect_uri=' + redirectURI + '&scope=openid';

        return url;
    };
}



export default new Constant();

