import { RadioButton } from 'react-native-paper';
import * as React from 'react';
import { Platform, View } from 'react-native';
import TextElement from './Text';
// import ColorConstant from '../../utils/ColorConstant.json';


const RadioButtonElement = (props) => {
    const [value, setValue] = React.useState('');
    return (
        // <RadioButton 
        //     value={props.value}
        //     status={props.value === props.index ? 'checked' : 'unchecked'}
        //     onPress={props.handleRadioButton}
        // />
        <RadioButton.Group
            
            onValueChange={newValue => {
                setValue(newValue);
                props.handleRadioButton({
                    'userAnswer': newValue,
                    'sequence': props.sequence
                });
            }}
            value={value}>
            {props.data?.length > 0 ?
                props.data.map((option, idx) => {
                    return (
                        <View key={idx} style={{ flexDirection: 'row', alignItems: 'center', marginTop: 5 }}>
                            <View style={{ borderColor: '#e6e6e6', borderWidth: Platform.OS === 'ios' ? 1 : 0, borderRadius: Platform.OS === 'ios' ? '50%' : 0 }}>
                                <RadioButton color='#424A7A' value={idx.toString()} testID={'radio' + idx.toString()} />
                            </View>
                            <View style={{ height: 5 }} />
                            <TextElement> {option}</TextElement>
                        </View>
                    );
                }) : <View></View>}

        </RadioButton.Group>
    );
};
export default RadioButtonElement;