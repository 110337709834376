import * as React from 'react';

import { List } from 'react-native-paper';
import { capitalize } from 'lodash';
import { useState } from 'react';

const NewDropdown = ({ value, data, setValue, disableCapitalize, style }) => {
  const [expanded, setExpanded] = useState(false);
  const valueHandler = (value) => {
    setValue(value);
    setExpanded(!expanded);
  };

  return (
    <List.Accordion
      expanded={expanded}
      title={disableCapitalize ? value : capitalize(value)}
      onPress={() => setExpanded(!expanded)}
      style={style ? style : { backgroundColor: '#ffffff', marginLeft: 5, marginRight: 5 }}
      titleStyle={{ color: '#000000' }}>
      {data.map((item, index) => (
        <List.Item
          title={item}
          key={index}
          onPress={() => valueHandler(item)}
          style={style ? style : { backgroundColor: '#ffffff', marginHorizontal: 10 }}
        />
      ))}
    </List.Accordion>
  );
};

export default NewDropdown;
