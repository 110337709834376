import { ScrollView, View } from 'react-native';
import { darkColors, lightColors } from './Metadata';

import { Avatar } from 'react-native-paper';
import TextElement from '../../elements/Text';
import TextInputElement from '../../elements/TextInput';
import TouchableElement from '../../elements/TouchableElement';
import journeyConfig from '../JourneyConfig.json';
import style from './styles/dropdownStyle';
import { useState } from 'react';

const Dropdown = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  // const [value, setValue] = useState(props.value);
  const [searchText, setSearchText] = useState('');

  const getTextColor = (d) => {
    // console.log(color)
    if (props.label == 'Select Icon') {
      return '#000000';
    } else if (props.label == 'Select Colour') {
      if (lightColors.includes(d)) {
        return '#ffffff';
      } else if (darkColors.includes(d)) {
        return '#000000';
      }
    } else {
      const color = props.value == d ? journeyConfig.travelexColors.actionBlue : '#000000';
      return color;
    }
  };

  const getBackgroundColor = (d) => {
    if (props.label == 'Select Icon') {
      const color = props.value == d ? journeyConfig.travelexColors.actionBlue : '#f9f9f9';
      return color;
    } else if (props.label == 'Select Colour') {
      const color = journeyConfig.colors[d];
      return color;
    } else {
      return '#f9f9f9';
    }
  };

  return (
    <View style={style.container}>
      <View style={style.container}>
        <TextElement style={style.label}>{props.label || 'Select'}</TextElement>

        <TouchableElement
          id={props.label || 'select'}
          style={style.dropdownContainer}
          onPress={() => setIsOpen(!isOpen)}>
          <TextElement style={style.dropdownValue}>{props.value || 'Select'}</TextElement>
          <Avatar.Icon
            icon='arrow-down-drop-circle-outline'
            size={35}
            style={style.icon}
            color='#000000'
          />
        </TouchableElement>
      </View>
      {isOpen ? (
        <ScrollView
          style={style.scrollView}
          nestedScrollEnabled={true}
          showsVerticalScrollIndicator={props.showScroller || false}
          showsHorizontalScrollIndicator={props.showScroller || false}>
          <TextInputElement
            style={style.searchInput}
            label='Search'
            value={searchText}
            onSubmitEditing={() => {}}
            onChangeText={(text) => {
              setSearchText(text);
            }}></TextInputElement>
          {(props.data || []).map((d, index) => {
            if (searchText) {
              if (!d.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()))
                return <View key={d} />;
            }
            return (
              <TouchableElement
                id={'item-' + index}
                key={d}
                style={{ ...style.listItemContainer, backgroundColor: getBackgroundColor(d) }}
                onPress={() => {
                  // setValue(d);
                  setIsOpen(false);
                  if (props.onChange) props.onChange(d, index);
                }}>
                <Avatar.Icon
                  size={30}
                  icon={
                    props.value == d
                      ? 'checkbox-marked-circle-outline'
                      : 'checkbox-blank-circle-outline'
                  }
                  style={style.icon}
                  color='#000000'
                />
                {props.label == 'Select Icon' ? (
                  <View>
                    <Avatar.Icon
                      size={50}
                      icon={journeyConfig.icons[d]}
                      style={style.icon}
                      color='#000000'
                    />
                  </View>
                ) : (
                  <View></View>
                )}
                {
                  <TextElement
                    style={{
                      ...style.listItemText,
                      fontWeight: props.value == d ? 'bold' : '400',
                      color: getTextColor(d),
                    }}>
                    {' '}
                    {props.label == 'Select Icon' ? d : d}{' '}
                  </TextElement>
                }
              </TouchableElement>
            );
          })}
        </ScrollView>
      ) : (
        <View></View>
      )}
    </View>
  );
};
export default Dropdown;
