import { StyleSheet } from 'react-native';
import ColorConstant from '../../../../utils/ColorConstant.json';
import journeyConfig from '../../../journey/JourneyConfig.json';

export default StyleSheet.create({
    keyboard: { height: '100%', justifyContent: 'center' },
    labelStyle: { fontSize: 16, color: '#7D8792', fontWeight: 'bold' },
    divider5: { height: 5 },
    divider:{ height: 10 },
    bottomSpace: { height: 70 },
    buttonContainer: { alignItems: 'flex-end' },
    mandatory: { color: '#F47765' },
    tagsView: { backgroundColor: ColorConstant.LIGHT?.BACKGROUND_COLOR_MEDIUM_SHADE || '#ffffff', borderRadius: 10, padding: 5 },
    main: { padding: 5 },
    button: { backgroundColor: journeyConfig.travelexColors.actionBlue, width: 90 },
});
