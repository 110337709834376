import { StyleSheet } from 'react-native';
import journeyConfig from '../../journey/JourneyConfig.json';

export default StyleSheet.create({
    divider: { height: 10 },
    noUsersFoundContainer: {
        alignItems: 'center',
    },
    icon: { backgroundColor: 'transparent' },
    rowContainer: { flexDirection: 'row' },
    noUsersFoundText: {
        fontSize: 11,
        color: '#ADADAD',
    },
    resultLabel: {
        fontWeight: 'bold',
        padding: 5,
        color: '#FFFFFF',
    },
    name: { fontWeight: 'bold', fontSize: 15 },
    userContainer: { flex: 5 },
    email: { fontSize: 12 },
    buttonCotainer: { flexDirection: 'row', alignItems: 'center' },
    headerView: { flex: 5, justifyContent: 'center' },
    resultHeader: { fontWeight: 'bold', padding: 5, color: '#FFFFFF', fontSize: 15 },
    addUsersButton: { backgroundColor: journeyConfig.travelexColors.actionBlue },
    listContainer: { backgroundColor: '#f8f8f8', padding: 10, borderWidth: 0.3, borderColor: '#F8F8FF' },
});

