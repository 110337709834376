const chartData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June'],
    datasets: [
        {
            data: [20, 45, 28, 80, 99, 43]
        }
    ]
};
const chartConfig = {
    backgroundGradientFrom: '#ffffff',
    backgroundGradientTo: '#ffffff',
    decimalPlaces: 0,
    color: (opacity = 1) => `rgba(0, 89, 57, ${opacity + 1})`
};

const colors = {
    'created': '#FFAA33',
    'inprogress': '#00BFFF',
    'completed': '#50C878',
    'aborted': '#CD2702'
};
const goalStatusList = {
    'created': 'Created',
    'inprogress': 'In progress',
    'completed': 'Completed',
    'aborted': 'Aborted',
};

module.exports = {
    chartData,
    chartConfig,
    colors,
    goalStatusList
};