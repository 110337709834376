import Alert from '../components/elements/Alert';
import Constants from '../utils/Constants';
import Request from '../dal/RequestNew';
import Store from '../Store/Store';
import { loaderReducer } from '../components/elements/customLoader/customLoaderSlice';

class ProfileService {
  constructor(props) {
    this.props = props;
  }

  async fetchProfile(emailID, isApiDataLoaded) {
    try {
      let url = Constants.getConfig('GET_PROFILE');
      url = url.replace('${emailID}', emailID);
      if (!isApiDataLoaded) Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const request = new Request(this.props);
      let response = await request.getNew(url);
      // console.log("fetchProfile response", response )
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      // console.log("fetchProfile response :: ", response);
      return response;
    } catch (exception) {
      // console.log("exception", exception);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([500, 400].includes(exception.status)) {
        Alert.alert('Whoops', exception.error);
      }
      return {};
    }
  }

  async createProfile(data) {
    try {
      let url = Constants.getConfig('CREATE_PROFILE');
      Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const request = new Request(this.props);
      let response = await request.postNew(url, data);
      // console.log("createProfile response :: ", response);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      // const alertMessage = 'User profile created!';
      Alert.alert('Success', response.message);
      this.props.navigation.navigate('Profile');
      return response;
    } catch (exception) {
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([500, 422, 409].includes(exception.status)) {
        Alert.alert('Whoops', exception.error);
      }
      return {};
    }
  }

  async updateProfile(emailID, data) {
    try {
      let url = Constants.getConfig('GET_PROFILE');
      url = url.replace('${emailID}', emailID);
      Store.dispatch(loaderReducer({ loaderProperties: { show: true } }));
      const request = new Request(this.props);
      let response = await request.putNew(url, data);
      // console.log("updateProfile response :: ", response);
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      // let alertMessage = 'User profile updated.';
      Alert.alert('Success', response.message);
      this.props.navigation.navigate('Home', { screen: 'Profile' });
      return response;
    } catch (exception) {
      Store.dispatch(loaderReducer({ loaderProperties: { show: false } }));
      if ([500, 404].includes(exception.status)) {
        Alert.alert('Whoops', exception.error);
      }
      return {};
    }
  }
}

export default ProfileService;
