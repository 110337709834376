import ColorConstant from '../../utils/ColorConstant.json';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: { flex: 1 },
  accountText: { color: '#ffffff' },
  divider10: { height: 10 },
  divider5: { height: 5 },
  divider80: { height: 80 },
  textStyle: { fontSize: 12, textAlign: 'center' },
  name: { fontSize: 10, fontWeight: 'bold', textAlign: 'center' },
  noRecords: { width: '100%', alignItems: 'center', justifyContent: 'center', padding: 5 },
  noRecordsText: { color: '#B8B8B8' },
  keyboard: { height: '100%', justifyContent: 'center' },
  containerStyle: { height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center' },
  dialogView: {
    backgroundColor: ColorConstant.LIGHT?.BACKGROUND_COLOR_MEDIUM_SHADE || 'white',
    width: 350,
    padding: 20,
  },
  dialogButtonView: { flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'flex-end' },
  dialogButton: {
    backgroundColor: ColorConstant.LIGHT?.BACKGROUND_COLOR_MEDIUM_SHADE || 'white',
  },
  accountSwitch: { display: 'flex', alignItems: 'flex-end' },
  connectionHeader: { fontWeight: 'bold' },
  connectionView: { borderWidth: 0.5, borderColor: '#2E3456', padding: 10, borderRadius: 5 },
  pendingCancel: { marginRight: 10 },
  icon: { backgroundColor: 'transparent' },
  // cancelButton: {
  //   width: '80%',
  //   borderRadius: 10,
  //   backgroundColor: 'transparent',
  //   alignItems: 'center',
  //   borderColor: '#48B2EB',
  //   borderWidth: 1,
  //   margin: 2,
  //   marginTop: 5,
  // },
  drop: {
    padding: 5,
    backgroundColor: '#ffffff',
    borderRadius: 5,
    width: 100,
    display: 'flex',
    alignItems: 'center',
  },
  padding5: { padding: 5 },
  userInfo: {
    backgroundColor: '#7D8792',
    paddingLeft: 10,
    paddingBottom: 10,
    borderBottomStartRadius: 40,
    borderBottomEndRadius: 40,
  },
  userViewRow: { flexDirection: 'row', padding: 10 },
  accountUser: { fontWeight: 'bold', fontSize: 20, color: '#ffffff' },
  userView: { flex: 5.5 },
  dropView: { flex: 0.5, alignItems: 'flex-end' },
  pending: {
    width: 150,
    height: 190,
    backgroundColor: '#E6E6E6',
    borderRadius: 10,
    padding: 10,
    display: 'flex',
    alignItems: 'center',
  },
  cancelled: { width: 150, height: 190, backgroundColor: '#E6E6E6', borderRadius: 10, padding: 5, 
  display: 'flex', alignItems: 'center' },
  accepted: {
    width: 150,
    height: 190,
    backgroundColor: '#E6E6E6',
    borderRadius: 10,
    padding: 5,
    marginRight: 10,
    display: 'flex', alignItems: 'center'
  },
  remove: { padding: 2, color: '#48B2EB', fontSize: 12 },
  accept: { padding: 2, color: '#2EADB7', fontSize: 12 },
  decline: { padding: 2, color: '#DA292F', fontSize: 12 },
  acceptButton: {
    padding: 3,
    flex: 3,
    borderWidth: 1,
    borderColor: '#2EADB7',
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  cancelButton: {
    padding: 3,
    marginRight: 2,
    flex: 3,
    borderWidth: 1,
    borderColor: '#DA292F',
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  centeredView: {display: 'flex', alignItems: 'center', width: '100%' },
  remark: { fontSize: 10, textAlign: 'center' },
  cancelView: { flexDirection: 'row', alignItems: 'center', width: '100%', gap: 5, padding: 2 },
  removeButton: {
    padding: 3,
    width: '70%',
    borderWidth: 1,
    borderColor: '#48B2EB',
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  removeButtonContainer1: {
    width: '100%',
    position: 'absolute',
    bottom: 6,
  },
  removeButtonContainer2: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
