

const definitionStatusList = [
  'created', 'inprogress', 'completed'
];


module.exports = {
  definitionStatusList
};
