import { INITIAL_OFFSET, LIMIT, colors, goalStatusList } from './Metadata';
import { StyleSheet, View } from 'react-native';

import GoalsService from '../../../../service/GoalsService';
import { List } from 'react-native-paper';
import React from 'react';
import Table from './Table';
import TextElement from '../../../elements/Text';
import { getName } from '../../../../utils/NameMapping';
import { getUserIdentity } from '../../../../utils/UserIdentity';

// import { getName } from '../../../utils/utils';

const moment = require('moment');
let userIdentity = null;

const getData = async (props, pageNumber) => {
  // console.log('getdata props', props);
  userIdentity = await getUserIdentity();
  //   console.log('userIdentity', userIdentity);
  const goalsService = new GoalsService(props);

  let response = {};
  if (props.isAdmin) {
    const data = {
      offset: pageNumber ? pageNumber * LIMIT : INITIAL_OFFSET,
      limit: LIMIT,
    };
    response = await goalsService.getAllUsersGoals(data, false);
    // console.log('getAllUsersGoals response', response);
  } else {
    const data = {
      mentor_email_id: userIdentity.email_id,
    };
    response = await goalsService.getMenteeGoals(data, false);
    // console.log('getMenteeGoals response', response);
  }
  return response?.items ? response.items : [];
};

const MenteeGoals = (parentProps) => {
  // console.log('MenteeGoals parentProps', parentProps);
  const { isMentor, isAdmin } = parentProps;
  const Item = (props) => {
    const goalName = props?.goal_name ? props?.goal_name : props?.goal_id;
    // console.log('item', props, props.goal_name, props.goal_id, goalName);
    return (
      <List.Item
        testID='item'
        style={style.item}
        title={goalName?.substring(0, goalName.indexOf('_'))}
        description={() => <Description {...props} />}
        onPress={() => {
          if (isMentor) {
            parentProps.navigation.navigate('Viewgoal', {
              goal: { ...props, goal_name: goalName },
              goalName: goalName,
              isMentor: isMentor,
            });
          }
          if (isAdmin) {
            parentProps.navigation.navigate('Menteegoaldetail', props);
          }
        }}
        right={() => {
          return (
            <View style={style.right}>
              <TextElement style={style.created}>
                Created on {'\n'} {moment.unix(props.created_at).format('YYYY/MM/DD')}
              </TextElement>
            </View>
          );
        }}
      />
    );
  };

  return (
    <Table
      item={({ item }) => <Item {...item} />}
      getData={(pageNumber) => {
        return getData(parentProps, pageNumber);
      }}
      isAdmin={isAdmin}
    />
  );
};

const Description = (props) => {
  return (
    <View>
      <TextElement style={style.description}>{getName(props.email_id)}</TextElement>
      <TextElement style={style.description}>
        Status:
        <TextElement style={{ fontWeight: 'bold', color: getColor(props.status) }}>
          {` ${goalStatusList[props.status.toString()]}`}
        </TextElement>
      </TextElement>
    </View>
  );
};

const getColor = (name) => {
  return colors[name];
};

const style = StyleSheet.create({
  main: {
    flex: 1,
  },
  item: {
    backgroundColor: '#ffffff',
  },
  description: {
    fontSize: 11,
    color: '#7D8792',
  },
  right: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  created: {
    color: '#ADADAD',
    fontSize: 10,
    textAlign: 'center',
  },
  buttonLabel: { color: 'blue' },
});
export default MenteeGoals;
