import { SafeAreaView, StyleSheet } from 'react-native';
import { Provider as PaperProvider } from 'react-native-paper';
import { Modal, Portal } from 'react-native-paper';
import * as React from 'react';

const CustomModal = (props) => {
    const [visible, setVisible] = React.useState(false);
    const openClose = () => setVisible(!visible);

    return (
        <SafeAreaView style={styles.container}>
            <PaperProvider>
                <Portal>
                    <Modal
                        visible={visible}
                        onDismiss={openClose}
                        contentContainerStyle={styles.containerStyle}>
                        {/* {props.modalView} */}
                        {React.cloneElement(props.modalView, { ...props, openClose })}
                    </Modal>
                </Portal>
                {React.cloneElement(props.children, { ...props, openClose })}
            </PaperProvider>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#212644'
    },
    containerStyle: {
        backgroundColor: 'white', padding: 20
    }
});


export default CustomModal;