import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import Alert from '../../elements/Alert';
import { Avatar } from 'react-native-paper';
import Button from '../../journey/designer/Button';
import DesignerService from '../../../service/DesignerService';
import Elements from '../../elements';
import SearchService from '../../../service/SearchService';
import TextElement from '../../elements/Text';
import TouchableElement from '../../elements/TouchableElement';
import { View } from 'react-native';
import { convertToJSON } from '../../../dal/Parser';
import { getUserIdentity } from '../../../utils/UserIdentity';
import journeyConfig from '../../journey/JourneyConfig.json';
import style from './Style';
import { useIsFocused } from '@react-navigation/native';
import { userReducer } from './UserSlice';

const getName = require('../../../utils/NameMapping').getName;

const Users = (props) => {
  const isFocused = useIsFocused();
  const { roleId } = props.route.params;

  const allUsers = useSelector((state) => state.users.value);
  const allUsersEmails = useSelector((state) => state.users.allUsersEmails);
  const recommendedUsers = useSelector((state) => state.users.recommendedUsers);
  //   console.log('recommendedUsers', recommendedUsers);

  const users = Object.keys(allUsers).length ? (allUsers[roleId] ? allUsers[roleId] : []) : [];
  //   console.log('users from redux', users);
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [showAddUser, setShowAddUser] = useState(false);
  const [isSearched, setIsSearched] = useState(false);
  const [pageReload, setPageReload] = useState(false);
  //   console.log('pageReload', pageReload);
  const showAddUserHandler = () => {
    setShowAddUser(true);
    setSearchQuery('');
    setSearchResult([]);
    setIsSearched(false);
  };

  const designerService = new DesignerService(props);

  const fetchData = () => {
    designerService.getUserRoles(users.length ? true : false, roleId).then((response) => {
      // console.log('fetchData getUserRoles', response);
      dispatch(userReducer({ users: response, roleId: roleId }));
      //   if (response.length === 0 && recommendedUsers.length === 0) {
      toRecommendUsers();
      //   }
    });
  };

  const removeUserRole = (userEmail) => {
    designerService.removeUserRole(false, roleId, userEmail).then((response) => {
      setPageReload(true);
      Alert.alert('Success', response.message);
      fetchData();
    });
  };

  const assignUserRole = (userEmail) => {
    const userRole = {
      role_id: roleId,
      email: userEmail,
    };
    designerService.assignUserRole(false, userRole).then((response) => {
      setPageReload(true);
      Alert.alert('Success', response.message);
      setShowAddUser(false);
      fetchData();
    });
  };

  const toRecommendUsers = () => {
    getUserIdentity().then((uIdentity) => {
      if (uIdentity) {
        // setUserIdentity(uIdentity);
        const searchFields = {
          department: uIdentity.department,
          location: uIdentity.country,
          email: uIdentity.email_id,
        };
        // console.log('searchFields', searchFields);
        getSearchResult(searchFields, true, 'recommended');
      }
    });
  };

  const getSearchResult = (searchFields, toSave = false, type) => {
    let jsonData = convertToJSON(searchFields);

    const fetchData = async () => {
      const searchService = new SearchService(props);
      searchService.fetchSearchData(jsonData).then((response) => {
        // console.log('Inside toRecommendUsers response', response);
        // console.log('type', type);
        if (type == 'recommended') {
          if (toSave) {
            dispatch(userReducer({ recommendedUsers: response }));
          }
        } else {
          setSearchResult(response);
          setIsSearched(true);
        }
      });
    };
    try {
      if (isFocused) {
        fetchData();
      }
    } catch (ex) {
      return ex;
    }
  };

  const search = () => {
    getUserIdentity().then((uIdentity) => {
      if (uIdentity) {
        const searchFields = {
          name: searchQuery,
          email: uIdentity.email_id,
        };
        validateData(searchFields);
      }
    });
  };

  const onChangeSearch = (query) => {
    if (query) {
      setSearchQuery(query);
    } else {
      setSearchQuery('');
      setSearchResult([]);
      setIsSearched(false);
    }
  };

  const validateData = (searchFields) => {
    const isInvalid = checkInputData(searchFields);
    if (isInvalid) {
      Alert.alert('Missing field', isInvalid);
      return false;
    } else {
      getSearchResult(searchFields);
    }
  };

  const checkInputData = (data) => {
    if (!data.name) {
      return 'Please enter name';
    } else if (data?.name?.length <= 2) {
      return 'Name should\'ve at least three letters';
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (isFocused) {
      fetchData();
    }
  }, [isFocused]);

  return (
    <View>
      <View style={style.divider}></View>
      {!showAddUser ? (
        <View>
          {users.length ? (
            <UsersComponent
              resultLabel={'Existing Users:'}
              resultData={users}
              showAddUser={showAddUser}
              allUsersEmails={allUsersEmails}
              removeUserRole={removeUserRole}
              buttonHandler={showAddUserHandler}
              buttonLabel={'Add Users'}
            />
          ) : (
            <UsersComponent
              resultLabel={'Recommended Users:'}
              type={'recommended'}
              resultData={recommendedUsers}
              showAddUser={showAddUser}
              assignUserRole={assignUserRole}
              allUsersEmails={allUsersEmails}
              buttonHandler={showAddUserHandler}
              buttonLabel={'Add Users'}
            />
          )}
        </View>
      ) : (
        <View>
          {searchResult.length == 0 ? (
            <SearchBarComponent
              placeHolder={'Search User'}
              resultLabel={'Recommended Users:'}
              type={'recommended'}
              search={search}
              onChangeSearch={onChangeSearch}
              searchQuery={searchQuery}
              resultData={recommendedUsers}
              showAddUser={showAddUser}
              assignUserRole={assignUserRole}
              allUsersEmails={allUsersEmails}
              isSearched={isSearched}
              searchResult={searchResult}
            />
          ) : (
            <SearchBarComponent
              placeHolder={'Search User'}
              resultLabel={'Search Result:'}
              type={'recommended'}
              search={search}
              onChangeSearch={onChangeSearch}
              searchQuery={searchQuery}
              resultData={searchResult}
              showAddUser={showAddUser}
              assignUserRole={assignUserRole}
              allUsersEmails={allUsersEmails}
              isSearched={isSearched}
              searchResult={searchResult}
            />
          )}
        </View>
      )}
    </View>
  );
};

const UsersComponent = (props) => {
  const {
    resultLabel,
    resultData,
    type,
    showAddUser,
    assignUserRole,
    removeUserRole,
    allUsersEmails,
    buttonHandler,
    buttonLabel,
  } = props;
  return (
    <View>
      <View style={style.rowContainer}>
        <View style={style.headerView}>
          <TextElement theme='LIGHT' style={style.resultHeader}>
            {resultLabel}
          </TextElement>
        </View>
        <View>
          <Button style={style.addUsersButton} onPress={buttonHandler}>
            {buttonLabel}
          </Button>
        </View>
      </View>
      <ListComponent
        dataList={resultData}
        type={type}
        showAddUser={showAddUser}
        assignUserRole={assignUserRole}
        removeUserRole={removeUserRole}
        allUsersEmails={allUsersEmails}
        {...props}
      />
    </View>
  );
};

const SearchBarComponent = (props) => {
  const {
    placeHolder,
    search,
    onChangeSearch,
    searchQuery,
    resultLabel,
    resultData,
    type,
    showAddUser,
    assignUserRole,
    allUsersEmails,
    isSearched,
    searchResult,
  } = props;

  return (
    <View>
      <Elements.Searchbar
        placeholder={placeHolder}
        onSubmitEditing={search}
        onChangeText={onChangeSearch}
        value={searchQuery}
      />
      <View style={style.divider}></View>
      {isSearched && searchResult.length == 0 ? (
        <View style={style.noUsersFoundContainer}>
          <Avatar.Icon icon='email-open-outline' color='#ADADAD' size={70} style={style.icon} />
          <TextElement style={style.noUsersFoundText}>No users Found!</TextElement>
        </View>
      ) : (
        <View></View>
      )}
      <View>
        <TextElement theme='LIGHT' style={style.resultLabel}>
          {resultLabel}
        </TextElement>
        <ListComponent
          dataList={resultData}
          type={type}
          showAddUser={showAddUser}
          assignUserRole={assignUserRole}
          allUsersEmails={allUsersEmails}
        />
      </View>
    </View>
  );
};

const ListComponent = (props) => {
  const { dataList, showAddUser, removeUserRole, assignUserRole, type, allUsersEmails } = props;
  return dataList.length ? (
    <View>
      <View style={style.divider}></View>
      {dataList.map((data, index) => {
        return (
          <View key={index} style={style.listContainer}>
            <TouchableElement key={index} id={'add-user' + index}>
              <View style={style.rowContainer}>
                {type == 'recommended' ? (
                  <View style={style.userContainer}>
                    <TextElement style={style.name}>
                      {data.name != null ? data.name : data.name}
                    </TextElement>
                    <TextElement style={style.email}>{data.email_id}</TextElement>
                  </View>
                ) : (
                  <View style={style.userContainer}>
                    <TextElement style={style.name}>{getName(data.email)} </TextElement>
                    <TextElement style={style.email}>{data.email}</TextElement>
                  </View>
                )}

                <View style={style.buttonCotainer}>
                  {assignUserRole ? (
                    <TouchableElement
                      onPress={() => {
                        if (!allUsersEmails.includes(data.email_id)) {
                          assignUserRole(data.email_id);
                        }
                      }}
                      id='account-check'>
                      <Avatar.Icon
                        size={25}
                        icon={
                          allUsersEmails.includes(data.email_id) ? 'account-check' : 'account-plus'
                        }
                        style={style.icon}
                        color={
                          journeyConfig.travelexColors.TRXBlue ||
                          ColorConstant[props.theme]?.BUTTON_BACKGROUND_COLOR
                        }
                      />
                    </TouchableElement>
                  ) : (
                    <TouchableElement
                      onPress={() => {
                        removeUserRole(data.email);
                      }}
                      id='add-remove-account'>
                      <Avatar.Icon
                        size={25}
                        icon={showAddUser ? 'account-plus' : 'account-minus'}
                        style={style.icon}
                        color={
                          journeyConfig.travelexColors.TRXBlue ||
                          ColorConstant[props.theme]?.BUTTON_BACKGROUND_COLOR
                        }
                      />
                    </TouchableElement>
                  )}
                </View>
              </View>
            </TouchableElement>
          </View>
        );
      })}
    </View>
  ) : (
    <View></View>
  );
};

export default Users;
