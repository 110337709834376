import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  section: {
    padding: 5,
    backgroundColor: '#f5f5f5',
    borderRadius: 5,
    margin: 1,
  },
  title: { fontSize: 13, fontWeight: 'bold', lineHeight: 20 },
  header: { padding: 5, fontWeight: 'bold', fontSize: 20 },
  link: { color: 'blue', textDecorationLine: 'underline', fontSize: 15 },
  icon: { backgroundColor: 'transparent' },
  seeAllContainer: { flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' },
  seeAllText: { padding: 7, paddingRight: 2 }
});