import { StyleSheet } from 'react-native';
import { colors } from './Metadata';

export default StyleSheet.create({
  chartConfig: {
    backgroundGradientFrom: '#f1f1f1',
    backgroundGradientTo: '#DADADA',
    decimalPlaces: 1,
    color: (opacity = 1) => `rgba(0, 89, 57, ${opacity + 1})`,
  },
  chart: { borderRadius: 5 },
  headerContainer: {
    margin: 10,
    padding: 5,
    flexDirection: 'row',
  },
  headerIconsContainer: {
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
  },
  headerCogIconContainer: {
    flex: 1,
    alignItems: 'flex-end',
  },
  menuCloseContainer: {
    flex: 1,
    alignItems: 'flex-end'
  },
  goalsContainer: {
    padding: 5,
    flexDirection: 'row',
  },
  goalsItemContainer: {
    flex: 2,
    alignItems: 'center',
  },
  goalsCountCreated: {
    fontWeight: 'bold',
    fontSize: 20,
    color: colors.created,
  },
  goalsCountInProgress: {
    fontWeight: 'bold',
    fontSize: 20,
    color: colors.inProgress,
  },
  goalsCountCompleted: {
    fontWeight: 'bold',
    fontSize: 20,
    color: colors.completed,
  },
  goalsTypeText: {
    fontWeight: 'bold',
    fontSize: 10,
  },
  chartContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  preferencesHeaderContainer: {
    flexDirection: 'row',
  },
  preferencesHeaderTextContainer: {
    width: '50%',
  },
  preferencesHeaderText: { fontWeight: 'bold', fontSize: 16, marginTop: 5, marginBottom: 5 },
  preferencesSeeAllContainer: {
    width: '50%',
    alignItems: 'flex-end',
  },
  seeAll: { padding: 7, paddingRight: 2 },
  skillsHeaderContainer: {
    flexDirection: 'row',
  },
  skillsHeaderText: { fontWeight: 'bold', fontSize: 16, marginTop: 5, marginBottom: 5 },
  skillsHeaderTextContainer: {
    width: '50%',
  },
  skillsSeeAllContainer: {
    width: '50%',
    alignItems: 'flex-end',
  },
  personalDetailsContainer: {
    marginRight: 10,
  },
  personalDetailsHeader: { fontWeight: 'bold', fontSize: 16, marginTop: 10, marginBottom: 5 },
  tileContainer: {
    width: '100%',
    backgroundColor: '#f0f0f0',
    borderWidth: 0.3,
    borderColor: '#F8F8FF',
    borderRadius: 5,
    padding: 5,
    marginLeft: 5,
  },
  tileTextContainer: {
    padding: 5,
  },
  tileTitle: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  paragraph: {
    fontSize: 12,
  },
  icon: { backgroundColor: 'transparent' },
  divider5: { height: 5 },
  divider15: { height: 15 },
  divider80: { height: 80 },
  scrollView: { width: '100%' },
  scrollViewRow: { flexDirection: 'row', marginRight: 5 },
  name: { fontWeight: 'bold', fontSize: 14 },
  seeAllView: { flexDirection: 'row', alignItems: 'center' },
  viewElement: { flex: 4 },
  tileView: { flexDirection: 'row' },
  profileContainer: {
    alignItems: 'center',
    display: 'flex',
  },
  drawerLabel: { color: '#ffffff' },
  menuItem: {
    backgroundColor: '#f5f5f5',
    padding: 12,
    borderBottomWidth: 2,
    borderColor: '#f9f9f9',
  },
  menuItemText: {
    color: '#212644',
    fontSize: 14,
    fontWeight: 'bold',
  },
  closeButton: {
    backgroundColor: '#ffffff',
  },
  profileNavigationMenu: {
    backgroundColor: '#f5f5f5',
    height: '100%',
    flex: 2,
  },
  mainScreenContainer: { backgroundColor: '#212644', flex: 1 },
  mainScreen: { backgroundColor: '#ADADAD', flex: 1 },
  safeArea: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#212644'
  },
});
