/* eslint-disable react/no-unescaped-entities */

import { Image, SafeAreaView, StatusBar, View } from 'react-native';

import { Avatar } from 'react-native-paper';
import TextElement from '../elements/Text';
import TouchableElement from '../elements/TouchableElement';
import style from './styles/welcomeSlideStyle';


const WelcomeSlide2 = (props) => {

    const back = () => {
        props.navigation.navigate('Welcomeslide');
    };

    const next = () => {
        props.navigation.navigate('Welcomeslide3');
    };

    return (
        <SafeAreaView style={[style.safeArea]}>
            <StatusBar backgroundColor='#212644' />
            <View style={[style.verticalCenter, style.phalf]}>
                <TextElement theme="DARK" style={{ fontSize: 20, fontWeight: 'bold' }}>
                    Update Profile
                </TextElement>
            </View>
            <View style={[style.verticalCenter, style.pfour]}>
                <Image resizeMode={'stretch'} source={require('../../assets/profile-welcome.png')} style={style.profileImage} />
                <TextElement theme="DARK" style={style.textStyle}>
                    To get started, update your profile data by clicking on the edit icon at the top on the Profile page. We ask you for information like your areas of expertise, skills, industry knowledge to help you find your ideal match for a great mentoring experience.
                </TextElement>
            </View>
            <View style={[style.phalf, { display: 'flex', flexDirection: 'row', alignItems: 'center' }]}>
                <TouchableElement
                    onPress={back}
                    style={{ flex: .6, margin: 10 }}
                    id='skip'>
                    <Avatar.Icon icon={'arrow-left-bold-circle'} size={50} style={style.icon} />
                </TouchableElement>

                <View style={{ flex: 4.8 }} />

                <TouchableElement
                    onPress={next}
                    style={{ flex: .6, display: 'flex', flexDirection: 'row-reverse', margin: 10 }}
                    id='skip'>
                    <Avatar.Icon icon={'arrow-right-bold-circle'} size={50} style={style.icon} />
                </TouchableElement>
            </View>
        </SafeAreaView >
    );
};

export default WelcomeSlide2;