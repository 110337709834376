import { memo, useEffect, useState } from 'react';

import Alert from './Alert';
import ColorConstant from '../../utils/ColorConstant.json';
import TextElement from './Text';
import { TextInput } from 'react-native-paper';

const TextInputElement = (props) => {
    const [value, setValue] = useState(Array.isArray(props?.value) ? props?.value.join(',') : props?.value);
    const type = 'flat';
    const textstyle = {
        backgroundColor: '#f7f7f7',
        width: '100%',
        ...props.style
    };

    useEffect(() => {
        let tmpValue = Array.isArray(props?.value) ? props?.value.join(',') : props?.value;
        if(value !== tmpValue)
            setValue(tmpValue);
        
    }, [props.value]);

    // console.log('Called text Input');
    // console.log("TextInputElement props?.labelStyle", props?.label);
    // console.log("Test 1234 :: ", typeof props.label)
    // console.log("test 12345678 :: ", props.label && typeof props.label === "object" ? props?.placeholder : props?.label)
    return (
        <TextInput
            accessible={true}
            // testID={props?.label || props?.placeholder}
            testID={props.label && typeof props.label === 'object' ? props?.placeholder : props?.label}
            nativeID={props.label && typeof props.label === 'object' ? props?.placeholder : props?.label}
            accessibilityLabel={props.label && typeof props.label === 'object' ? props?.placeholder : props?.label}
            disabled={props.disabled}
            editable={props.editable}
            placeholder={props.placeholder}
            label={<TextElement style={props?.labelStyle} theme={props.theme}>{props?.label}  {props?.isMandatory ? <TextElement theme={props.theme} style={{ color: '#F47765' }}> *</TextElement> : <TextElement></TextElement>}</TextElement>}
            type={props.type ? props.type : type}
            value={value}
            keyboardType={props?.keyboardType}
            onChangeText={(txt) => { 
                // let reg = new RegExp(`[^A-Za-z0-9.,&?!\n()+/$€£¥₹₽-:${props.allowedChars|| ''} ]`);
                let tempChars = props.allowedChars;
                let tempReg = /[`~'";{}|<>]/;
                
                if(tempChars && tempChars?.includes('\''))
                    tempReg = /[`~";{}|<>]/;
                
                const reg= new RegExp(tempReg);
                
                if (txt.search(reg) !== -1) {
                    Alert.alert('info', 'Special characters are not allowed.');
                }
                else {
                    setValue(txt);
                    props.onChangeText ? props.onChangeText(txt) : console.log();
                    props.onChange ? props.onChange(txt) : console.log();
                }
            }}
            style={textstyle}
            theme={{ colors: { text: ColorConstant[props.theme]?.COLOR || '#000000' } }}
            multiline={props.multiline || false}
            numberOfLines={props.numberOfLines || 1}
            maxLength={props.maxLength ? props.maxLength : undefined}
            onSubmitEditing={props.onSubmitEditing}
        />
    );
};

export default memo(TextInputElement, (prev, next) => {
    return prev.value === next.value && JSON.stringify(prev.reloadData || []) === JSON.stringify(next.reloadData || []);
});
