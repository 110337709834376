/* eslint-disable react/no-unescaped-entities */

import * as WebBrowser from 'expo-web-browser';

import { Avatar, Paragraph, Title } from 'react-native-paper';
import React, { memo } from 'react';

import TextElement from '../elements/Text';
import TouchableElement from '../elements/TouchableElement';
import { View } from 'react-native';
import ViewElement from '../elements/ViewWIthScroll';
import style from './Style';
import { useNavigation } from '@react-navigation/native';

const privacyNoticeLink = 'https://www.theloungetravelex.com/Interact/Pages/Section/ContentListing.aspx?subsection=23040&utm_source=interact&utm_medium=quick_search&utm_term=privacy+notice+global';

const Help = () => {
    const navigation = useNavigation();
    const props = { navigation };

    return (
        <ViewElement pageName="Help" {...props} header={true} backAction={() => props.navigation.navigate('Home', { screen: 'Profile' })}>

            <View style={{ display: 'flex', flex: 1, gap: 5 }}>
                <TextElement theme={'DARK'} text="FAQ" style={style.header}></TextElement>

                <Section title="How do I give feedback about the app?" {...props}>
                    <Paragraph>
                        You can give feedback regarding the app features in 'Feedback' section under 'Settings' menu in Profile page.
                    </Paragraph>
                </Section>

                <Section title="How can I request for my data to be deleted or make a subject access request?" {...props}>
                    <Paragraph>
                        You can email IT Service Desk IT.Service.Desk@travelex.com or raise a Service Generic Request in Ivanti to request for the data on the app to be deleted or to make a data subject access request.
                    </Paragraph>
                </Section>

                <Section title="How my data will be deleted?" {...props}>
                    <Paragraph>
                        If a user leaves the organisation, the data will be deleted within two weeks' time. If the user is in the organisation but has withdrawn from the app, the data will be deleted within 4 weeks of leaving the app. Notifications can be stopped instantly from leaving the app.
                    </Paragraph>
                </Section>


                <Section title="Where can I find the employee privacy notice?" {...props}>
                    <Paragraph>
                        {'Employee privacy notices are geo or country specific and can be found on the Lounge'}
                        <Paragraph style={style.link} onPress={() => WebBrowser.openBrowserAsync(privacyNoticeLink)}> here</Paragraph>.
                    </Paragraph>
                </Section>

                <Section title="How do I report misuse or inappropriate comments on the app?" {...props}>
                    <Paragraph>
                        You can report misuse of the app by emailing PS.Home@travelex.com
                    </Paragraph>
                </Section>

                <Section title="I am having problems accessing the app. What should I do?" {...props}>
                    <Paragraph>
                        You can raise a ticket with IT service desk by raising a “Service request generic” on Ivanti.
                    </Paragraph>
                </Section>

                <Section title="How do I setup a new Goal?" {...props}>
                    <Paragraph>
                        You can open the Goals screen from tab menu,
                        where all the goals will be displayed if you have created them earlier, otherwise it will show "No goals found",{'\n'}
                        On the right bottom corner an "+" add goal button will be shown.
                        Once you click on it, a new create goal screen will open.{'\n'}
                        In which the mentor's email address with whom you have connected will be auto-populated.{'\n'}
                        Now specify the name of the goal and add a remark optionally.{'\n'}
                        Once you have provided all the details correctly, you can add a definition by clicking the "+ DEFINITION" button.{'\n'}
                        After that, a new popup box will open where you can add the definition (different modules or packages, weightage, and add a remark optionally.{'\n'}
                        Finally, on the bottom right side, there is an "ADD" button from which you can add your goal.{'\n'}
                    </Paragraph>
                </Section>

                <Section title="What happens to the goal when connection with the mentor associated is deleted?" {...props}>
                    <Paragraph>
                        The goal will remain as is, and will not be deleted. You can still work on the goal and update it accordingly.{'\n'}
                        If you don't wish to work on the goal you can update it as 'aborted'.{'\n'}
                    </Paragraph>
                </Section>

                <Section title="What is mentor status?" {...props}>
                    <Paragraph>
                        Mentor status shows the availability of the mentor for connection. It can be booked, open, or not available.{'\n'}
                        Booked: The mentor is connected to maximum(limit = 2) number of mentees.{'\n'}
                        Open: The mentor is available for mentorship.{'\n'}
                        Not Available: The mentor is not available for mentorship.{'\n'}
                    </Paragraph>
                </Section>

                <TouchableElement
                    onPress={() => {
                        props.navigation.navigate('Learning');
                    }}
                    id='learning'
                >
                    <View style={style.seeAllContainer}>
                        <TextElement theme="DARK" style={style.seeAllText}>See All</TextElement>
                        <Avatar.Icon
                            testID="preferences-see-all"
                            icon="arrow-right"
                            size={25}
                            color="#E1E1E1"
                            style={style.icon}
                        />
                    </View>
                </TouchableElement>

                <Section title="About" {...props}>
                    <Paragraph>
                        Colleagues at our core is a fundamental strategic pillar for Travelex. We know that learning and development is a key area that our colleagues are passionate about. This is why we decided to launch a new global mentoring programme at Travelex.
                    </Paragraph>
                    <Paragraph>
                        The aim of the programme is to empower colleagues to maximise their potential, enrich relationships and accelerate personal and professional development.
                    </Paragraph>
                    <Paragraph>
                        In order to amplify the mentoring programme, we decided to build our very own Travelex Mentoring App by harnessing the talent of our colleagues through a series of hackathons.
                    </Paragraph>
                    <Paragraph>
                        The LeadX App is the product of the vision, passion and spirit of the people of Travelex.
                    </Paragraph>
                    <Paragraph>
                        Travelex is full of amazing people with fantastic knowledge, expertise skills and capabilities and we harnessed the knowledge of our teams to build this app that colleagues could use to connect with mentors and mentees across the world.
                    </Paragraph>
                    <Paragraph>
                        To learn more about the App journey please visit the Lounge
                        <Paragraph style={style.link} onPress={() => WebBrowser.openBrowserAsync('https://www.theloungetravelex.com/blog/74/post/13442')}> here</Paragraph>.
                    </Paragraph>
                </Section>

                <Section title="App Version" {...props}>
                    <Paragraph>
                        1.0.0
                    </Paragraph>
                </Section>
            </View>
        </ViewElement >
    );
};

const Section = (props) => {
    return (
        <View style={style.section}>
            <Title style={style.title}>{props.title}</Title>
            {props.children}
        </View>
    );
};

export default memo(Help, (prevProps, nextProps) => {
    if (prevProps.theme !== nextProps.theme)
        return false;
    else
        return true;
});
