import { Avatar, Button, Provider } from 'react-native-paper';
import { Modal, Portal } from 'react-native-paper';
import React, { memo, useEffect, useState } from 'react';
import { Text, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import FeedbackService from '../../service/FeedbackService';
import { Rating } from 'react-native-ratings';
import TextElement from '../elements/Text';
import TopicRow from './TopicRow';
import TouchableElement from '../elements/TouchableElement';
import ViewWIthScroll from '../elements/ViewWIthScroll';
import { adminFeedbackReducer } from './AdminFeedbackSlice';
import style from './Style';
import { useIsFocused, useNavigation, useRoute } from '@react-navigation/native';

const AdminFeedbacks = () => {
    const navigation = useNavigation();
    const route = useRoute();
    const props = { navigation, route };
    
    const dispatch = useDispatch();
    const adminFeedbacks = useSelector((state) => state.adminFeedbacks.value);
    
    const feedbackService = new FeedbackService(props);

    const isFocused = useIsFocused();
    const [isApiDataLoaded, setIsApiDataLoaded] = useState(false);
    const [userIdentity] = React.useState(props.route.params.userIdentity);
    // const [rating, setRating] = React.useState('-');
    const [ratingFilter, setRatingFliter] = React.useState(0);

    const [show, setShow] = useState(false);
    const hideModal = () => setShow(false);


    const fetchData = async () => {
        if (adminFeedbacks.length) {
            setIsApiDataLoaded(true);
        }
        // console.log("isApiDataLoaded", isApiDataLoaded)
        const response = await feedbackService.getFeedbacks('getAllFeedbacks', false);
        // console.log("response", response);
        if (Object.keys(response).length) {
            setIsApiDataLoaded(true);
            dispatch(adminFeedbackReducer({ 'adminFeedbacks': response.items }));
        }
    };

    useEffect(() => {
        if (isFocused) {
            fetchData();
        }
    }, [isFocused]);

    const getFilterdRating = (rating) => {
        // console.log("rating", rating);
        feedbackService.getFeedbacks('getFeedbacksByRating', false, rating).then((response) => {
            // console.log("filteredFeedbacks", response);
            setIsApiDataLoaded(true);
            dispatch(adminFeedbackReducer({ 'adminFeedbacks': response.items }));
            // setRating(rating);
            setRatingFliter(0);
        });
    };

    return (
        <Provider>
            <ViewWIthScroll pageName="User Feedbacks" {...props} header={true} backAction={() => {
                props.navigation.goBack();
            }}>
                {
                    <View style={style.centerAlign}>
                        <View style={style.seeAll}>
                            <TouchableElement
                                onPress={() => {
                                    setShow(!show);
                                }}
                                id='filter'
                            >
                                <Avatar.Icon
                                    icon="filter"
                                    size={30}
                                    color="#E1E1E1"
                                    style={style.icon}
                                />
                            </TouchableElement>
                        </View>
                        <FeedBackRow feedbackData={adminFeedbacks} userIdentity={userIdentity} isApiDataLoaded={isApiDataLoaded} {...props} />
                    </View>
                }
                {/* </ScrollView> */}
                <Portal>
                    <Modal visible={show} onDismiss={hideModal}>
                        <View style={style.centerAlign}>
                            <View style={style.ratingView}>
                                <Text style={style.textStyle}>Filter By</Text>
                                <View style={style.ratingsView}>
                                    <Text>Ratings</Text>
                                    <Rating
                                        type='star'
                                        startingValue={ratingFilter}
                                        onFinishRating={(no) => { setRatingFliter(no); }}
                                        imageSize={20}
                                        style={style.rating}
                                    />
                                </View>
                                <View style={style.buttonRow}>
                                    <Button labelStyle={style.buttonLabel} onPress={() => {
                                        setRatingFliter(0);
                                        setShow(false);
                                    }}>Close</Button>
                                    <Button labelStyle={style.buttonLabel} onPress={() => {
                                        setShow(false);
                                        setRatingFliter(0);
                                        fetchData();
                                    }}>Clear</Button>
                                    <Button labelStyle={style.buttonLabel} onPress={() => {
                                        // console.log("ratingFilter", ratingFilter);
                                        getFilterdRating(ratingFilter);
                                        setShow(false);
                                    }}>Apply</Button>
                                </View>
                            </View>
                        </View>
                    </Modal>
                </Portal>
            </ViewWIthScroll>
        </Provider>
    );
};


const FeedBackRow = (props) => {
    const { feedbackData, userIdentity, isApiDataLoaded } = props;
    return (
        isApiDataLoaded ?
            (
                feedbackData?.length ? (
                    feedbackData.map((feedback, index) => (
                        //To render the component using key={JSON.stringify(feedback) + index}
                        <TopicRow key={JSON.stringify(feedback) + index} index={index} data={feedback} userIdentity={userIdentity} isAdminUser={false} {...props} />
                    ))
                ) : (
                    <View style={style.centerAlign}>
                        <Avatar.Icon icon="email-open-outline" color="#ADADAD" size={70} style={style.icon} />
                        <TextElement style={style.info}>No Feedbacks Found!</TextElement>
                    </View>
                )
            ) : (
                <View style={style.centerAlign}>
                    <TextElement style={style.info}>Loading...</TextElement>
                </View>
            )

    );
};

export default memo(AdminFeedbacks, () => {
    return true;
});