import { createSlice } from '@reduxjs/toolkit';

const counterSlice = createSlice({
    name: 'journeys',
    initialState: {
        value: {
            'stats': {},
            'published': [],
            'drafted': [],
            'review': [],
            'rejected': []
        },
        allJourneys: []
    },
    reducers: {
        journeyReducer: (state, action) => {
            // console.log("Reducer called", action);
            const journeys = action.payload.journeys;
            // console.log("journeys in journeySlice", journeys);
            state.value = journeys;
            let allJourneys = [];
            if (Object.keys(journeys).length) {
                Object.keys(journeys).forEach((key) => {
                    if (key != 'stats') {
                        allJourneys = [...allJourneys, ...journeys[key]];
                    }
                });
            }
            // console.log("allJourneys", allJourneys);
            state.allJourneys = allJourneys;
        }
    }

});

// Action creators are generated for each case reducer function
export const { journeyReducer } = counterSlice.actions;
export default counterSlice.reducer;
