import { MultiSelect } from 'react-native-element-dropdown';
import AntDesign from 'react-native-vector-icons/AntDesign';
import { Text } from 'react-native';
import ColorConstant from '../../utils/ColorConstant.json';
import { memo } from 'react';

const MultiSelectElement = (props) => {
    return (
        <MultiSelect
            testID={props.accessibilityLabel || 'dropdown'}
            accessibilityLabel={props.accessibilityLabel || 'dropdown'}
            itemTestIDField={props.accessibilityLabel || 'dropdown'}
            style={props?.style}
            placeholderStyle={{ color: ColorConstant[props.theme]?.COLOR }}
            selectedTextStyle={props?.selectedTextStyle}
            iconStyle={props?.iconStyle}
            selectedStyle={props?.selectedStyle}
            data={props.data}
            labelField="label"
            valueField="value"
            placeholder={props.value.length === 0 ? 'Select' : props.value.join(', ')}
            value={props.value}
            activeColor={ColorConstant[props.theme]?.MULTISELECT_BACKGROUND_COLOR}
            onChange={props.onChange}
            maxHeight={props?.maxHeight}
            renderLeftIcon={() => (
                props.leftIconStyle ? (<AntDesign
                    style={props.leftIconStyle}
                    color={props.value.length ? ColorConstant[props.theme]?.MULTISELECT_ICON : 'black'}
                    name="Safety"
                    size={20}
                />) : (<Text></Text>)

            )}
        />
    );
};

export default memo(MultiSelectElement, (prev, next) => {
    return JSON.stringify(prev.value || []) == JSON.stringify(next.value || []);
});