import { createSlice } from '@reduxjs/toolkit';

const counterSlice = createSlice({
    name: 'menteeStats',
    initialState: {
        value: {}
    },
    reducers: {
        menteeStatsReducer: (state, action) => {
            // console.log("mentee Reducer called", action);
            state.value = action.payload.menteeStats;
        }
    }

});

// Action creators are generated for each case reducer function
export const { menteeStatsReducer } = counterSlice.actions;
export default counterSlice.reducer;

