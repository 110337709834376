import React from 'react';
import { SafeAreaView } from 'react-native';

// import { View } from 'react-native';

const ViewElement = (props) => {
    return (
        <SafeAreaView style={{ backgroundColor: props.topColor || '#212644', flex: 1 }}>
            {props.children}
        </SafeAreaView>
    );
};

export default ViewElement;