import { ScrollView, View } from 'react-native';

import { Avatar } from 'react-native-paper';
import Elements from '../elements';
import { StyleSheet } from 'react-native';

export default (props) => {
    const record = props.route.params;

    return (
        <Elements.ViewWithBack
            pageName='Details'
            {...props}
        >

            <ScrollView
                showsVerticalScrollIndicator={false}
                showsHorizontalScrollIndicator={false}
            >

                <View style={styles.logo}>
                    <Avatar.Image source={require('../../asset/img/userImages/male2.png')} size={100} />
                </View>


                <ShowText label='Name' value={record.name} />

                <ShowText label='Department' value={record.department} />

                <ShowText label='Location' value={record.location} />

        
                {
                    record.remark ?
                      <ShowText label='Remarks' value={record.remark} />
                      :
                      <View />
                }

                {
                    record.reasonToCancel ?
                      <ShowText label='Reason to cancel' value={record.reasonToCancel} />
                      :
                      <View />
                }
                
                {
                    record.area_of_expertize?.length ?
                      <ShowText label='Area of Expertize' value={(record.area_of_expertize.join(', ')).replace(/,/g, ', ')} />
                      :
                      <View />
                }
                {
                    record.soft_skills?.length ?
                      <ShowText label='Soft skills' value={record.soft_skills.join(', ').replace(/,/g, ', ')} />
                      :
                      <View />
                }
                {
                    record.technologies?.length ?
                      <ShowText label='Technologies' value={record.technologies.join(', ').replace(/,/g, ', ')} />
                      :
                      <View />
                }
                {
                    record.communication_modes?.length ?
                      <ShowText label='Communication Modes' value={record.communication_modes.join(', ').replace(/,/g, ', ')} />
                      :
                      <View />
                }
                

            </ScrollView>

        </Elements.ViewWithBack>
    );
};


const ShowText = ({ value, label }) => {
    return (
        <View style={styles.textView}>
            <Elements.Text theme='light' style={styles.textLabel}>
                {label}
            </Elements.Text>
            <Elements.Text theme='light'>{value}</Elements.Text>
        </View>
    );
};

const styles = StyleSheet.create({
    textView: {
        padding: 8,
        backgroundColor: '#f5f5f5',
        borderRadius: 5,
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
        margin: 5
    },
    textLabel: {
        fontSize: 12,
        color: '#807C7C',
    },
    logo: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: 10
    }
});