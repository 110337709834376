import { StyleSheet } from 'react-native';

export default StyleSheet.create({

    container: { height: '100%', justifyContent: 'center' },
    divider:{ height: 10 },
    divider5: { height: 5 },
    textStyle: { fontWeight: 'bold', color: '#fff', fontSize: 16 },
    text: { color: '#fff', lineHeight: 20, fontSize: 13 },
    bottomSpace: { height: 70 },
    padding5: { padding: 5 },
    spaceModuleName: { flex: 4 },
    infoContainer:{ borderWidth: .5, borderColor: '#fff', padding: 10 }
});
