import ColorConstant from '../../utils/ColorConstant.json';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    dropdown: {
        height: 50,
        backgroundColor: 'transparent',
        borderBottomColor: 'gray',
        borderBottomWidth: 0.5,
    },
    placeholderStyle: {
        fontSize: 16,
    },
    selectedTextStyle: {
        fontSize: 14,
    },
    iconStyle: {
        width: 20,
        height: 20,
    },
    icon: {
        marginRight: 5,
        padding: 7
    },
    selectedStyle: {
        display: 'none'
    },
    textStyle: { flexShrink: 1, padding: 7, fontSize: 16 },
    keyboard: { flex: 1, justifyContent: 'center' },
    profileContainer: { justifyContent: 'center', alignItems: 'center' },
    madatory: { color: '#F47765' },
    viewElement: { backgroundColor: ColorConstant.LIGHT?.BACKGROUND_COLOR_MEDIUM_SHADE || '#ffffff', borderRadius: 10 },
    button: { backgroundColor: ColorConstant.LIGHT?.BUTTON_BACKGROUND_COLOR },
    progressStepLabel: { backgroundColor: '#f5f5f5' },
    info: { alignItems: 'flex-start', width: '100%' },
    buttonView: { alignItems: 'flex-end', flexDirection: 'row' },
    previousButton: { width: '50%', alignItems: 'flex-start' },
    nextButton1: { alignItems: 'flex-end' },
    nextButton: { width: '50%', alignItems: 'flex-end' },
    fromToTime: { padding: 20, width: '100%' },
    fromToTimeView: { backgroundColor: ColorConstant.LIGHT?.BACKGROUND_COLOR_MEDIUM_SHADE || '#ffffff', borderRadius: 10 },
    jobLevelView: { backgroundColor: ColorConstant.LIGHT?.BACKGROUND_COLOR_LIGHT_SHADE || '#ffffff', borderRadius: 10 },
    toggleView: { flexDirection: 'row', alignItems: 'center' }
});