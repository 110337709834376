import { INITIAL_OFFSET, LIMIT, colors, statusList } from './Metadata';
import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import DesignerService from '../../../service/DesignerService';
import Elements from '../../elements';
import { List } from 'react-native-paper';
import { useSelector } from 'react-redux';

const moment = require('moment');

const name = (email) => {
  let temp = email?.toUpperCase();
  return temp?.substring(0, temp.indexOf('@')).replace('.', ' ');
};

const MenteeStats = (props) => {
  return <Elements.CustomModal {...props} modalView={<ModalView />} children={<MainScreen />} />;
};

const ModalView = (props) => {
  const [status, setStatus] = useState('');
  return (
    <View>
      <Elements.Text
        style={{ width: '100%', textAlign: 'center', fontSize: 18, fontWeight: 'bold' }}>
        Apply Filters
      </Elements.Text>
      <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Elements.CustomDropdown
          data={statusList}
          value={status}
          setValue={setStatus}
          label='Select Status'
        />
      </View>
      <View style={{ height: 10 }} />

      <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Elements.TextInput onChange={() => {}} label='Serach User by email' />
      </View>
      <View style={{ height: 10 }} />

      <View style={{ display: 'flex', flexDirection: 'row-reverse' }}>
        <Elements.Button
          onPress={() => {
            props.openClose();
          }}
          style={{ backgroundColor: '#212644' }}>
          Apply
        </Elements.Button>
        <View style={{ width: 10 }} />
        <Elements.Button
          onPress={() => {
            props.openClose();
          }}
          style={{ backgroundColor: '#212644' }}>
          Close
        </Elements.Button>
      </View>
    </View>
  );
};

const MainScreen = (props) => {
  const [isApiDataLoaded, setIsApiDataLoaded] = useState(false);
  const [data, setData] = useState([]);
  const mentees = useSelector((state) => state.mentor.mentees);
  // console.log('mentees', mentees);
  const getData = async () => {
    if (mentees.length) {
      const designerService = new DesignerService(props);
      const params = {
        offset: INITIAL_OFFSET,
        limit: LIMIT,
        mentees: mentees,
      };
      const response = await designerService.getMenteesJourneys(params);
      // console.log('response', response);
      setData(response.items);
      setIsApiDataLoaded(true);
    } else {
      setIsApiDataLoaded(true);
    }
  };

  const Empty = () => {
    return isApiDataLoaded ? (
      <View style={[style.main, style.center]}>
        <Elements.Text>No Journey Statistics present!</Elements.Text>
      </View>
    ) : (
      <View></View>
    );
  };

  const Item = (item) => {
    return (
      <List.Item
        title={name(item.email_id)}
        description={() => <Description {...item} />}
        style={{ backgroundColor: '#ffffff', margin: 5, borderRadius: 5 }}
        right={() => <Right {...item} />}
      />
    );
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <Elements.ViewWithBack
      pageName={'Journey Statistics'}
      {...props}
      backAction={() => {
        props.navigation.goBack();
      }}>
      {/* <View style={{ display: 'flex', alignItems: 'flex-end' }}>
        <Elements.Button
          theme='LIGHT'
          style={{ width: 30 }}
          icon='filter-menu'
          onPress={() => {
            props.openClose();
          }}
          color='#ffffff'
          disabled={true}
        />
      </View> */}

      <Elements.CustomVList
        data={data}
        item={({ item }) => <Item {...item} />}
        empty={Empty}
        separator={() => <View />}
      />
    </Elements.ViewWithBack>
  );
};

const Right = (item) => {
  return (
    <View style={style.center}>
      <Elements.Text style={style.rightText}>Enrolled on</Elements.Text>
      <Elements.Text theme='LIGHT' style={style.rightText}>
        {item.created_at ? moment.unix(item.created_at).format('YYYY/MM/DD') : '-'}
      </Elements.Text>
    </View>
  );
};

const Description = (props) => {
  return (
    <View style={{ lineHeight: 10, alignContent: 'space-around' }}>
      <Elements.Text style={style.nameStyle}>{props.display_name}</Elements.Text>
      <Elements.Text style={style.textStyle} numberOfLines={1}>
        {props.description || ''}
      </Elements.Text>
      <Elements.Text style={{ color: colors[props?.status] }}>
        {props.status.toUpperCase()}
      </Elements.Text>
    </View>
  );
};

const style = StyleSheet.create({
  rightText: {
    color: '#898888',
    fontSize: 12,
  },
  textStyle: { color: '#898888' },
  nameStyle: { color: '#898888', fontWeight: 'bold' },
  main: {
    flex: 1,
    padding: 5,
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ffffff',
  },
});

export default MenteeStats;
