import { createSlice } from '@reduxjs/toolkit';

const counterSlice = createSlice({
    name: 'adminFeedbacks',
    initialState: {
        'value': []
    },
    reducers: {
        adminFeedbackReducer: (state, action) => {
            // console.log("action", action)
            state.value = action.payload.adminFeedbacks;
        }
    }
});

export const { adminFeedbackReducer } = counterSlice.actions;
export default counterSlice.reducer;