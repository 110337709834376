import * as WebBrowser from 'expo-web-browser';

import { ImageBackground, Text, View } from 'react-native';
import React, { memo } from 'react';

import Alert from '../elements/Alert';
import ApplicationStorage from '../../utils/ApplicationStorage';
import { Checkbox } from 'react-native-paper';
import Constants from '../../utils/Constants';
import Logo from '../../assets/white-new-logo.png';
import Request from '../../dal/RequestNew';
import TextElement from '../elements/Text';
import TouchableElement from '../elements/TouchableElement';
import { loaderReducer } from '../elements/customLoader/customLoaderSlice';
import style from './styles/welcomeStyle';
import { useDispatch } from 'react-redux';
import Elements from '../elements';

const privacyNoticeLink =
  'https://www.theloungetravelex.com/Interact/Pages/Section/ContentListing.aspx?subsection=23040&utm_source=interact&utm_medium=quick_search&utm_term=privacy+notice+global';

const Welcome = (parentProps) => {
  const props = { ...parentProps, ...parentProps.route.params };
  const dispatch = useDispatch();
  const [consent1, setChecked1] = React.useState(false);
  const [consent2, setChecked2] = React.useState(false);

  const updateConsent = (userIdentity) => {
    dispatch(loaderReducer({ loaderProperties: { show: true } }));
    const request = new Request(props);
    let consentURL = Constants.getConfig('UPDATE_CONSENT');
    let uIdentity = typeof userIdentity === 'string' ? JSON.parse(userIdentity) : userIdentity;
    consentURL = consentURL.replace('${email_id}', uIdentity.email_id);
    // console.log(consentURL);
    request
      .postNew(consentURL, {
        consent: {
          is_consent_agree: true,
        },
      })
      .then(() => {
        dispatch(loaderReducer({ loaderProperties: { show: false } }));
        props.navigation.navigate('Welcomeslide');
      })
      .catch(() => {
        dispatch(loaderReducer({ loaderProperties: { show: false } }));
        // console.log(e);
      });
  };

  return (
    <View style={style.container}>
      <View style={style.logoContainer}>
        <ImageBackground source={Logo} resizeMode='stretch' style={style.logo} />
        <TextElement theme='DARK' style={style.textBold}>
          WE VALUE YOUR PRIVACY
        </TextElement>
      </View>
      <View style={style.cosentContainer}>
        <View style={style.bottomPadding}>
          <TextElement theme='LIGHT' style={style.consentText}>
            Please read below consents carefully
          </TextElement>

          <View style={style.rowContainer}>
            <View style={style.checkBoxContainer}>
              <Elements.CheckBox
                value={consent1}
                handleCheckBox={() => {
                  setChecked1(!consent1);
                }}
              />
              {/* <Checkbox
                style={style.checkBox}
                color='#000000'
                status={consent1 ? 'checked' : 'unchecked'}
                onPress={() => {
                  setChecked1(!consent1);
                }}
              /> */}
            </View>
            <View style={style.consentContainer1}>
              <TextElement theme='LIGHT' style={style.textStyle}>
                I consent to the processing of my personal information in line with the applicable
                country specific privacy notice which can be found on the Lounge at
                <TouchableElement
                  style={style.employeeNotice}
                  onPress={() => {
                    WebBrowser.openBrowserAsync(privacyNoticeLink);
                  }}
                  id='privacy-notice'>
                  <Text style={style.link}> Employee Privacy Notices</Text>
                </TouchableElement>
              </TextElement>
            </View>
          </View>

          <View style={style.divider}></View>
          <View style={style.rowContainer}>
            <View style={style.checkBoxContainer}>
              <Elements.CheckBox
                value={consent2}
                handleCheckBox={() => {
                  setChecked2(!consent2);
                }}
              />
              {/* <Checkbox color='#000000'
                style={style.checkBox}
                status={consent2 ? 'checked' : 'unchecked'}
                onPress={() => {
                  setChecked2(!consent2);
                }} /> */}
            </View>
            <View style={style.consentContainer1}>
              <TextElement style={style.textStyle}>
                I consent to the terms and conditions of use of the app.
              </TextElement>
            </View>
          </View>

          <View style={style.divider}></View>
        </View>
        <View style={style.consentContainer4}>
          <TextElement>Visit </TextElement>
          <TouchableElement
            onPress={() => {
              props.navigation.navigate('Terms & Conditions', {
                backAction: () => {
                  props.navigation.navigate('Welcome');
                },
              });
            }}
            id='terms-and-conditions'>
            <TextElement style={style.link}>Terms and Conditions</TextElement>
          </TouchableElement>
          <TextElement>, to know more details</TextElement>
        </View>
      </View>



      
      <View style={style.buttonRow}>
        <TouchableElement
          style={style.acceptButton}
          onPress={() => {
            ApplicationStorage.getItem('userIdentity').then((data) => {
              if (!consent1 || !consent2) {
                Alert.alert('Info', 'Please agree to Terms & Conditions');
              } else {
                updateConsent(data);
              }
            });
          }}
          id='accept-proceed'>
          <TextElement theme='DARK'>Accept & Proceed</TextElement>
        </TouchableElement>
        <TouchableElement
          style={style.declineButton}
          id='login'
          onPress={() => {
            ApplicationStorage.removeAll().then(() => {
              props.navigation.navigate('Login');
            });
          }}>
          <TextElement style={style.declineText}>Decline</TextElement>
        </TouchableElement>
      </View>
    </View>
  );
};
export default memo(Welcome, () => {
  return true;
});
