import { AntDesign, Ionicons } from '@expo/vector-icons';

import Dashboard from '../components/dashboard/Dashboard';
import Goals from '../components/goals/goalsListing/Goals';
import HomeJourney from '../components/journey/learner/Home';
import Journeydetail from '../components/journey/learner/JourneyDetail';
import Profile from '../components/profile/Profile';
import Search from '../components/search/Search';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import MenteeGoalDetails from '../components/goals/goalsListing/MenteeGoals/MenteeGoalDetails';
import ProfileDetails from '../components/profile/ProfileDetails';
import TableOfContent from '../components/journey/learner/TOC';


const Tab = createBottomTabNavigator();

const TabNavigator = (props) => {
    const properties = { ...props.properties };
    
    return (
        <Tab.Navigator
            tabBarOptions={{
                activeTintColor: '#222745',
            }}
        >
            <Tab.Screen name="Dashboard" component={Dashboard} initialParams={{ ...properties }}
                options={{
                    tabBarLabel: 'Dashboard',
                    tabBarIcon: ({ color, size }) => (
                        <Ionicons name="home" color={color} size={size} />
                    ),
                }}
            />
            <Tab.Screen name="Search" component={Search} initialParams={{ ...properties }}
                options={{
                    tabBarLabel: 'Find Mentor',
                    tabBarIcon: ({ color, size }) => (
                        <Ionicons name="search" color={color} size={size} />
                    ),
                }}
            />
            <Tab.Screen name="Goals" component={Goals} initialParams={{ ...properties }}
                options={{
                    tabBarLabel: 'Goals',
                    tabBarIcon: ({ color, size }) => (
                        <Ionicons name="analytics" color={color} size={size} />
                    ),
                }}
            />
            <Tab.Screen name="Journey" component={HomeJourney} initialParams={{ ...properties }}
                options={{
                    tabBarLabel: 'Journey',
                    tabBarIcon: ({ color, size }) => (
                        <AntDesign name="book" color={color} size={size} />
                    ),
                }}
            />
            <Tab.Screen name="Profile" component={Profile} initialParams={{ ...properties }}
                options={{
                    tabBarLabel: 'Profile',
                    tabBarIcon: ({ color, size }) => (
                        <AntDesign name="user" color={color} size={size} />
                    ),
                }}
            />
            <Tab.Screen name="Journeydetail" component={Journeydetail} initialParams={{ ...properties }}
                options={{
                    tabBarButton: () => null
                }}
            />
            <Tab.Screen name="Journeytoc" component={TableOfContent} initialParams={{ ...properties }}
                options={{
                    tabBarButton: () => null
                }}
            />

            <Tab.Screen name="Menteegoaldetail" component={MenteeGoalDetails} initialParams={{ ...properties }}
                options={{
                    tabBarButton: () => null
                }}
            />
            <Tab.Screen name="Profiledetails" component={ProfileDetails} initialParams={{ ...properties }}
                options={{
                    tabBarButton: () => null
                }}
            />
        </Tab.Navigator>
    );
};
export default TabNavigator;
