import { TimePickerModal } from 'react-native-paper-dates';

const TimePickerModalElement = (props) => {
    return (
        <TimePickerModal
            visible={props.visible}
            onDismiss={props.onDismiss}
            onConfirm={props.onConfirm}
            // hours={12} // default: current hours
            // minutes={14} // default: current minutes
            label={props.label} // optional, default 'Select time'
            uppercase={false} // optional, default is true
            cancelLabel={props.cancelLabel ? props.cancelLabel : 'Cancel'} // optional, default: 'Cancel'
            confirmLabel= {props.confirmLabel ? props.confirmLabel : 'Ok'} // optional, default: 'Ok'
            animationType="fade" // optional, default is 'none'
            locale="en" // optional, default is automically detected by your system
        />
    );
};

export default TimePickerModalElement;