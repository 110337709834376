import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    alignItems: 'center',
    height: '100%',
    width: '100%',
    backgroundColor: '#f5f5f5'
  },
  headerText: {
    padding: 5,
    // fontSize: 12,
    margin: 0,
    color: '#ffffff',
  },
  divider15: { height: 15 },
  goalsView: { width: '100%' }
});