import React, { useEffect, useState } from 'react';
import { SURVEY_TYPE, USER_SURVEY_DEFAULT } from './Metadata';
import { StyleSheet, View } from 'react-native';
import { get, map } from 'lodash';

import Elements from '../elements';
import NewDropdown from '../elements/NewDropdown';
import SurveyList from './SurveyList';
import SurveyService from '../../service/SurveyService';
import { getName } from '../utils/utils';
import { getUserIdentity } from '../../utils/UserIdentity';
import { useNavigation } from '@react-navigation/core';

const MenteeSurvey = () => {
  const navigation = useNavigation();

  // let { activeMenteeList, ...menteeData } = getActiveMentees();
  const [activeMentees, setActiveMentees] = useState([]);
  const [menteeData, setMenteeData] = useState({});
  const [menteeEmail, setMenteeEmail] = useState();

  const [userSurvey, setUserSurvey] = useState(USER_SURVEY_DEFAULT);
  const [isApiDataLoaded, setIsApiDataLoaded] = useState(false);

  const surveyService = new SurveyService({ navigation });

  const fetchData = (email_id, menteeConnectionId) => {
    const surveyUserType = SURVEY_TYPE;
    surveyService
      .getSurveys(false, email_id, surveyUserType, menteeConnectionId)
      .then((response) => {
        // console.log('response', response);
        setUserSurvey(Object.keys(response).length ? response : USER_SURVEY_DEFAULT);
        setIsApiDataLoaded(true);
      });
  };

  useEffect(() => {
    fetchMentees().then((res) => {
      setActiveMentees(res.activeMenteeList);
      setMenteeData(res.menteeData);
    });
  }, []);

  const fetchMentees = async () => {
    let response = [];
    let menteeData = {};
    const uIdentity = await getUserIdentity();

    if (uIdentity) {
      const tmp = await surveyService.getMentorConnections(uIdentity.email_id, true);
      response = map(tmp.accepted, (item) => item.created_by);

      tmp.accepted.forEach((accepted) => {
        const connectionId = get(accepted, 'user_type', '').split('@travelex.com-')[1];
        menteeData[accepted.created_by] = connectionId;
      });
    }
    return { activeMenteeList: response, menteeData };
  };

  return (
    <View style={style.container}>
      <View style={style.dropdownContainer}>
        <Elements.Text style={style.dropdownTitle}>Select</Elements.Text>
        <View style={style.dropdownView}>
          <NewDropdown
            disableCapitalize={true}
            value={menteeEmail ? getName(menteeEmail).fullName : 'Mentee'}
            data={activeMentees}
            setValue={(email) => {
              setMenteeEmail(email);
              fetchData(email, menteeData[email]);
            }}
          />
        </View>
      </View>

      <SurveyList
        userSurvey={userSurvey}
        isApiDataLoaded={isApiDataLoaded}
        pageName={'mentee'}
        userSelected={menteeEmail ? true : false}
      />
    </View>
  );
};

const style = StyleSheet.create({
  container: { flex: 1, gap: 2, backgroundColor: '#f5f5f5' },
  dropdownTitle: { paddingLeft: 5, fontSize: 15, backgroundColor: '#ffffff', flex: 1 },
  dropdownContainer: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#ffffff',
    alignItems: 'center',
  },
  dropdownView: { flex: 5 },
});
export default MenteeSurvey;
