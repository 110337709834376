import ColorConstant from '../../../../utils/ColorConstant.json';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  rowContainer: {
    flexDirection: 'row',
  },
  textInput: { backgroundColor: '#ffffff' },
  scrollView: {
    // maxHeight: 400,
    width: '100%',
    display: 'flex',
    gap: 5,
  },
  icon: {
    backgroundColor: 'transparent',
  },
  mandatory: { color: '#F47765' },
  addSubItems: { alignSelf: 'flex-end' },
  buttonText: {
    color: ColorConstant.LIGHT?.BUTTON_TEXT,
  },
  buttonView: { flexDirection: 'row-reverse', marginBottom: 10, padding: 5 },
  timelineTextInput: { backgroundColor: '#ffffff' },

  pageName: { color: '#ffffff', padding: 5, fontSize: 15 },
  panelTouchable: { backgroundColor: '#ffffff', justifyContent: 'center' },
  panelDescriptionContainer: {
    alignItems: 'center',
  },
  panelDescription: { minHeight: 250, backgroundColor: '#ffffff', width: '100%', padding: 10 },
  pageHeader: {
    height: 40,
    width: '100%',
    backgroundColor: ColorConstant.LIGHT?.BUTTON_BACKGROUND_COLOR,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 5,
  },
  fullWidth: { width: '100%', gap: 2 },
  subItemsTitleView: {
    display: 'flex',
    flexDirection: 'row',
  },
  flex5: { flex: 5 },
  updateGoalContainer: { flexDirection: 'row-reverse', marginBottom: 10, padding: 5 },
  buttonText: { color: ColorConstant.LIGHT?.BUTTON_TEXT },
  updateGoal: { marginRight: 10, backgroundColor: '#0096FF' },
  actionItemsHeader: { fontWeight: 'bold', fontSize: 15 },
  actionItemsHeaderView: {
    display: 'flex',
    flex: 5,
    alignContent: 'center',
    justifyContent: 'center',
    padding: 10,
  },
  narrowSection: {
    width: '98%',
    alignSelf: 'center',
    marginTop: 10,
  },
  disabledDropdownContainer: {
    pointerEvents: 'none'
  },
  statusContainer: { flexDirection: 'row', justifyContent: 'space-between' },
  statusText: { padding: 10, flexDirection: 'row', alignItems: 'center'},
  container: { flex: 1, backgroundColor: '#f5f5f5' },
  marginTop5: { marginTop: 5 }
});
