import { KeyboardAvoidingView, ScrollView, View } from 'react-native';
import { getDate, validateFieldName } from '../../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import Alert from '../../../elements/Alert';
import { Avatar } from 'react-native-paper';
import DesignerService from '../../../../service/DesignerService';
import Elements from '../../../elements';
import TextElement from '../../../elements/Text';
import TextInputElement from '../../../elements/TextInput';
import TouchableElement from '../../../elements/TouchableElement';
import ViewWithBack from '../../../elements/ViewWithBack';
import { getUserIdentity } from '../../../../utils/UserIdentity';
import journeyConfig from '../../JourneyConfig.json';
import { spaceReducer } from './SpaceSlice';
import style from './styles/spacesStyle';
import { useIsFocused } from '@react-navigation/native';

const getName = require('../../../../utils/NameMapping').getName;

const Spaces = (parentProps) => {
  const props = { ...parentProps, ...parentProps.route.params };
  let spaces = useSelector((state) => state.spaces.value);
  // spaces = spaces ? spaces.filter((space) => space.display_name.toLowerCase().trim() != "global") : spaces;
  const dispatch = useDispatch();
  const isFocused = useIsFocused();
  // const { type } = props.route.params;
  // console.log("type", type);

  const [isApiDataLoaded, setIsApiDataLoaded] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [showCreateSpace, setShowCreateSpace] = useState(false);
  const [spaceName, setSpaceName] = useState('');
  const [userIdentity, setUserIdentity] = useState({});
  const [isSearchDataLoaded, setIsSearchDataLoaded] = useState(false);
  const [isSearchSubmitted, setIsSearchSubmitted] = useState(false);

  const spaceList = spaces.map((space) => space.display_name);
  // console.log("spaceList", spaceList);
  // console.log("spaces found", spaces);

  const designerService = new DesignerService(props);

  // console.log("isApiDataLoaded", isApiDataLoaded)
  const fetchData = () => {
    getUserIdentity().then((uIdentity) => {
      setUserIdentity(uIdentity);
      if (spaces.length) {
        // console.log("spaces found", spaces);
        setIsApiDataLoaded(true);
      }
      designerService.getSpaceModules(isApiDataLoaded).then((response) => {
        // console.log("--spaces----fetchdata called", response);
        // const spaces = response.filter((space) => space.display_name.toLowerCase().trim() != "global");
        setIsApiDataLoaded(true);
        // console.log("--spaces", spaces);
        dispatch(spaceReducer({ spaces: response }));
      });
    });
  };

  const search = () => {
    // console.log("search query", searchQuery);
    setIsSearchSubmitted(true);
    designerService.searchSpaces(false, searchQuery).then((response) => {
      // console.log("--spaces----searchSpaces called", response);
      // setIsApiDataLoaded(true);
      setIsSearchDataLoaded(true);
      // setSpaces(response);
      setSearchResult(response);
    });
  };

  const onChangeSearch = (query) => {
    if (query) {
      setSearchQuery(query);
    } else {
      setSearchQuery('');
      setSearchResult([]);
      setIsSearchDataLoaded(false);
      setIsSearchSubmitted(false);
    }
  };

  const createSpace = () => {
    setUnsavedChanges(false);
    const space = {
      scope: 'team',
      display_name: spaceName,
    };

    if (spaceName.toLowerCase().trim() == 'global') {
      const alertMessage = 'Global space already exists';
      Alert.alert('Whoops!', alertMessage);
    } else {
      const validationResult = validateFieldName(spaceName, 'Space', spaceList);
      // console.log("validation result", validationResult);
      if (validationResult.status) {
        designerService.createSpace(false, space).then(() => {
          setIsApiDataLoaded(true);
          Alert.alert('Success', 'Space created');
          setSpaceName('');
          fetchData();
        });
      } else {
        Alert.alert(validationResult.alertType, validationResult.alertMessage);
      }
    }
  };

  const flush = () => {
    setSearchResult([]);
    setSearchQuery('');
    setIsSearchDataLoaded(false);
    setIsSearchSubmitted(false);
  };

  useEffect(() => {
    if (isFocused) {
      fetchData();
    } else {
      flush();
    }
  }, [isFocused]);

  // console.log("unsaved changes", unsavedChanges);
  // console.log("editFlag", editFlag)
  // console.log("spaceList: ", spaceList);

  const listComponentProps = {
    isApiDataLoaded,
    spaceList,
    designerService,
    fetchData,
    setIsApiDataLoaded,
    isSearchDataLoaded,
    ...props,
  };

  return (
    <ViewWithBack
      pageName='Spaces'
      {...props}
      backAction={() => {
        if (unsavedChanges) {
          Alert.confirm('Confirm', 'Are you sure you want to discard the changes?', () => {
            props.navigation.navigate('DesignerDashboard');
          });
        } else {
          props.navigation.goBack();
        }
      }}>
      <Elements.Searchbar
        placeholder='Search Space'
        onSubmitEditing={search}
        onChangeText={onChangeSearch}
        value={searchQuery}
      />
      <View style={style.divider2}></View>

      {showCreateSpace ? (
        <View style={style.spaceRow}>
          <View style={style.spaceNameContainer}>
            <TextInputElement
              theme='LIGHT'
              label='Space Name'
              value={spaceName}
              style={style.spaceNameText}
              placeholder='Please click enter after space name'
              onChangeText={(spaceName) => {
                spaceName = spaceName.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                  letter.toUpperCase()
                );
                setSpaceName(spaceName);
                setUnsavedChanges(true);
              }}
              onSubmitEditing={createSpace}
              labelStyle={style.buttonText}
            />
          </View>
          <TouchableElement
            style={style.cancelButton}
            onPress={() => {
              setSpaceName('');
              setShowCreateSpace(false);
            }}
            id='close'>
            <Avatar.Icon icon='close' size={50} color='#000000' style={style.icon} />
          </TouchableElement>
        </View>
      ) : (
        <View>
          {userIdentity.user_role == 'admin' ? (
            <View style={style.addButtonContainer}>
              <TouchableElement
                id='space'
                onPress={() => {
                  setShowCreateSpace(true);
                }}>
                <View style={style.addButton}>
                  <Avatar.Icon icon='plus' size={25} color='#ffffff' style={style.icon} />
                  <TextElement theme='DARK' style={style.addText}>
                    Space
                  </TextElement>
                </View>
              </TouchableElement>
            </View>
          ) : (
            <View></View>
          )}
        </View>
      )}

      <ScrollView
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        style={style.scrollview}>
        <KeyboardAvoidingView behavior='position' style={style.keyboard}>
          {isSearchSubmitted ? (
            <View>
              <TextElement theme='LIGHT' style={style.searchResultText}>
                Search Result:{' '}
              </TextElement>
              <ListComponent dataList={searchResult} isSearch={true} {...listComponentProps} />
            </View>
          ) : (
            <ListComponent dataList={spaces} {...listComponentProps} />
          )}
          <View style={style.bottomSpace}></View>
        </KeyboardAvoidingView>
      </ScrollView>
    </ViewWithBack>
  );
};

const ListComponent = (props) => {
  const { dataList, isApiDataLoaded, spaceList, isSearchDataLoaded, isSearch } = props;

  const isDataLoaded = isSearch ? isSearchDataLoaded : isApiDataLoaded;

  if (isDataLoaded) {
    if (dataList.length) {
      return (
        <View>
          {dataList.map((data, index) => (
            <View key={index} style={style.listContainer}>
              <SpaceRow
                key={index}
                spaceData={data}
                index={index}
                spaceList={spaceList}
                {...props}
              />
            </View>
          ))}
        </View>
      );
    } else {
      return (
        <View style={style.noResultContainer}>
          <Avatar.Icon icon='email-open-outline' size={50} style={style.icon} />
          <View style={style.divider5}></View>
          <TextElement style={style.noResultsText}>No Spaces Found!</TextElement>
        </View>
      );
    }
  } else {
    return (
      <View style={style.alignItemCenter}>
        <TextElement style={style.loadingText}>Loading...</TextElement>
      </View>
    );
  }
};

const SpaceRow = (props) => {
  const { spaceData, spaceList, designerService, fetchData, setIsApiDataLoaded } = props;

  // console.log("SpaceRow", index, "spaceData", spaceData)
  const [editFlag, setEditFlag] = useState(false);
  const [updatedSpaceName, setUpdatedSpaceName] = useState(spaceData.display_name);
  // console.log("SpaceRow called", index, "editFlag", editFlag, "unsavedChanegs", unsavedChanges);

  const updateSpace = () => {
    if (spaceData.display_name.toLowerCase() == 'global') {
      const alertMessage = 'Global space cannot be updated';
      Alert.alert('Whoops!', alertMessage);
    } else if (updatedSpaceName.toLowerCase().trim() == 'global') {
      const alertMessage = 'Global space already exists';
      Alert.alert('Whoops!', alertMessage);
    } else {
      // const validationResult = validateSpaceName(updatedSpaceName);
      const validationResult = validateFieldName(updatedSpaceName, 'Space', spaceList);
      // console.log("validation result", validationResult);
      if (validationResult.status) {
        const spaceToUpdate = {
          scope: spaceData.scope,
          id: spaceData.id,
          display_name: updatedSpaceName,
        };

        designerService.updateSpace(false, spaceToUpdate).then(() => {
          setEditFlag(false);
          fetchData();
          setIsApiDataLoaded(true);
          Alert.alert('Success', 'Space updated');
        });
      } else {
        Alert.alert(validationResult.alertType, validationResult.alertMessage);
      }
    }
  };

  // const deleteSpace = () => {
  //     // console.log("Delete space", spaceData);

  //     designerService.deleteSpace(false, spaceData).then(() => {
  //         setIsApiDataLoaded(true);
  //         props.setAlertProperties(true, 'Space Deleted', () => { }, 'Success');
  //         fetchData();
  //     });
  // };

  const spaceRowHandler = () => {
    if (!editFlag) {
      props.navigation.navigate('Space', {
        spaceId: spaceData.id,
      });
    }
  };
  // console.log("editFlag", editFlag)

  // console.log("searchResult1", searchResult)
  return (
    <TouchableElement id='spaces' onPress={spaceRowHandler}>
      <View style={style.rowContainer}>
        <View style={style.flex5}>
          {editFlag ? (
            <View style={style.spaceRow}>
              <View style={style.spaceNameContainer}>
                <TextInputElement
                  theme='LIGHT'
                  label='Space Name'
                  value={updatedSpaceName}
                  style={style.spaceNameText}
                  placeholder='Please click enter after space name'
                  onChangeText={(updatedSpaceName) => {
                    updatedSpaceName = updatedSpaceName.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                      letter.toUpperCase()
                    );
                    setUpdatedSpaceName(updatedSpaceName);
                  }}
                  onSubmitEditing={updateSpace}
                  labelStyle={style.buttonText}
                />
              </View>

              <TouchableElement
                style={style.cancelButton}
                onPress={() => {
                  setEditFlag(false);
                }}
                id='close'>
                <Avatar.Icon icon='close' size={40} color='#000000' style={style.icon} />
              </TouchableElement>
            </View>
          ) : (
            <TextElement style={style.spaceName}>{spaceData.display_name}</TextElement>
          )}
          <View style={style.divider5}></View>
          <TextElement style={style.createdBy}>
            Created by {spaceData.created_by ? getName(spaceData.created_by) : '-'} on{' '}
            {spaceData.created_at ? getDate(spaceData.created_at) : '-'}
          </TextElement>
        </View>
        {editFlag ? (
          <View></View>
        ) : (
          <View>
            {spaceData.display_name.toLowerCase().trim() != 'global' ? (
              <View style={style.spaceRow}>
                <TouchableElement
                  onPress={() => {
                    setEditFlag(true);
                  }}
                  id='edit'>
                  <Avatar.Icon
                    size={25}
                    icon='pencil'
                    style={style.icon}
                    color={
                      journeyConfig.travelexColors.TRXBlue ||
                      ColorConstant[props.theme]?.BUTTON_BACKGROUND_COLOR
                    }
                  />
                </TouchableElement>
              </View>
            ) : (
              <View></View>
            )}
          </View>
        )}
      </View>
    </TouchableElement>
  );
};

export default Spaces;
