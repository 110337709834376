import ColorConstant from '../../../../utils/ColorConstant.json';
import { Platform } from 'react-native';
import { StyleSheet } from 'react-native';
import journeyConfig from '../../JourneyConfig.json';

export default StyleSheet.create({
  markdownHeading: {
    fontWeight: 'bold',
  },
  topicIcon: {
    marginTop: -2,
    backgroundColor: 'transparent',
  },
  contentIconContainer: {
    flexDirection: 'row',
    padding: 10,
    alignItems: 'center',
  },
  avatarStyle: {
    backgroundColor: '#222745',
  },
  countText: {
    fontSize: 20,
    padding: 10,
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
    margin: 10,
  },
  enrollStatus: {
    fontSize: 15,
    fontWeight: 'bold',
  },
  createdBy: {
    fontSize: 12,
    lineHeight: 7,
    paddingTop: 10,
  },
  journeyTitle: {
    fontWeight: 'bold',
    fontSize: 16,
  },
  journeyDescription: {
    fontSize: 12,
    lineHeight: 14,
  },
  tabContainer: {
    flexDirection: 'row',
    backgroundColor: '#f5f5f5',
    borderBottomWidth: 2,
    borderColor: '#D8D8D8',
  },
  contentTab: {
    flex: 3,
    alignItems: 'center',
    borderRightWidth: 2,
    borderColor: '#D8D8D8',
  },
  aboutTab: { flex: 3, alignItems: 'center' },
  tabText: {
    padding: 10,
    fontWeight: 'bold',
  },
  contentContainer: {
    flex: 1,
    // height: '70%',
  },
  name: { fontWeight: 'bold', width: '99%', color: '#ffffff' },
  container: {
    // height: '100%',
    flex: 1,
    backgroundColor: '#f5f5f5',
  },
  contentIconsContainer: {
    padding: 10,
  },
  contentIconsRow: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  aboutIconContainer: { flexDirection: 'row', flexWrap: 'wrap' },
  aboutAuthorContainer: {
    padding: 10,
  },
  aboutTitle: {
    padding: 5,
    fontSize: 16,
    fontWeight: 'bold',
  },
  aboutAuthorText: {
    fontSize: 14,
    padding: 5,
  },
  modalcontainerStyle: {
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  // containerStyle: { alignItems: 'center', justifyContent: 'center' },
  scrollView: { backgroundColor: '#f5f5f5' },
  topicsContainer: { height: '100%' },
  noResultContainer: { alignItems: 'center' },
  noResultsText: {
    fontSize: 11,
    color: '#CECECE',
  },
  seperator: { height: 5 },
  marginBottomSpacer: {
    height: 160,
  },
  icon: { backgroundColor: 'transparent' },
  loadingContainer: { alignItems: 'center', height: '100%' },
  loadingText: { fontSize: 11, color: '#CECECE', marginTop: 10 },
  topicDescription: { fontSize: 10, width: '100%' },
  topicInfo: { padding: 10, flexDirection: 'column', width: '90%', flexWrap: 'wrap' },
  subTopicContainer: {
    backgroundColor: '#ffffff',
    flexDirection: 'row',
    padding: 5,
    alignItems: 'center',
    borderRadius: 5,
  },
  rowArea: {
    padding: 5,
    flexDirection: 'row',
    display: 'flex',
  },
  bold: {
    fontWeight: 'bold',
  },
  subTopicIconView: {
    alignItems: 'center',
    backgroundColor: journeyConfig.travelexColors.TRXBlue,
    borderRadius: 5,
  },
  topicIconView: {
    alignItems: 'center',
    backgroundColor: journeyConfig.travelexColors.TRXBlue,
    padding: 0,
    borderRadius: 10,
    margin: 0,
  },
  subTopicInfo: { padding: 10, width: '100%', flexWrap: 'wrap' },
  modalView: { backgroundColor: '#f5f5f5', width: 350, padding: 10, width: '100%', height: '100%' },
  contentView: { width: '100%', flexDirection: 'row-reverse' },
  closeButton: {},
  contentTitle: { padding: 10, backgroundColor: '#A7C96A' },
  topicRowView: { flexDirection: 'row', padding: 10, alignItems: 'center' },
  topicRowContainer: { padding: 10, margin: 5 },
  contentOuterView: { padding: 10, margin: 5 },
  description: { fontSize: 11 },
  link: { fontSize: 9, color: 'black', width: '85%' },
  journeyInfoContainer: { width: '100%' },
  journeyStatusContainer: { display: 'flex', flexDirection: 'row', paddingTop: 7 },
  journeyStatus: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
  keyboard: { height: '100%', width: '100%', justifyContent: 'center' },
  modalView1: {
    backgroundColor: ColorConstant.LIGHT?.BACKGROUND_COLOR_MEDIUM_SHADE || 'white',
    // width: '80%',
    padding: 10,
    borderRadius: 10,
    // minHeight: Platform.OS == 'web' ? '50%' : '40%',
    alignSelf: 'center',
    minHeight: 270,
  },
  // modalView1: {
  //   backgroundColor: ColorConstant.LIGHT?.BACKGROUND_COLOR_MEDIUM_SHADE || 'white',
  //   width: '50%',
  //   padding: 10,
  //   borderRadius: 10,
  //   minHeight: '40%',
  //   alignSelf: 'center',
  // },
  rowContainer: {
    flexDirection: 'row',
  },
  completionDateTextInput: { width: '90%' },
  completionDateTouchable: { width: '100%' },
  completionDateView: {
    // marginLeft: 5,
    // marginRight: 5,
    flexDirection: 'row',
    backgroundColor: '#f7f7f7',
    alignItems: 'center',
  },
  buttonText: { color: ColorConstant.LIGHT?.BUTTON_TEXT },
  closeButton: { marginRight: 5 },
  editButtonContainer: { display: 'flex', flexDirection: 'row-reverse', gap: 10, marginBottom: 10 },
  dropdownContainer: {
    // flexDirection: 'row',
    // justifyContent: 'flex-end'
  },
  dropdown: {
    height: 35,
    width: 150,
    backgroundColor: ColorConstant.LIGHT?.BACKGROUND_COLOR_LIGHT_SHADE || 'white',
    borderColor: 'white',
    borderWidth: 0.5,
    borderRadius: 8,
    paddingHorizontal: 8,
    alignSelf: 'center',
  },
  placeholderStyle: {
    fontSize: 12,
  },
  selectedTextStyle: {
    fontSize: 12,
  },
  statusTimeline: { flex: 1, height: 30, alignItems: 'center' },
  statusTimelineContainer: {
    flex: 1,
    height: 30,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  topicName: { flex: 4, height: 30 },
  topicContainer: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#E6E6E6',
    borderRadius: 5,
    padding: 5,
    width: '100%',
  },
});
