import { KeyboardAvoidingView, ScrollView, View } from 'react-native';
import { filterData, getFields } from '../../utils/utils';
import { useEffect, useState } from 'react';

import Alert from '../../elements/Alert';
import Button from '../../journey/designer/Button';
import DesignerService from '../../../service/DesignerService';
import Dropdown from '../../journey/designer/Dropdown';
import MultiSelect from '../MultiSelect.js';
import TagInput from '../../elements/TagInput';
import TextElement from '../../elements/Text';
import TextInputElement from '../../elements/TextInput';
import ViewWithBack from '../../elements/ViewWithBack';
import style from './styles/roleFormStyle';
import { useIsFocused } from '@react-navigation/native';
import { useSelector } from 'react-redux';

const hash = require('object-hash');

let hashes = [];

const jp = require('jsonpath');
const textStyle = { flexShrink: 1, padding: 7, fontSize: 16 };

const RoleForm = (parentProps) => {
    const props = { ...parentProps, ...parentProps.route.params };
    const isFocused = useIsFocused();
    const { type, roleId } = props.route.params;

    // console.log("type", type);
    // console.log('roleId', roleId);

    const roles = useSelector((space) => space.roles.value);
    const roleToUpdate = roles.filter(role => role.id == roleId)[0];
    // console.log("roleToUpdate", roleToUpdate);

    const spaces = useSelector(state => state.spaces.value)?.filter(space => space.display_name != 'Global');
    const modules = useSelector(state => state.spaces.modules);
    // console.log("modules from redux", modules);
    // const disableCondition = type == 'Create' ? {} : type == 'Update' ? { pointerEvents: "none" } : '';
    const [isApiDataLoaded, setIsApiDataLoaded] = useState(false);
    const [roleDesc, setRoleDesc] = useState('');
    const [unsavedChanges, setUnsavedChanges] = useState(false);

    // const [space, setSpace] = useState('');
    const [access, setAccess] = useState('Read');
    const [createdAt, setCreatedAt] = useState('');
    const [createdBy, setCreatedBy] = useState('');
    const [tags, setTags] = useState([]);
    const [accessList] = useState(['Read', 'Read-Write']);

    const [spaceId, setSpaceId] = useState([]);
    const [spaceName, setSpaceName] = useState([]);
    const [availableModules, setAvailableModules] = useState([]);
    const [moduleId, setModuleId] = useState([]);
    // const [moduleName, setModuleName] = useState([]);
    const [selectedModules, setSelectedModules] = useState([]);
    // const [userIdentity, setUserIdentity] = useState({});

    const designerService = new DesignerService(props);

    const role = {
        id: type == 'Update' ? roleId : '',
        description: roleDesc,
        is_deleted: false,
        access: access,
        created_at: createdAt,
        created_by: createdBy,
        // updated_at: type == 'Update' ? moment().format('YYYY-MM-DD HH:mm:ss') : '',
        // updated_by: type == 'Update' ? userIdentity?.email_id : '',
        tags: tags.join()
    };

    // console.log("tags", tags);
    const fetchData = () => {
        if (type == 'Create') {
            if (spaces.length) {
                // console.log("spaces found", spaces);
                setIsApiDataLoaded(true);
            }
        }
        if (type == 'Update') {
            // for create we need to show all spaces list and modules list
            // for update we need to show selected spaces and their module list
            const { access, created_at, created_by, description, details, tags } = roleToUpdate;

            // console.log("details", details);
            // console.log("space from role", space);

            if (spaces.length) {
                // console.log("spaces found", spaces);
                setIsApiDataLoaded(true);
            }

            if (roleToUpdate && Object.keys(roleToUpdate).length) {
                // console.log('roleToUpdate found', roleToUpdate);
                setIsApiDataLoaded(true);
            }
            setRoleDesc(description);
            setAccess(access);
            // setSpace(details);
            setCreatedBy(created_by);
            setCreatedAt(created_at);
            setTags(tags ? tags.split(',').filter(tag => tag != '') : []);
            setSpaceId(jp.query(details, '$..space'));
            setModuleId(jp.query(details, '$..modules').flat());
        }
    };

    const prepareSpaceObject = (spaceIds, moduleIds) => {
        // console.log('spaceIds', spaceIds);
        // console.log('moduleIds', moduleIds);
        // console.log('spaces', spaces);
        let spaceModules = [];

        spaceIds.forEach((spaceId) => {
            let spaceModulesObj = {};
            spaceModulesObj.space = spaceId;
            spaceModulesObj.modules = [];

            if (moduleIds.includes('*')) {
                spaceModulesObj.modules.push('*');
            } else {
                const modulesQuery = `$[?(@.id=='${spaceId}')].modules..id`;
                let modulesList = jp.query(spaces, modulesQuery);
                // console.log("modulesQuery result", modulesList);
                modulesList = modulesList.filter(moduleId => moduleIds.includes(moduleId));
                spaceModulesObj.modules = modulesList;
            }
            spaceModules.push(spaceModulesObj);
        });
        return spaceModules;
    };


    const getUpdatedRole = () => {
        for (let key of Object.keys(roleToUpdate)) {
            if (roleToUpdate[key] === role[key] && key != 'id' && key != 'created_at' && key != 'created_by') {
                delete role[key];
            }
        }
        // console.log("Role after getUpdatedRole", role);
    };

    const saveRole = () => {
        // console.log('Tags', tags);
        const filterdTags = tags.filter(tag => tag!= '');
        // console.log('filterdTags rr', filterdTags);
        let missingRoleFields = [];

        if (!spaceId.length) {
            missingRoleFields.push('Space');
        }
        if (!moduleId.length) {
            missingRoleFields.push('Module');
        }
        if (!filterdTags.length) {
            missingRoleFields.push('Tags');
        }
        if (missingRoleFields.length) {
            const alertMessage = `Please enter mandatory fields for role: ${missingRoleFields}`;
            Alert.alert('Required',alertMessage);
            return false;
        } else {
            if (type == 'Create') {
                const spaceObject = prepareSpaceObject(spaceId, moduleId);
                // console.log("spaceObject", spaceObject);
                role.details = spaceObject;
                // console.log("role", role);
                let roleHash = hash(role);

                if (!hashes.includes(roleHash)) {
                    hashes.push(roleHash);
                    designerService.createRole(isApiDataLoaded, role).then((response) => {
                        // console.log("response", response);
                        setIsApiDataLoaded(true);
                        Alert.alert('Success', response.message);
                        props.navigation.navigate('Roles');
                    });
                }
            }
            if (type == 'Update') {
                getUpdatedRole();
                designerService.updateRole(isApiDataLoaded, role).then((response) => {
                    // console.log("response", response);
                    setIsApiDataLoaded(true);
                    Alert.alert('Success', response.message);
                    props.navigation.navigate('Roles');
                });
            }
        }
    };

    useEffect(() => {
        if (isFocused) {
            fetchData();
        }
    }, [isFocused]);


    // console.log("moduleTableProps", moduleTableProps);
    // console.log("modules", modules)
    // console.log("role", role);
    // console.log("spaceName", spaceName);
    // console.log("spaceId", spaceId);
    // console.log("availableModules", availableModules);
    // console.log("moduleId", moduleId);
    // console.log("moduleName", moduleName);
    // console.log("selectedModules", selectedModules);


    return (
        <ViewWithBack pageName={type + ' ' + 'Role'}  {...props} backAction={() => {
            if (unsavedChanges) {
                Alert.confirm('Confirm', 'Are you sure you want to discard the changes?', () => { props.navigation.navigate('Roles'); });
            } else {
                props.navigation.navigate('Roles');
            }
        }} >

            <ScrollView
                showsVerticalScrollIndicator={false}
                showsHorizontalScrollIndicator={false}
            >
                <KeyboardAvoidingView behavior="position" style={style.keyboard}>
                    <View style={style.main}>

                        <View style={style.divider}></View>
                        {/* <View {...disableCondition}> */}

                        {
                            type == 'Update' ?
                                <TextInputElement
                                    theme="LIGHT"
                                    label="Role Id"
                                    value={roleId}
                                    editable={false}
                                    labelStyle={style.labelStyle} />
                                :
                                <View></View>
                        }

                        {/* </View> */}

                        {
                            type == 'Create' ?

                                <View>
                                    <MultiSelect
                                        data={spaces}//change in case of update
                                        onChange={(spaceResult) => {
                                            const modulesList = spaceResult.selectedObjects.map(space => space.modules).flat();
                                            const spaceIdList = spaceResult.selectedObjects.map(space => space.id);
                                            const spaceNameList = spaceResult.selectedObjects.map(space => space.display_name);

                                            setUnsavedChanges(true);
                                            setSpaceId(spaceIdList);
                                            setSpaceName(spaceNameList);

                                            if (spaceResult.selectedObjects.length != spaceId.length) {
                                                setAvailableModules(modulesList);

                                                const updatedSelectedModules = filterData(selectedModules, 'id', modulesList);
                                                setSelectedModules(updatedSelectedModules);
                                                setModuleId(updatedSelectedModules.map(module => module.id));
                                            }
                                        }}

                                        value={spaceName.join(',')}
                                        label="Select Space"
                                        mandatory={true}
                                    />
                                    <View style={style.divider5}></View>

                                    <MultiSelect
                                        data={availableModules.length ? [{ id: '*', display_name: 'All' }, ...availableModules] : availableModules}
                                        onChange={(moduleResult) => {
                                            // console.log("moduleResult", moduleResult);
                                            setUnsavedChanges(true);
                                            setSelectedModules(moduleResult.selectedObjects);
                                            setModuleId(moduleResult.selectedObjects.map(module => module.id));
                                        }}
                                        value={selectedModules.map(module => module.display_name).join(',')}
                                        label="Select Module"
                                        mandatory={true}
                                    />
                                    <View style={style.divider5}></View>
                                </View>

                                :
                                <View>
                                    <TextInputElement
                                        theme="LIGHT"
                                        label="Spaces"
                                        value={getFields('id', spaceId, 'display_name', spaces)}
                                        editable={false}
                                        labelStyle={style.labelStyle} />

                                    <TextInputElement
                                        theme="LIGHT"
                                        label="Modules"
                                        value={moduleId.includes('*') ? 'All' : getFields('id', moduleId, 'display_name', modules)}
                                        editable={false}
                                        labelStyle={style.labelStyle} />
                                </View>
                        }

                        <View style={style.divider5}></View>

                        <View style={style.divider5}></View>
                        <TextInputElement
                            theme="LIGHT"
                            label="Description"
                            multiline={true}
                            numberOfLines={4}
                            value={roleDesc}
                            onChangeText={(desc) => { setRoleDesc(desc); setUnsavedChanges(true); }}
                            labelStyle={style.labelStyle}
                        />
                        <View style={style.divider5}></View>

                        <Dropdown
                            data={accessList}
                            onChange={(access) => { setAccess(access); setUnsavedChanges(true); }}
                            value={access}
                            label="Select Access"
                        />
                        <View style={style.divider5}></View>
                        <View style={style.tagsView}>
                            <TextElement theme={props.theme} style={textStyle}>Enter Tags<TextElement theme={props.theme} style={style.mandatory}> *</TextElement></TextElement>
                            <TagInput value={tags} setter={setTags} accessibilityLabel={'Enter Tags'} />
                        </View>

                        <View style={style.divider}></View>

                        <View style={style.buttonContainer} >
                            <Button style={style.button} onPress={saveRole}>{type}</Button>
                        </View>
                        <View style={style.bottomSpace}></View>
                    </View>
                </KeyboardAvoidingView>
            </ScrollView>

        </ViewWithBack>
    );
};

export default RoleForm;