import { StyleSheet, Text, View, VirtualizedList } from 'react-native';

const CustomVList = (props) => {
  // const [refresh, setRefresh] = useState(false);
  const getItem = (data, index) => data[index];
  const getItemCount = (data) => data.length;
  
  return (
    <VirtualizedList
      initialNumToRender={10}
      renderItem={props.item}
      keyExtractor={() => Math.random()}
      getItemCount={getItemCount}
      getItem={getItem}
      data={props.data}
      ListEmptyComponent={props.empty || (() => <Empty />)}
      ItemSeparatorComponent={props.separator || (() => <Separator />)}
      showsVerticalScrollIndicator={false}
      showsHorizontalScrollIndicator={false}
    />
  );
};

const Empty = () => (
  <View style={[style.main, style.center]}>
    <Text style={{ color: '#ffffff' }}>No data found</Text>
  </View>
);

const Separator = () => <View style={style.separator} />;

const style = StyleSheet.create({
  main: {
    flex: 1,
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 200,
  },
  separator: {
    height: 2,
    backgroundColor: '#ADADAD',
  },
});

export default CustomVList;
