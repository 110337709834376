const colors = {
    'enrolled': '#FFAA33',
    'completed': '#50C878',
};

const MAX_JOURNEYS = 10;

const menteeJourneyCard = {
    'background_color': 'Yellow',
    'icon': 'MenteeJourneys',
    'description': '',
    // 'display_name': 'Mentee\'s Journeys',
    'is_deleted': false,
    'pageToNavigate': 'Menteejourneystats'
};

const topicStatusListEnrolled = [
    {
        label: 'IN PROGRESS',
        value: 'inprogress',
    },
    {
        label: 'COMPLETED',
        value: 'completed',
    }
];

const topicStatusListInProgress = [
    {
        label: 'ENROLLED',
        value: 'enrolled',
    },
    {
        label: 'COMPLETED',
        value: 'completed',
    }
];

const topicStatusListCompleted = [
    {
        label: 'IN PROGRESS',
        value: 'inprogress',
    },
    {
        label: 'COMPLETED',
        value: 'completed',
    }
];

const topicStatusList = {
    'enrolled': 'Enrolled',
    'inprogress': 'In progress',
    'completed': 'Completed',
};


export {
    colors,
    MAX_JOURNEYS,
    menteeJourneyCard,
    topicStatusListEnrolled,
    topicStatusListInProgress,
    topicStatusListCompleted,
    topicStatusList
};