import 'intl';
import 'intl/locale-data/jsonp/en';

import * as React from 'react';

import { KeyboardAvoidingView, Platform, SafeAreaView, ScrollView, View } from 'react-native';
import { ProgressStep, ProgressSteps } from 'react-native-progress-steps';
import { commList, daysList, jobLevelList } from './Metadata';
import { getName, getTimeWithMode } from '../utils/utils';
import { memo, useEffect } from 'react';
import { useIsFocused, useNavigation } from '@react-navigation/native';

import Alert from '../elements/Alert';
import AvatarElement from '../elements/Avatar';
import ButtonElement from '../elements/Button';
import ColorConstant from '../../utils/ColorConstant.json';
import DividerElement from '../elements/Divider';
import DropdownElement from '../elements/Dropdown';
import { LogBox } from 'react-native';
import MultiSelectElement from '../elements/Multiselect';
import ProfileService from '../../service/ProfileService';
import TagInput from '../elements/TagInput';
import TextElement from '../elements/Text';
import TextInputElement from '../elements/TextInput';
import TimePickerModal from '../elements/TimePickerModal';
import ToggleElement from '../elements/Toggle';
import TouchableElement from '../elements/TouchableElement';
import ViewWithBack from '../elements/ViewWIthScroll';
import { getUserIdentity } from '../../utils/UserIdentity';
import icon from '../../asset/img/userImages/male6.jpg';
import style from './profileEditStyle';
import { useSelector } from 'react-redux';
import { Appbar } from 'react-native-paper';

// import { Dimensions } from 'react-native';

LogBox.ignoreLogs(['EventEmitter.removeListener']);

const moment = require('moment');

// const { height } = Dimensions.get('window');

const ProfileEdit = () => {
  const navigation = useNavigation();
  let props = {
    navigation,
  };

  const profileService = new ProfileService(props);
  const profileData = useSelector((state) => state.profile.value);
  const isFocused = useIsFocused();
  const [isMentor, setIsMentor] = React.useState(false);
  const handleToggle = () => {
    setIsMentor(!isMentor);
  };
  // console.log("profileData", profileData);
  // Time Picker
  const [visibleFromTime, setVisibleFromTime] = React.useState(false);
  const [visibleToTime, setVisibleToTime] = React.useState(false);
  const [fromTime, setFromTime] = React.useState('');
  const [toTime, setToTime] = React.useState('');
  const onDismissFromTime = React.useCallback(() => {
    setVisibleFromTime(false);
  }, [setVisibleFromTime]);
  const onDismissToTime = React.useCallback(() => {
    setVisibleToTime(false);
  }, [setVisibleToTime]);
  const [softSkills, setSoftSkills] = React.useState([]);
  const [tools, setTools] = React.useState([]);
  const [technologies, settechnologies] = React.useState([]);
  const [aoe, setAOE] = React.useState([]);
  const [knowledge, setKnowledge] = React.useState([]);
  const [others, setOthers] = React.useState([]);
  const [aboutMe, setAboutMe] = React.useState('');
  const [linkedinId, setLinkedinId] = React.useState('');
  const [userIdentity, setUserIdentity] = React.useState({});
  const [activeStep, setActiveStep] = React.useState(2);
  // const [isApiDataLoaded, setIsApiDataLoaded] = useState(false);
  const [jobLevel, setJobLevel] = React.useState('');

  const [department, setDepartment] = React.useState('');
  const [location, setLocation] = React.useState('');
  // const [jobTitle, setjobTitle] = React.useState('');

  const [communicationMode, setCommunicationMode] = React.useState('');

  const [days, setDays] = React.useState('');

  const [status, setStatus] = React.useState('');

  const getStatusList = (status) => {
    return status == 'OPEN'
      ? [
        { value: 'OPEN', label: 'OPEN' },
        { value: 'NOT_AVAILABLE', label: 'NOT AVAILABLE' },
      ]
      : status == 'BOOKED'
        ? [{ value: 'BOOKED', label: 'BOOKED' }]
        : [
          { value: 'OPEN', label: 'OPEN' },
          { value: 'NOT_AVAILABLE', label: 'NOT AVAILABLE' },
        ];
  };

  const onConfirmFromTime = React.useCallback(({ hours, minutes }) => {
    setVisibleFromTime(false);
    hours = hours.toString().length > 1 ? hours : '0' + hours;
    minutes = minutes.toString().length > 1 ? minutes : '0' + minutes;
    setFromTime(hours + ':' + minutes);
  }, []);
  const onConfirmToTime = React.useCallback(({ hours, minutes }) => {
    setVisibleToTime(false);
    hours = hours.toString().length > 1 ? hours : '0' + hours;
    minutes = minutes.toString().length > 1 ? minutes : '0' + minutes;
    setToTime(hours + ':' + minutes);
  }, []);

  let fetchData = () => {
    getUserIdentity().then((uIdentity) => {
      if (uIdentity) {
        setUserIdentity(uIdentity);
        if (profileData && Object.keys(profileData).length) {
          // console.log("*****profileData found", profileData);
          // setIsApiDataLoaded(true);
          setProfile(profileData, uIdentity);
        }
      }
    });
  };

  const setProfile = (profileData, uIdentity) => {
    if (profileData.error_message) {
      // console.log("error_message", profileData.error_message);
    } else {
      setAboutMe(profileData.about_me || '');
      setLinkedinId(
        profileData.linkedin_id && profileData.linkedin_id !== 'N/A' ? profileData.linkedin_id : ''
      );
      setJobLevel(profileData.job_level ? profileData.job_level.toString() : '');
      setDepartment(uIdentity.department);
      // setjobTitle(profileData.jobTitle || '');
      const mode = profileData.preferences
        ? profileData.preferences.communication_modes
          ? profileData.preferences.communication_modes
          : []
        : [];
      setCommunicationMode(mode);
      setStatus(
        profileData.mentor ? (profileData.mentor.status ? profileData.mentor.status : 'OPEN') : ''
      );
      const days = profileData.preferences
        ? profileData.preferences.availability
          ? profileData.preferences.availability.days
            ? profileData.preferences.availability.days || []
            : []
          : []
        : [];
      setDays(days);
      setSoftSkills(
        profileData.skills
          ? profileData.skills.soft_skills
            ? profileData.skills.soft_skills
            : []
          : []
      );
      setTools(
        profileData.skills ? (profileData.skills.tools ? profileData.skills.tools : []) : []
      );
      settechnologies(
        profileData.skills
          ? profileData.skills.technologies
            ? profileData.skills.technologies
            : []
          : []
      );
      setKnowledge(
        profileData.skills
          ? profileData.skills.industry_knowledge
            ? profileData.skills.industry_knowledge
            : []
          : []
      );
      setAOE(profileData.area_of_expertize ? profileData.area_of_expertize.split(',') : []);
      setOthers(
        profileData.skills ? (profileData.skills.others ? profileData.skills.others : []) : []
      );
      setFromTime(profileData?.preferences?.availability?.times?.[0]?.from);
      setToTime(profileData?.preferences?.availability?.times?.[0]?.to);
      setLocation(uIdentity.country);
      setIsMentor(profileData.is_mentor ? profileData.is_mentor : false);
      // console.log("Profile edit ", profileData);
    }
  };

  let updateProfile = async () => {
    // setIsLoading(true)
    const userEmailId = userIdentity.email_id;
    // console.log('userEmailId', userEmailId);
    // console.log("location", location);
    // console.log("department", department);
    if (aboutMe && jobLevel && aoe.length) {
      if (fromTime && toTime) {
        const fromHour = fromTime.split(':')[0];
        const toHour = toTime.split(':')[0];
        const fromMinute = fromTime.split(':')[1];
        const toMinute = toTime.split(':')[1];

        // console.log("fromTime", fromTime);
        // console.log("toTime", toTime);
        // console.log("fromMinute", fromMinute);
        // console.log("toMinute", toMinute);
        // console.log("fromHour", fromHour);
        // console.log("toHour", toHour);

        let diff = moment.duration(moment.duration(toTime).subtract(moment.duration(fromTime)));
        // console.log("diff hours", diff.hours());
        // console.log("diff mins", diff.minutes());

        if (toHour < fromHour || (toHour == fromHour && toMinute < fromMinute)) {
          Alert.alert('Bad input', 'Please select To Time greater than From Time');
        } else if (diff.hours() == 0 && diff.minutes() < 30) {
          Alert.alert('Info', 'Availability should be minimum of 30 minutes');
        } else {
          let skills = {
            soft_skills: softSkills,
            tools: tools,
            technologies: technologies,
            industry_knowledge: knowledge,
            others: others,
          };
          let sendRequestParams = {
            data: {
              name: {
                first:
                  userIdentity && userIdentity.email_id
                    ? getName(userIdentity.email_id).firstName
                    : '',
                last:
                  userIdentity && userIdentity.email_id
                    ? getName(userIdentity.email_id).lastName
                    : '',
              },
              department: department,
              location: location,
              about_me: aboutMe,
              // "linkedin_id": linkedinId ? linkedinId : userIdentity && userIdentity["email_id"] ? getName(userIdentity["email_id"]).firstName + getName(userIdentity["email_id"]).lastName : '',
              linkedin_id: linkedinId ? linkedinId : 'N/A',
              job_level: jobLevel,
              area_of_expertize: aoe.join(','),
              skills: skills,
              mentor: {
                status: status ? status : 'OPEN',
                mentoring_skills: skills,
              },
              mentee: {},
              preferences: {
                communication_modes: communicationMode,
                availability: {
                  days: days,
                  times: [
                    {
                      from: fromTime,
                      to: toTime,
                    },
                  ],
                },
              },
              is_mentor: isMentor ? isMentor : false,
            },
          };
          await profileService.updateProfile(userEmailId, sendRequestParams.data);
          // console.log("response", response);
        }
      } else if (!fromTime && !toTime) {
        let skills = {
          soft_skills: softSkills,
          tools: tools,
          technologies: technologies,
          industry_knowledge: knowledge,
          others: others,
        };
        let sendRequestParams = {
          data: {
            name: {
              first:
                userIdentity && userIdentity.email_id
                  ? getName(userIdentity.email_id).firstName
                  : '',
              last:
                userIdentity && userIdentity.email_id
                  ? getName(userIdentity.email_id).lastName
                  : '',
            },
            department: department,
            location: location,
            about_me: aboutMe,
            linkedin_id: linkedinId
              ? linkedinId
              : userIdentity && userIdentity.email_id
                ? getName(userIdentity.email_id).firstName + getName(userIdentity.email_id).lastName
                : '',
            job_level: jobLevel,
            area_of_expertize: aoe.join(','),
            skills: skills,
            mentor: {
              status: status ? status : 'OPEN',
              mentoring_skills: skills,
            },
            mentee: {},
            preferences: {
              communication_modes: communicationMode,
              availability: {
                days: days,
                times: [
                  {
                    from: fromTime,
                    to: toTime,
                  },
                ],
              },
            },
            is_mentor: isMentor ? isMentor : false,
          },
        };

        await profileService.updateProfile(userEmailId, sendRequestParams.data);
      } else if (fromTime && !toTime) {
        Alert.alert('Required', 'Please select To Time');
      } else if (toTime && !fromTime) {
        Alert.alert('Required', 'Please select From Time');
      }
    } else {
      let missingFields = [];
      if (!aboutMe) {
        missingFields.push('About Me');
      }
      if (!jobLevel) {
        missingFields.push('Job Level');
      }
      if (!aoe.length) {
        missingFields.push('Area Of Expertise');
      }

      const alertMessage = `Please enter mandatory fields : ${missingFields}`;
      Alert.alert('Required', alertMessage);
    }
  };

  useEffect(() => {
    if (isFocused) {
      fetchData();
      setActiveStep(0);
    } else {
      setActiveStep('0');
    }
  }, [isFocused]);

  // const scrollHeight = Platform.OS === 'ios' ? 87 : 92;

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: '#212644' }}>
      <Appbar.Header style={{ backgroundColor: '#212644', padding: 5 }}>
        <Appbar.Action icon="keyboard-backspace" onPress={() => navigation.goBack()} color="#ffffff" />
      </Appbar.Header>
      <KeyboardAvoidingView
        behavior={Platform.OS == 'android' ? 'height' : 'padding'}
        // keyboardVerticalOffset={Platform.OS == 'android' ? -550 : -450}
        style={style.keyboard}>
        {/* <ScrollView style={{ maxHeight: (height * (scrollHeight / 100)) }}
                    showsVerticalScrollIndicator={false}
                    showsHorizontalScrollIndicator={false}> */}
        {/* <View style={{ padding: 10, height: "100%", width: "100%" }}> */}
        <View style={style.profileContainer}>
          <AvatarElement path={icon} size={50} />
        </View>

        <ProgressSteps
          progressBarColor={ColorConstant.LIGHT?.PROGRESSSTEP_COLOR}
          activeStepIconColor={ColorConstant.LIGHT?.PROGRESSSTEP_COLOR}
          activeStepIconBorderColor={ColorConstant.LIGHT?.PROGRESSSTEP_COLOR}
          disabledStepIconColor={ColorConstant.LIGHT?.PROGRESSSTEP_COLOR}
          labelColor={ColorConstant.LIGHT?.PROGRESSSTEP_COLOR}
          activeLabelColor={ColorConstant.LIGHT?.PROGRESSSTEP_COLOR}
          completedProgressBarColor='green'
          completedStepIconColor='green'
          activeStep={activeStep}>
          <ProgressStep label='Personal' style={style.progressStepLabel} removeBtnRow={true}>
            <View style={style.info}>
              <TextElement theme={props.theme} style={style.madatory}>
                *Please use comma ( , ) to add values
              </TextElement>
            </View>
            <DividerElement height={15} />
            <View>
              <TextInputElement
                theme={props.theme}
                label='First Name'
                value={
                  userIdentity && userIdentity.email_id
                    ? getName(userIdentity.email_id).firstName
                    : ''
                }
              />
              <DividerElement height={15} />

              <TextInputElement
                theme={props.theme}
                label='Last Name'
                value={
                  userIdentity && userIdentity.email_id
                    ? getName(userIdentity.email_id).lastName
                    : ''
                }
              />
              <DividerElement height={15} />

              <TextInputElement
                theme={props.theme}
                label='Email'
                value={userIdentity && userIdentity.email_id ? userIdentity.email_id : ''}
              />
              <DividerElement height={15} />
            </View>

            <View style={style.jobLevelView}>
              <TextElement theme={props.theme} style={style.textStyle}>
                Select Job Level
                <TextElement theme={props.theme} style={style.madatory}>
                  {' '}
                  *
                </TextElement>
              </TextElement>
              <DropdownElement
                theme={props.theme}
                data={jobLevelList}
                value={jobLevel ? jobLevel : ''}
                onChange={(item) => {
                  setJobLevel(item.value);
                }}
                style={style.dropdown}
                placeholderStyle={style.placeholderStyle}
                selectedTextStyle={style.selectedTextStyle}
                iconStyle={style.iconStyle}
                leftIconStyle={style.icon}
                placeholder={'Select Job Level'}
                maxHeight={340}
              />
            </View>
            <DividerElement height={15} />

            <View>
              {/* <TextElement theme={"DARK"} style={style.textStyle}>About Me<TextElement theme={props.theme} style={style.madatory}> *</TextElement></TextElement> */}
              <TextInputElement
                theme={props.theme}
                // style={{ height: height * (8 / 100) }}
                label='About Me'
                isMandatory={true}
                onChangeText={(aboutMe) => {
                  setAboutMe(aboutMe);
                }}
                value={aboutMe}
                multiline={true}
                numberOfLines={4}
              />
            </View>
            <DividerElement height={15} />
            <View>
              {/* <TextElement theme={"DARK"} style={style.textStyle}>LinkedIn ID</TextElement> */}
              <TextInputElement
                theme={props.theme}
                label='LinkedIn ID'
                placeholder='LinkedIn ID'
                onChangeText={(linkedinId) => {
                  setLinkedinId(linkedinId);
                }}
                value={linkedinId}
              />
            </View>

            <DividerElement height={15} />
            {/* </View> */}
          </ProgressStep>
          <ProgressStep label='Professional' removeBtnRow={true}>
            {/* <View> */}
            <View style={style.info}>
              <TextElement theme={props.theme} style={style.madatory}>
                *Please use comma ( , ) to add values
              </TextElement>
            </View>
            <DividerElement height={15} />

            <View style={style.viewElement}>
              <TextElement theme={props.theme} style={style.textStyle}>
                Enter Area of Expertise
                <TextElement theme={props.theme} style={style.madatory}>
                  {' '}
                  *
                </TextElement>
              </TextElement>
              <TagInput
                value={aoe}
                setter={setAOE}
                accessibilityLabel={'Enter Area of Expertise'}
              />
            </View>

            <DividerElement height={15} />

            <View style={style.viewElement}>
              <TextElement theme={props.theme} style={style.textStyle}>
                Enter Soft Skills
              </TextElement>
              <TagInput
                value={softSkills}
                setter={setSoftSkills}
                accessibilityLabel={'Enter Soft Skills'}
              />
            </View>
            <DividerElement height={15} />

            <View style={style.viewElement}>
              <TextElement theme={props.theme} style={style.textStyle}>
                Enter Tools
              </TextElement>
              <TagInput value={tools} setter={setTools} accessibilityLabel={'Enter Tools'} />
            </View>
            <DividerElement height={15} />

            <View style={style.viewElement}>
              <TextElement theme={props.theme} style={style.textStyle}>
                Enter Technologies
              </TextElement>
              <TagInput
                value={technologies}
                setter={settechnologies}
                accessibilityLabel={'Enter Technologies'}
              />
            </View>
            <DividerElement height={15} />

            <View style={style.viewElement}>
              <TextElement theme={props.theme} style={style.textStyle}>
                Enter Industry Knowledge
              </TextElement>
              <TagInput
                value={knowledge}
                setter={setKnowledge}
                accessibilityLabel={'Enter Industry Knowledge'}
              />
            </View>
            <DividerElement height={15} />

            <View style={style.viewElement}>
              <TextElement theme={props.theme} style={style.textStyle}>
                Enter Other Info
              </TextElement>
              <TagInput value={others} setter={setOthers} accessibilityLabel={'Enter Other Info'} />
            </View>
            <DividerElement height={15} />

            {/* </View> */}
          </ProgressStep>
          <ProgressStep
            label='Preferences'
            removeBtnRow={true}
            onSubmit={() => {
              updateProfile();
            }}>
            {/* <View> */}
            <View style={style.viewElement}>
              <TextElement theme={props.theme} style={style.textStyle}>
                Select Days
              </TextElement>
              <MultiSelectElement
                // placeholder={"Select Days"}
                accessibilityLabel={'Select Days'}
                theme={props.theme}
                data={daysList}
                value={days}
                onChange={(item) => {
                  setDays(item);
                }}
                style={style.dropdown}
                placeholderStyle={style.placeholderStyle}
                selectedTextStyle={style.selectedTextStyle}
                iconStyle={style.iconStyle}
                leftIconStyle={style.icon}
                selectedStyle={style.selectedStyle}
                maxHeight={340}
              />
            </View>
            <DividerElement height={15} />

            <View style={style.viewElement}>
              <TextElement theme={props.theme} style={style.textStyle}>
                Select Status
              </TextElement>
              <DropdownElement
                placeholder={'Select Status'}
                theme={props.theme}
                data={getStatusList(status)}
                value={status}
                onChange={(item) => {
                  setStatus(item.value);
                }}
                style={style.dropdown}
                placeholderStyle={style.placeholderStyle}
                selectedTextStyle={style.selectedTextStyle}
                iconStyle={style.iconStyle}
                leftIconStyle={style.icon}
                maxHeight={115}
              />
            </View>
            <DividerElement height={15} />

            <View style={style.viewElement}>
              <TextElement theme={props.theme} style={style.textStyle}>
                Select Communication Modes
              </TextElement>
              <MultiSelectElement
                // placeholder={"Select Communication Modes"}
                accessibilityLabel={'Select Communication Modes'}
                theme={props.theme}
                data={commList}
                value={communicationMode}
                onChange={(item) => {
                  setCommunicationMode(item);
                }}
                style={style.dropdown}
                placeholderStyle={style.placeholderStyle}
                selectedTextStyle={style.selectedTextStyle}
                iconStyle={style.iconStyle}
                leftIconStyle={style.icon}
                selectedStyle={style.selectedStyle}
                maxHeight={115}
              />
            </View>
            <DividerElement height={15} />

            <View>
              <TimePickerModal
                visible={visibleFromTime}
                onDismiss={onDismissFromTime}
                onConfirm={onConfirmFromTime}
                label='Select from time'
              />
              <View style={style.fromToTimeView}>
                <TouchableElement
                  style={style.fromToTime}
                  onPress={() => {
                    setVisibleFromTime(true);
                  }}
                  id='from-time'>
                  <TextElement label='From Time' theme={props.theme} style={style.textStyle}>
                    From Time : {getTimeWithMode(fromTime)}
                  </TextElement>
                </TouchableElement>
              </View>
            </View>

            <DividerElement height={15} />

            <View>
              <TimePickerModal
                visible={visibleToTime}
                onDismiss={onDismissToTime}
                onConfirm={onConfirmToTime}
                label='Select to time'
              />
              <View style={style.fromToTimeView}>
                <TouchableElement
                  style={style.fromToTime}
                  onPress={() => {
                    setVisibleToTime(true);
                  }}
                  id='to-time'>
                  <TextElement label='To Time' theme={props.theme} style={style.textStyle}>
                    To Time : {getTimeWithMode(toTime)}
                  </TextElement>
                </TouchableElement>
              </View>
              <DividerElement height={5} />
            </View>

            <DividerElement height={15} />
            <View style={style.toggleView}>
              <ToggleElement
                label='toggle'
                theme={props.theme}
                isMentor={isMentor}
                handleToggle={handleToggle}></ToggleElement>
              <TextElement theme={'DARK'}> Are you interested to be Mentor?</TextElement>
            </View>
            <DividerElement height={15} />

            {/* </View> */}
          </ProgressStep>
        </ProgressSteps>
        <View>
          {activeStep == 0 ? (
            <View style={style.nextButton1}>
              <ButtonElement
                testId='profile-next'
                mode='contained'
                onPress={() => {
                  setActiveStep(activeStep + 1);
                }}
                color={ColorConstant.LIGHT?.BUTTON_BACKGROUND_COLOR}
                style={style.button}>
                Next
              </ButtonElement>
            </View>
          ) : (
            <View style={style.buttonView}>
              <View style={style.previousButton}>
                <ButtonElement
                  testId='profile-prvious'
                  mode='contained'
                  onPress={() => {
                    setActiveStep(activeStep - 1);
                  }}
                  color={ColorConstant.LIGHT?.BUTTON_BACKGROUND_COLOR}
                  style={style.button}>
                  Previous
                </ButtonElement>
              </View>
              <View style={style.nextButton}>
                <ButtonElement
                  testId={activeStep == 2 ? 'profile-submit' : 'profile-next'}
                  mode='contained'
                  onPress={() => {
                    if (activeStep == 2) {
                      updateProfile();
                    } else {
                      setActiveStep(activeStep + 1);
                    }
                  }}
                  color={ColorConstant.LIGHT?.BUTTON_BACKGROUND_COLOR}
                  style={style.button}>
                  {activeStep == 2 ? 'Submit' : 'Next'}
                </ButtonElement>
              </View>
            </View>
          )}
        </View>
        <DividerElement height={15} />
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};

export default memo(ProfileEdit, (prevProps, nextProps) => {
  if (prevProps.theme !== nextProps.theme) return false;
  else return true;
});
