import { KeyboardAvoidingView, ScrollView, View } from 'react-native';
import { allContentTypes, assessmentOptions, assessmentQuestionTypes, filteredContentTypes, loungeLink } from './Metadata';
import { useEffect, useState } from 'react';

import Alert from '../../elements/Alert';
import { Avatar } from 'react-native-paper';
import Button from '../../elements/Button';
import Dropdown from './Dropdown';
import Editor from '../../elements/Editor/';
import TextElement from '../../elements/Text';
import TextInputElement from '../../elements/TextInput';
import TouchableElement from '../../elements/TouchableElement';
import ViewWithBack from '../../elements/ViewWithBack';
import date from 'date-and-time';
import { getUserIdentity } from '../../../utils/UserIdentity';
import style from './styles/addContentStyle';
import { useIsFocused } from '@react-navigation/native';

const Divider = (props) => {
    return (
        <View style={{ ...style.divider, height: 5 || props.height }}></View>
    );
};

const SingleSelect = (props) => {
    const obj = { ...props.data };
    return (
        <View style={style.singleSelectContainer}>
            <Divider />
            <TextInputElement theme="LIGHT" label="Question" value={obj.question} reloadData={obj} multiline={true} numberOfLines={4}
                onChange={(e) => {
                    // setQuestion(e);
                    let tmp = { ...obj };
                    tmp.question = e;
                    props.updateAssessment(props.index, tmp);
                }}
            />
            <Divider />
            <View style={style.rowContainer}>
                <TouchableElement
                    style={style.option}
                    id='a'
                >
                    <TextInputElement theme="LIGHT" label="Option A" reloadData={obj} value={obj.options[0]} onChange={(e) => {
                        let tmp = { ...obj };
                        tmp.options[0] = e;
                        props.updateAssessment(props.index, tmp);
                    }} />
                </TouchableElement>
                <TouchableElement
                    style={style.option}
                    id='b'
                >
                    <TextInputElement value={obj.options[1]} reloadData={obj} theme="LIGHT" label="Option B" onChange={(e) => {
                        let tmp = { ...obj };
                        tmp.options[1] = e;
                        props.updateAssessment(props.index, tmp);
                    }} />
                </TouchableElement>
            </View>
            <Divider height={2.5} />
            <View style={style.rowContainer}>
                <TouchableElement
                    style={style.option}
                    id='c'
                >
                    <TextInputElement value={obj.options[2]} reloadData={obj} theme="LIGHT" label="Option C" onChange={(e) => {
                        let tmp = { ...obj };
                        tmp.options[2] = e;
                        props.updateAssessment(props.index, tmp);
                    }} />
                </TouchableElement>
                <TouchableElement
                    style={style.option}
                    id='d'
                >
                    <TextInputElement value={obj.options[3]} reloadData={obj} theme="LIGHT" label="Option D" onChange={(e) => {
                        let tmp = { ...obj };
                        tmp.options[3] = e;
                        props.updateAssessment(props.index, tmp);
                    }} />
                </TouchableElement>
            </View>
            <Divider />
            <Dropdown
                data={assessmentOptions.singleSelect}
                onChange={(d, index) => {
                    let tmp = { ...obj };
                    if (tmp.options.filter(option => option == '').length) {
                        Alert.alert('Required', 'Please enter options first to select correct anwser');
                    } else {
                        tmp.answer = d;
                        tmp.answerIndex = index;
                        props.updateAssessment(props.index, tmp);
                    }
                }}
                value={obj.answer}
                label="Correct Answer"
            />
        </View>
    );
};

const TrueFalse = (props) => {
    let obj = props.data;
    let tmp = { ...obj };
    return (
        <View>
            <Divider />
            <TextInputElement theme="LIGHT" label="Question" multiline={true} numberOfLines={4} value={obj.question} onChange={(e) => {
                tmp.question = e;
                props.updateAssessment(props.index, tmp);
            }}
            />
            <Divider />
            <Dropdown
                data={assessmentOptions.trueFalse}
                onChange={(d, index) => {
                    tmp.answer = d;
                    tmp.answerIndex = index;
                    tmp.options = assessmentOptions.trueFalse;
                    props.updateAssessment(props.index, tmp);
                }}
                value={obj.answer}
                label="Correct Answer"
            />
        </View>
    );
};


const Assessment = (props) => {
    // console.log("assesssment", props);
    const { content, data, setData, isApiDataLoaded, setUnsavedChanges } = props;
    useEffect(() => {
        if (isApiDataLoaded) {
            if (content) {
                setData(content);
            }
        }
    }, [isApiDataLoaded]);

    const updateAssessment = (index, questionObj) => {
        // console.log("index", index, "questionObj", questionObj);
        let temp = [...data];
        temp[index] = questionObj;
        setUnsavedChanges(true);
        setData(temp);
    };

    const addNextQuestion = () => {
        const result = validateAssessmentQuestion();
        if (result) {
            let temp = [...data];
            temp.push({
                'type': 'True/False',
                'question': '',
                'options': ['', '', '', ''],
                'answer': ''
            });
            setData(temp);
            setUnsavedChanges(true);
        }
    };

    const validateAssessmentQuestion = () => {
        if (data.length) {
            let missingAssessmentFields = [];
            const { type, question, options, answer } = data[data.length - 1];
            if (type == '') {
                missingAssessmentFields.push('Type');
            }
            if (question == '') {
                missingAssessmentFields.push('Question');
            }
            if (type != 'True/False' && options.filter(option => option == '').length) {
                missingAssessmentFields.push('Options');
            }
            if (answer == '') {
                missingAssessmentFields.push('Correct Answer');
            }
            if (missingAssessmentFields.length) {
                const alertMessage = `Please enter mandatory fields for Assessment: ${missingAssessmentFields}`;
                Alert.alert('Required', alertMessage);
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    };

    return (
        <View>
            {
                isApiDataLoaded ?
                    <View>
                        {data.map((d, index) => {
                            // console.log("d.type", d.type)
                            return (
                                <View key={index}>
                                    <Divider />
                                    <Dropdown
                                        // data={["Single Choice", "True/False", "Multiple Choice", "Yes/No"]}
                                        data={assessmentQuestionTypes}
                                        onChange={(e) => {
                                            // console.log("onchange", e, index);
                                            if (e != d.type) {
                                                let temp = [...data];

                                                temp[index] = {
                                                    'type': e,
                                                    'question': '',
                                                    'options': ['', '', '', ''],
                                                    'answer': ''
                                                };
                                                // console.log("temp after", temp[index]);
                                                setData(temp);
                                            }
                                        }}
                                        value={d.type}
                                        label="Select type of question"
                                    />
                                    {d.type === 'Single Choice' ? (
                                        <SingleSelect {...props} index={index} updateAssessment={updateAssessment} data={d} />
                                    ) : <View></View>}

                                    {d.type === 'True/False' ? (
                                        <TrueFalse {...props} index={index} updateAssessment={updateAssessment} data={d} />
                                    ) : <View></View>}
                                </View>

                            );
                        })}
                        <Divider />
                        <TouchableElement
                            onPress={addNextQuestion}
                            id='plus'
                        >
                            <View style={style.nextQuestion}>
                                <Avatar.Icon
                                    icon="plus"
                                    size={25}
                                    color="#ffffff"
                                    style={style.icon}
                                />
                                <TextElement theme="DARK" style={style.nextQuestionButton}>Question</TextElement>
                            </View>
                        </TouchableElement>
                    </View>
                    : <View></View>
            }
        </View>
    );
};

const ContentBox = (props) => {
    const { contentType, content, setContent, setUnsavedChanges } = props;
    // console.log("contentType", contentType);
    return (
        contentType == 'Content' ?
            <View style={{ minHeight: 550, backgroundColor: '#ffffff' }}>
                <Editor
                    setValue={setContent}
                    value={content}
                />
            </View >
            // <TextInputElement
            //     theme="LIGHT"
            //     label="Content"
            //     allowedChars={['\'']}
            //     multiline={true}
            //     numberOfLines={15}
            //     onChange={(txt) => {
            //         setUnsavedChanges(true);
            //         setContent(txt);
            //     }}
            //     value={content}
            // />
            : contentType == 'Link' ?
                <TextInputElement
                    theme="LIGHT"
                    label="Link"
                    onChange={(txt) => {
                        setUnsavedChanges(true);
                        setContent(txt);
                    }}
                    value={content}
                    placeholder={loungeLink}
                // allowedChars ={'-_/:=+;@$()#'}
                /> : contentType == 'Assessment' ?
                    <Assessment setUnsavedChanges={setUnsavedChanges} {...props} />
                    : <View></View>
    );
};

const validateAllAssessmentQuestions = (data) => {
    if (data.length) {
        const result = data.map((questionObject) => {
            // console.log("questionObject", questionObject, "index", index);
            const { type, question, options, answer } = questionObject;
            let missingAssessmentFields = [];
            if (type == '') {
                missingAssessmentFields.push('Type');
            }
            if (question == '') {
                missingAssessmentFields.push('Question');
            }
            if (type != 'True/False' && options.filter(option => option == '').length) {
                missingAssessmentFields.push('Options');
            }
            if (answer == '') {
                missingAssessmentFields.push('Correct Answer');
            }

            if (missingAssessmentFields.length) {
                const alertMessage = `Please enter mandatory fields for Assessment: ${missingAssessmentFields}`;
                Alert.alert('Required', alertMessage);
                return false;
            } else {
                return true;
            }
        });
        if (result.includes(false)) {
            return false;
        } else {
            return true;
        }
    } else {
        // return true;
        Alert.alert('Required', 'Please add at least one Assessment Question');
        return false;
    }
};

const AddContent = (parentProps) => {
    const props = { ...parentProps, ...parentProps.route.params };
    // console.log("add content props", props);
    const { subTopic, setSubTopic, type, updateIndex } = props.route.params;
    // console.log('subTopic', subTopic);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [contentType, setContentType] = useState('');
    const [content, setContent] = useState('');
    const [data, setData] = useState([]);
    const [userIdentity, setUserIdentity] = useState({});
    const isFocused = useIsFocused();
    const [isApiDataLoaded, setIsApiDataLoaded] = useState(false);
    const [unsavedChanges, setUnsavedChanges] = useState(false);

    const checkAssessmentPresent = () => {
        let result = false;
        if (subTopic && subTopic.length) {
            subTopic?.forEach((subtopic) => {
                if (subtopic?.content_type == 'Assessment') {
                    result = true;
                }
            });
        }
        // console.log('result', result);
        return result;
    };

    const [assessmentAdded] = useState(checkAssessmentPresent() || false);
    // console.log("assessmentAdded", assessmentAdded);
    const contentTypes = assessmentAdded ? filteredContentTypes : allContentTypes;
    // console.log("contentTypes", contentTypes);

    let contentToAdd = {
        'name': name,
        'description': description,
        'created_by': userIdentity?.email_id,
        'created_at': date.format(new Date(), 'YYYYMMDD'),
        'is_deleted': false,
        'content': contentType == 'Assessment' ? data : content,
        'content_type': contentType,
    };

    const contentBoxProps = {
        contentType,
        content,
        setContent,
        data,
        setData,
        type,
        isApiDataLoaded,
        setUnsavedChanges,
        parentProps: props
    };
    // console.log("contentToAdd", contentToAdd);

    const submitContent = () => {
        const result = validateContent(contentToAdd);
        if (result) {
            if (type == 'Create') {
                // console.log("create content called", subTopic);
                setSubTopic([...subTopic, contentToAdd]);
            }
            if (type == 'Update') {
                // console.log("update content called", subTopic);
                let temp = subTopic;
                temp[updateIndex] = contentToAdd;
                setSubTopic([...temp]);
            }
            props.navigation.navigate('Addtopic', { backAction: true });
        }
    };

    const validateContent = (contentToAdd) => {
        // console.log("contentToAdd:", contentToAdd);
        const { name, content_type, content } = contentToAdd;
        let missingFields = [];
        if (!name) {
            missingFields.push('Content Title');
        }
        if (!content_type) {
            missingFields.push('Content Type');
        }
        if (!content) {
            if (contentType) {
                missingFields.push(contentType);
            } else {
                missingFields.push('Content');
            }
        }
        if (missingFields.length) {
            // console.log("name",name)
            // console.log("content_type",content_type)
            // console.log("content",content)
            const alertMessage = `Please enter mandatory field: ${missingFields}`;
            Alert.alert('Required', alertMessage);
            return false;
        }
        else if (content_type == 'Assessment') {
            const result = validateAllAssessmentQuestions(content);
            // console.log("validateAllAssessmentQuestions", result);
            return result;
        }
        // else if (subTopic.map(topic => topic.name).includes(contentName)) {
        //     const alertMessage = "Content Name already exists";
        //     props.setAlertProperties(true, alertMessage, () => { }, "Bad input");
        // }
        else {
            return true;
        }
    };


    const fetchData = async () => {
        const uIdentity = await getUserIdentity();
        setUserIdentity(uIdentity);
        if (type == 'Update') {
            // console.log("content array", subTopic);
            const contentToEdit = subTopic[updateIndex];
            // console.log("clicked on content", contentToEdit);
            const { name, description, content_type, content } = contentToEdit;
            setName(name);
            setDescription(description);
            setContentType(content_type);
            setContent(content);
            setIsApiDataLoaded(true);
        } else if (type == 'Create') {
            setIsApiDataLoaded(true);
        }
    };

    // console.log("contentToAdd ", contentToAdd);

    useEffect(() => {
        fetchData();
    }, [isFocused]);

    return (
        <ViewWithBack pageName={type == 'Create' ? 'Create Content' : 'Update Content'}  {...props} backAction={() => {
            if (unsavedChanges) {
                Alert.confirm('Confirm', 'Are you sure you want to discard the changes?', () => { props.navigation.navigate('Addtopic', { backAction: true }); });
            } else {
                props.navigation.navigate('Addtopic', { backAction: true });
            }
        }} >
            <View style={style.container}>
                <View style={style.container1}>
                    <ScrollView
                        showsVerticalScrollIndicator={false}
                        showsHorizontalScrollIndicator={false}
                    >
                        <KeyboardAvoidingView behavior="position" style={style.keyboard}>
                            <View style={style.contentContainer}>
                                <View style={style.divider5}></View>
                                <TextInputElement
                                    theme="LIGHT"
                                    label="Content Title"
                                    value={name}
                                    onChange={(name) => {
                                        // if (subTopic.map(topic => topic.name).includes(name)) {
                                        //     // console.log("alertMessage", name);
                                        //     const alertMessage = "Content Name already exists!";
                                        //     setName(name.trim());
                                        //     props.setAlertProperties(true, alertMessage, () => { }, "Bad input");
                                        // } else {
                                        setName(name);
                                        setUnsavedChanges(true);
                                        // }
                                    }}
                                />
                                <View style={style.divider5}></View>
                                {/* <TextInputElement
                                    theme="LIGHT"
                                    label="Short Description"
                                    multiline={true}
                                    numberOfLines={2}
                                    value={description}
                                    onChange={(txt) => { setDescription(txt); setUnsavedChanges(true); }}
                                /> */}
                                <View style={style.divider5}></View>
                                <Dropdown
                                    data={contentTypes}
                                    onChange={(txt) => {
                                        setContentType(txt);
                                        setContent('');
                                        setUnsavedChanges(true);
                                    }}
                                    value={contentType}
                                    label="Content Type"
                                />
                                <View style={style.divider5}></View>
                                <ContentBox {...contentBoxProps} />
                                <View style={style.divider10}></View>
                                <View style={style.submit}>
                                    <Button style={style.submitButton} onPress={submitContent}>Submit</Button>
                                </View>
                                <View style={style.bottomSpace}></View>
                            </View>
                        </KeyboardAvoidingView>
                    </ScrollView>
                </View>
            </View>
        </ViewWithBack>
    );
};

export default AddContent;
