import { Button, Modal, Portal } from 'react-native-paper';

import { Avatar } from 'react-native-paper';
import TextElement from '../elements/Text';
import TouchableElement from '../elements/TouchableElement';
import { View } from 'react-native';
import ViewElement from '../elements/ViewWIthScroll';
import style from './Style';
import { useState } from 'react';

const Notifications = [
    {
        id: 'uuid',
        title: 'Survey',
        description: 'This is a dummy notification screen where we need to show description.',
        created_at: '17/03/2023',
        created_by: 'John.Doe@travelex.com'
    },
    {
        id: 'uuid',
        title: 'Survey',
        description: 'This is a dummy notification screen where we need to show description.',
        created_at: '17/03/2023',
        created_by: 'John.Doe@travelex.com'
    },
    {
        id: 'uuid',
        title: 'Survey',
        description: 'This is a dummy notification screen',
        created_at: '17/03/2023',
        created_by: 'John.Doe@travelex.com'
    }
];

const CustomModal = (props) => {
    const hideModal = () => props.setVisible(false);
    const containerStyle = { padding: 20, display: 'flex', alignItems: 'center', justifyContent: 'center' };
    return (
        <Portal>
            <Modal visible={props.visible} onDismiss={hideModal} contentContainerStyle={containerStyle}>
                <View style={{ backgroundColor: '#ffffff', padding: 15, borderRadius: 10 }}>
                    <TextElement>
                        {props.modalData.title}
                    </TextElement>
                    <TextElement style={{ fontSize: 12 }}>
                        {props.modalData.description}
                    </TextElement>
                    <TextElement style={{ fontSize: 10, color: '#CDCBCB' }}>
                        Received at : {props.modalData.created_at}
                    </TextElement>
                    <Button style={{ marginTop: 30 }} onPress={hideModal} color="#48B2EB">
                        Close
                    </Button>
                </View>
            </Modal>
        </Portal>
    );
};





export default (parentProps) => {
    const props = { ...parentProps, ...parentProps.route.params };
    const [visible, setVisible] = useState(false);
    const [modalData, setModalData] = useState({});
    const rowClick = (data) => {
        setModalData(data);
        setVisible(true);
    };
    return (
        <ViewElement pageName="Notifications" {...props} header={true} innerColor="#FAFAFA">
            <View style={style.viewRow}>
                {
                    Notifications.map((n, index) => {
                        return (

                            <TouchableElement
                                key={index}
                                style={style.row}
                                onPress={() => rowClick(n)}
                                id={'notification' + index}
                            >
                                <Avatar.Icon icon="email-outline" color="#000000" style={style.icon} size={45} />
                                <View style={{ width: '90%' }}>
                                    <TextElement style={style.title}>{n.title}</TextElement>
                                    <TextElement>{n.description}</TextElement>
                                    <View style={style.created_at}>
                                        <TextElement style={style.created}>{n.created_at}</TextElement>
                                    </View>
                                </View>
                            </TouchableElement>
                        );
                    })
                }
            </View>
            <CustomModal {...{ visible, setVisible, modalData }} />
        </ViewElement>
    );
};