import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  rowContainer: { flexDirection: 'row' },
  textInputContainer: { flex: 1, marginLeft: 5, marginRight: 5 },
  icon: { backgroundColor: 'transparent' },
  completionDateTextInput: { width: '90%' },
  completionDateTouchable: { width: '100%' },
  completionDateView: {
    marginLeft: 5,
    marginRight: 5,
    padding: 10,
    flexDirection: 'column',
    backgroundColor: '#f7f7f7',
    gap: 8,
  },
  progressView: {
    padding: 15,
    backgroundColor: '#f5f5f5',
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    marginLeft: 5,
    marginRight: 5,
  },
  progressBar: { height: 20, borderRadius: 5 },
  statusText: {
    padding: 10,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#f5f5f5',
    marginLeft: 5,
    marginRight: 5,
    paddingBottom: 0
  },
  dropwdown: {
    backgroundColor: '#f5f5f5',
    marginLeft: 5,
    marginRight: 5
  }
});
