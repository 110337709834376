import { KeyboardAvoidingView, ScrollView, View } from 'react-native';
import { Modal, Portal, Provider } from 'react-native-paper';
import { useEffect, useState } from 'react';

import Alert from '../../elements/Alert';
import { Avatar } from 'react-native-paper';
import ButtonElement from '../../elements/Button';
import CardScrollView from '../learner/CardScrollView';
import ColorConstant from '../../../utils/ColorConstant.json';
import DesignerService from '../../../service/DesignerService';
import TextElement from '../../elements/Text';
import TextInputElement from '../../elements/TextInput';
import ViewWithBack from '../../elements/ViewWithBack';
import { getDate } from '../../utils/utils';
import { getUserIdentity } from '../../../utils/UserIdentity';
import style from './styles/reviewJourneyStyle';
import { useIsFocused } from '@react-navigation/native';
import { useSelector } from 'react-redux';

const getName = require('../../../utils/NameMapping').getName;

const ReviewJourney = (parentProps) => {
  const props = { ...parentProps, ...parentProps.route.params };
  // console.log("ReviewJourney props", props)
  // console.log("REVIEW JOURNEY props", props);
  const { type, journeyId } = props.route.params;
  // console.log("type", type, "journeyId", journeyId);
  // const designerJourneys = useSelector((state) => state.designerJourneys.value);
  const allJourneys = useSelector((state) => state.designerJourneys.allJourneys);
  const journeyToUpdate = allJourneys.filter((journey) => journey.journey_id == journeyId)[0];

  const [journeyName, setJourneyName] = useState('');
  const [journeyDesc, setJourneyDesc] = useState('');
  const [icon, setIcon] = useState('Security');
  const [color, setColor] = useState('Blue');
  const [status, setStatus] = useState('drafted');
  // const [tags, setTags] = useState([]);
  const [createdBy, setCreatedBy] = useState('');
  const [createdOn, setCreatedOn] = useState('');

  const [userIdentity, setUserIdentity] = useState({});
  const isFocused = useIsFocused();
  const [isApiDataLoaded, setIsApiDataLoaded] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [topics, setTopics] = useState([]);
  const [isTopicApiDataLoaded, setIsTopicApiDataLoaded] = useState(false);

  // const [show, setShow] = useState(false);
  const [dialogComponent, setDialogComponent] = useState(<View />);
  // const handleShow = () => setShow(true);

  const [history, setHistory] = useState([]);

  const designerService = new DesignerService(props);

  // console.log("topics", topics);
  const fetchData = async () => {
    getUserIdentity().then((uIdentity) => {
      setUserIdentity(uIdentity);
    });

    if (type == 'Update') {
      // console.log("fetchdata", type, journeyToUpdate, unsavedChanges);
      getHistory();
      const {
        background_color,
        created_at,
        created_by,
        description,
        display_name,
        icon,
        journey_status,
      } = journeyToUpdate;

      designerService.getTopics(journeyToUpdate, isTopicApiDataLoaded).then((response) => {
        setIsTopicApiDataLoaded(true);
        setTopics(response);
      });

      if (journeyToUpdate) {
        // console.log("journeyToUpdate found!!", journeyToUpdate);
        setIsApiDataLoaded(true);
      }
      setJourneyName(display_name);
      setJourneyDesc(description);
      setIcon(icon);
      setColor(background_color);
      setStatus(journey_status);
      setCreatedBy(created_by);
      setCreatedOn(created_at);
      // setTags(tags)
    }
  };

  useEffect(() => {
    if (isFocused) {
      fetchData();
    }
    // else {
    //     flush();
    // }
  }, [isFocused]);

  const journey = {
    created_by: createdBy ? createdBy : userIdentity?.email_id,
    created_at: createdOn,
    description: journeyDesc,
    display_name: journeyName,
    icon: icon,
    is_deleted: false,
    journey_status: status,
    // tags: tags.join(),
    background_color: color,
  };
  // console.log("journey", journey)

  const getUpdatedJourney = () => {
    journey.space_id = journeyToUpdate.space_id;
    journey.id = journeyToUpdate.id;
    journey.journey_id = journeyId;
    // console.log("journey before getUpdatedJourney", journey);
    for (let key of Object.keys(journeyToUpdate)) {
      if (
        journeyToUpdate[key] === journey[key] &&
        !['space_id', 'id', 'journey_id'].includes(key)
      ) {
        delete journey[key];
      }
    }
    // console.log("journey after getUpdatedJourney", journey);
  };

  const publishJourney = () => {
    setUnsavedChanges(false);
    if (!topics.length) {
      Alert.alert('Whoops!', 'Please add topics before publishing the journey');
    } else {
      let alertMessage = '';
      if (userIdentity.user_role == 'admin') {
        if (status == 'review') {
          alertMessage = 'Journey approved';
        } else {
          alertMessage = 'Journey published';
        }
        journey.journey_status = 'published';
      }
      // console.log("journey publishJourney", journey);
      getUpdatedJourney();
      designerService.updateJourney(journey, isApiDataLoaded).then(() => {
        // console.log("publishJourney response", response, userIdentity.user_role);
        setIsApiDataLoaded(true);
        if (userIdentity.user_role == 'admin') {
          setStatus('published');
        }
        Alert.alert('Success', alertMessage);
        props.navigation.navigate('DesignerJourney');
      });
    }
  };

  const declineJourney = (reasonToDecline) => {
    let missingFields = [];
    if (!reasonToDecline.length) {
      missingFields.push('reason to decline');
      // console.log("here", missingFields);
      setDialogComponent(<View />);
      const alertMessage = `Please enter ${missingFields}`;
      Alert.alert('Required', alertMessage);
    } else {
      setUnsavedChanges(false);
      setDialogComponent(<View />);
      const alertMessage = 'Journey declined';
      journey.journey_status = 'rejected';
      journey.reason_to_reject = reasonToDecline;
      // console.log("journey declineJourney", journey);
      getUpdatedJourney();
      const journeyHistory = {
        comments: reasonToDecline,
      };
      // console.log("journeyHistory", journeyHistory);
      designerService.saveJourneyHistory(journey, journeyHistory, isApiDataLoaded).then(() => {
        // console.log("saveJourneyHistory response", response, userIdentity.user_role);
        setIsApiDataLoaded(true);
        designerService.updateJourney(journey, isApiDataLoaded).then(() => {
          // console.log("declineJourney response", response, userIdentity.user_role);
          setIsApiDataLoaded(true);
          Alert.alert('Success', alertMessage);
          setStatus('rejected');
          props.navigation.navigate('DesignerJourney');
        });
      });
    }
  };

  const getHistory = () => {
    // const journeyId = journeyToUpdate.id + '#' + journeyToUpdate.name;
    // console.log("journeyId-----designer Home", journeyId);
    designerService.showJourneyHistory(journeyToUpdate, isApiDataLoaded).then((response) => {
      // console.log("showJourneyHistory response", response, userIdentity.user_role);
      if (response.length) {
        setHistory(response);
      }
      setIsApiDataLoaded(true);
    });
  };
  // console.log("history", history);

  const component = (
    <Dialog
      show={true}
      title='Declining Journey'
      hintInput='Enter comments'
      handleClose={() => {
        setDialogComponent(<View />);
      }}
      declineJourney={declineJourney}
      {...props}
    />
  );

  const cardScrollViewProps = {
    ...props,
    data: [journeyToUpdate],
    isApiDataLoaded: true,
    type: 'Designer',
    publishJourney: publishJourney,
    setDialogComponent: setDialogComponent,
    component: component,
  };

  return (
    <Provider>
      <ViewWithBack
        pageName={'Review Journey'}
        {...props}
        backAction={() => {
          if (unsavedChanges) {
            Alert.confirm('Confirm', 'Are you sure you want to discard the changes?', () => {
              props.navigation.goBack();
            });
          } else {
            props.navigation.goBack();
          }
        }}>
        <View style={style.reviewJourneyView}>
          <KeyboardAvoidingView behavior='position' style={style.heightWeight100}>
            <View style={style.cardContainer}>
              {dialogComponent}
              <CardScrollView {...cardScrollViewProps} />
            </View>
            <ScrollView showsVerticalScrollIndicator={false} showsHorizontalScrollIndicator={false}>
              {isApiDataLoaded ? (
                <View style={style.commentsContainer}>
                  <TextElement style={style.commentsHeader}>Recent Comments</TextElement>
                  <View>
                    {history.length ? (
                      history.map((obj, index) => {
                        return (
                          <View key={index} style={{ ...style.historyContainer }}>
                            <TextElement
                              style={style.header}
                              theme={props.trans ? 'DARK' : 'LIGHT'}>
                              Reason:{obj.comments}
                            </TextElement>
                            <TextElement
                              style={style.textStyle}
                              theme={props.trans ? 'DARK' : 'LIGHT'}>
                              Created by {obj.created_by ? getName(obj.created_by) : '-'} on{' '}
                              {obj.created_at ? getDate(obj.created_at) : '-'}
                            </TextElement>
                          </View>
                        );
                      })
                    ) : (
                      <View style={style.noResultContainer}>
                        <Avatar.Icon
                          icon='email-open-outline'
                          color='#ADADAD'
                          size={70}
                          style={style.icon}
                        />
                        <TextElement style={style.noResultsText}>No Comments Found!</TextElement>
                      </View>
                    )}
                  </View>
                  <View style={style.bottomSpace}></View>
                </View>
              ) : (
                <View style={style.loadingContainer}>
                  <TextElement style={style.loadingText}>Loading...</TextElement>
                </View>
              )}
            </ScrollView>
          </KeyboardAvoidingView>
        </View>
      </ViewWithBack>
    </Provider>
  );
};

const Dialog = (props) => {
  const { show, title, hintInput, handleClose, declineJourney } = props;
  let remark = '';
  return (
    <Portal>
      <Modal visible={show} onDismiss={handleClose} contentContainerStyle={style.containerStyle}>
        <KeyboardAvoidingView behavior={'padding'} style={style.dialogView}>
          <View style={style.dialogContainer}>
            <TextElement theme={props.theme}>
              {title}
              <TextElement theme={props.theme} style={style.mandatory}>
                {' '}
                *
              </TextElement>
            </TextElement>

            <TextInputElement
              theme={props.theme}
              editable={true}
              placeholder={hintInput}
              maxLength={100}
              onChangeText={(message) => {
                remark = message;
              }}
            />

            <View style={style.dialogButtonRow}>
              <ButtonElement
                theme={props.theme}
                color={ColorConstant[props.theme]?.BUTTON_BACKGROUND_COLOR}
                style={style.button}
                onPress={handleClose}>
                Cancel
              </ButtonElement>
              <ButtonElement
                theme={props.theme}
                color={ColorConstant[props.theme]?.BUTTON_BACKGROUND_COLOR}
                style={style.button}
                onPress={() => {
                  // console.log("remark", remark);
                  declineJourney(remark);
                }}>
                Submit
              </ButtonElement>
            </View>
          </View>
        </KeyboardAvoidingView>
      </Modal>
    </Portal>
  );
};

export default ReviewJourney;
